<div class="row">
    <a class="pull-left"><h4>{{incomingForm.attributes.hasOwnProperty('name') ? incomingForm.getName().toString().toUpperCase() : 'Untitled Form'}}, settings</h4></a>
    <ul class="list-inline pull-right">
        <li>
            <a class="" [matDialogClose]="true" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
        </li>
        <li (click)="updateSettings()">
            <mat-icon><i class="material-icons">call_made</i></mat-icon>
        </li>
    </ul>
    <br><br>
</div>
<div [ngStyle]="{'margin-top': '20px'}">
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="All">
            <br><br>
            <mat-accordion [multi]="true">
                <!--        <mat-expansion-panel [expanded]="true">-->
                <!--            <mat-expansion-panel-header>-->
                <!--                <mat-panel-title>-->
                <!--                    Custom Report-->
                <!--                </mat-panel-title>-->
                <!--                <mat-panel-description>-->
                <!--                    Pick questionnaries dimensions and metrics and decide how they should be displayed to get insight.-->
                <!--                </mat-panel-description>-->
                <!--            </mat-expansion-panel-header>-->
                <!--            <div [ngStyle]="{'margin-top': '10px'}">-->
                <!--                <div> <a mat-mini-fab (click)="addCustomReport()"-->
                <!--                         class="pull-right defaultColor2">-->
                <!--                    <i class="material-icons">add</i>-->
                <!--                </a>-->
                <!--                </div><br><br><br>-->
                <!--                <div [ngStyle]="{'margin-top': '20px'}">-->
                <!--                    <mat-card class="example-card" *ngIf="incomingCustomReportSelected.attributes.hasOwnProperty('title')">-->
                <!--                        <mat-card-title-group>-->
                <!--                            <mat-card-title>-->
                <!--                                <span contentEditable="true"-->
                <!--                                      #titleElement-->
                <!--                                      (input)="afterContentEditable(titleElement.innerText, incomingCustomReportSelected.getAttributes())"-->
                <!--                                      [innerHTML]="incomingCustomReportSelected.getTitle()">-->
                <!--                                </span>-->
                <!--                                <i-->
                <!--                                        class="material-icons cursor"-->
                <!--                                        [ngStyle]="{'margin-left': '5px'}"-->
                <!--                                        (click)="menuStatus = 2; onContextMenu($event, incomingCustomReportSelected.getAttributes())">arrow_drop_down_circle</i>-->
                <!--                            </mat-card-title>-->
                <!--                            <mat-card-subtitle></mat-card-subtitle>-->
                <!--                            <img mat-card-sm-image-->
                <!--                                 class="cursor thumbnail"-->
                <!--                                 [src]="getChartById(incomingCustomReportSelected.getChart()).icon"-->
                <!--                                 (click)="menuStatus = 1 ;onContextMenu($event, incomingCustomReportSelected.getAttributes())"-->
                <!--                                 (contextmenu)="menuStatus === 1; onContextMenu($event, incomingCustomReportSelected.getAttributes())"-->
                <!--                            >-->
                <!--                        </mat-card-title-group>-->
                <!--                        <mat-card-content>-->
                <!--                            <div [ngStyle]="{'margin-top': '10px'}">-->
                <!--                                <mat-form-field  class="example-full-width" appearance="fill">-->
                <!--                                    <mat-label>Include questions</mat-label>-->
                <!--                                    <input type="text"-->
                <!--                                           #customReportElement-->
                <!--                                           placeholder=""-->
                <!--                                           aria-label = "Number"-->
                <!--                                           matInput-->
                <!--                                           [formControl] = "customReportControl"-->
                <!--                                           [matAutocomplete] = "customReport">-->
                <!--                                    <mat-autocomplete #customReport = "matAutocomplete"-->
                <!--                                                      (optionSelected)="onCustomReportQuestionSelected($event, incomingCustomReportSelected.getAttributes()); customReportElement.value = ''"-->
                <!--                                                      [displayWith]="displayFn">-->
                <!--                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >-->
                <!--                                            {{question.label}}-->
                <!--                                        </mat-option>-->
                <!--                                    </mat-autocomplete>-->
                <!--                                    <h4 style="color: red; font-weight: normal"-->
                <!--                                        *ngIf="service.empty(customReportElement.value) === false &&-->
                <!--                                        ((filteredQuestionsOptions | async) | json).length === 2">-->
                <!--                                        **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
                <!--                                </mat-form-field>-->
                <!--                            </div>-->
                <!--                            <div class="default">-->
                <!--                                <mat-list>-->
                <!--                                    <mat-list-item *ngFor="let question of incomingCustomReportSelected.getQuestions(); let questionIncremental = index">-->
                <!--                                        <span class="" mat-line-->
                <!--                                              (click)="menuStatus = 3 ;onContextMenu($event, question)"-->
                <!--                                              (contextmenu)="menuStatus = 3 ;onContextMenu($event, question)"-->
                <!--                                              (mousedown)="incomingCustomReportQuestionSelected.setAttributes(question)">-->
                <!--                                            <span>-->
                <!--                                                {{question.label}}-->
                <!--                                            </span>-->
                <!--                                            <i class="material-icons pull-right cursor" (click)="onCustomReportQuestionRemoved(question)">close</i>-->
                <!--                                            <span class="pull-right" *ngIf="!service.empty(incomingCustomReportSelected.getGroupBy()) && question.cntrl_num.toString().includes(incomingCustomReportSelected.getGroupBy())">-->
                <!--                                                <mat-checkbox-->
                <!--                                                    #customReportcheckedElement-->
                <!--                                                    [checked]="true">-->
                <!--                                                </mat-checkbox>-->
                <!--                                            </span>-->
                <!--                                        </span>-->
                <!--                                    </mat-list-item>-->
                <!--                                    <mat-list-item>-->
                <!--                                        <span class="" mat-line [ngStyle]="{'margin-top': '50px'}">-->
                <!--                                            <textarea-->
                <!--                                                    matInput-->
                <!--                                                    [placeholder]="'Description'"-->
                <!--                                                    (input)="updateCustomReport(incomingCustomReportSelected.getAttributes())"-->
                <!--                                                    [(ngModel)]="incomingCustomReportSelected.attributes.description">-->
                <!--                                            </textarea>-->
                <!--                                        </span>-->
                <!--                                    </mat-list-item>-->
                <!--                                </mat-list>-->
                <!--                            </div>-->
                <!--                        </mat-card-content>-->
                <!--                        <mat-card-actions>-->
                <!--                            &lt;!&ndash;                         <button mat-button>Delete</button>&ndash;&gt;-->
                <!--                        </mat-card-actions>-->
                <!--                    </mat-card>-->
                <!--                </div>-->
                <!--            </div>-->
                <!--        </mat-expansion-panel>-->
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Set criteria
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-tab-group>
                        <mat-tab label="Criteria">
                            <div [ngStyle]="{'margin-top': '10px'}">
                                <div>
                                    <a mat-mini-fab (click)="addCriteria()"
                                       class="pull-right defaultColor2">
                                        <i class="material-icons">add</i></a>
                                </div><br><br>
                                <div [ngStyle]="{'margin-top': '0px'}">
                                    <table [ngStyle]="{'width':'100%'}">
                                        <tr *ngIf="incomingCriteriaSelected.hasAttribute('title')">
                                            <td class="cursor">
                                                <span (click)="menuStatus = 5 ;updateCriteria()">{{incomingCriteriaSelected.getTitle()}}</span>
                                                <span class="cursor"
                                                      [ngStyle]="{'margin-left':'5px'}"
                                                      (click)="menuStatus = 5 ;onContextMenu($event, null)">
                                            <a><i class="material-icons">keyboard_arrow_down</i></a>
                                        </span>
                                                <span class="cursor"
                                                      [ngStyle]="{'margin-left':'5px'}"
                                                      (click)="deleteCriteria()">
                                            <a><i class="material-icons">close</i></a>
                                        </span>
                                                <span class="cursor"
                                                      [ngStyle]="{'margin-left':'5px'}"
                                                      (click)="openSettingsDialog(incomingCriteriaSelected.getAttributes())">
                                            <a><i class="material-icons">settings</i></a>
                                        </span>
                                                <span class="cursor"
                                                      [ngStyle]="{'margin-left':'5px'}"
                                                      (click)="flag= 1; img.click()">
                                            <a><i class="material-icons">photo_camera</i></a>
                                        </span>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline"
                                                                [ngStyle]="{'width':'80px'}">
                                                    <mat-label><span class="headline"></span></mat-label>
                                                    <input type="text"
                                                           placeholder=""
                                                           aria-label = "Number"
                                                           matInput
                                                           autocomplete="off"
                                                           [(ngModel)]="incomingCriteriaSelected.attributes.target"
                                                           (input)="update()"
                                                           [ngStyle]="{'text-align':'center'}"
                                                    />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-slide-toggle
                                                        [checked]="this.incomingCriteriaSelected.getAttributes().hasOwnProperty('trending') && service.parseFloat(this.incomingCriteriaSelected.getTrending()) === 1"
                                                        (change)="($event.checked ? this.incomingCriteriaSelected.setTrending(1) : this.incomingCriteriaSelected.setTrending(0));update()">
                                                </mat-slide-toggle>
                                            </td>
                                            <td>
                                                <center>
                                                    <table>
                                                        <tr *ngFor="let question of incomingCriteriaSelected.getQuestions();let criterialQuestionIncremental = index">
                                                            <td ngClass='cursor'
                                                                (click)="openPromptQuestionlabel(question)">
                                                                {{question.label}}
                                                                <div *ngIf="question.hasOwnProperty('reference1')">({{question.reference1}})</div>
                                                            </td>
                                                            <td class="cursor"
                                                                [ngStyle]="{'color':'red'}"
                                                                (click)="incomingCriteriaQuestionSelected.setAttributes(question);menuStatus = 4 ;onContextMenu($event, null)"
                                                                (click)="incomingCriteriaQuestionSelected.setAttributes(question);">
                                                                {{question.operator}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'100px'}">
                                                                <div *ngIf="!(question.hasOwnProperty('group_by') &&
                                                            question.group_by === 1 &&
                                                            !(question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 18||
                                                                question.control_id === -18||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32))">
                                                                    <ng-container *ngIf="
                                                                !(question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 18||
                                                                question.control_id === -18||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32)">
                                                                        <mat-form-field  class="example-full-width" appearance="outline">
                                                                            <mat-label></mat-label>
                                                                            <input type="text"
                                                                                   placeholder=""
                                                                                   aria-label = "Number"
                                                                                   autocomplete="off"
                                                                                   [(ngModel)]="question.value"
                                                                                   [ngStyle]="{'text-align':'center'}"
                                                                                   (input)="update()"
                                                                                   matInput>
                                                                            <!--                                                                list="listQuestions{{question.id}}"-->
                                                                            <datalist id="listQuestions{{question.id}}">
                                                                                <option value="{{option.label}}" *ngFor="let option of question.options">
                                                                            </datalist>
                                                                        </mat-form-field>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="
                                                                question.control_id === 3 ||
                                                                question.control_id === 4 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 8 ||
                                                                question.control_id === 5 ||
                                                                question.control_id === 18||
                                                                question.control_id === -18||
                                                                question.control_id === 20||
                                                                question.control_id === 27||
                                                                question.control_id === 32">
                                                                        <table>
                                                                            <tr *ngFor="let option of question.options">
                                                                                <td>
                                                                                    <mat-checkbox
                                                                                        #criteriaQuestionCheckbox
                                                                                        class="pull-left"
                                                                                        [value]="option"
                                                                                        [checked]="option.hasOwnProperty('checked') && option.checked === 1"
                                                                                        (change)="($event.checked ? option.checked = 1 : option.checked = 0);
                                                                                     checkCriteriaQuestionOption($event, question,option,criteriaQuestionCheckbox);
                                                                                     update()">
                                                                              <span [ngStyle]="{'font-size':'14px'}">
                                                                                {{service.upperStartingCharacter(option.label, true)}}
                                                                              </span>
                                                                                    </mat-checkbox>
                                                                                </td>
                                                                                <td class="cursor"
                                                                                    *ngIf="this.service.parseFloat(question.control_id) === -18"
                                                                                    (click)="openPromptQuestionOptionlabel(question,option)">
                                                                                    <i class="materials-icon">edit</i>
                                                                                </td>
                                                                                <td class="cursor"
                                                                                    *ngIf="this.service.parseFloat(question.control_id) === -18"
                                                                                    (click)="removeCriteriaQuestionOption(question,option)">
                                                                                    <i class="materials-icon">close</i>
                                                                                </td>
                                                                                <td class="cursor"
                                                                                    (click)="openSettingsDialog(option)">
                                                                                    <i class="materials-icon">settings</i>
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="question.control_id === -18">
                                                                                <td>
                                                                                    <center>
                                                                                        <i class="material-icons"
                                                                                           (click)="addCriteriaQuestionOption(question)">
                                                                                            add_circle_outline</i>
                                                                                    </center>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </ng-container>
                                                                </div>
                                                            </td>
                                                            <td title="Group by">
                                                                <ng-container *ngIf="
                                                        question.control_id === 3 ||
                                                        question.control_id === 4 ||
                                                        question.control_id === 5 ||
                                                        question.control_id === 8 ||
                                                        question.control_id === 5 ||
                                                        question.control_id === 18||
                                                        question.control_id === -18||
                                                        question.control_id === 20||
                                                        question.control_id === 27||
                                                        question.control_id === 32">

                                                                    <div>
                                                                        <mat-slide-toggle
                                                                            #cateriQuestionSlide
                                                                            class="pull-right"
                                                                            [checked]="question.hasOwnProperty('group_by') && question.group_by === 1"
                                                                            (change)="($event.checked ? question.group_by = 1 : question.group_by = 0); hasCompleteCriteriaQuestions($event,question);update()">
                                                                        </mat-slide-toggle>
                                                                    </div>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                         <span class="cursor"
                                                               [ngStyle]="{'margin-left':'5px'}"
                                                               (click)="removeCriteriaQuestion(question, criterialQuestionIncremental)">
                                                            <a><i class="material-icons">close</i></a>
                                                        </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </center>
                                                <center>
                                                    <a mat-mini-fab (click)="selectQuestion()"
                                                       class="defaultColor2">
                                                        <i class="material-icons">keyboard_arrow_down</i></a>
                                                    <br><br>
                                                    <a mat-mini-fab (click)="addCriteriaQuestion()"
                                                       class="defaultColor2">
                                                        <i class="material-icons"><i class="fa fa-angle-double-down"></i></i>
                                                    </a>
                                                </center>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Review">
                            <div [ngStyle]="{'margin': '20px'}">
                                <center>
                                    <table>
                                        <tr *ngFor="let criteria of service.orderBy((incomingFormSettings.attributes.hasOwnProperty('criterias')?incomingFormSettings.getCriterias():[]),'asc','index'); let incremental = index">
                                            <td>{{criteria.title}}</td>
                                            <td>
                                        <span><i class="material-icons"
                                                 *ngIf="incremental !== 0"
                                                 [ngStyle]="{'color':'#888','cursor':'pointer'}"
                                                 (click)="upShiftCriteria(criteria,incremental)">arrow_upward</i></span>
                                                <span><i class="material-icons"
                                                         *ngIf="incremental !== (incomingFormSettings.getCriterias().length-1)"
                                                         [ngStyle]="{'color':'#888','cursor':'pointer'}"
                                                         (click)="downShiftCriteria(criteria,incremental)">arrow_downward</i></span>
                                            </td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </mat-tab>
                    </mat-tab-group>

                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class=""> Pre-ﬁlled forms for faster data collection</h4>
                        </mat-panel-title>
                        <mat-panel-description>
                            Follow-up surveys and repeat data collection locations
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('phase') && incomingForm.getSettings().phase === 1"
                                (change)="($event.checked ? incomingForm.getSettings().phase = 1 : incomingForm.getSettings().phase = 0); update()"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Is Text 2 Speech Enabled ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingFormSettings.getAttributes().hasOwnProperty('text_2_speech_enabled') && incomingFormSettings.getText2SpeechEnabled() === 1"
                                (change)="($event.checked ? incomingFormSettings.setText2SpeechEnabled(1): incomingFormSettings.setText2SpeechEnabled(0)); update()"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Is GPS Tracked ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingFormSettings.getAttributes().hasOwnProperty('is_gps_tracked') && incomingFormSettings.getIsGPSTracked() === 1"
                                (change)="($event.checked ? incomingFormSettings.setIsGPSTracked(1): incomingFormSettings.setIsGPSTracked(0)); update()"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Is Outlet ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingFormSettings.getAttributes().hasOwnProperty('is_outlet') && incomingFormSettings.getIsOutlet() === 1"
                                (change)="($event.checked ? incomingFormSettings.setIsOutlet(1): incomingFormSettings.setIsOutlet(0)); update()"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Allow saving responses</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('canBeSavedBeforePublished') && incomingForm.getSettings().canBeSavedBeforePublished === 1"
                                (change)="($event.checked ? incomingForm.getSettings().canBeSavedBeforePublished = 1 : incomingForm.getSettings().canBeSavedBeforePublished = 0); update()"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <!--            <mat-expansion-panel-header>-->
                    <!--                <mat-panel-title>-->
                    <!--                    <h4 class=""></h4>-->
                    <!--                </mat-panel-title>-->
                    <!--                <mat-panel-description>-->
                    <!--                </mat-panel-description>-->
                    <!--            </mat-expansion-panel-header>-->
                    <div class="">
                        Large Format Display
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('lfd') && incomingForm.getSettings().lfd === 1"
                                (change)="($event.checked ? incomingForm.getSettings().lfd = 1 : incomingForm.getSettings().lfd = 0); update()"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div class="">
                        Header
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('header') && incomingForm.getSettings().header === 1"
                                (change)="($event.checked ? incomingForm.getSettings().header = 1 : incomingForm.getSettings().header = 0); update()"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div class="">
                        Sales Target based on outlets
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('sales_based_on_outlets') && incomingForm.getSettings().sales_based_on_outlets === 1"
                                (change)="($event.checked ? incomingForm.getSettings().sales_based_on_outlets = 1 : incomingForm.getSettings().sales_based_on_outlets = 0); update()"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div class="">
                        Rate options
                        <div>
                            <table>
                                <td class="cursor" (click)="updateRateOption()">{{incomingRateOptionSelected.hasAttribute('cntrl_num') ? incomingRateOptionSelected.getTitle() : ''}}</td>
                                <td><a><i class="material-icons cursor">keyboard_arrow_down</i></a></td>
                                <td><a><i class="material-icons cursor" (click)="removeRateOption()">close</i></a></td>
                                <td><a><i class="material-icons cursor" (click)="addRateOption()">add</i></a></td>
                            </table>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <div class="">
                        Hide response counter
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingForm.getSettings().hasOwnProperty('responses_counter') && incomingForm.getSettings().responses_counter === 1"
                                (change)="($event.checked ? incomingForm.getSettings().responses_counter = 1 : incomingForm.getSettings().responses_counter = 0); update()"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table class="" [ngStyle]="{'width':'100%'}">
                            <tr>
                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                    Powered by
                                    <i class="material-icons pull-right cursor"
                                       (click)="img.click()">add</i>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" [ngStyle]="{'width':'100%'}">
                                    <img src="{{logo.path}}"
                                         class="thumbnail pull-left mdl-card mdl-shadow--2dp"
                                         *ngFor="let logo of incomingForm.attributes.settings.logo"
                                         [ngStyle]="{'max-width':'200px', 'max-height':'200px', 'margin-left':  '10px'}"
                                         (contextmenu)="this.incomingPoweredBySelected.setAttributes(logo); menuStatus = 6; onContextMenu($event, logo)"
                                         alt="{{logo.caption}}">
                                    <!--                            <mat-grid-list cols="4" rowHeight="4:1">-->
                                    <!--                                <mat-grid-tile-->
                                    <!--                                        [ngStyle]="{'background':'#ebebeb'}"-->
                                    <!--                                        *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                                    <!--                                    -->
                                    <!--                                </mat-grid-tile>-->
                                    <!--                            </mat-grid-list>-->

                                    <!--                           <div class="demo-card-image mdl-card mdl-shadow--2dp pull-right"-->
                                    <!--                                 style="background:url({{logo.path}}) center / cover"-->
                                    <!--                                *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                                    <!--                                <div class="mdl-card__title mdl-card--expand"></div>-->
                                    <!--                                <div class="mdl-card__actions">-->
                                    <!--                                    <span class="demo-card-image__filename"></span>-->
                                    <!--                                </div>-->
                                    <!--                            </div>-->
                                    <!--                            <mat-card class="example-card"-->
                                    <!--                                      *ngFor="let logo of incomingForm.attributes.settings.logo">-->
                                    <!--                                <mat-card-header>-->
                                    <!--                                    <div mat-card-avatar class="example-header-image"></div>-->
                                    <!--                                    <mat-card-title></mat-card-title>-->
                                    <!--                                    <mat-card-subtitle></mat-card-subtitle>-->
                                    <!--                                </mat-card-header>-->
                                    <!--                                <img mat-card-image src="{{logo.path}}"-->
                                    <!--                                     [ngStyle]="{'width':'100%'. 'height':'auto','max-height':'200px'}"-->
                                    <!--                                     alt="Photo of a Shiba Inu">-->
                                    <!--                                <mat-card-content>-->

                                    <!--                                </mat-card-content>-->
                                    <!--                                <mat-card-actions>-->
                                    <!--                                    <button mat-button>LIKE</button>-->
                                    <!--                                    <button mat-button>SHARE</button>-->
                                    <!--                                </mat-card-actions>-->
                                    <!--                            </mat-card>-->

                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Advert link
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #advertUrl
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.advert_url"
                                           (input)="incomingForm.attributes.settings.advert_url = advertUrl.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Auto Number
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #autoNumber
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.auto_number"
                                           (input)="incomingForm.attributes.settings.auto_number = autoNumber.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Reference
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea
                                            placeholder=""
                                            #reference
                                            aria-label = "Number"
                                            matInput
                                            autocomplete="off"
                                            [(ngModel)]="incomingForm.attributes.settings.reference"
                                            (input)="incomingForm.attributes.settings.reference = reference.value; update()"
                                            [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Reference 1
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea
                                            placeholder=""
                                            #reference1
                                            aria-label = "Number"
                                            matInput
                                            autocomplete="off"
                                            [(ngModel)]="incomingForm.attributes.settings.reference1"
                                            (input)="incomingForm.attributes.settings.reference1 = reference1.value; update()"
                                            [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Reference 2
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea
                                            placeholder=""
                                            #reference2
                                            aria-label = "Number"
                                            matInput
                                            autocomplete="off"
                                            [(ngModel)]="incomingForm.attributes.settings.reference2"
                                            (input)="incomingForm.attributes.settings.reference2 = reference2.value; update()"
                                            [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Reference 3
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea
                                            placeholder=""
                                            #reference3
                                            aria-label = "Number"
                                            matInput
                                            autocomplete="off"
                                            [(ngModel)]="incomingForm.attributes.settings.reference3"
                                            (input)="incomingForm.attributes.settings.reference3 = reference3.value; update()"
                                            [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Submit button text
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #submit
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.submit"
                                           (input)="incomingForm.attributes.settings.submit = submit.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <!--    <mat-expansion-panel [expanded]="true">-->
                <!--            <div style="margin-top: 10px">-->
                <!--                <table>-->
                <!--                    <td [ngStyle]="{'width':'100%'}" valign="top">-->
                <!--                        Banner-->
                <!--                    </td>-->
                <!--                    <td valign="top">-->
                <!--                        <mat-form-field  class="example-full-width" appearance="outline">-->
                <!--                            <mat-label class=""></mat-label>-->
                <!--                            <input type="text"-->
                <!--                                   class="textAlignCenter"-->
                <!--                                   #elmBanner-->
                <!--                                   placeholder=""-->
                <!--                                   aria-label = "Number"-->
                <!--                                   matInput-->
                <!--                                   (input)="incomingFormSettings.setBanner(''); update()"-->
                <!--                                   [formControl] = "bannerControl"-->
                <!--                                   [matAutocomplete] = "banner"/>-->
                <!--                            <mat-autocomplete #banner = "matAutocomplete"-->
                <!--                                              (optionSelected)="incomingFormSettings.setBanner($event.option.value.cntrl_num); update()"-->
                <!--                                              [displayWith]="displayFn">-->
                <!--                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >-->
                <!--                                    {{question.label}}-->
                <!--                                </mat-option>-->
                <!--                            </mat-autocomplete>-->
                <!--                            <h4 style="color: red; font-weight: normal"-->
                <!--                                *ngIf="service.isNull(elmBanner.value) === false &&-->
                <!--                                 ((filteredQuestionsOptions | async) | json).length === 2">-->
                <!--                                **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
                <!--                        </mat-form-field>-->
                <!--                    </td>-->
                <!--                </table>-->
                <!--            </div>-->
                <!--    </mat-expansion-panel>-->
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                outlets That Sell Kpi
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #outletsThatSellKpi
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.outlets_that_sell_kpi"
                                           (input)="incomingForm.attributes.settings.outlets_that_sell_kpi = outletsThatSellKpi.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Visted outlets alias name
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #vistedOutletAlias
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.visted_outlet_alias_name"
                                           (input)="incomingForm.attributes.settings.visted_outlet_alias_name = vistedOutletAlias.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Text color
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #textColor
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.color"
                                           (input)="incomingForm.attributes.settings.color = textColor.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table class="">
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Target
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #target
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.target"
                                           (input)="incomingForm.attributes.settings.target = target.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Starting
                            </td>
                            <td valign="top">
                                <mat-form-field
                                        class="pull-right"
                                        appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #startingAtId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.attributes[this.strings.getStartingAt()]"
                                           (input)="incomingFormSettings.setStartingAt(startingAtId.value); update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Mail Password
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #mailPasswordId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.mail_password"
                                           (input)="incomingForm.attributes.settings.mail_password = mailPasswordId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Mail User name
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #mailUserNameId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.mail_username"
                                           (input)="incomingForm.attributes.settings.mail_username = mailUserNameId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Background color
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #backgroundColor
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.background_color"
                                           (input)="incomingForm.attributes.settings.background_color = backgroundColor.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <ng-container *ngIf="!service.isNull(incomingFormSettings.attributes.outlet)">
                    <mat-expansion-panel [expanded]="true">
                        <div style="margin-top: 10px">
                            <table>
                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                    New Customers reach kpi
                                </td>
                                <td valign="top">
                                    <mat-form-field  class="pull-right" appearance="outline">
                                        <mat-label></mat-label>
                                        <input type="text"
                                               #newCustomersKpi
                                               placeholder=""
                                               aria-label = "Number"
                                               matInput
                                               autocomplete="off"
                                               [(ngModel)]="incomingForm.attributes.settings.new_customers_kpi"
                                               (input)="incomingForm.attributes.settings.new_customers_kpi = newCustomersKpi.value; update()"
                                               [ngStyle]="{'text-align':'center'}"
                                        />
                                    </mat-form-field>
                                </td>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </ng-container>

                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Set Responses Display Title</mat-label>
                            <input matInput
                                   type="text"
                                   #responseDisplayTitleElement
                                   [(ngModel)]="incomingForm.attributes.response_display_title"
                                   (input)="incomingForm.setResponseDisplayTitle(responseDisplayTitleElement.value); update()">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Set Key Performance Display Title</mat-label>
                            <input matInput
                                   type="text"
                                   #kpiDisplayTitleElement
                                   [(ngModel)]="incomingForm.attributes.kpi_display_title"
                                   (input)="incomingForm.setKpiDisplayTitle(kpiDisplayTitleElement.value);
                           update()">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Title based on</mat-label>
                            <input type="text"
                                   #titleId
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   [formControl] = "titleControl"
                                   [matAutocomplete] = "title"/>
                            <mat-autocomplete #title = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setTitle($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredTitleBasedQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(titleId.value) === false &&
                                 ((filteredTitleBasedQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Capture location throught this field</mat-label>
                            <input type="text"
                                   #coordinateElement
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   [formControl] = "coordinateControl"
                                   [matAutocomplete] = "coordinates"/>
                            <mat-autocomplete #coordinates = "matAutocomplete"
                                              (optionSelected)="incomingForm.setGpsReference($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(coordinateElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Restriction</mat-label>
                            <input type="text"
                                   #uniqidElement
                                   placeholder=""
                                   aria-label = "Number"
                                   (input)="onTextChange($event, 'uniqid')"
                                   matInput
                                   [formControl] = "uniqidControl"
                                   [matAutocomplete] = "uniqid"/>
                            <mat-autocomplete #uniqid = "matAutocomplete"
                                              (optionSelected)="incomingForm.setUniqid($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(uniqidElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Change Respodent Name</mat-label>
                            <input matInput
                                   #respodentAliasElement
                                   type="text"
                                   [(ngModel)]="incomingForm.attributes.respondent_alias"
                                   (input)="incomingForm.setRespondentAlias(respodentAliasElement.value);
                           update()">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <!--    <mat-expansion-panel [expanded]="true">-->
                <!--            <div style="margin-top: 10px">-->
                <!--                <mat-form-field  class="example-full-width" appearance="outline">-->
                <!--                    <mat-label>Core Business field</mat-label>-->
                <!--                    <input type="text"-->
                <!--                           #coreBusinessFieldElement-->
                <!--                           placeholder=""-->
                <!--                           aria-label = "Number"-->
                <!--                           matInput-->
                <!--                           [formControl] = "coreBusinessFieldControl"-->
                <!--                           [matAutocomplete] = "coreBusinessField"/>-->
                <!--                    <mat-autocomplete #coreBusinessField = "matAutocomplete"-->
                <!--                                      (optionSelected)="incomingForm.setCoreBusinessField($event.option.value.cntrl_num); this.update()"-->
                <!--                                      [displayWith]="displayFn">-->
                <!--                        <mat-option *ngFor="let question of filteredCoreBusinessFieldOptions | async; let questionOf = index"-->
                <!--                                    [value]= "question" >-->
                <!--                            {{question.label}}-->
                <!--                        </mat-option>-->
                <!--                    </mat-autocomplete>-->
                <!--                    <h4 style="color: red; font-weight: normal"-->
                <!--                        *ngIf="service.empty(coreBusinessFieldElement.value) === false &&-->
                <!--                                 ((filteredCoreBusinessFieldOptions | async) | json).length === 2">-->
                <!--                        **---Not Found *---</h4>-->
                <!--                </mat-form-field>_-->
                <!--            </div>-->
                <!--    </mat-expansion-panel>-->
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Products</mat-label>
                            <mat-chip-list #chipList aria-label="selection">
                                <mat-chip
                                        *ngFor="let questional of currentQuestionalProducts"
                                        [selectable]="selectable"
                                        [removable]="removable"
                                        (removed)="remove(questional)">
                                    {{questional.label}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input
                                        placeholder=""
                                        #productElement
                                        [formControl]="productCtrl"
                                        [matAutocomplete]="product"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                            </mat-chip-list>
                            <mat-autocomplete #product="matAutocomplete" (optionSelected)="onSelectProduct($event)">
                                <mat-option *ngFor="let question of filteredProductOptions | async" [value]="question">
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(productElement.value) === false &&
                                     ((filteredProductOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class="">Outlet</mat-label>
                            <input type="text"
                                   #outletElm
                                   placeholder=""
                                   aria-label = "Number"
                                   (input)="incomingFormSettings.setOutlet(''); update()"
                                   matInput
                                   [formControl] = "outletControl"
                                   [matAutocomplete] = "outlet"/>
                            <mat-autocomplete #outlet = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setOutlet($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(outletElm.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class="">Address</mat-label>
                            <input type="text"
                                   #addressId
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingFormSettings.setLocation(''); update()"
                                   [formControl] = "addressControl"
                                   [matAutocomplete] = "address"/>
                            <mat-autocomplete #address = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setLocation($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(addressId.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Contact
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #contact
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.contact"
                                           (input)="incomingForm.attributes.settings.contact = contact.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Frame
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #frameId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.attributes.frame"
                                           (input)="incomingForm.attributes.settings.frame = frameId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Logo
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmLogoUrl
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.logo"
                                           (input)="incomingForm.attributes.settings.logo = elmLogoUrl.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Description
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmDescription
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.description"
                                           (input)="incomingForm.attributes.settings.description = elmDescription.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label class="">Stock</mat-label>
                            <input type="text"
                                   placeholder=""
                                   aria-label = "Number"
                                   #stockItemElm
                                   matInput
                                   [formControl] = "stockItemControl"
                                   [matAutocomplete] = "stockItems"/>
                            <mat-autocomplete #stockItems = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setStock($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(stockItemControl.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Delivered
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #deliveredId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setDelivered(''); update()"
                                           [formControl] = "deliveredControl"
                                           [matAutocomplete] = "delivered"/>
                                    <mat-autocomplete #delivered = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setDelivered($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredDeliveredQuestionsOptions | async; let questionOf = index"
                                                    [value]= "question">
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(deliveredId.value) === false &&
                                 ((filteredDeliveredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Invitation card
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #invitationCardId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setInvitationCard(''); update()"
                                           [formControl] = "invitationCardControl"
                                           [matAutocomplete] = "invitationCard"/>
                                    <mat-autocomplete #invitationCard = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setInvitationCard($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredInvitationCardQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(invitationCardId.value) === false &&
                                 ((filteredInvitationCardQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                is Invited
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #elmIsInvited
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsInvited(''); update()"
                                           [formControl] = "isInvitedControl"
                                           [matAutocomplete] = "isInvited"/>
                                    <mat-autocomplete #isInvited = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsInvited($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmIsInvited.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Job Position
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #jobPositionId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeJobPosition(''); update()"
                                           [formControl] = "attendeeJobPositionControl"
                                           [matAutocomplete] = "attendeeJobPosition"/>
                                    <mat-autocomplete #attendeeJobPosition = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeJobPosition($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeJobPositionQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(jobPositionId.value) === false &&
                                 ((filteredAttendeeJobPositionQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Job Title ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #jobTitleId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeJobTitle(''); update()"
                                           [formControl] = "attendeeJobTitleControl"
                                           [matAutocomplete] = "attendeeJobTitle"/>
                                    <mat-autocomplete #attendeeJobTitle = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeJobTitle($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeJobTitleQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(jobTitleId.value) === false &&
                                 ((filteredAttendeeJobTitleQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Banner
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #banner
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.attributes.banner"
                                           (input)="incomingFormSettings.setBanner(banner.value); update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                is Confirm
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #elmIsConfirm
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsConfirm(''); update()"
                                           [formControl] = "isConfirmControl"
                                           [matAutocomplete] = "IsConfirm"/>
                                    <mat-autocomplete #IsConfirm = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsConfirm($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmIsConfirm.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                is Invited by whatsapp
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #elmIsInvitedByWhatsapp
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsInvitedByWhatsapp(''); update()"
                                           [formControl] = "isInvitedByWhatsappControl"
                                           [matAutocomplete] = "IsInvitedByWhatsapp"/>
                                    <mat-autocomplete #IsInvitedByWhatsapp = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsInvitedByWhatsapp($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmIsInvitedByWhatsapp.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Host
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #host
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.host"
                                           (input)="incomingForm.attributes.settings.host = host.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>



                <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Response Channel
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #elmresponseChannel
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           [formControl] = "responseChannelControl"
                                           [matAutocomplete] = "responseChannel"/>
                                    <mat-autocomplete #responseChannel = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setResponseChannel($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmresponseChannel.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Invitation card sent through whatsapp
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #elmInvitationCardSentThroughWhatsapp
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setInvitationCardSentThroughWhatsapp(''); update()"
                                           [formControl] = "invitationCardSentThroughWhatsappControl"
                                           [matAutocomplete] = "invitationCardSentThroughWhatsapp"/>
                                    <mat-autocomplete #invitationCardSentThroughWhatsapp = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setInvitationCardSentThroughWhatsapp($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmInvitationCardSentThroughWhatsapp.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Invitation card emailed
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #elmInvitationCardEmailed
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setInvitationCardEmailed(''); update()"
                                           [formControl] = "invitationCardEmailedControl"
                                           [matAutocomplete] = "invitationCardEmailed"/>
                                    <mat-autocomplete #invitationCardEmailed = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setInvitationCardEmailed($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(elmInvitationCardEmailed.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Venue
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmVenue
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.venue"
                                           (input)="incomingForm.attributes.settings.venue = elmVenue.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Time
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmTime
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.time"
                                           (input)="incomingForm.attributes.settings.time = elmTime.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attending Date
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmAttendingDate
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.attending_date"
                                           (input)="incomingForm.attributes.settings.attending_date = elmAttendingDate.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Declined Landing Page Contents
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <textarea type="text"
                                              #elementDeclinedLandingPageContents
                                              placeholder=""
                                              aria-label = "Number"
                                              matInput
                                              autocomplete="off"
                                              [(ngModel)]="incomingForm.attributes.settings.declined_landing_page_contents"
                                              (input)="incomingForm.attributes.settings.declined_landing_page_contents = elementDeclinedLandingPageContents.value; update()"
                                              [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Arriving invitation card contents
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <textarea type="text"
                                              #arrivingInvitationCardContents
                                              placeholder=""
                                              aria-label = "Number"
                                              matInput
                                              autocomplete="off"
                                              [(ngModel)]="incomingForm.attributes.settings.arriving_invitation_card_contents"
                                              (input)="incomingFormSettings.setArrivingInvitationCardContents(arrivingInvitationCardContents.value); update()"
                                              [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                After Accept Invitation Landing Page Contents
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <textarea type="text"
                                              #elementAfterAcceptLandingPageContents
                                              placeholder=""
                                              aria-label = "Number"
                                              matInput
                                              autocomplete="off"
                                              [(ngModel)]="incomingForm.attributes.settings.after_accept_landing_page_contents"
                                              (input)="incomingForm.attributes.settings.after_accept_landing_page_contents = elementAfterAcceptLandingPageContents.value; update()"
                                              [ngStyle]="{'text-align':'center'}">
                            </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                RSVP Date
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #elmARSVPDate
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.rsvp_date"
                                           (input)="incomingForm.attributes.settings.rsvp_date = elmARSVPDate.value; update()"
                                           [ngStyle]="{'text-align':'center'}"/>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <ng-container>
                    <mat-expansion-panel [expanded]="true">
                        <div style="margin-top: 10px">
                            <table>
                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                    is Attending
                                </td>
                                <td valign="top">
                                    <mat-form-field  class="example-full-width" appearance="outline">
                                        <mat-label class=""></mat-label>
                                        <input type="text"
                                               class="textAlignCenter"
                                               #elmIsAttending
                                               placeholder=""
                                               aria-label = "Number"
                                               matInput
                                               (input)="incomingFormSettings.setIsAttending(''); update()"
                                               [formControl] = "isAttendingControl"
                                               [matAutocomplete] = "isAttending"/>
                                        <mat-autocomplete #isAttending = "matAutocomplete"
                                                          (optionSelected)="incomingFormSettings.setIsAttending($event.option.value.cntrl_num); update()"
                                                          [displayWith]="displayFn">
                                            <mat-option *ngFor="let question of filteredQuestionsAttendingOptions | async; let questionOf = index" [value]= "question" >
                                                {{question.label}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <h4 style="color: red; font-weight: normal"
                                            *ngIf="service.isNull(elmIsAttending.value) === false &&
                                 ((filteredQuestionsAttendingOptions | async) | json).length === 2">
                                            **---Not Found *---</h4>
                                    </mat-form-field>
                                </td>
                            </table>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="true">
                        <div style="margin-top: 10px">
                            <table>
                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                    Whatsapp ?
                                </td>
                                <td valign="top">
                                    <mat-form-field  class="example-full-width" appearance="outline">
                                        <mat-label class=""></mat-label>
                                        <input type="text"
                                               class="textAlignCenter"
                                               #isWhatsappId
                                               placeholder=""
                                               aria-label = "Number"
                                               matInput
                                               (input)="incomingFormSettings.setIsWhatsapp(''); update()"
                                               [formControl] = "isWhatsappControl"
                                               [matAutocomplete] = "isWhatsapp"/>
                                        <mat-autocomplete #isWhatsapp = "matAutocomplete"
                                                          (optionSelected)="incomingFormSettings.setIsWhatsapp($event.option.value.cntrl_num); update()"
                                                          [displayWith]="displayFn">
                                            <mat-option *ngFor="let question of filteredQuestionsIsWhatsappOptions | async; let questionOf = index" [value]= "question" >
                                                {{question.label}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <h4 style="color: red; font-weight: normal"
                                            *ngIf="service.isNull(isWhatsappId.value) === false &&
                                 ((filteredQuestionsIsWhatsappOptions | async) | json).length === 2">
                                            **---Not Found *---</h4>
                                    </mat-form-field>
                                </td>
                            </table>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="true">
                        <div style="margin-top: 10px">
                            <table>
                                <td [ngStyle]="{'width':'100%'}" valign="top">
                                    Email ?
                                </td>
                                <td valign="top">
                                    <mat-form-field  class="example-full-width" appearance="outline">
                                        <mat-label class=""></mat-label>
                                        <input type="text"
                                               class="textAlignCenter"
                                               #isEmailId
                                               placeholder=""
                                               aria-label = "Number"
                                               matInput
                                               (input)="incomingFormSettings.setIsEmail(''); update()"
                                               [formControl] = "isEmailControl"
                                               [matAutocomplete] = "isEmail"/>
                                        <mat-autocomplete #isEmail = "matAutocomplete"
                                                          (optionSelected)="incomingFormSettings.setIsEmail($event.option.value.cntrl_num); update()"
                                                          [displayWith]="displayFn">
                                            <mat-option *ngFor="let question of filteredIsEmailQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                                {{question.label}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <h4 style="color: red; font-weight: normal"
                                            *ngIf="service.isNull(isEmailId.value) === false &&
                                 ((filteredIsEmailQuestionsOptions | async) | json).length === 2">
                                            **---Not Found *---</h4>
                                    </mat-form-field>
                                </td>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee photo ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #attendeePicId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeePic(''); update()"
                                           [formControl] = "attendeePicControl"
                                           [matAutocomplete] = "attendeePic"/>
                                    <mat-autocomplete #attendeePic = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeePic($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeePicQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeePicId.value) === false &&
                                 ((filteredAttendeePicQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Payment Method
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #paymentMethodId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setPaymentMethod(''); update()"
                                           [formControl] = "paymentMethodControl"
                                           [matAutocomplete] = "paymentMethod"/>
                                    <mat-autocomplete #paymentMethod = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setPaymentMethod($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredPaymentMethodQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(paymentMethodId.value) === false &&
                                 ((filteredPaymentMethodQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class="">Contact person phone</mat-label>
                            <input type="text"
                                   #elmContactPersonPhone
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingFormSettings.setContactPersonPhone(''); update()"
                                   [formControl] = "contactPersonPhoneControl"
                                   [matAutocomplete] = "contactPersonPhone"/>
                            <mat-autocomplete #contactPersonPhone = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setContactPersonPhone($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(elmContactPersonPhone.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class="">Contact person title</mat-label>
                            <input type="text"
                                   #elmContactPersonTitle
                                   placeholder=""
                                   aria-label = "Number"
                                   matInput
                                   (input)="incomingFormSettings.setContactPersonTitle(''); update()"
                                   [formControl] = "contactPersonTitleControl"
                                   [matAutocomplete] = "contactPersonTitle"/>
                            <mat-autocomplete #contactPersonTitle = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setContactPersonTitle($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(elmContactPersonTitle.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Name ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #attendeeNameId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeName(''); update()"
                                           [formControl] = "attendeeNameControl"
                                           [matAutocomplete] = "attendeeName"/>
                                    <mat-autocomplete #attendeeName = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeName($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeNameQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeeNameId.value) === false &&
                                 ((filteredAttendeeNameQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Invitation card width
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #invitationCardWidth
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.invitation_card_width"
                                           (input)="incomingForm.attributes.settings.invitation_card_width = invitationCardWidth.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Template
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #template
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.template"
                                           (input)="incomingForm.attributes.settings.template = template.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 4">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Invitation card Height
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #invitationCardHeight
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.invitation_card_height"
                                           (input)="incomingForm.attributes.settings.invitation_card_height = invitationCardHeight.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Email ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #attendeeEmailId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeEmail(''); update()"
                                           [formControl] = "attendeeEmailControl"
                                           [matAutocomplete] = "attendeeEmail"/>
                                    <mat-autocomplete #attendeeEmail = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeEmail($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeEmailQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeeEmailId.value) === false &&
                                 ((filteredAttendeeEmailQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Is Card sent ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #isCardSentId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsCardSent(''); update()"
                                           [formControl] = "isCardSentControl"
                                           [matAutocomplete] = "isCardSent"/>
                                    <mat-autocomplete #isCardSent = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsCardSent($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredIsCardSentQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(isCardSentId.value) === false &&
                                 ((filteredIsCardSentQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Phone ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #attendeePhoneId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeePhone(''); update()"
                                           [formControl] = "attendeePhoneControl"
                                           [matAutocomplete] = "attendeePhone"/>
                                    <mat-autocomplete #attendeePhone = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeePhone($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeePhoneQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeePhoneId.value) === false &&
                                 ((filteredAttendeePhoneQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Company ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #attendeeCompanyId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeCompany(''); update()"
                                           [formControl] = "attendeeCompanyControl"
                                           [matAutocomplete] = "attendeeCompany"/>
                                    <mat-autocomplete #attendeeCompany = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeCompany($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeCompanyQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeeCompanyId.value) === false &&
                                 ((filteredAttendeeCompanyQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Attendee Salutation ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #attendeeSalutationId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setAttendeeSalutation(''); update()"
                                           [formControl] = "attendeeSalutationControl"
                                           [matAutocomplete] = "attendeeSalutation"/>
                                    <mat-autocomplete #attendeeSalutation = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setAttendeeSalutation($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredAttendeeSalutationQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(attendeeSalutationId.value) === false &&
                                 ((filteredAttendeeSalutationQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Receiving card ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           #isReceivingInvitationCardId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsReceivingInvitationCard(''); update()"
                                           [formControl] = "isReceivingInvitationCardControl"
                                           [matAutocomplete] = "isReceivingInvitationCard"/>
                                    <mat-autocomplete #isReceivingInvitationCard = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsReceivingInvitationCard($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredIsReceivingInvitationCardQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(isReceivingInvitationCardId.value) === false &&
                                 ((filteredIsReceivingInvitationCardQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">

                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Invoice
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""> </mat-label>
                                    <input type="text"
                                           #invoiceId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setInvoice(''); update()"
                                           [formControl] = "invoiceControl"
                                           [matAutocomplete] = "invoice"/>
                                    <mat-autocomplete #invoice = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setInvoice($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredInvoiceQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(invoiceId.value) === false &&
                                 ((filteredInvoiceQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>

                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Order status
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           #orderStatusId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setOrderStatus(''); update()"
                                           [formControl] = "orderStatusControl"
                                           [matAutocomplete] = "orderStatus"/>
                                    <mat-autocomplete #orderStatus = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setOrderStatus($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredOrderStatusQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(orderStatusId.value) === false &&
                                 ((filteredOrderStatusQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label class="">Date</mat-label>
                            <input type="text"
                                   #elementDate
                                   placeholder=""
                                   aria-label = "Number"
                                   (input)="incomingFormSettings.setDate(''); update()"
                                   matInput
                                   [formControl] = "dateControl"
                                   [matAutocomplete] = "date"/>
                            <mat-autocomplete #date = "matAutocomplete"
                                              (optionSelected)="incomingFormSettings.setDate($event.option.value.cntrl_num); update()"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                    {{question.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(elementDate.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-tab>
        <mat-tab label="Events">
            <br><br>
            <mat-accordion [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Redirect Url
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #redirectUrlId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.redirect_url"
                                           (input)="incomingForm.attributes.settings.redirect_url = redirectUrlId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Crop Width
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #cropWId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.getAttributes()[strings.getCropW()]"
                                           (input)="incomingFormSettings.setCropW(cropWId.value); update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Crop Height
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #cropHId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.getAttributes()[strings.getCropH()]"
                                           (input)="incomingFormSettings.setCropH(cropHId.value); update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                After Register message
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #afterRegisterSuccessfulMessageId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.after_register_successful_message"
                                           (input)="incomingForm.attributes.settings.after_register_successful_message = afterRegisterSuccessfulMessageId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-tab>
        <mat-tab label="Marketing">
            <br><br>
            <mat-accordion [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Channel eg. shop. distributor
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #outletChannelId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setOutletChannel(''); update()"
                                           [formControl] = "outletChannelControl"
                                           [matAutocomplete] = "outletChannel"/>
                                    <mat-autocomplete #outletChannel = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setOutletChannel($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredOutletChannelQuestionsOptions | async; let questionOf = index"
                                                    [value]= "question">
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(outletChannelId.value) === false &&
                                 ((filteredOutletChannelQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Bill payed ?
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #isPayedId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setIsPayed(''); update()"
                                           [formControl] = "isPayedControl"
                                           [matAutocomplete] = "isPayed"/>
                                    <mat-autocomplete #isPayed = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setIsPayed($event.option.value.cntrl_num); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredIsPayedQuestionsOptions | async; let questionOf = index"
                                                    [value]= "question">
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(isPayedId.value) === false &&
                                 ((filteredIsPayedQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Packages
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #packagesId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setPackages(''); update()"
                                           [formControl] = "packagesControl"
                                           [matAutocomplete] = "packages"/>
                                    <mat-autocomplete #packages = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setPackages($event.option.value[strings.getCntrlNum()]); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredPackagesQuestionsOptions | async; let questionOf = index"
                                                    [value]= "question">
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(packagesId.value) === false &&
                                 ((filteredPackagesQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Tickets
                            </td>
                            <td valign="top">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <mat-label class=""></mat-label>
                                    <input type="text"
                                           class="textAlignCenter"
                                           #ticketsId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           (input)="incomingFormSettings.setTickets(''); update()"
                                           [formControl] = "ticketsControl"
                                           [matAutocomplete] = "tickets"/>
                                    <mat-autocomplete #tickets = "matAutocomplete"
                                                      (optionSelected)="incomingFormSettings.setTickets($event.option.value[strings.getCntrlNum()]); update()"
                                                      [displayWith]="displayFn">
                                        <mat-option *ngFor="let question of filteredTicketsQuestionsOptions | async; let questionOf = index"
                                                    [value]= "question">
                                            {{question.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <h4 style="color: red; font-weight: normal"
                                        *ngIf="service.isNull(ticketsId.value) === false &&
                                 ((filteredTicketsQuestionsOptions | async) | json).length === 2">
                                        **---Not Found *---</h4>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Distributors
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         [ngStyle]="{'margin-top': '10px'}">
                        <app-distributors></app-distributors>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-tab>
        <mat-tab label="Whatsapp">
            <br><br>
            <mat-accordion [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Whatsapp Business Phone ID
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #whatsappBusinessPhoneId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.whatsapp_business_phone_id"
                                           (input)="incomingForm.attributes.settings.whatsapp_business_phone_id = whatsappBusinessPhoneId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Meta Facebook Whatsapp Token
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #metaFacebookWhatsappTokenId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingFormSettings.attributes[this.strings.getMetaFacebookWhatsappToken()]"
                                           (input)="incomingFormSettings.setMetaFacebookWhatsappToken(metaFacebookWhatsappTokenId.value); update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}"
                                class=''
                                valign="top">
                                Whatsapp Business Account ID
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           #whatsappBusinessAccountId
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [(ngModel)]="incomingForm.attributes.settings.whatsapp_business_acccount_id"
                                           (input)="incomingForm.attributes.settings.whatsapp_business_acccount_id = whatsappBusinessAccountId.value; update()"
                                           [ngStyle]="{'text-align':'center'}"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-tab>
    </mat-tab-group>
</div>
<input type="file" (change)="getImg($event)" [ngStyle]="{'display':'none'}" accept="image/*" #img>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <div *ngIf="menuStatus === 1">
            <div style="min-width: 400px; margin: 5px">
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile
                            [title]="chart.title"
                            *ngFor="let chart of charts; let chartIncremental = index">
                        <img class="cursor"
                             src="{{chart.icon}}"
                             (click)="incomingCustomReportSelected.setChart(chart.id); updateCustomReport(incomingCustomReportSelected.getAttributes())"
                             [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}"
                        />
                        <mat-checkbox [checked]="true" class="pull-right" *ngIf="chart.id === incomingCustomReportSelected.getChart()"></mat-checkbox>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <div *ngIf="menuStatus === 2" [ngStyle]="{'margin': '5px'}" class="default">
            <mat-list>
                <mat-list-item *ngFor="let report of incomingForm.getCustomReports().data">
                    <span class="" mat-line (click)="onCustomReportSelected(report)">
                        {{report.title}}
                        <mat-checkbox class="pull-right cursor" [ngStyle]="{'margin-left': '20px'}"></mat-checkbox>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="menuStatus === 3" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (click)="incomingCustomReportSelected.setGroupBy(incomingCustomReportQuestionSelected.getCntrlNum());
                                                updateCustomReport(incomingCustomReportSelected.getAttributes())">Group By</button>
        </div>
        <div *ngIf="menuStatus === 4" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item (click)="setCriteriaQuestionOperator(operator)"
                    *ngFor="let operator of operators">{{operator}}</button>
        </div>
        <div *ngIf="menuStatus === 5" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (click)="incomingCriteriaSelected.setAttributes(criteria)"
                    *ngFor="let criteria of (incomingFormSettings.getCriterias()| sortBy:'asc':'index')">
                {{criteria.title}}
            </button>
        </div>
        <div *ngIf="menuStatus === 6" [ngStyle]="{'margin': '5px'}" class="default">
            <button mat-menu-item
                    (click)="removePoweredBy()">Delete
            </button>
        </div>
    </ng-template>
</mat-menu>
