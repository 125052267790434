<ng-container>
    <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
        <p>
            <input type="text" class="nullInput"/>
            <mat-list>
                <mat-list-item>
          <span class="" mat-line>
            <mat-form-field class="example-full-width">
              <input type="text"
                     placeholder="Form"
                     aria-label = "Number"
                     (keyup)="onFormTextChanged($event)"
                     #formElement
                     matInput
                     [formControl] = "formControl"
                     [matAutocomplete] = "form">
                          <mat-autocomplete #form = "matAutocomplete"
                                            (optionSelected)="onFormSelectionChanged($event)"
                                            [displayWith]="displayFn">
                              <mat-option *ngFor="let form of (filteredFormsOptions | async); let formOf = index" [value]= "form" >
                                  {{form.name}}
                              </mat-option>
                          </mat-autocomplete>
            </mat-form-field>
          </span>
                    <mat-checkbox #checkedform [checked]="formChecked" (click)="onFormChecked(checkedform)" class="pull-right"></mat-checkbox>
                </mat-list-item>
            </mat-list>
        </p>
    </form>

    <div class="row">
        <div class="col-md-6 col-sm-6">
            <mat-calendar [selected]="startingAt" (selectedChange)="onSelectStartingAt($event)" id="startingAt"></mat-calendar>
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-calendar [selected]="endingAt" (selectedChange)="onSelectEndingAt($event)"></mat-calendar>
        </div>
    </div>
    <div class="row">
        <br><br><br>
        <center>
            <a class="btn btn-primary" (click)="ngSubmit()" #applyElement >Apply</a>
            <a class="btn btn-primary" [mat-dialog-close]="">Cancel</a>
        </center>
    </div>
    <div type='hidden' id="form"> </div>
</ng-container>