<div [ngStyle]="{'z-index': '9999x','position': 'relative','top':'5%','right':'5%'}">
    <app-label-create-control [question]="question"></app-label-create-control>
    <div [ngStyle]="{'margin-top':'50px'}">
        <ul class="list-inline">
            <li (click)="selectedTabIndex = 1; openCamera()"><button mat-fab class="defaultColor2"><mat-icon>photo_camera</mat-icon></button></li>
    <!--    <li (click)="image.click()"><button mat-mini-fab class="defaultColor2"><mat-icon>open_in_browser</mat-icon></button></li>-->
        </ul>
    </div>
    <div *ngIf="incomingQuestion.getData() && !service.isNull(incomingQuestion.getData())">
        <img [src]="incomingQuestion.getData()"
             ngClass="mdl-shadow--3dp img-circle"
             [ngStyle]="{'width':'200px','height':'200px'}"/>
    </div>
</div>


<!--<div [ngStyle]="{'z-index': '9999x','position': 'relative','top':'5%','right':'5%'}" *ngIf="selectedTabIndex === 3">-->
<!--    <app-label-create-control [question]="question"></app-label-create-control>-->
<!--    <div [ngStyle]="{'margin-top':'50px'}">-->
<!--        <ul class="list-inline">-->
<!--            <li (click)="selectedTabIndex = 1; openCamera()"><button mat-fab class="defaultColor2"><mat-icon>photo_camera</mat-icon></button></li>-->
<!--&lt;!&ndash;        <li (click)="image.click()"><button mat-mini-fab class="defaultColor2"><mat-icon>open_in_browser</mat-icon></button></li>&ndash;&gt;-->
<!--        </ul>-->
<!--    </div>-->
<!--    <div *ngIf="incomingQuestion.getData() && !service.isNull(incomingQuestion.getData()) && selectedTabIndex === 3">-->
<!--        <img [src]="incomingQuestion.getData()"-->
<!--             ngClass="mdl-shadow&#45;&#45;3dp img-circle"-->
<!--             [ngStyle]="{'width':'200px','height':'200px'}"/>-->
<!--    </div>-->
<!--</div>-->
<!--<ng-container *ngIf="selectedTabIndex !== 3">-->
<!--    <ng-container *ngIf="!isUploading">-->
<!--        <div [ngStyle]="{'position': 'fixed','top':'-108px','right':'0px'}">-->
<!--            <div [ngStyle]="{'width':'100%','height':(this.service.getHeight())+'px','overflow-y':'hidden'}">-->
<!--                <mat-tab-group [(selectedIndex)]="selectedTabIndex"-->
<!--                               (click)="onClickTabGroup()"-->
<!--                               (selectedTabChange)="onSelectedTabChange($event)"-->
<!--                               [ngStyle]="{'overflow': 'hidden'}">-->
<!--                    <mat-tab style="overflow: hidden">-->
<!--                        <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height':(this.service.getHeight()+9)+'px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">-->
<!--                            <carousel [cellsToScroll]="1"-->
<!--                                      [cellsToShow]="1"-->
<!--                                      [height]="this.service.getHeight()"-->
<!--                                      [autoplay]="true"-->
<!--                                      [pauseOnHover]="true"-->
<!--                                      [arrows]="false"-->
<!--                                      [dots]="false"-->
<!--                                      [loop]="true"-->
<!--                                      *ngIf="incomingForm.getResponses().data.length && selectedTabIndex === 0 && showCarousel">-->
<!--                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">-->
<!--                                    <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">-->
<!--                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">-->
<!--                                            <div class="carousel-cell" *ngIf="incrementalResponseResult === 0" style="overflow: hidden">-->
<!--                                                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
<!--                                                     (error)="onErrorHandle($event)"-->
<!--                                                     src="{{responseResult.data}}"-->
<!--                                                     #img />-->
<!--&lt;!&ndash;                                                <div [ngStyle]="{'position':'fixed','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="vertical-center"&ndash;&gt;-->
<!--&lt;!&ndash;                                                         [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <img src="{{this.service.logo}}"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 class="img-circle mdl-shadow&#45;&#45;2dp"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 [ngStyle]="{'width': '60px','height':'60px','margin-bottom':'20px'}"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 *ngIf="!this.service.isNull(this.service.logo)"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <h2 class="sawasdee18 txtWhite">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                welcome to kisiga wedding,live photo wall&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </h2>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div  class="sawasdee26 lineHeight23 txtWhite">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                let got everything saved as memories, share your wedding photos or view existing ones&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <h4 class="sawasdee20 txtWhite">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                every upload is saved automatically in a kisiga wedding album and appears on TV screen&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </h4>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="sawasdee14"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 [ngStyle]="{'margin-top': '10px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                <div class="banner-menu">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                    <ul>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            (click)="image.click()">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">upload image</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">upload video</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            (click)="selectedTabIndex = 1">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">open camera</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            (click)="selectedTabIndex = 3">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">view album</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                    </ul>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="btn btn-lg btn-default sawasdee14"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         (click)="image.click()">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        upload photo&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                <div class="btn ovalButton">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                    upload video&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="btn btn-lg btn-default sawasdee14"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         (click)="selectedTabIndex = 1">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        open camera&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="btn btn-lg btn-default sawasdee14"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         (click)="selectedTabIndex = 3">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        view album&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        &lt;!&ndash;                                                                {{service.upperStartingCharacter(incomingForm.getName(),true).toLowerCase()}}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                        </center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--                                            </div>-->
<!--                                        </ng-container>-->
<!--                                    </ng-container>-->
<!--                                </ng-container>-->
<!--                            </carousel>-->
<!--                        </div>-->
<!--                    </mat-tab>-->
<!--                    <mat-tab style="overflow: hidden" [ngStyle]="{'background-color':'#000'}">-->
<!--                        <div  [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height': this.service.getHeight()+'px'}">-->
<!--                            <webcam [ngStyle]="{'background-color':'#000'}"-->
<!--                                    (imageCapture)="handleImage($event)"-->
<!--                                    [height]="service.getHeight()"-->
<!--                                    [width]="service.getWidth()"-->
<!--                                    [trigger]="triggerObservable"-->
<!--                                    [mirrorImage]="'never'"-->
<!--                                    [allowCameraSwitch]="allowCameraSwitch"-->
<!--                                    [switchCamera]="nextWebcamObservable"-->
<!--                                    [videoOptions]="videoOptions"-->
<!--                                    [captureImageData]="true"-->
<!--                                    [imageType]="'image/jpeg'"-->
<!--                                    (imageClick)="afterimageClick()"-->
<!--                                    [imageQuality]="1"-->
<!--                                    (cameraSwitched)="cameraWasSwitched($event)"-->
<!--                                    (initError)="handleInitError($event)"-->
<!--                                    *ngIf="showWebcam">-->
<!--                            </webcam>-->
<!--                            <div [ngStyle]="{'background-color':'#000','z-index':'9999x','position':'fixed','bottom':'0px','left':'0px','width':service.getWidth()+'px'}">-->
<!--                                <center>-->
<!--                                    <ul class="list-inline">-->
<!--                                        <li class="cursor">-->
<!--                                            <i (click)="triggerSnapshot()"-->
<!--                                               class="material-icons cursor"-->
<!--                                               [ngStyle]="{'color':'#fff','font-size':'62px'}">motion_photos_on</i>-->
<!--                                        </li>-->
<!--                                        <li (click)="showNextWebcam(true)">-->
<!--                                            <i class="material-icons cursor"-->
<!--                                               [ngStyle]="{'color':'#fff'}">flip_camera_android</i>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </center>-->
<!--                            </div>-->
<!--                            <div [ngStyle]="{'z-index':'9999999999999999','position':'fixed','top':'5px','right':'20px'}">-->
<!--                                <ul class="list-inline">-->
<!--                                    <li class="cursor" (click)="selectedTabIndex = 3;afterClose()">-->
<!--                                        <mat-icon class="cursor" [ngStyle]="{'color':'#fff'}">close</mat-icon>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </mat-tab>-->
<!--                    <mat-tab style="overflow: hidden">-->
<!--                        &lt;!&ndash;            <ng-template mat-tab-label>&ndash;&gt;-->
<!--                        &lt;!&ndash;                <i class="material-icons">tune</i>&ndash;&gt;-->
<!--                        &lt;!&ndash;            </ng-template>&ndash;&gt;-->
<!--                        <div>-->
<!--                            <div [ngStyle]="{'background-color': '#000','position': 'fixed','top': '0px','left': '0px','width': service.getWidth()+'px','height': service.getHeight()+'px'}">-->
<!--                                <img  class="mdl-shadow&#45;&#45;2dp"-->
<!--                                      src="{{this.picUrl}}" [ngStyle]="{'width':'100%', 'height':'auto'}"/>-->
<!--                            </div>-->

<!--                            <div [ngStyle]="{'background-color':'rgba(30, 18, 18, 0.67)','width': service.getWidth()+'px','position': 'fixed','right': '0px','top': '40%','padding-top': '0px','padding-bottom': '0px'}">-->
<!--                                <center>-->
<!--                                    &lt;!&ndash;                                <section class="post-section blog-post"&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                         [ngStyle]="{'background-color': 'transparent'}">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                    <div class="container">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        <div class="row">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                 (click)="sendCapturedCameraImg(drawer)">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <div class="post-box">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    <div class="text-box sawasdee16BoldX4">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        <h4>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                            <a>Upload your image</a>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        </h4>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        <p></p>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                 (click)="cancelCaptureCameraImg()">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <div class="post-box">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    <div class="text-box sawasdee16BoldX4">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        <h4>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                            <a>Cancel</a>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        </h4>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                        <p></p>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                </section>&ndash;&gt;-->
<!--                                    <table [ngStyle]="{'width':'100%'}">-->
<!--                                        <tr [ngStyle]="{'width':'100%'}">-->
<!--                                            <td class="mitra18" [ngStyle]="{'width':'100%'}">-->
<!--                                                <center>-->
<!--                                                    <div class="btn ovalButtonBlackground sawasdee cursor"-->
<!--                                                         (click)="sendCapturedCameraImg(drawer);afterClose()">-->
<!--                                                        <mat-icon class="material-icons">done</mat-icon>-->
<!--                                                    </div>-->
<!--                                                    <div class="btn ovalButtonBlackground cursor"-->
<!--                                                         (click)="cancelCaptureCameraImg();afterClose()"-->
<!--                                                         [ngStyle]="{'width':'100%'}">-->
<!--                                                        <mat-icon class="material-icons">close</mat-icon>-->
<!--                                                    </div>-->
<!--                                                </center>-->
<!--                                            </td>-->
<!--                                        </tr>-->
<!--                                    </table>-->
<!--                                </center>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </mat-tab>-->
<!--                    <mat-tab style="overflow: hidden">-->
<!--                        &lt;!&ndash;            <ng-template mat-tab-label>&ndash;&gt;-->
<!--                        &lt;!&ndash;                <i class="material-icons">tune</i>&ndash;&gt;-->
<!--                        &lt;!&ndash;            </ng-template>&ndash;&gt;-->
<!--                        <div [ngStyle]="{'position': 'fixed','top':'-97px','right':'0px'}">-->
<!--                            <div [ngStyle]="{'width':'100%','height': (this.service.getHeight()+97)+'px','overflow-y':'hidden'}">-->
<!--                                <mat-tab-group [(selectedIndex)]="selectedTabIndex2">-->
<!--                                    <mat-tab style="overflow: hidden">-->
<!--                                        <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px'}"-->
<!--                                             *ngIf="incomingForm.getResponses().data.length !== 0">-->
<!--                                            <carousel [cellsToScroll]="1"-->
<!--                                                      [cellsToShow]="1"-->
<!--                                                      [height]="this.service.getHeight()"-->
<!--                                                      [autoplay]="false"-->
<!--                                                      [arrows]="true"-->
<!--                                                      [dots]="false"-->
<!--                                                      [loop]="true">-->
<!--                                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">-->
<!--                                                    <ng-container *ngFor="let responseResult of response.datas; let incrementalResponseResult =index">-->
<!--                                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">-->
<!--                                                            <div class="carousel-cell" *ngIf="incrementalResponseResult === 0" style="overflow: hidden">-->
<!--                                                                <img [ngStyle]="{'width': '100%', 'height': this.service.getHeight()+'px', 'display':'block', 'object-fit': 'cover'}"-->
<!--                                                                     src="{{responseResult.data}}" #img />-->
<!--                                                            </div>-->
<!--                                                        </ng-container>-->
<!--                                                    </ng-container>-->
<!--                                                </ng-container>-->
<!--                                            </carousel>-->
<!--                                        </div>-->
<!--                                    </mat-tab>-->
<!--                                    <mat-tab style="overflow: hidden">-->
<!--&lt;!&ndash;                                        <ng-template mat-tab-label>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <img src="assets/images/option-icon.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                                 [ngStyle]="{'color':'#fff'}" />&ndash;&gt;-->
<!--&lt;!&ndash;                                        </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div [ngStyle]="{'background-color': '#000','width':this.service.getWidth()+'px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <mat-grid-tile>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="cursor demo-card-image mdl-card mdl-shadow&#45;&#45;2dp"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                     (contextmenu)="incomingResponseResultSelected.setAttributes(responseResult);onContextMenu($event, responseResult)"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                     style="background:url({{responseResult.data}}) center / cover"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                     (click)="incomingResponseResultSelected.setAttributes(responseResult);view(responseResult)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <i class="material-icons cursor" [ngStyle]="{'z-index':'9999x9999x999','position':'absolute','top':'0px','left':'0px','color':'#000'}"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                   (click)="incomingResponseResultSelected.setAttributes(responseResult); downloadImg()">system_update_alt</i>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                <div class="demo-card-image mdl-card mdl-shadow&#45;&#45;2dp" style="background:url({{responseResult.data}}) center / cover">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    <div class="mdl-card__title mdl-card&#45;&#45;expand"></div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    <div class="mdl-card__actions" [ngStyle]="{'background': 'rgba(0, 0, 0, 0.2)','padding': '16px','height': '52px'}">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                        <span class="demo-card-image__filename">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                            <i class="material-icons" [ngStyle]="{'color':'red'}">archive</i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                        </span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </mat-grid-tile>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </mat-grid-list>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="cursor"&ndash;&gt;-->
<!--&lt;!&ndash;                                                 [ngStyle]="{'z-index': '9999x999','position': 'fixed','top':'5px','right':'5px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div [ngStyle]="{'padding':'0px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <ul class="list-inline" [ngStyle]="{'color':'#fff'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <li (click)="flag=1; onContextMenu($event, {})">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="mitrax"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                 [ngStyle]="{'margin-top': '0px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <img src="assets/images/menu-icon-2.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         [ngStyle]="{'color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}" />&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    <div class="btn ovalButtonBlackground2 mitra"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                         (click)="image.click()">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                        upload image <i class="material-icons">north_east</i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    &lt;!&ndash;                                                            <div class="btn ovalButtonBlackground">&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    &lt;!&ndash;                                                                    upload video&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    &lt;!&ndash;                                                            </div>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    <div class="btn ovalButtonBlackground2 mitra"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                         (click)="selectedTabIndex = 1">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                        open camera <i class="material-icons">linked_camera</i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                                    </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </li>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash;                                                    <li (click)="selectedTabIndex2 === 1 ? selectedTabIndex2 = 0 : selectedTabIndex2 = 1;">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash;                                                        <img src="assets/images/option-icon-2.png"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash;                                                             [ngStyle]="{'color':'#fff'}" />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash;                                                    </li>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <a  class=""&ndash;&gt;-->
<!--&lt;!&ndash;                                                *ngIf="this.incomingForm.getResponses().data.length !== 0"&ndash;&gt;-->
<!--&lt;!&ndash;                                                (click)="getOldImg()"&ndash;&gt;-->
<!--&lt;!&ndash;                                                mat-mini-fab&ndash;&gt;-->
<!--&lt;!&ndash;                                                [ngStyle]="{'position': 'fixed','z-index': '9999x999','bottom':'20px','left':'47%','background': '#fff','color':'#000'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <i class="fa fa-chevron-down"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </a>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                    </mat-tab>-->
<!--                                </mat-tab-group>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </mat-tab>-->
<!--                </mat-tab-group>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="isUploading">-->
<!--        <ng-container *ngIf="!service.isNull(service.logo)">-->
<!--            <img  class="logo img-circle"-->
<!--                  src="{{service.logo}}"-->
<!--                  alt=""/>-->
<!--        </ng-container>-->
<!--    </ng-container>-->
<!--</ng-container>-->
<!--&lt;!&ndash;<ng-container *ngIf="service.parseFloat(this.incomingForm.getCategoryId()) !== 6">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="trans-tab">&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-tab-group [(selectedIndex)]="selectedTabIndex"&ndash;&gt;-->
<!--&lt;!&ndash;                       (selectedTabChange)="onSelectedTabChange($event)">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">&ndash;&gt;-->
<!--&lt;!&ndash;                    <li>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class=""&ndash;&gt;-->
<!--&lt;!&ndash;                             [ngStyle]="{'margin-top': '0px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                            <center>&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="btn ovalButtonBlackground2"&ndash;&gt;-->
<!--&lt;!&ndash;                                     [ngStyle]="{'background-color':'transparent'}"&ndash;&gt;-->
<!--&lt;!&ndash;                                     (click)="image.click()">&ndash;&gt;-->
<!--&lt;!&ndash;                                    upload image&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="btn ovalButtonBlackground2"&ndash;&gt;-->
<!--&lt;!&ndash;                                     [ngStyle]="{'background-color':'transparent'}"&ndash;&gt;-->
<!--&lt;!&ndash;                                     (click)="selectedTabIndex = 1">&ndash;&gt;-->
<!--&lt;!&ndash;                                    open camera&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <img src="{{incomingQuestion.getUnknown('imgSrc')}}" [ngStyle]="{'width':'400px','height':'400px'}"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </center>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                </ul>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;                xsbjsbfjf dfhdbfd&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;        </mat-tab-group>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--<input type="file"-->
<!--       (change)="getImage($event)"-->
<!--       [ngStyle]="{'display':'none'}"-->
<!--       accept="image/*"-->
<!--       #image>-->

<!--<div style="visibility: hidden; position: fixed"-->
<!--     [style.left]="contextMenuPosition.x"-->
<!--     [style.top]="contextMenuPosition.y"-->
<!--     [matMenuTriggerFor]="contextMenu">-->
<!--</div>-->
<!--<mat-menu #contextMenu="matMenu">-->
<!--    <ng-template matMenuContent let-item="inspector">-->
<!--        <ng-container *ngIf="flag===0">-->
<!--            <button mat-menu-item (click)="downloadImg()">Download</button>-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="flag===1">-->
<!--            <button mat-menu-item (click)="image.click()">Upload photo</button>-->
<!--            <button mat-menu-item (click)="selectedTabIndex = 1">Open Camera</button>-->
<!--        </ng-container>-->
<!--    </ng-template>-->
<!--</mat-menu>-->

