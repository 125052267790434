<ng-container *ngIf="
        incomingReport.attributes.hasOwnProperty(this.strings.getData()) &&
        !this.service.isNull(incomingReport.getData())">
    <div class="dash" [ngStyle]="{'margin-top':'10px'}">
        <div class="page">
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <div class="fixed">
                            <div class="roww">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="row top_tiles">
                                        <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="tile-stats" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
                                                <div class="pull-right">
                                                    <table>
                                                        <td class='headline2 selectedMatTab2 cursor' (click)="matMenuStatus = 2; onContextMenu($event, {})"><i class="material-icons">view_comfy</i></td>
                                                        <td valign="top" class='headline2 smallBoldx selectedMatTab2'>{{incomingClient.getName().toString().toLowerCase()}}</td>
                                                        <td [hidden]='true'><img class ='img-thumbnailx' src="{{incomingClient.getLogo()}}" style="min-width: 60px; max-height: 25px; float: right;margin-left: 5px"/></td>
                                                        <td class='cursor'>
                                                            <a mat-mini-fab (click)="add()"
                                                               ngClass="defaultColor2"><mat-icon>add</mat-icon></a>
                                                        </td>
                                                    </table>
                                                </div>
                                                <div class="pull-left" [ngStyle]="{'margin-top':'10px'}">
                                                    <table>
                                                        <td class="{{this.service.parseFloat(incomingForm.getId()) === form.id ? 'selectedMatTab2': ''}} cursor"
                                                            style="margin-left: 0px; padding-right: 50px;"
                                                            *ngFor="let form of incomingCampaign.attributes.forms.data"
                                                            (click)="onFormSelected(form)">
                                                            <div class="headline cursor"
                                                                 (dblclick)="onSelectForm(form)">
                                                                {{form.name.toString().toUpperCase()}}
                                                            </div>
                                                        </td>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY {{!extend ? 'col-lg-8 col-md-8' : 'col-lg-12 col-md-12'}} col-sm-12 col-xs-12">
                                            <div class="tile-stats" [ngStyle]="{'margin':'0px','padding':'0px'}">
                                                <ng-container *ngFor="let time of durations">
                                                    <ng-container *ngIf="navTime === time.id">
                                                        <div class="">
                                                            <!--                                                        <div (contextmenu)="matMenuStatus = 3; onContextMenu($event, {})"-->
                                                            <!--                                                             [ngStyle]="{'max-width':'130px','padding':'5px'}"-->
                                                            <!--                                                             (click)="mainNav = 0"-->
                                                            <!--                                                             class="pull-left cursor {{mainNav === 0  ? 'selectedMatTab2': ''}}">-->
                                                            <!--                                                            <div class="headline">{{this.service.isNull(incomingFormSettings.getOutlet()) ?-->
                                                            <!--                                                                incomingForm.getResponseDisplayTitle().toString().toUpperCase() : ('outlets visited').toString().toUpperCase()}}-->
                                                            <!--                                                            </div>-->
                                                            <!--                                                            <div [ngStyle]="{'margin-top':'5px'}" class="counter">-->
                                                            <!--                                                                <span>{{service.numberWithCommans(incomingReport.attributes.data[time.tm].responses)}}</span>-->
                                                            <!--                                                            </div>-->
                                                            <!--                                                        </div>-->
                                                            <div *ngIf="this.service.parseFloat(incomingFormSettings.getIsOutlet()) !== 0"
                                                                 [ngStyle]="{'max-width':'100px','padding':'5px'}"
                                                                 (click)="mainNav = 3"
                                                                 class="col-lg-1 col-md-1 col-sm-6 col-xs-12 cursor {{mainNav === 3  ? 'selectedMatTab2': ''}}">
                                                                <div class="headline">{{('outlets that sell [Effective calls Achieved]').toString().toUpperCase()}}</div>
                                                                <div [ngStyle]="{'margin-top':'5px'}" class="counter">
                                                                    <span>{{service.numberWithCommans(incomingReport.attributes.data[time.tm].outlets_that_sell)}}</span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="this.service.parseFloat(incomingFormSettings.getIsOutlet()) !== 0"
                                                                 [ngStyle]="{'max-width':'100px','padding':'5px'}"
                                                                 (click)="mainNav = 4"
                                                                 class="col-lg-1 col-md-1 col-sm-6 col-xs-12 cursor {{mainNav === 4  ? 'selectedMatTab2': ''}}">
                                                                <div class="headline">{{('new customers').toString().toUpperCase()}}</div>
                                                                <div [ngStyle]="{'margin-top':'5px'}" class="counter">
                                                                    <span>{{service.numberWithCommans(incomingReport.attributes.data[time.tm].new_customers)}}</span>
                                                                </div>
                                                            </div>
                                                            <div *ngFor="let question of incomingReport.attributes.data.products; let qenstionalIndex = index"
                                                                 (contextmenu)="matMenuStatus = 4; incomingSelectedQuestion.setAttributes(question); onContextMenu($event, {})"
                                                                 [ngStyle]="{'max-width':'130px','margin':'5px'}"
                                                                 (click)="mainNav = 2;incomingSelectedQuestion.setAttributes(question);"
                                                                 class="pull-left cursor {{mainNav === 2 && service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(question.id)  ? 'selectedMatTab2': ''}}">
                                                                <div class="headline">{{question.settings.hasOwnProperty('alias') ? question.settings.alias.toUpperCase() : question.label.toString().toUpperCase()}}</div>
                                                                <div [ngStyle]="{'margin-top':'5px'}" class="counter">
                                                                    <span>{{service.numberWithCommans(question[time.tm].responses)}}</span>
                                                                </div>
                                                            </div>
                                                            <div *ngFor="let question of incomingReport.attributes.data.questions; let qenstionalIndex = index"
                                                                 (contextmenu)="matMenuStatus = 4; incomingSelectedQuestion.setAttributes(question); onContextMenu($event, {})"
                                                                 [ngStyle]="{'max-width':'130px','padding':'5px'}"
                                                                 (click)="mainNav = 1;incomingSelectedQuestion.setAttributes(question);"
                                                                 class="pull-left cursor {{mainNav === 1 && service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(question.id)  ? 'selectedMatTab2': ''}}">
                                                                <div class="headline">{{question.label.toString().toUpperCase()}}</div>
                                                                <div [ngStyle]="{'margin-top':'5px'}" class="counter">
                                                                    <span>{{service.numberWithCommans(question[time.tm].currency)}}</span>
                                                                </div>
                                                            </div>
                                                            <div [ngStyle]="{'padding-top':'5px'}">
                                                                <ng-container *ngIf="incomingReport.attributes.data.hasOwnProperty('criterias') && incomingReport.attributes.data.criterias.length !== 0">
                                                                    <ng-container *ngFor="let criteria of (this.service.orderBy(incomingReport.attributes.data.criterias,'asc','index')); let i = index">
                                                                        <ng-container *ngIf="service.parseFloat(criteria.level) === 1">
                                                                            <div class="pull-left cursor {{mainNav === 5 &&
                                                                                            this.incomingCriteriaSelected.getAttributes().hasOwnProperty(strings.getCntrlNum()) &&
                                                                                            this.incomingCriteriaSelected.getCntrlNum().toString().includes(criteria[strings.getCntrlNum()])  ?
                                                                                            'selectedMatTab2': ''}}"
                                                                                 (dblclick)="onSelectCriteria(criteria)"
                                                                                 (click)="mainNav = 5;incomingCriteriaSelected.setAttributes(criteria);"
                                                                                 [ngStyle]="{'max-width':'130px','padding':'5px'}">
                                                                                <div class="headline">
                                                                                    <span class="">{{service.upperStartingCharacter(criteria.title, true).toUpperCase().toString()}}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <img [src]="criteria.hasOwnProperty('src') ? criteria.src : ''"
                                                                                         [ngStyle]="{'max-width':'40px','max-height':'40px'}"
                                                                                         ngClass="mdl-shadow--2dpx"/>
                                                                                </div>
                                                                                <div [ngStyle]="{'margin-top':'5px'}">
                                                                                    <span class="counter"
                                                                                          [countUp]="criteria[time.tm].responses"
                                                                                          [options]="{startVal: 0,useGrouping: true}">
                                                                                        0
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="cursor pull-right" (click)='extend = (extend ? false : true)'>
                                                            <i class="cursor fa fa-angle-left "   [ngStyle]="{'margin-right':'5px'}"></i>
                                                            <i class="cursor fa fa-angle-right"   [ngStyle]="{'margin-right':'2px'}"></i>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]= "{'padding-botom':'20px'}">
                                                            <div *ngIf="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses').length > 0">
                                                                <div *ngIf="mainNav === 0">
                                                                    <div *ngIf="navFunc === 1">
                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'line'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="incomingReport.attributes.data.chart === 2">
                                                                        </app-bar-chartjs>

                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'bar'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="incomingReport.attributes.data.chart === 4"
                                                                        >
                                                                        </app-bar-chartjs>
                                                                        <div *ngIf="incomingReport.attributes.data.chart === 0">
                                                                            <app-piechart-chartjs
                                                                                    [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                    [label]="incomingForm.getResponseDisplayTitle()"
                                                                                    [limit]="10"
                                                                                    [type]="'pie'"
                                                                                    [height]="'105'"
                                                                                    [showLabels]="true"
                                                                                    [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                    [element]="'overallElement'"
                                                                                    *ngIf="hiddenFlag === 1"
                                                                                    [color]="'blue'">
                                                                            </app-piechart-chartjs>
                                                                            <div style="position: absolute; top:50px; right: 5px" [hidden]='true'>
                                                                                <div class="animated flipInY">
                                                                                    <div clfass="tile-stats" [ngStyle]="{'margin': '2px'}">
                                                                                        <table>
                                                                                            <tr *ngFor="let legend of only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label); let incremental=index">
                                                                                                <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                                <td class="headline2"> {{legend}}</td>
                                                                                                <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')[incremental]}}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="incomingForm.getResponseDisplayTitle()"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navFunc === 2"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                    <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="incomingForm.getResponseDisplayTitle()"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'progress')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navFunc === 3"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                    <ng-container *ngIf="navFunc === 4">
                                                                        <app-user-activity-chartjs
                                                                                [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                [labe2]="this.service.isNull(incomingFormSettings.getOutlet()) ? incomingForm.getResponseDisplayTitle().toString().toLowerCase() : ('outlet visited').toString().toLowerCase()"
                                                                                [label1]="'kpi'"
                                                                                [fill1]="false"
                                                                                [fill2]="true"
                                                                                [type]="'line'"
                                                                                [height]="'175'"
                                                                                [limit]="100"
                                                                                [d2]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                [d1]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                [element]="'overallElement'"
                                                                                [color1]="'blue'"
                                                                                [color2]="'blue'"
                                                                        >
                                                                        </app-user-activity-chartjs>
                                                                        <!--                                                                    <div style="position: absolute; bottom:5px;left: 40%; width: auto">-->
                                                                        <!--                                                                        <div class="animated flipInY">-->
                                                                        <!--                                                                            <div class="tile-stats" [ngStyle]="{'margin': '1px'}">-->
                                                                        <!--                                                                                <table>-->
                                                                        <!--                                                                                    <tr>-->
                                                                        <!--                                                                                        <td><div class="round" [ngStyle]="{'background': 'rgb(255, 199, 15)'}"></div></td>-->
                                                                        <!--                                                                                        <td class="headline2"> outlets visited</td>-->
                                                                        <!--                                                                                        <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(incomingReport.attributes.data[time.tm].responses)}}</td>-->
                                                                        <!--                                                                                        &lt;!&ndash;                                                                                            </tr>&ndash;&gt;-->
                                                                        <!--                                                                                        &lt;!&ndash;                                                                                            <tr>&ndash;&gt;-->
                                                                        <!--                                                                                        <td><div class="round" [ngStyle]="{'background': '#1f8ef1'}"></div></td>-->
                                                                        <!--                                                                                        <td class="headline2"> Kpi</td>-->
                                                                        <!--                                                                                        <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(incomingReport.attributes.data[time.tm].target)}}</td>-->
                                                                        <!--                                                                                    </tr>-->
                                                                        <!--                                                                                </table>-->
                                                                        <!--                                                                            </div>-->
                                                                        <!--                                                                        </div>-->
                                                                        <!--                                                                    </div>-->
                                                                    </ng-container>
                                                                </div>
                                                                <div *ngIf="mainNav === 4">
                                                                    <div *ngIf="navFunc === 1">
                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="'new customers'"
                                                                                         [type]="'line'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="incomingReport.attributes.data.chart === 2">
                                                                        </app-bar-chartjs>


                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="'new customers'"
                                                                                         [type]="'bar'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="incomingReport.attributes.data.chart === 4"
                                                                        >
                                                                        </app-bar-chartjs>
                                                                        <div *ngIf="incomingReport.attributes.data.chart === 0">
                                                                            <app-piechart-chartjs
                                                                                    [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                    [label]="'new customers'"
                                                                                    [limit]="10"
                                                                                    [type]="'pie'"
                                                                                    [height]="'105'"
                                                                                    [showLabels]="true"
                                                                                    [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers')"
                                                                                    [element]="'overallElement'"
                                                                                    *ngIf="hiddenFlag === 1"
                                                                                    [color]="'blue'">
                                                                            </app-piechart-chartjs>
                                                                            <div style="position: absolute; top:50px; right: 5px" [hidden]='true'>
                                                                                <div class="animated flipInY">
                                                                                    <div clfass="tile-stats" [ngStyle]="{'margin': '2px'}">
                                                                                        <table>
                                                                                            <tr *ngFor="let legend of only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label); let incremental=index">
                                                                                                <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                                <td class="headline2"> {{legend}}</td>
                                                                                                <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers')[incremental]}}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="incomingForm.getResponseDisplayTitle()"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navFunc === 2"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                    <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="incomingForm.getResponseDisplayTitle()"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'progress')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navFunc === 3"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                    <ng-container *ngIf="navFunc === 4">
                                                                        <app-user-activity-chartjs
                                                                                [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                [labe2]="('new customers').toString().toLowerCase()"
                                                                                [label1]="'kpi'"
                                                                                [fill1]="true"
                                                                                [type]="'line'"
                                                                                [height]="'175'"
                                                                                [limit]="100"
                                                                                [d2]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers')"
                                                                                [d1]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'new_customers_kpi')"
                                                                                [element]="'overallElement'"
                                                                                [color]="'blue'"
                                                                        >
                                                                        </app-user-activity-chartjs>
                                                                        <div style="position: absolute; bottom:5px;left: 40%; width: auto">
                                                                            <div class="animated flipInY">
                                                                                <div class="tile-stats" [ngStyle]="{'margin': '1px'}">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td><div class="round" [ngStyle]="{'background': 'rgb(255, 199, 15)'}"></div></td>
                                                                                            <td class="headline2"> new customers</td>
                                                                                            <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(incomingReport.attributes.data[time.tm].new_customers)}}</td>
                                                                                            <!--                                                                                            </tr>-->
                                                                                            <!--                                                                                            <tr>-->
                                                                                            <td><div class="round" [ngStyle]="{'background': '#1f8ef1'}"></div></td>
                                                                                            <td class="headline2"> Kpi</td>
                                                                                            <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(incomingReport.attributes.data[time.tm].new_customers_kpi)}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div *ngIf="mainNav === 3">
                                                                    <div *ngIf="navFunc === 1">
                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="'outlets_that_sell'"
                                                                                         [type]="'line'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'outlets_that_sell')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="incomingReport.attributes.data.chart === 2">
                                                                        </app-bar-chartjs>
                                                                    </div>
                                                                </div>
                                                                <div class="chartMargin" *ngIf="mainNav === 1">
                                                                    <div *ngFor="let question of getQuestions(incomingReport.attributes.data.questions, time.tm); let questionalIndex = index">
                                                                        <ng-container *ngIf="service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(question.id)">
                                                                            <ng-container *ngIf="navFunc === 1">
                                                                                <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="question.label"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                                 [element]="'overallElement'"
                                                                                                 [color]="'blue'">
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="navFunc === 4">
                                                                                <app-user-activity-chartjs
                                                                                        [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                        [labe2]="this.service.isNull(incomingFormSettings.getOutlet()) ? incomingForm.getResponseDisplayTitle().toString().toLowerCase() : ('outlet visited').toString().toLowerCase()"
                                                                                        [label1]="'kpi'"
                                                                                        [fill1]="true"
                                                                                        [type]="'line'"
                                                                                        [height]="'175'"
                                                                                        [limit]="100"
                                                                                        [d2]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                        [d1]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                        [element]="'overallElement'"
                                                                                        [color]="'blue'"
                                                                                >
                                                                                </app-user-activity-chartjs>
                                                                                <div style="position: absolute; bottom:5px;left: 40%; width: auto">
                                                                                    <div class="animated flipInY">
                                                                                        <div class="tile-stats" [ngStyle]="{'margin': '1px'}">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><div class="round" [ngStyle]="{'background': 'rgb(255, 199, 15)'}"></div></td>
                                                                                                    <td class="headline2"> {{question.label.toString().toLowerCase()}}</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(question[time.tm].currency)}}</td>
                                                                                                    <!--                                                                                            </tr>-->
                                                                                                    <!--                                                                                            <tr>-->
                                                                                                    <td><div class="round" [ngStyle]="{'background': '#1f8ef1'}"></div></td>
                                                                                                    <td class="headline2"> Kpi</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(question[time.tm].target)}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>

                                                                </div>
                                                                <div class="chartMargin" *ngIf="mainNav === 2">
                                                                    <div *ngFor="let product of incomingReport.attributes.data.products; let productIndex = index">
                                                                        <ng-container *ngIf="service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(product.id)">
                                                                            <ng-container *ngIf="navFunc === 1">
                                                                                <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="product.label"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                                 [element]="'overallElement'"
                                                                                                 [color]="'blue'"
                                                                                                 *ngIf="mainNav === 2"
                                                                                >
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="navFunc === 4">
                                                                                <app-user-activity-chartjs
                                                                                        [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                        [labe2]="product.label.toString().toLowerCase()"
                                                                                        [label1]="'kpi'"
                                                                                        [fill1]="true"
                                                                                        [type]="'line'"
                                                                                        [height]="'175'"
                                                                                        [limit]="100"
                                                                                        [d2]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                        [d1]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                        [element]="'overallElement'"
                                                                                        [color]="'blue'"
                                                                                >
                                                                                </app-user-activity-chartjs>
                                                                                <div style="position: absolute; bottom:5px;left: 40%; width: auto">
                                                                                    <div class="animated flipInY">
                                                                                        <div class="tile-stats" [ngStyle]="{'margin': '1px'}">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><div class="round" [ngStyle]="{'background': 'rgb(255, 199, 15)'}"></div></td>
                                                                                                    <td class="headline2"> {{product.label.toString().toLowerCase()}}</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(product[time.tm].responses)}}</td>
                                                                                                    <!--                                                                                            </tr>-->
                                                                                                    <!--                                                                                            <tr>-->
                                                                                                    <td><div class="round" [ngStyle]="{'background': '#1f8ef1'}"></div></td>
                                                                                                    <td class="headline2"> Kpi</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{service.numberWithCommans(product[time.tm].target)}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div class="chartMargin" *ngIf="mainNav === 5 && incomingCriteriaSelected.hasAttribute(strings.getTitle())">
                                                                    <div *ngFor="let criteria of incomingReport.attributes.data.criterias; let criteriaIndex = index">
                                                                        <ng-container *ngIf="incomingCriteriaSelected.getCntrlNum().toString().includes(criteria.cntrl_num)">
                                                                            <ng-container *ngIf="navFunc === 1">
                                                                                <app-bar-chartjs [labels]="only(criteria[time.tm][criteria[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], criteria[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="criteria.title"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(criteria[time.tm][criteria[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                                 [element]="'overallElement'"
                                                                                                 [color]="'blue'"
                                                                                >
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="navFunc === 4">
                                                                                <app-user-activity-chartjs
                                                                                        [labels]="only(criteria[time.tm][criteria[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], criteria[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                        [labe2]="criteria.title.toString().toLowerCase()"
                                                                                        [label1]="'kpi'"
                                                                                        [type1]="'line'"
                                                                                        [type2]="'line'"
                                                                                        [fill1]="true"
                                                                                        [fill2]="false"
                                                                                        [color1]="'gentelella'"
                                                                                        [color2]="'blue'"
                                                                                        [height]="'175'"
                                                                                        [limit]="100"
                                                                                        [d2]="only(criteria[time.tm][criteria[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                        [d1]="only(criteria[time.tm][criteria[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                        [element]="'overallElement'"
                                                                                        [color]="'blue'"
                                                                                >
                                                                                </app-user-activity-chartjs>
                                                                                <div style="position: absolute; bottom:5px;left: 40%; width: auto">
                                                                                    <div class="animated flipInY">
                                                                                        <div class="tile-statsx" [ngStyle]="{'margin': '1px'}">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><div class="round" [ngStyle]="{'background': '#1f8ef1'}"></div></td>
                                                                                                    <td class="mitra2"> {{this.service.limitTo(criteria.title.toString().toLowerCase(),10)}}</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="mitra2"> {{service.numberWithCommans(criteria[time.tm].responses)}}</td>
                                                                                                    <!--                                                                                            </tr>-->
                                                                                                    <!--                                                                                            <tr>-->
                                                                                                    <td><div class="round" [ngStyle]="{'background': 'rgb(255, 199, 15)'}"></div></td>
                                                                                                    <td class="mitra2"> Kpi</td>
                                                                                                    <td [ngStyle]="{'margin-left': '2px'}" class="mitra2"> {{service.numberWithCommans(criteria[time.tm].target)}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br><br>
                                                        </div>
                                                        <div [ngStyle]= "{'position':'absolute','bottom':'0px', 'width':'100%'}">
                                                            <table class="pull-right">
                                                                <td class="{{navFunc === 4 ? 'selectedMatTab3' : ''}} cursor"
                                                                    (click)="navFunc = 4"
                                                                    style="margin-left: 5px; padding-right: 5px">
                                                                    <div class="headline2" [ngStyle]="{'color':'#000','font-size':'small','font-weight':'bold'}">
                                                                        <span>actual vs kpi, </span>
                                                                    </div>
                                                                </td>
                                                                <td class="{{navFunc === 1 ? 'selectedMatTab3' : ''}} cursor"
                                                                    (click)="navFunc = 1"
                                                                    style="margin-left: 5px; padding-right: 5px">
                                                                    <center>
                                                                        <div class="headline2" [ngStyle]="{'color':'#000','font-size':'small','font-weight':'bold'}">
                                                                            <span> trending</span>
                                                                        </div>
                                                                    </center>
                                                                </td>
                                                            </table>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-2 col-md-2 col-sm-6 col-xs-12" *ngIf="!extend">
                                            <div class="tile-stats" [ngStyle]="{'height': 'auto'}">
                                                <table class="pull-right" [ngStyle]="{'margin-top': '0px'}">
                                                    <td class="selectedMatTab2 headline2">
                                                        <img [src]="incomingClient.getUnknown('logo')"
                                                             [ngStyle]="{'width':'30px','height':'30px'}"
                                                             ngClass="img-circle mdl-shadow--2dp"/>
                                                    </td>
                                                </table>
                                                <div>
                                                    <mat-calendar [selected]="startingAt"
                                                                  (selectedChange)="onSelectStartingAt($event)"
                                                                  id="startingAt">
                                                    </mat-calendar><br><br>
                                                </div>
                                                <div style="position: absolute;bottom: 0px;width: 100%" class="headline2">
                                                    <table class="pull-right" [ngStyle]="{'margin-top': '10px'}">
                                                        <td *ngFor="let time of durations" class="{{navTime === time.id ? 'selectedMatTab3' : ''}} cursor"
                                                            (click)="navTime = time.id; onChangeDuration(time.tm)"
                                                            style="margin-left: 0px; padding-right: 0px">
                                                            <div class="headline2" [ngStyle]="{'font-size':'smaller','font-weight':'bold'}">{{time.name}}&nbsp;</div>
                                                        </td>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-1 col-md-1 col-sm-6 col-xs-12"
                                             *ngIf="!extend">
                                            <div class="tile-stats" [ngStyle]="{'height': '275px'}">
                                                <table class="pull-right">
                                                    <td valign="top" style="margin-left: 5px; padding-right: 5px" class="selectedMatTab2">
                                                        <div class="headline2" [ngStyle]="{'font-size':'small','font-weight':'bold'}">kpi on set </div>
                                                    </td>
                                                </table>
                                                <div style="position: absolute;bottom: 5px;width: 100%" class="counter">
                                                    <div>
                                                        <ng-container *ngFor="let time of durations">
                                                            <ng-container *ngIf="navTime === time.id">
                                                                <div class="pull-right">
                                                                    <span *ngIf="mainNav === 0">{{service.numberWithCommans(incomingReport.attributes.data[time.tm].target)}}</span>
                                                                    <span *ngIf="mainNav === 3">{{service.numberWithCommans(incomingReport.attributes.data[time.tm].outlets_that_sell_kpi)}}</span>
                                                                    <span *ngIf="mainNav === 4">{{service.numberWithCommans(incomingReport.attributes.data[time.tm].new_customers_kpi)}}</span>
                                                                    <span *ngIf="mainNav === 1 || mainNav === 2">{{service.numberWithCommans(incomingSelectedQuestion.attributes[time.tm].target)}}</span>&nbsp;
                                                                    <span *ngIf="mainNav === 5 && incomingCriteriaSelected.getAttributes().hasOwnProperty(strings.getCntrlNum())">{{service.numberWithCommans(incomingCriteriaSelected.getAttributes()[time.tm].target)}}</span>&nbsp;
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-1 col-md-1 col-sm-6 col-xs-12"
                                             *ngIf="!extend">
                                            <div class="tile-stats" style="height: 275px">
                                                <center>
                                                    <ng-container *ngFor="let time of durations">
                                                        <ng-container *ngIf="navTime === time.id">
                                                            <center>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 0"
                                                                     [innerHTML]="service.easyPieChart(incomingReport.attributes.data[time.tm].progress) | safeUrl"></div>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 4"
                                                                     [innerHTML]="service.easyPieChart(incomingReport.attributes.data[time.tm].new_customers_progress) | safeUrl"></div>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 3"
                                                                     [innerHTML]="service.easyPieChart(incomingReport.attributes.data[time.tm].outlets_that_sell_progress) | safeUrl"></div>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 1"
                                                                     [innerHTML]="service.easyPieChart(incomingSelectedQuestion.attributes[time.tm].progress) | safeUrl"></div>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 2"
                                                                     [innerHTML]="service.easyPieChart(incomingSelectedQuestion.attributes[time.tm].progress) | safeUrl"></div>
                                                                <div class="counter"
                                                                     *ngIf="mainNav === 5 && incomingCriteriaSelected.getAttributes().hasOwnProperty('progress')"
                                                                     [innerHTML]="service.easyPieChart(incomingCriteriaSelected.getAttributes()[time.tm].progress) | safeUrl"></div>
                                                            </center>
                                                        </ng-container>
                                                    </ng-container>
                                                </center>
                                                <div style="position: absolute;bottom: 5px;width: 100%"
                                                     class="headline2 smallBoldx">
                                                    <center>
                                                        <span>progress in %</span>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-12 col-md-12 col-sm-6 col-xs-12"></div>
                                        <div class="animated flipInY col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                            <div class="tile-stats" style="height: 255px">
                                                <div *ngFor="let time of durations">
                                                    <div *ngIf="navTime === time.id">
                                                        <agm-map
                                                            #gm
                                                            [latitude]="incomingReport.attributes.data.markers[time.tm].markers.length  === 0 ? lat : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lat)"
                                                            [longitude]="incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? lng : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lng)"
                                                            [fitBounds]="false"
                                                            [zoom]="zoom"
                                                            (mapClick)="onMapClicked()"
                                                            [disableDefaultUI]="false"
                                                            [styles]="app.settings.map.styles.style1"
                                                            style="height : 300px">
                                                            <agm-marker
                                                                    *ngFor="let marker of (incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? [] :  incomingReport.attributes.data.markers[time.tm].markers)"
                                                                    [latitude]="marker.lat"
                                                                    [longitude]="marker.lng"
                                                                    [agmFitBounds]="true"
                                                                    [openInfoWindow]="true"
                                                                    (markerClick)="onClickMarker($event, marker)"
                                                                    [iconUrl]="icon">
                                                                <agm-info-window
                                                                        (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"
                                                                        #infoWindow>
                                                                    <div>
                                                                        <div class="sawasdee20">{{service.upperStartingCharacter(marker.postedBy.length === 0 ? marker.date : marker.postedBy.name, true)}}</div>
                                                                        <mat-divider></mat-divider>
                                                                        <div class="sawasdee20">{{service.upperStartingCharacter(marker.title, true)}}</div>
                                                                        <div>
                                                                            <!--                                                                    <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">-->
                                                                            <!--                                                                        <div class="carousel-cell">-->
                                                                            <!--                                                                            <img style='width: 200px;max-height: 300px' src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                                                            <!--                                                                        </div>-->
                                                                            <!--                                                                        <div class="carousel-cell">-->
                                                                            <!--                                                                            <img src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
                                                                            <!--                                                                        </div>-->
                                                                            <!--                                                                    </carousel>-->
                                                                        </div>
                                                                        <div> <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner></div>
                                                                        <div *ngIf="marker.hasOwnProperty('response')">
                                                                            <table>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td class="sawasdee20">Address</td>
                                                                                    <td class="sawasdee20">{{service.upperStartingCharacter(marker.response[0].address, true)}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="sawasdee20">Date</td>
                                                                                    <td class="sawasdee20">{{service.upperStartingCharacter(marker.response[0].date, true)}}</td>
                                                                                </tr>
                                                                                <tr *ngFor="let response of  marker.response[0].datas">
                                                                                    <td class="sawasdee20" style="border-bottom-style: dotted;border-bottom: 1px solid #5f6368;padding-right: 20px;">{{service.upperStartingCharacter(response.formControl.label.toString(), true)}}</td>
                                                                                    <td class="sawasdee20" style="border-bottom-style: dotted;border-left-style: dotted;border-bottom: 1px solid #5f6368;padding-left: 20px;border-left: 1px solid #5f6368;">{{service.upperStartingCharacter(response.data, true)}}</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </agm-info-window>
                                                            </agm-marker>
                                                        </agm-map>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <div class="tile-stats" style="height: 255px;">
                                                <table class="pull-right">
                                                    <td class="{{navAddress === 2 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navAddress = 2">
                                                        <div class="headline2 smallBoldx">Regions</div>
                                                    </td>
                                                    <td class="{{navAddress === 1 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navAddress = 1">
                                                        <div class="headline2 smallBoldx text-lowercase">Country</div>
                                                    </td>
                                                </table>
                                                <span class="iconn" style="margin-right: 5px"><i class="fa fa-checkk"></i>
                                            <span>
                                                <div class="headline2 smallerBold">
                                                    {{'geo-presentation'}}
                                                </div>
                                            </span>
                                        </span>
                                                <br><br>
                                                <div *ngFor="let time of durations">
                                                    <div *ngIf="time.id === navTime && incomingReport.attributes.hasOwnProperty(this.strings.getData()) && this.service.app.data.regions.hasOwnProperty(this.strings.getData())">
                                                        <div *ngIf="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'name').length === this.service.app.data.regions.data.length">
                                                            <ng-container *ngIf="mainNav === 0">
                                                                <app-piechart-chartjs
                                                                        [labels]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'responses')), 'name')"
                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                        [limit]="4"
                                                                        [type]="'pie'"
                                                                        [height]="'175px'"
                                                                        [showLabels]="true"
                                                                        [data]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'responses')), 'responses')"
                                                                        [element]="'addressElement'"
                                                                        [color]="'blue'">
                                                                </app-piechart-chartjs>
                                                            </ng-container>
                                                            <ng-container *ngIf="mainNav === 3">
                                                                <app-piechart-chartjs
                                                                        [labels]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'outlets_that_sell')), 'name')"
                                                                        [label]="'outlets that sell'"
                                                                        [limit]="4"
                                                                        [type]="'pie'"
                                                                        [height]="'175px'"
                                                                        [showLabels]="true"
                                                                        [data]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'outlets_that_sell')), 'outlets_that_sell')"
                                                                        [element]="'addressElement'"
                                                                        [color]="'blue'">
                                                                </app-piechart-chartjs>
                                                            </ng-container>
                                                            <ng-container *ngIf="mainNav === 4">
                                                                <app-piechart-chartjs
                                                                        [labels]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'new_customers')), 'name')"
                                                                        [label]="'new customers'"
                                                                        [limit]="4"
                                                                        [type]="'pie'"
                                                                        [height]="'175px'"
                                                                        [showLabels]="true"
                                                                        [data]="only((extractAndDesc(incomingReport.attributes.data.regions, time.tm, 'new_customers')), 'new_customers')"
                                                                        [element]="'addressElement'"
                                                                        [color]="'blue'">
                                                                </app-piechart-chartjs>
                                                            </ng-container>
                                                            <ng-container *ngIf="mainNav ===1">
                                                                <ng-container *ngFor="let question of getQuestions(incomingReport.attributes.data.questions, time.tm); let qenstionalIndex = index">
                                                                    <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                        <app-piechart-chartjs
                                                                                [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'),'name')"
                                                                                [label]="question.label.toString().toLowerCase()"
                                                                                [limit]="4"
                                                                                [type]="'pie'"
                                                                                [height]="'175px'"
                                                                                [showLabels]="true"
                                                                                [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'),'result')"
                                                                                [element]="'addressElement'"
                                                                                [color]="'blue'">
                                                                        </app-piechart-chartjs>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="mainNav ===2">
                                                                <ng-container *ngFor="let question of incomingReport.attributes.data.products; let qenstionalIndex = index">
                                                                    <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                        <app-piechart-chartjs
                                                                                [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'),'name')"
                                                                                [label]="question.label.toString().toLowerCase()"
                                                                                [limit]="4"
                                                                                [type]="'pie'"
                                                                                [height]="'175px'"
                                                                                [showLabels]="true"
                                                                                [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'),'result')"
                                                                                [element]="'addressElement'"
                                                                                [color]="'blue'">
                                                                        </app-piechart-chartjs>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12" [ngStyle]="{'padding': '0px','margin': '0px'}">-->
                                        <!--                                    <ng-container *ngIf="!service.empty(incomingFormSettings.getOutlet())">-->
                                        <!--                                        <div class="animated flipInY col-lg-11 col-md-11 col-sm-12 col-xs-12">-->
                                        <!--                                            <div class="tile-stats" style="height: 260px">-->
                                        <!--                                                <div [ngStyle]= "{'margin':'5px'}">-->
                                        <!--                                                    <div class="tile-stats cursor pull-right"-->
                                        <!--                                                         [ngStyle]= "{'height':'98px', 'max-width':'130px','background-color':'rgb(51 122 183 / 40%)'}">-->
                                        <!--                                                        <div [ngStyle]= "{'margin':'5px'}">-->
                                        <!--                                                            <span class="headlineX3">{{'Dealers'}}</span>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <br>-->
                                        <!--                                                        <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">-->
                                        <!--                                                            <div class='counter'>0</div>-->
                                        <!--                                                        </span>-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <div class="tile-stats cursor pull-right"-->
                                        <!--                                                         [ngStyle]= "{'margin-right':'5px','height':'98px', 'max-width':'130px','background-color':'rgb(51 122 183 / 40%)'}">-->
                                        <!--                                                        <div [ngStyle]= "{'margin':'5px'}">-->
                                        <!--                                                            <span class="headlineX3">{{'Distributors'}}</span>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <br>-->
                                        <!--                                                        <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">-->
                                        <!--                                                            <div class='counter'>0</div>-->
                                        <!--                                                        </span>-->
                                        <!--                                                    </div>-->
                                        <!--                                                    <ng-container *ngFor="let time of durations">-->
                                        <!--                                                        <ng-container *ngIf="time.id === navTime">-->
                                        <!--                                                            <ng-container *ngFor="let question of incomingReport.attributes.data.others.questions">-->
                                        <!--                                                                <ng-container *ngIf="question.settings.hasOwnProperty('distinct')">-->
                                        <!--                                                                    <ng-container *ngIf="service.parseFloat(question.settings.distinct) === 1">-->
                                        <!--                                                                        <div class="tile-stats cursor pull-right"-->
                                        <!--                                                                             [ngStyle]= "{'margin-right':'5px','height':'98px', 'max-width':'130px','background-color':'rgb(51 122 183 / 40%)'}">-->
                                        <!--                                                                            <div [ngStyle]= "{'margin':'5px'}">-->
                                        <!--                                                                                <span class="headlineX3">{{service.upperStartingCharacter(question.label, true)}}</span>-->
                                        <!--                                                                            </div>-->
                                        <!--                                                                            <br>-->
                                        <!--                                                                            <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">-->
                                        <!--                                                                              <div class='counter'>{{service.parseFloat(question[time.tm].sum) * service.parseFloat(incomingReport.attributes.data[time.tm].responses)}}</div>-->
                                        <!--                                                                            </span>-->
                                        <!--                                                                        </div>-->
                                        <!--                                                                    </ng-container>-->
                                        <!--                                                                </ng-container>-->
                                        <!--                                                            </ng-container>-->
                                        <!--                                                        </ng-container>-->
                                        <!--                                                    </ng-container>-->
                                        <!--                                                </div>-->
                                        <!--                                                &lt;!&ndash;                                            <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                <div class="tile-stats cursor pull-right"&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                     [ngStyle]= "{'height':'98px', 'max-width':'100px','background-color':'rgb(51 122 183 / 40%)'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        <span class="headline">{{('Dealers').toString()}}</span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <br>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div class='counter'>0</div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        &lt;!&ndash;                                                <mat-progress-bar mode="determinate" color='primary' value="100"></mat-progress-bar>&ndash;&gt;&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                <div class="tile-stats cursor pull-right"&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                     [ngStyle]= "{'height':'98px', 'max-width':'100px','background-color':'rgb(51 122 183 / 40%)'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        <span class="headline">{{('Receiving').toString()}}</span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <br>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div class='counter'>0</div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        &lt;!&ndash;                                                <mat-progress-bar mode="determinate" color='primary' value="100"></mat-progress-bar>&ndash;&gt;&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                <div class="tile-stats cursor pull-right"&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                     [ngStyle]= "{'height':'98px', 'max-width':'100px','background-color':'rgb(51 122 183 / 40%)'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        <span class="headline">{{('Order processed').toString()}}</span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <br>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div class='counter'>0</div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        &lt;!&ndash;                                                <mat-progress-bar mode="determinate" color='primary' value="100"></mat-progress-bar>&ndash;&gt;&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                <div class="tile-stats cursor pull-right"&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                     [ngStyle]= "{'height':'98px', 'max-width':'100px','background-color':'rgb(51 122 183 / 40%)'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        <span class="headline">{{('Order pendding').toString()}}</span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <br>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div class='counter'>0</div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        &lt;!&ndash;                                                <mat-progress-bar mode="determinate" color='primary' value="100"></mat-progress-bar>&ndash;&gt;&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                <div class="tile-stats cursor pull-right"&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                     [ngStyle]= "{'height':'98px', 'max-width':'100px','background-color':'rgb(51 122 183 / 40%)'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div [ngStyle]= "{'margin':'5px'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        <span class="headline">{{('Order placed').toString()}}</span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <br>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <span [ngStyle]= "{'position':'absolute','bottom':'0px','width':'100%'}">&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                    <div class='counter'>0</div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                        &lt;!&ndash;                                               <mat-progress-bar mode="determinate" color='primary' value="100"></mat-progress-bar>&ndash;&gt;&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </span>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
                                        <!--                                                &lt;!&ndash;                                            </div>&ndash;&gt;-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <!--                                        <div class="animated flipInY col-lg-1 col-md-1 col-sm-12 col-xs-12">-->
                                        <!--                                            <div class="tile-stats" style="height: 260px">-->
                                        <!--                                                <span class="headline2 pull-left selectedMatTab2"><span>order</span></span><br><br>-->
                                        <!--                                                <ng-container *ngFor="let time of durations">-->
                                        <!--                                                    <ng-container *ngIf="time.id === navTime">-->
                                        <!--                                                        &lt;!&ndash;                                                    <table [ngStyle]="{'width': '100%'}">&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                        <tr *ngFor="let user of extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'responses')" [ngStyle]="{'width': '100%'}">&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                            <td [ngStyle]="{'width': '100%'}">{{this.service.limitTo(user.name.toString().toLowerCase(), 100)}}</td>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                            <td [ngStyle]="{'width': '100%'}">&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                <div class="progress progress_sm">&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                    <div class="progress-bar bg-green" role="progressbar"&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                         aria-valuenow="60"&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                         aria-valuemin="0"&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                         aria-valuemax="100"&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                         [ngStyle]="{'width': this.service.percentageOf(user[time.tm].responses,incomingReport.attributes.data[time.tm].responses) + '%'}">&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                        <span class="sr-only">60% Complete</span>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                    </div>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                                </div>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                            </td>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                        </tr>&ndash;&gt;-->
                                        <!--                                                        &lt;!&ndash;                                                    </table>&ndash;&gt;-->
                                        <!--                                                        <div [ngStyle]="{'margin-left': '5px','margin-right': '5px'}">-->
                                        <!--                                                            <div class="widget_summaryx">-->
                                        <!--                                                                <div class="top5" [ngStyle]="{'width': '100%'}">-->
                                        <!--&lt;!&ndash;                                                                <mat-progress-bar mode="determinate" color='primary' value="40"></mat-progress-bar>&ndash;&gt;-->
                                        <!--                                                                    <div class="progress progress_sm" [ngStyle]="{'padding': '0px','margin': '0px'}">-->
                                        <!--                                                                        <div class="progress-bar bg-green" role="progressbar"-->
                                        <!--                                                                             aria-valuenow="60"-->
                                        <!--                                                                             aria-valuemin="0"-->
                                        <!--                                                                             aria-valuemax="100"-->
                                        <!--                                                                             [ngStyle]="{'width': 78 + '%'}">-->
                                        <!--                                                                            <span class="sr-only">60% Complete</span>-->
                                        <!--                                                                        </div>-->
                                        <!--                                                                    </div>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <div class="" [ngStyle]="{'width': '100%'}">-->
                                        <!--                                                                    <span class="headline2__">pendding</span>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <div class="" [ngStyle]="{'width': '100%'}">-->
                                        <!--                                                                    <span class="headline2">23,677</span>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <br>-->
                                        <!--                                                            </div>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <div [ngStyle]="{'margin-left': '5px','margin-right': '5px'}">-->
                                        <!--                                                            <div class="widget_summaryx">-->
                                        <!--                                                                <div class="top5" [ngStyle]="{'width': '100%'}">-->
                                        <!--&lt;!&ndash;                                                                    <mat-progress-bar mode="determinate" color='primary' value="40"></mat-progress-bar>&ndash;&gt;-->
                                        <!--                                                                        <div class="progress progress_sm" [ngStyle]="{'padding': '0px','margin': '0px'}">-->
                                        <!--                                                                            <div class="progress-bar bg-green" role="progressbar"-->
                                        <!--                                                                                 aria-valuenow="60"-->
                                        <!--                                                                                 aria-valuemin="0"-->
                                        <!--                                                                                 aria-valuemax="100"-->
                                        <!--                                                                                 [ngStyle]="{'width': 60 + '%'}">-->
                                        <!--                                                                                <span class="sr-only">60% Complete</span>-->
                                        <!--                                                                            </div>-->
                                        <!--                                                                        </div>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <div class="" [ngStyle]="{'width': '100%'}">-->
                                        <!--                                                                    <span class="headline2__">processed</span>-->
                                        <!--                                                                </div><br>-->
                                        <!--                                                            </div>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <div [ngStyle]="{'margin-left': '5px','margin-right': '5px'}">-->
                                        <!--                                                            <div class="widget_summaryx">-->
                                        <!--                                                                <div class="top5" [ngStyle]="{'width': '100%'}">-->
                                        <!--&lt;!&ndash;                                                                    <mat-progress-bar mode="determinate" color='primary' value="40"></mat-progress-bar>&ndash;&gt;-->
                                        <!--                                                                        <div class="progress progress_sm" [ngStyle]="{'padding': '0px','margin': '0px'}">-->
                                        <!--                                                                            <div class="progress-bar bg-green" role="progressbar"-->
                                        <!--                                                                                 aria-valuenow="60"-->
                                        <!--                                                                                 aria-valuemin="0"-->
                                        <!--                                                                                 aria-valuemax="100"-->
                                        <!--                                                                                 [ngStyle]="{'width': 99 + '%'}">-->
                                        <!--                                                                                <span class="sr-only">60% Complete</span>-->
                                        <!--                                                                            </div>-->
                                        <!--                                                                        </div>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <div class="" [ngStyle]="{'width': '100%'}">-->
                                        <!--                                                                    <span class="headline2__">transported</span>-->
                                        <!--                                                                </div><br>-->
                                        <!--                                                            </div>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <div [ngStyle]="{'margin-left': '5px','margin-right': '5px'}">-->
                                        <!--                                                            <div class="widget_summaryx">-->
                                        <!--                                                                <div class="top5" [ngStyle]="{'width': '100%'}">-->
                                        <!--&lt;!&ndash;                                                                    <mat-progress-bar mode="determinate" color='primary' value="40"></mat-progress-bar>&ndash;&gt;-->
                                        <!--                                                                        <div class="progress progress_sm" [ngStyle]="{'padding': '0px','margin': '0px'}">-->
                                        <!--                                                                            <div class="progress-bar bg-green" role="progressbar"-->
                                        <!--                                                                                 aria-valuenow="60"-->
                                        <!--                                                                                 aria-valuemin="0"-->
                                        <!--                                                                                 aria-valuemax="100"-->
                                        <!--                                                                                 [ngStyle]="{'width': 11 + '%'}">-->
                                        <!--                                                                                <span class="sr-only">60% Complete</span>-->
                                        <!--                                                                            </div>-->
                                        <!--                                                                        </div>-->
                                        <!--                                                                </div>-->
                                        <!--                                                                <div class="" [ngStyle]="{'width': '100%'}">-->
                                        <!--                                                                    <span class="headline2__">delivery</span>-->
                                        <!--                                                                </div><br><br>-->
                                        <!--                                                            </div>-->
                                        <!--                                                        </div>-->
                                        <!--                                                    </ng-container>-->
                                        <!--                                                </ng-container>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <!--                                    </ng-container>-->
                                        <!--                                </div>-->
                                        <div class="animated flipInY col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                            <div class="tile-stats" style="height: 260px">
                                                <div class="{{navTop5 === 3 ? 'selectedMatTab2' : ''}} headline2 sawasdee26">Top 5</div>
                                                <table class="pull-right">
                                                    <!--                                            <td class="{{navTop5 === 2 ? 'selectedMatTab2' : ''}} cursor"-->
                                                    <!--                                                style="margin-left: 5px; padding-right: 5px"-->
                                                    <!--                                                (click)="navTop5 = 2">-->
                                                    <!--                                                <div class="headline2">Branch</div>-->
                                                    <!--                                            </td>-->
                                                    <td class="{{navTop5 === 3 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navTop5 = 3">
                                                        <div class="headline2 smallBoldx">Teams</div>
                                                    </td>
                                                    <td class="{{navTop5 === 5 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navTop5 = 5">
                                                        <div class="headline2 smallBoldx">country</div>
                                                    </td>
                                                    <td class="{{navTop5 === 1 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navTop5 = 1">
                                                        <div class="headline2 smallBoldx">{{this.service.limitTo(incomingForm.getRespondentAlias(), 10)}}</div>
                                                    </td>
                                                    <td class="{{navTop5 === 4 ? 'selectedMatTab2' : ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navTop5 = 4">
                                                        <div class="headline2 smallBoldx">Regions</div>
                                                    </td>
                                                </table>
                                                <div>
                                                    <ng-container *ngFor="let time of durations">
                                                        <ng-container *ngIf="time.id === navTime">
                                                            <ng-container *ngIf="navTop5 === 1">
                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'responses')| slice: 0 : 5),'name')"
                                                                        [label]="(this.service.isNull(incomingFormSettings.getOutlet()) ? service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true).toLowerCase() : service.upperStartingCharacter('outlet visited', true)).toString().toLowerCase()"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'responses')| slice: 0 : 5),'responses')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 0"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'outlets_that_sell')| slice: 0 : 5),'name')"
                                                                        [label]="'outlets that sell'"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'outlets_that_sell')| slice: 0 : 5),'outlets_that_sell')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 3"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'new_customers')| slice: 0 : 5),'name')"
                                                                        [label]="'new customers'"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.users, time.tm, 'new_customers')| slice: 0 : 5),'new_customers')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 4"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <ng-container *ngIf="mainNav === 2">
                                                                    <ng-container *ngFor="let question of this.incomingReport.attributes.data.products; let qenstionalIndex = index">
                                                                        <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                            <app-bar-chartjs
                                                                                    [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.users,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                    [label]="question.label"
                                                                                    [limit]="10"
                                                                                    [type]="'line'"
                                                                                    [showYAxis]="'false'"
                                                                                    [height]="'175'"
                                                                                    [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.users,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                    [element]="'top5Element'"
                                                                                    [color]="'blue'">
                                                                            </app-bar-chartjs>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>

                                                                <ng-container *ngIf="mainNav === 1">
                                                                    <ng-container *ngFor="let question of getQuestions(this.incomingReport.attributes.data.questions,time.tm); let qenstionalIndex = index">
                                                                        <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                            <app-bar-chartjs
                                                                                    [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.users,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                    [label]="question.label"
                                                                                    [limit]="10"
                                                                                    [type]="'line'"
                                                                                    [showYAxis]="'false'"
                                                                                    [height]="'175'"
                                                                                    [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.users,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                    [element]="'top5Element'"
                                                                                    [color]="'blue'">
                                                                            </app-bar-chartjs>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-container *ngIf="navTop5 === 3">
                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'responses')| slice: 0 : 5),'name')"
                                                                        [label]="(this.service.isNull(incomingFormSettings.getOutlet()) ? service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true).toLowerCase() : service.upperStartingCharacter('outlet visited', true)).toString().toLowerCase()"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'responses')| slice: 0 : 5),'responses')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 0"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'outlets_that_sell')| slice: 0 : 5),'name')"
                                                                        [label]="'outlets that sell'"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'outlets_that_sell')| slice: 0 : 5),'outlets_that_sell')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 3"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <app-bar-chartjs
                                                                        [labels]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'new_customers')| slice: 0 : 5),'name')"
                                                                        [label]="'new customers'"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extractAndDesc(this.incomingReport.attributes.data.teams, time.tm, 'new_customers')| slice: 0 : 5),'new_customers')"
                                                                        [element]="'top5Element'"
                                                                        *ngIf="mainNav === 4"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>

                                                                <ng-container *ngIf="mainNav === 2">
                                                                    <ng-container *ngFor="let question of this.incomingReport.attributes.data.products; let qenstionalIndex = index">
                                                                        <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                            <app-bar-chartjs
                                                                                    [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.teams,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                    [label]="question.label"
                                                                                    [limit]="10"
                                                                                    [type]="'line'"
                                                                                    [showYAxis]="'false'"
                                                                                    [height]="'175'"
                                                                                    [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.teams,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                    [element]="'top5Element'"
                                                                                    [color]="'blue'">
                                                                            </app-bar-chartjs>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>

                                                                <ng-container *ngIf="mainNav === 1">
                                                                    <ng-container *ngFor="let question of getQuestions(this.incomingReport.attributes.data.questions,time.tm); let qenstionalIndex = index">
                                                                        <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                            <app-bar-chartjs
                                                                                    [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.teams,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                    [label]="question.label"
                                                                                    [limit]="10"
                                                                                    [type]="'line'"
                                                                                    [showYAxis]="'false'"
                                                                                    [height]="'175'"
                                                                                    [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.teams,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                    [element]="'top5Element'"
                                                                                    [color]="'blue'">
                                                                            </app-bar-chartjs>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-container *ngIf="navTop5 === 4">
                                                                <ng-container *ngIf="this.incomingReport.attributes.data.regions.length === this.service.app.data.regions.data.length">
                                                                    <ng-container  *ngIf="mainNav === 0">
                                                                        <app-bar-chartjs
                                                                                [labels]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'responses')| slice: 0 : 5),'name')"
                                                                                [label]="(this.service.isNull(incomingFormSettings.getOutlet()) ? service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true).toLowerCase() : service.upperStartingCharacter('outlet visited', true)).toString().toLowerCase()"
                                                                                [limit]="10"
                                                                                [type]="'line'"
                                                                                [showYAxis]="'false'"
                                                                                [height]="'175'"
                                                                                [data]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'responses')| slice: 0 : 5),'responses')"
                                                                                [element]="'top5Element'"
                                                                                [color]="'blue'">
                                                                        </app-bar-chartjs>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="mainNav === 1">
                                                                        <ng-container *ngFor="let question of getQuestions(this.incomingReport.attributes.data.questions,time.tm); let qenstionalIndex = index">
                                                                            <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                                <app-bar-chartjs
                                                                                        [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                        [label]="question.label"
                                                                                        [limit]="10"
                                                                                        [type]="'line'"
                                                                                        [showYAxis]="'false'"
                                                                                        [height]="'175'"
                                                                                        [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'questions',question.id,time.tm,'currency')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                        [element]="'top5Element'"
                                                                                        [color]="'blue'">
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="mainNav === 2">
                                                                        <ng-container *ngFor="let question of this.incomingReport.attributes.data.products; let qenstionalIndex = index">
                                                                            <ng-container  *ngIf="service.parseFloat(question.id) === service.parseFloat(incomingSelectedQuestion.getId())">
                                                                                <app-bar-chartjs
                                                                                        [labels]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'name')"
                                                                                        [label]="question.label"
                                                                                        [limit]="10"
                                                                                        [type]="'line'"
                                                                                        [showYAxis]="'false'"
                                                                                        [height]="'175'"
                                                                                        [data]="only((getSumByItemsAndColumnItemsAndItemIdAndPeriodAndColumn(incomingReport.attributes.data.regions,'products',question.id,time.tm,'responses')| sortBy: 'desc':'result'| slice: 0 : 5),'result')"
                                                                                        [element]="'top5Element'"
                                                                                        [color]="'blue'">
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container  *ngIf="mainNav === 3">
                                                                        <app-bar-chartjs
                                                                                [labels]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'outlets_that_sell')| slice: 0 : 5),'name')"
                                                                                [label]="'outlets that sell'"
                                                                                [limit]="10"
                                                                                [type]="'line'"
                                                                                [showYAxis]="'false'"
                                                                                [height]="'175'"
                                                                                [data]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'outlets_that_sell')| slice: 0 : 5),'outlets_that_sell')"
                                                                                [element]="'top5Element'"
                                                                                [color]="'blue'">
                                                                        </app-bar-chartjs>
                                                                    </ng-container>
                                                                    <ng-container  *ngIf="mainNav === 4">
                                                                        <app-bar-chartjs
                                                                                [labels]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'new_customers')| slice: 0 : 5),'name')"
                                                                                [label]="'new customers'"
                                                                                [limit]="10"
                                                                                [type]="'line'"
                                                                                [showYAxis]="'false'"
                                                                                [height]="'175'"
                                                                                [data]="only((extractAndDesc(this.incomingReport.attributes.data.regions, time.tm, 'new_customers')| slice: 0 : 5),'new_customers')"
                                                                                [element]="'top5Element'"
                                                                                [color]="'blue'">
                                                                        </app-bar-chartjs>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="mainNav === 5">
                                                                    <ng-container *ngFor="let criteria of incomingReport.attributes.data.criterias; let criteriaIndex = index">
                                                                        <ng-container *ngIf="service.parseFloat(criteria.level) === 1">
                                                                            <ng-container  *ngIf="incomingCriteriaSelected.attributes.hasOwnProperty(strings.getCntrlNum()) &&
                                                                                incomingCriteriaSelected.getCntrlNum().includes(criteria.cntrl_num) && incomingReport.getDuration() === strings.getCustom()">
                                                                                <app-bar-chartjs
                                                                                        [labels]="only(criteria[time.tm].regions, 'name')"
                                                                                        [label]="'regions'"
                                                                                        [limit]="10"
                                                                                        [type]="'line'"
                                                                                        [showYAxis]="'false'"
                                                                                        [height]="'175'"
                                                                                        [data]="only(criteria[time.tm].regions, 'responses')"
                                                                                        [element]="'top5Element'"
                                                                                        [color]="'blue'">
                                                                                </app-bar-chartjs>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                            <div class="tile-stats" style="height: 260px">
                                                <div class="headline2 smallBoldx">Recent</div>
                                                <table class="pull-right">
                                                    <td class="{{navRecent === 2 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 1px; padding-right: 1px"
                                                        (click)="navRecent = 2">
                                                        <div class="headline2 smallBoldx">videos</div>
                                                    </td>
                                                    <td class="{{navRecent === 3 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 1px; padding-right: 1px"
                                                        (click)="navRecent = 3">
                                                        <div class="headline2 smallBoldx">{{incomingForm.getResponseDisplayTitle().toLowerCase()}}</div>
                                                    </td>
                                                    <td class="{{navRecent === 1 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 1px; padding-right: 1px"
                                                        (click)="navRecent = 1">
                                                        <div class="headline2 smallBoldx">images</div>
                                                    </td>
                                                </table><br><br><br>
                                                <div>
                                                    <div [hidden]="navRecent !== 1">
                                                        <center>
                                                            <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">
                                                                <div class="carousel-cell" *ngFor="let image of incomingReport.attributes.data.recent.images">
                                                                    <img style='width: 200px;max-height: 300px' src="{{image.data}}">
                                                                </div>
                                                            </carousel>
                                                        </center>
                                                    </div>
                                                    <div *ngIf="navRecent === 3">
                                                        <table class="countries_list" style="width:100%" *ngIf="incomingReport.attributes.data.recent.responses.length !== 0">
                                                            <thead>
                                                            <tr>
                                                                <th class="headline2" *ngFor="let response of (incomingReport.attributes.data.recent.responses.length === 0 ? [] : onlyProduct(incomingReport.attributes.data.recent.responses[0].datas))">
                                                        <span title="{{service.removeHtmlTags(response.formControl.label)}}"
                                                              [innerHTML]="service.limitTo(response.formControl.label.toString(), 60)">
                                                        </span>
                                                                </th>
                                                                <!--                                                    <th class="headline2">{{incomingFormValues.getRespondentAlias()}}</th>-->
                                                                <!--                                                <th class="headline2">Team</th>-->
                                                                <!--                                                <th class="headline2">Address</th>-->
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr *ngFor="let response of incomingReport.attributes.data.recent.responses">
                                                                <td class="headline2" *ngFor="let resp of onlyProduct(response.datas)">
                                                                    <center>
                                                                        <div *ngIf="resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"> <span [innerHTML]="resp.data"></span></div>
                                                                        <div *ngIf="resp.formControl.control.id === 2">{{resp.data}}</div>
                                                                    </center>
                                                                </td>
                                                                <!--                                                    <td class="headline2">{{response.postedBy.name ? service.limitTo(response.postedBy.name, 10) : ''}}</td>-->
                                                                <!--                                                    <td class="headline2">{{response.postedBy.team ? service.limitTo(response.postedBy.team.name, 5) : ''}}</td>-->
                                                                <!--                                                    <td class="headline2 ">{{service.limitTo(response.address, 10)}}</td>-->
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <ng-container  *ngIf="incomingReport.attributes.data.criterias.length > 0">
                                            <ng-container *ngFor="let time of durations">
                                                <ng-container *ngIf="time.id === navTime">
                                                    <ng-container *ngFor="let criteria of (this.service.orderBy(incomingReport.attributes.data.criterias,'asc','index')); let criteriaIncrement = index">
                                                        <div class="pull-left {{criteria.hasOwnProperty('default_container_width') ? 'col-lg-'+criteria.default_container_width + ' col-md-'+criteria.default_container_width+' ' : 'col-lg-3 col-md-3 '}} col-sm-6 col-xs-12"
                                                             *ngIf="service.parseFloat(criteria.level) === 2">
                                                            <div class="tile-stats"
                                                                 [ngStyle]="{'height': criteria.hasOwnProperty('default_container_height') &&  !service.isNull(criteria.default_container_height) ? criteria.default_container_height + 'px' : '275px','padding':'0px'}">
                                                                <table>
                                                                    <td class="cursor"
                                                                        (click)="openCriterialabelPrompt(criteria)"
                                                                        [ngStyle]="{'width':'100%', 'margin-left': '10px', 'padding-right': '50px'}">
                                                                        <div class="headline2 smallBoldx" title="{{criteria.title}}">{{service.limitTo(service.upperStartingCharacter(criteria.title, true).toString().toLowerCase(), 400000)}}</div>
                                                                    </td>
                                                                    <td class="cursor" valign="bottom">
                                                                        <i class="fa fa-sort-asc"
                                                                           (click)="upperShiftCriteria(criteria,criteriaIncrement)"></i>
                                                                    </td>
                                                                    <td class="cursor" valign="top">
                                                                        <i class="fa fa-sort-desc"
                                                                           (click)="downShiftCriteria(criteria,criteriaIncrement)"></i>
                                                                    </td>
                                                                    <td class="selectedMatTab2 cursor"
                                                                        (click)="matMenuStatus = 7; this.outgoingCriteriaSelected.setAttributes(criteria);onContextMenu($event, {})"
                                                                        (mousedown)="outgoingCriteriaSelected.setAttributes(criteria)">
                                                                        <i class="fa fa-cog"></i>
                                                                    </td>
                                                                </table>
                                                                <ng-container *ngIf="hiddenFlag === 1">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'margin-top':'10px'}">
                                                                        <app-piechart-chartjs
                                                                                [labels]="only(criteria[time.tm].result, 'label')"
                                                                                [label]="criteria.title"
                                                                                [limit]="criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ? criteria.text_size : 10"
                                                                                [type]="'pie'"
                                                                                [showLabels]="true"
                                                                                [xGridlines]="false"
                                                                                [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                                [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                                [xAxis]="false"
                                                                                [data]="only(criteria[time.tm].result, 'responses')"
                                                                                [element]="criteria.cntrl_num + 'criteriaLevel2'"
                                                                                [color]="'blue'"
                                                                                [barThickness]="20"
                                                                                *ngIf="criteria.chart === 0">
                                                                        </app-piechart-chartjs>

                                                                        <ng-container *ngIf="criteria.chart === 1">
                                                                            <div class="top5">
                                                                                <div class="widget_summary"
                                                                                     *ngFor="let option of (criteria[time.tm].result); let incremental = index">
                                                                                    <div class=""
                                                                                         title="{{option.label}}"
                                                                                         [ngStyle]="{'width': (criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ?  criteria.text_size : '120') +'px'}">
                                                                                        <span class="headline2" >{{this.service.limitTo(option.label.toString().toLowerCase(), 200000)}}</span>
                                                                                    </div>
                                                                                    <div class=""
                                                                                         title="{{option.label}},{{service.numberWithCommans(option.responses) + ' ' +this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses')))}} %"
                                                                                         [ngStyle]="{'width': '100%'}">
                                                                                        <div class="progress">
                                                                                            <div class="progress-bar bg-green" role="progressbar"
                                                                                                 aria-valuenow="60"
                                                                                                 aria-valuemin="0"
                                                                                                 aria-valuemax="100"
                                                                                                 [ngStyle]="{'width': this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses'))) + '%'}">
                                                                                                <span class="sr-only">60% Complete</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="headline2"><span>{{this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses')))}}%</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>


                                                                        <app-bar-chartjs
                                                                                [labels]="only(criteria[time.tm].result, 'label')"
                                                                                [label]="criteria.title"
                                                                                [limit]="criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ? criteria.text_size : 10"
                                                                                [type]="'line'"
                                                                                [showLabels]="true"
                                                                                [xGridlines]="false"
                                                                                [xAxis]="true"
                                                                                [data]="only(criteria[time.tm].result, 'responses')"
                                                                                [element]="criteria.cntrl_num + 'criteriaLevel2'"
                                                                                [color]="'blue'"
                                                                                [barThickness]="20"
                                                                                *ngIf="criteria.chart === 2">
                                                                        </app-bar-chartjs>
                                                                        <app-bar-chartjs
                                                                                [labels]="only(criteria[time.tm].result, 'label')"
                                                                                [label]="criteria.title"
                                                                                [limit]="criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ? criteria.text_size : 10"
                                                                                [fill]="true"
                                                                                [type]="'bar'"
                                                                                [height]="criteria.hasOwnProperty('default_container_height') &&  !service.isNull(criteria.default_container_height) ? (criteria.default_container_height - 60) : '215'"
                                                                                [showLabels]="true"
                                                                                [xGridlines]="false"
                                                                                [xAxis]="true"
                                                                                [data]="only(criteria[time.tm].result, 'responses')"
                                                                                [element]="criteria.cntrl_num + 'criteriaLevel2'"
                                                                                [option]="criteria"
                                                                                [color]="'blue'"
                                                                                [barThickness]="20"
                                                                                [borderRadius]="180"
                                                                                *ngIf="criteria.chart === 4">
                                                                        </app-bar-chartjs>

                                                                        <center>
                                                                            <app-piechart
                                                                                    *ngIf="criteria.chart === 5"
                                                                                    [type]="'donut'"
                                                                                    [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                                    [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                                    [data]="{legend:this.service.orderBy(only(criteria[time.tm].result, 'label'), 'desc','label'),data:this.service.orderBy(getValuesByLableAndValue2(criteria[time.tm].result,'label','responses'),'desc','responses')}">
                                                                            </app-piechart>
                                                                        </center>

                                                                        <app-bar-chartjs
                                                                                [labels]="only(criteria[time.tm].result, 'label')"
                                                                                [label]="criteria.title"
                                                                                [limit]="criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ? criteria.text_size : 10"
                                                                                [fill]="true"
                                                                                [type]="'radar'"
                                                                                [height]="'260'"
                                                                                [showLabels]="true"
                                                                                [xGridlines]="false"
                                                                                [xAxis]="true"
                                                                                [data]="only(criteria[time.tm].result, 'responses')"
                                                                                [element]="criteria.cntrl_num + 'criteriaLevel2'"
                                                                                [color]="'blue'"
                                                                                [barThickness]="20"
                                                                                [borderRadius]="180"
                                                                                *ngIf="criteria.chart === 6">
                                                                        </app-bar-chartjs>

                                                                        <ng-container *ngIf="criteria.chart === 7">
                                                                            <table class="headline2" [ngStyle]="{'width': '100%'}">
                                                                                <tr [ngStyle]="{'width': '100%'}"
                                                                                    title="{{option.label}} : {{service.numberWithCommans(option.responses) + ' (' +this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses')))}} %)"
                                                                                    *ngFor="let option of (criteria[time.tm].result); let incremental = index">
                                                                                    <td [ngStyle]="{'width': (criteria.hasOwnProperty('text_size') && !service.isNull(criteria.text_size) ?  criteria.text_size : '120') +'px'}"
                                                                                        valign="top"
                                                                                        title="{{option.label.toString()}}">
                                                                                        <span>{{this.service.limitTo(option.label.toString().toLowerCase(), 20000)}}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                <span>
                                                                                    <mat-progress-bar
                                                                                            [ngStyle]="{'width': '100%'}"
                                                                                            mode="determinate"
                                                                                            color='primary'
                                                                                            value="{{this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses')))}}">
                                                                                    </mat-progress-bar>
                                                                                </span>
                                                                                    </td>
                                                                                    <td class="headline2">
                                                                                        <span>{{this.service.percentageOf(option.responses,getSum(only(criteria[time.tm].result, 'responses')))}}%</span>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </ng-container>
                                                                    </div>
                                                                    <div style="position: absolute; top:50px; right: 5px" *ngIf="criteria.chart === 0">
                                                                        <div class="animated flipInY">
                                                                            <div class="tile-stats" [ngStyle]="{'margin': '2px','height': '90px','overflow-y': 'auto'}">
                                                                                <table>
                                                                                    <tr *ngFor="let legend of only(criteria[time.tm].result, 'label'); let incremental=index">
                                                                                        <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                        <td class="headline2" title="{{legend}}"> {{this.service.limitTo(legend, 10)}}</td>
                                                                                        <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only(criteria[time.tm].result, 'responses')[incremental]}}</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="criteria.chart === 4">
                                                                        <div class="animated flipInY">
                                                                            <div class="tile-stats" [ngStyle]="{'margin': '2px','height': '90px','overflow-y': 'auto'}">
                                                                                <table>
                                                                                    <tr *ngFor="let legend of only(criteria[time.tm].result, 'label'); let incremental=index">
                                                                                        <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                        <td class="headline2" title="{{legend}}"> {{this.service.limitTo(legend, 10)}}</td>
                                                                                        <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only(criteria[time.tm].result, 'responses')[incremental]}}</td>
                                                                                    </tr>
                                                                                </table>
                                                                                +


                                                                                ++











                                                                                ++++++++
                                                                                ++
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div class="pull-left {{criteria.hasOwnProperty('default_container_width') ? 'col-lg-'+criteria.default_container_width + ' col-md-'+criteria.default_container_width+' ' : 'col-lg-6 col-md-6 '}} col-sm-6 col-xs-12"
                                                             *ngIf="service.parseFloat(criteria.level) === 3">
                                                            <div class="tile-stats"
                                                                 [ngStyle]="{'height': criteria.hasOwnProperty('default_container_height') &&  !service.isNull(criteria.default_container_height) ? criteria.default_container_height + 'px' : '275px','padding':'0px'}">
                                                                <table>
                                                                    <td class="cursor" [ngStyle]="{'width':'100%', 'margin-left': '10px', 'padding-right': '50px'}">
                                                                        <div class="headline2 smallBoldx" title="{{criteria.title}}">{{service.limitTo(criteria.title, 4000)}}</div>
                                                                    </td>
                                                                    <td class="cursor" valign="bottom">
                                                                        <i class="fa fa-sort-asc"
                                                                           (click)="upperShiftCriteria(criteria,criteriaIncrement)"></i>
                                                                    </td>
                                                                    <td class="cursor" valign="top">
                                                                        <i class="fa fa-sort-desc"
                                                                           (click)="downShiftCriteria(criteria,criteriaIncrement)"></i>
                                                                    </td>
                                                                    <td class="selectedMatTab2 cursor">
                                                                        <i class="fa fa-cog"
                                                                           (click)="matMenuStatus = 7; this.outgoingCriteriaSelected.setAttributes(criteria);onContextMenu($event, {})"
                                                                           (mousedown)="outgoingCriteriaSelected.setAttributes(criteria)">
                                                                        </i>
                                                                    </td>
                                                                </table>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'margin-top':'10px'}">
                                                                    <app-mitiple-user-activity
                                                                            [legends]="only(criteria[time.tm].options, 'label')"
                                                                            [options]="criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []"
                                                                            [label]="criteria.title"
                                                                            [limit]="10"
                                                                            [type]="'horizontalBar'"
                                                                            [showLabels]="true"
                                                                            [height]="'175'"
                                                                            [xGridlines]="false"
                                                                            [xAxis]="true"
                                                                            [data]="criteria[time.tm].options"
                                                                            [flag]="'criteria'"
                                                                            [element]="criteria.cntrl_num + 'criteriaLevel3'"
                                                                            [color]="'blue'"
                                                                            [barThickness]="20"
                                                                            [borderRadius]="15"
                                                                            *ngIf="criteria.chart === 1">
                                                                    </app-mitiple-user-activity>
                                                                    <app-mitiple-user-activity
                                                                            [legends]="only(criteria[time.tm].options, 'label')"
                                                                            [options]="criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []"
                                                                            [label]="criteria.title"
                                                                            [limit]="10"
                                                                            [type]="'line'"
                                                                            [showLabels]="true"
                                                                            [xGridlines]="false"
                                                                            [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                            [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                            [xAxis]="true"
                                                                            [data]="criteria[time.tm].options"
                                                                            [flag]="'criteria'"
                                                                            [element]="criteria.cntrl_num + 'criteriaLevel3'"
                                                                            [color]="'blue'"
                                                                            [barThickness]="20"
                                                                            [borderRadius]="15"
                                                                            *ngIf="criteria.chart === 2">
                                                                    </app-mitiple-user-activity>
                                                                    <app-mitiple-user-activity
                                                                            [legends]="only(criteria[time.tm].options, 'label')"
                                                                            [options]="criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []"
                                                                            [label]="criteria.title"
                                                                            [limit]="10"
                                                                            [type]="'area'"
                                                                            [showLabels]="true"
                                                                            [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                            [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                            [xGridlines]="false"
                                                                            [xAxis]="true"
                                                                            [data]="criteria[time.tm].options"
                                                                            [flag]="'criteria'"
                                                                            [element]="criteria.cntrl_num + 'criteriaLevel3'"
                                                                            [color]="'blue'"
                                                                            [barThickness]="20"
                                                                            [borderRadius]="15"
                                                                            *ngIf="criteria.chart === 3">
                                                                    </app-mitiple-user-activity>
                                                                    <app-mitiple-user-activity
                                                                            [legends]="only(criteria[time.tm].options, 'label')"
                                                                            [options]="criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []"
                                                                            [label]="criteria.title"
                                                                            [limit]="10"
                                                                            [type]="'bar'"
                                                                            [showLabels]="true"
                                                                            [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                            [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                            [xGridlines]="false"
                                                                            [xAxis]="true"
                                                                            [data]="criteria[time.tm].options"
                                                                            [flag]="'criteria'"
                                                                            [element]="criteria.cntrl_num + 'criteriaLevel3'"
                                                                            [color]="'blue'"
                                                                            [barThickness]="20"
                                                                            [borderRadius]="15"
                                                                            *ngIf="criteria.chart === 4">
                                                                    </app-mitiple-user-activity>
                                                                    <app-mitiple-user-activity
                                                                            [legends]="only(criteria[time.tm].options, 'label')"
                                                                            [options]="criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []"
                                                                            [label]="criteria.title"
                                                                            [limit]="10"
                                                                            [type]="'radar'"
                                                                            [showLabels]="true"
                                                                            [width]="criteria.hasOwnProperty('canvas_width') &&  !service.isNull(criteria.canvas_width) ? criteria.canvas_width : '120'"
                                                                            [height]="criteria.hasOwnProperty('canvas_height') &&  !service.isNull(criteria.canvas_height) ? criteria.canvas_height : '120'"
                                                                            [xGridlines]="false"
                                                                            [xAxis]="true"
                                                                            [data]="criteria[time.tm].options"
                                                                            [flag]="'criteria'"
                                                                            [element]="criteria.cntrl_num + 'criteriaLevel3'"
                                                                            [color]="'blue'"
                                                                            [barThickness]="20"
                                                                            [borderRadius]="15"
                                                                            *ngIf="criteria.chart === 6">
                                                                    </app-mitiple-user-activity>
                                                                </div>
                                                                <div style="position: absolute; top:40px; right: 5px">
                                                                    <div class="animated flipInY">
                                                                        <div class="tile-stats" [ngStyle]="{'margin': '2px','height': '90px','overflow-y': 'auto'}">
                                                                            <table>
                                                                                <tr *ngFor="let option of (criteria[time.tm].options.length >0 ? (criteria[time.tm].options[0].options.length > 0 ? criteria[time.tm].options[0].options : []) : []); let incremental=index">
                                                                                    <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                    <td class="headline2" title="{{option.label}}"> {{this.service.limitTo(option.label, 10)}}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <div *ngFor="let customReport of incomingReport.attributes.data.custom_reports">
                                            <div *ngIf="customReport.result.status === 0">
                                                <div class="animated flipInY col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                    <div class="tile-stats">
                                                        <table>
                                                            <td class="selectedMatTab2 cursor" [ngStyle]="{'width':'100%', 'margin-left': '10px', 'padding-right': '50px'}">
                                                                <div class="headline2" title="{{customReport.title}}">{{service.limitTo(customReport.title, 20)}}</div>
                                                            </td>
                                                            <td class="selectedMatTab2 cursor">
                                                                <i class="fa fa-cog"
                                                                   (click)="matMenuStatus = 1; onContextMenu($event, {})"
                                                                   (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
                                                                    <!--                                                          settings-->
                                                                </i>
                                                            </td>
                                                            <td class="selectedMatTab2">
                                                        <span class="cursor"
                                                              [ngStyle]="{'padding-left':'10px', 'padding-right':'10px'}"
                                                              (click)="matMenuStatus = 0; onContextMenu($event, {})"
                                                              (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
                                                            <i class="fa fa-angle-right"></i>
                                                        </span>
                                                            </td>
                                                        </table>
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="time.id === navTime">
                                                                <div *ngIf="customReport.chart === 0">
                                                                    <app-piechart-chartjs
                                                                            [labels]="only((extractAndDesc(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                            [label]="incomingForm.getResponseDisplayTitle()"
                                                                            [limit]="10"
                                                                            [type]="'pie'"
                                                                            [height]="'85'"
                                                                            [showLabels]="true"
                                                                            [data]="only((extractAndDesc(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                            [element]="customReport.id + 'customReportStatus0'"
                                                                            *ngIf="hiddenFlag === 1"
                                                                            [color]="'blue'">
                                                                    </app-piechart-chartjs>
                                                                    <div style="position: absolute; top:50px; right: 5px">
                                                                        <div class="animated flipInY">
                                                                            <div class="tile-stats" [ngStyle]="{'margin': '2px','height': '90px','overflow-y': 'auto'}">
                                                                                <table>
                                                                                    <tr *ngFor="let legend of only((extractAndDesc(customReport.result.data[0].options, time.tm, 'responses')), 'label'); let incremental=index">
                                                                                        <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                        <td class="headline2" title="{{legend}}"> {{this.service.limitTo(legend, 10)}}</td>
                                                                                        <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only((extractAndDesc(customReport.result.data[0].options, time.tm, 'responses')), 'responses')[incremental]}}</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <app-bar-chartjs
                                                                        [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                        [limit]="10"
                                                                        [type]="'line'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                        [element]="customReport.id + 'customReportStatus0'"
                                                                        *ngIf="hiddenFlag === 1 && customReport.chart === 2"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>
                                                                <app-bar-chartjs
                                                                        [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                        [limit]="10"
                                                                        [type]="'bar'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                        [element]="customReport.id + 'customReportStatus0'"
                                                                        *ngIf="hiddenFlag === 1 && customReport.chart === 4"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>
                                                                <app-bar-chartjs
                                                                        [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                        [limit]="10"
                                                                        [type]="'bar'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                        [element]="customReport.id + 'customReportStatus0'"
                                                                        *ngIf="hiddenFlag === 1 && customReport.chart === 1"
                                                                        [color]="'blue'">
                                                                </app-bar-chartjs>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="customReport.result.status === 1">
                                                <div class="animated flipInY col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                    <div class="tile-stats">
                                                        <table>
                                                            <td class="selectedMatTab cursor" [ngStyle]="{'width':'100%','margin-left': '10px', 'padding-right': '50px'}">
                                                                <div class="headline2">{{service.limitTo(customReport.title, 20)}}</div>
                                                            </td>
                                                            <td class="selectedMatTab">
                                                        <span class="cursor"
                                                              (click)="onContextMenu($event, {})"
                                                              (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
                                                            <i class="material-icons">expand_more</i>
                                                        </span>
                                                            </td>
                                                        </table>
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="time.id === navTime">
                                                                <app-mitiple-user-activity
                                                                        [legends]="only(customReport.result.data.data, 'label')"
                                                                        [options]="customReport.result.data.options"
                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                        [limit]="10"
                                                                        [type]="'bar'"
                                                                        [showYAxis]="'false'"
                                                                        [height]="'175'"
                                                                        [data]="customReport.result.data.data"
                                                                        [element]="customReport.id + 'customReportStatus1'"
                                                                        *ngIf="hiddenFlag === 1"
                                                                        [color]="'blue'">
                                                                </app-mitiple-user-activity>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="animated flipInY col-lg-12 col-md-12 col-sm-6 col-xs-12" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
                                            <div class="tile-stats" style="min-height: auto">
                                                <div *ngFor="let time of durations">
                                                    <div *ngIf="time.id === navTime">
                                                        <table class="pull-right" *ngIf="incomingSelectedRegion.attributes.hasOwnProperty('id')">
                                                            <td class="{{service.parseFloat(incomingSelectedRegion.getId()) === service.parseFloat(region.id) ? 'selectedMatTab2': ''}} cursor"
                                                                style="margin-left: 10px; padding-right: 50px"
                                                                (click)="navRegion = regionIndexOf; incomingSelectedRegion.setAttributes(region);handleReportByDistricts()"
                                                                *ngFor="let region of incomingReport.attributes.data.regions; let regionIndexOf = index">
                                                                <div class="headline">{{region.name}}</div>
                                                                <div class="counter" *ngIf="mainNav === 0">{{service.numberWithCommans(region[time.tm].responses)}}</div>
                                                                <div class="counter" *ngIf="mainNav === 3">{{service.numberWithCommans(region[time.tm].outlets_that_sell)}}</div>
                                                                <div class="counter" *ngIf="mainNav === 4">{{service.numberWithCommans(region[time.tm].new_customers)}}</div>
                                                                <div *ngIf="mainNav === 1">
                                                                    <div *ngFor="let question of getQuestions(region.questions, time.tm); let qenstionalIndex = index">
                                                                        <div class="counter"
                                                                             *ngIf="service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(question.id)">
                                                                            {{service.numberWithCommans(question[time.tm].currency)}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="mainNav === 2">
                                                                    <div *ngFor="let question of region.products; let productIndex = index">
                                                                        <div class="counter"
                                                                             *ngIf="service.parseFloat(incomingSelectedQuestion.getId()) === service.parseFloat(question.id)">
                                                                            {{service.numberWithCommans(question[time.tm].responses)}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;"><br><br>
                                                    <div *ngFor="let time of durations">
                                                        <div *ngIf="time.id === navTime">
                                                            <ng-container *ngIf="incomingSelectedRegion.attributes.hasOwnProperty('id')">
                                                                <div *ngFor="let region of (incomingReport.attributes.data.regions | jsonPropertyFilter: incomingSelectedRegion.getId() : 'id')">
                                                                    <div *ngIf="mainNav === 0">
                                                                        <app-bar-chartjs [labels]="only(region[time.tm][region[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], region[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'line'"
                                                                                         [limit]="1000"
                                                                                         [height]="'175'"
                                                                                         [data]="only(region[time.tm][region[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                         [element]="'regionOverallElement'"
                                                                                         [color]="'blue'">
                                                                        </app-bar-chartjs>
                                                                    </div>
                                                                    <div *ngIf="mainNav === 1">
                                                                        <div *ngFor="let question of getQuestions(region.questions, time.tm); let questionalIndex = index">
                                                                            <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="question.label"
                                                                                             [type]="'line'"
                                                                                             [limit]="100"
                                                                                             [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                             [element]="'regionOverallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="navCurrencies === questionalIndex" >
                                                                            </app-bar-chartjs>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="mainNav === 2">
                                                                        <div *ngFor="let product of region.products; let productIndex = index">
                                                                            <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="product.label"
                                                                                             [type]="'line'"
                                                                                             [limit]="100"
                                                                                             [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                             [element]="'regionOverallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="mainNav === 2" >
                                                                            </app-bar-chartjs>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="visibility: hidden; position: fixed"
             [style.left]="contextMenuPosition.x"
             [style.top]="contextMenuPosition.y"
             [matMenuTriggerFor]="contextMenu">
        </div>
        <mat-menu #contextMenu="matMenu">
            <ng-template matMenuContent let-item="inspector">
                <button mat-menu-item *ngIf="matMenuStatus === 0" (click)="exportCustomReport({})">Download</button>
                <div *ngIf="matMenuStatus === 1">
                    <div style="min-width: 400px; margin: 5px">
                        <mat-grid-list cols="2" rowHeight="2:1">
                            <mat-grid-tile *ngFor="let chart of charts; let chartIncremental = index">
                                <img class="cursor"
                                     src="{{chart.icon}}"
                                     (click)="incomingSelectedCustomReport.setChart(chart.id)"
                                     [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}" />
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
                <div *ngIf="matMenuStatus === 2"
                     [ngStyle]="{'color': '#000', 'padding':'0px'}">
                    <div style="min-width: 400px; margin: 0px">
                        <!--                <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12 normal">-->
                        <!--                    <div class="tile-statss">-->
                        <!--                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
                        <!--                            <section class="post-section blog-post" style="background-color: transparent !important;">-->
                        <!--                                <div class="container">-->
                        <!--                                    <div class="row">-->
                        <!--                                        <div class="col-md-4 col-sm-6 cursor"-->
                        <!--                                             (click)="linkAmbassadors()">-->
                        <!--                                            <div class="post-box">-->
                        <!--                                                <div class="text-box">-->
                        <!--                                                    <h4>-->
                        <!--                                                        <a>{{incomingForm.getRespondentAlias()}} list</a>-->
                        <!--                                                    </h4>-->
                        <!--                                                    <p></p>-->
                        <!--                                                </div>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                        <div class="col-md-4 col-sm-6 cursor"-->
                        <!--                                             (click)="getResponses()">-->
                        <!--                                            <div class="post-box">-->
                        <!--                                                <div class="text-box">-->
                        <!--                                                    <h4>-->
                        <!--                                                        <a>{{service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true)}}</a>-->
                        <!--                                                    </h4>-->
                        <!--                                                    <p></p>-->
                        <!--                                                </div>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                    </div>-->

                        <!--                                </div>-->
                        <!--                            </section>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <!--                </div>-->
                        <mat-grid-list cols="2" rowHeight="2:1" class="mitra14">
                            <!--                <mat-grid-tile class="cursor"  (click)="exportExcel = true; openDateMenu()">Export data to excel CSV for customized, in-depth analysis</mat-grid-tile>-->
                            <mat-grid-tile class="cursor"  (click)="gallery()">Gallery</mat-grid-tile>
                            <mat-grid-tile class="cursor"  (click)="getResponses()">{{service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true)}} in a table view</mat-grid-tile>
                            <!--                    <mat-grid-tile class="cursor"  (click)="outletEngagementAndSales()"> Outlet engagement and sales report</mat-grid-tile>-->
                            <!--                    <mat-grid-tile class="cursor"  (click)="dateRangeReport()"> Date range report</mat-grid-tile>-->
                            <!--                    <mat-grid-tile class="cursor"  (click)="redirectAmbassadorsPage()"> Ambassador list</mat-grid-tile>-->
                            <!--                    <mat-grid-tile class="cursor"  (click)="getAmbassadorsFinalReport()"> Ambassador final report</mat-grid-tile>-->
                        </mat-grid-list>
                    </div>
                </div>
                <!--     responses-->
                <div *ngIf="matMenuStatus === 3" class="headline2">
                    <button mat-menu-item (click)="viewResponsesByAll()">view</button>
                </div>
                <!--     questions-->
                <div *ngIf="matMenuStatus === 4" class="headline2">
                    <button mat-menu-item (click)="viewResponsesByQuestionId()">view</button>
                </div>
                <!--    products-->
                <div *ngIf="matMenuStatus === 5" class="headline2">
                    <button mat-menu-item (click)="viewResponsesByQuestionIdAndLabel()">view</button>
                </div>
                <!--    inventories-->
                <div *ngIf="matMenuStatus === 6" class="headline2">
                    <button mat-menu-item (click)="viewInventoriesByQuestionId()">view</button>
                </div>
                <!-- criteria-->
                <div *ngIf="matMenuStatus === 7">
                    <ng-container *ngIf="this.service.app.data.user.role.id === 1">
                        <button mat-menu-item (click)="openChartDialog(outgoingCriteriaSelected.getAttributes())">chart</button>
                        <button mat-menu-item (click)="openSettingsDialog(outgoingCriteriaSelected.getAttributes())">settings</button>
                    </ng-container>
                    <ng-container *ngIf="this.service.app.data.user.role.id !== 1">
                        <div style="min-width: 400px; margin: 5px">
                            <mat-grid-list cols="2" rowHeight="2:1">
                                <mat-grid-tile *ngFor="let chart of charts; let chartIncremental = index"
                                               (click)="incomingCriteriaSelected.setChart(chart.id)">
                                    <img class="cursor"
                                         src="{{chart.icon}}"
                                         [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}" />
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </ng-container>

                </div>
            </ng-template>
        </mat-menu>
        <a mat-mini-fab
           class=""
           (click)="scrollUp()"
           [ngStyle]="{'position':'fixed','bottom':'20px','right':'80px','background-color':'#fff','color':'#000'}">
            <i class="material-icons">keyboard_arrow_down</i>
        </a>
    </div>
<!--    <mat-drawer-container class="example-container">-->
<!--        <mat-drawer #drawer-->
<!--                    [disableClose]="false"-->
<!--                    [opened]="false"-->
<!--                    [position]="'end'"-->
<!--                    [mode]="'side'"-->
<!--                    [ngStyle]="{'width': 'auto','height': '100%','z-index': '999999999'}">-->
<!--            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"-->
<!--                 *ngIf="this.incomingCriteriaSelected.hasAttribute(strings.getTitle())">-->
<!--                <table [ngStyle]="{'width':'100%'}">-->
<!--                    <tr>-->
<!--                        <td ngClass="cursor"-->
<!--                            (dblclick)="openCriterialabelPrompt(this.incomingCriteriaSelected.getAttributes())"-->
<!--                            [ngStyle]="{'width':'100%'}"><h2>{{this.incomingCriteriaSelected.getTitle()}}</h2></td>-->
<!--                        <td><mat-icon ngClass="cursor" (click)="drawer.toggle()">close</mat-icon></td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td colSpan="2">-->
<!--                            <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="mdl-shadow&#45;&#45;2dp">-->
<!--                                <table [ngStyle]="{'min-width':'350px','margin':'5px'}">-->
<!--                                    <td [ngStyle]="{'width':'100%'}">-->
<!--                                        <input  [ngStyle]="{'-webkit-transform':'scaleX(1.37)','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
<!--                                                autocomplete="off"-->
<!--                                                ngClass="sawasdee18 searchbar"-->
<!--                                                #searchText-->
<!--                                                placeholder="">-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <mat-spinner-->
<!--                                                *ngIf="isCriteriaSearching"-->
<!--                                                class=""-->
<!--                                                color='warn'-->
<!--                                                [diameter]="20">-->
<!--                                        </mat-spinner>-->
<!--                                    </td>-->
<!--                                </table>-->
<!--                            </div>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </table><br><br>-->
<!--                <div>-->
<!--                    <center>-->
<!--                        &lt;!&ndash;                  <app-list-criterials></app-list-criterials>&ndash;&gt;-->
<!--                        &lt;!&ndash;                  <table [ngStyle]="{'-webkit-transform':'scaleX(1.37)'}" class="sawasdee">&ndash;&gt;-->
<!--                        &lt;!&ndash;                   <thead>&ndash;&gt;-->
<!--                        &lt;!&ndash;                       <tr>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2"></th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Outlet</th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Address</th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Phone</th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Category</th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <th class="mdl-data-table__cell&#45;&#45;non-numeric sawasdee"&ndash;&gt;-->
<!--                        &lt;!&ndash;                               *ngFor="let responseResult of (&ndash;&gt;-->
<!--                        &lt;!&ndash;                               incomingCriteriaSelected.getUnknown(incomingReport.getDuration())[strings.getData()].length === 0 ? [] :&ndash;&gt;-->
<!--                        &lt;!&ndash;                               incomingCriteriaSelected.getUnknown(incomingReport.getDuration())[strings.getData()][0].datas)">&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <ng-container *ngIf="responseResult.formControl.settings.hidden === 0">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #qrcode>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else advert">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #advert>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else description">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <span></span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #description>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else content">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <span></span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #content>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    <span title="{{this.service.removeHtmlTags(responseResult.formControl.label)}}"&ndash;&gt;-->
<!--                        &lt;!&ndash;                                          [innerHTML]="this.service.limitTo((responseResult.formControl.label), 20)">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           </th>&ndash;&gt;-->
<!--                        &lt;!&ndash;                       </tr>&ndash;&gt;-->
<!--                        &lt;!&ndash;                   </thead>&ndash;&gt;-->
<!--                        &lt;!&ndash;                   <tbody>&ndash;&gt;-->
<!--                        &lt;!&ndash;                       <tr *ngFor="let response of getResponsesByCriterialsAndSearchText(incomingCriteriaSelected.getUnknown(incomingReport.getDuration())[strings.getData()],searchText.value)">&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <td class="mdl-data-table__cell&#45;&#45;non-numeric sawasdee">{{response.reference2}}</td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <ng-container *ngIf="response.hasOwnProperty('outlet')">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{response.outlet.name}}</td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{response.outlet.address}}</td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{response.outlet.phone}}</td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{response.outlet.category.name}}</td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <ng-container *ngIf="!response.hasOwnProperty('outlet')">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"></td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"></td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"></td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"></td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           <td title="{{service.removeHtmlTags(responseResult.formControl.label)}}"&ndash;&gt;-->
<!--                        &lt;!&ndash;                               class="mdl-data-table__cell&#45;&#45;non-numeric headline2"&ndash;&gt;-->
<!--                        &lt;!&ndash;                               *ngFor="let responseResult of response.datas; let responseResultIncrease = index">&ndash;&gt;-->
<!--                        &lt;!&ndash;                               <ng-container *ngIf="responseResult.formControl.settings.hidden === 0">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #qrcode>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else photo">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #photo>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 2; else description">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <img [src]="responseResult.data"&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                [ngStyle]="{'max-width':'100px','max-height':'100px'}"/>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #description>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else checkbox">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #checkbox>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 3; else advert">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                               <center>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                   <table>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                       <tr *ngFor="let option of responseResult.formControl.options">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                           <td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                               {{service.upperStartingCharacter(option.label, true)}}&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                           </td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                       </tr>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                   </table>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                               </center>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           </div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #advert>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else content">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                           <div></div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                       </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   <ng-template #content><div [innerHTML]="responseResult.data|safeUrl"></div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                   </ng-template>&ndash;&gt;-->
<!--                        &lt;!&ndash;                               </ng-container>&ndash;&gt;-->
<!--                        &lt;!&ndash;                           </td>&ndash;&gt;-->
<!--                        &lt;!&ndash;                       </tr>&ndash;&gt;-->
<!--                        &lt;!&ndash;                   </tbody>&ndash;&gt;-->
<!--                        &lt;!&ndash;               </table>&ndash;&gt;-->
<!--                    </center>-->
<!--                </div>-->
<!--            </div>-->
<!--        </mat-drawer>-->
<!--        <mat-drawer-content>-->
<!--            -->
<!--        </mat-drawer-content>-->
<!--    </mat-drawer-container>-->
</ng-container>
