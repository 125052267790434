import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ResponseService} from '../../forms/responses/data/response.service';
import {FormService} from '../../forms/form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {AppService} from '../../app.service';
import * as moment from 'moment/moment';
import {NotifierService} from 'angular-notifier';
import {MatDrawer} from '@angular/material/sidenav';
import {Geolocation} from '@capacitor/geolocation';
import * as worker_threads from 'worker_threads';
import {Options, NgxQrcodeStylingService} from 'ngx-qrcode-styling';
import html2canvas from 'html2canvas';
import {ImageService} from '../../images/image.service';
import {PromptComponent} from '../../prompt/prompt.component';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
@Component({
  selector: 'app-chatbot-responses',
  templateUrl: './chatbot-responses.component.html',
  styleUrls: ['./chatbot-responses.component.scss']
})
export class ChatbotResponsesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('suggestionId')  inputElm: any;
  @ViewChild('drawer', {static: false})  drawer: any;
  @ViewChild('addresselm', {static: false})  addresselm: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  formId: any;
  incomingResult: any;
  incomingForm: any;
  strings: any;
  addressValue: any;
  incomingResponse: any;
  suggestions: any = [];
  flagforContextmenu: any = 0;
  suggestionControl = new FormControl();
  filteredSuggestionsOptions: any;
  outgoingRequest: any;
  incomingClient: any;
  incomingCampaign: any;
  pdfUrl: any = null;
  incomingInvitee: any;
  incomingFormSettings: any;
  outgoingResults: any;
  outgoingResult: any;
  outgoingResponseResults: any = [];
  incomingResponseResults: any;
  showHtmlDOC: any = true;
  yScrollingPart: any = 0;
  incomingResponseResultSelected: any;
  iconUrl: any;
  yHeight: any = 0;
  lat: any = -6.8643365;
  subscription: any;
  lng: any = 39.2712821;
  zoom: any = 5;
  shc: any = true;
  isCardGenerated = false;
  currentLocationLat: any  = 0;
  currentLocationLng: any = 0;
  positionOptions: any = {
    // timeout: 80000,
    // maximumAge: 60000,
    enableHighAccuracy: true,
  };
  constructor(private responseService: ResponseService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zone: NgZone,
              private qrcode: NgxQrcodeStylingService,
              public reponseService: ResponseService,
              public service: AppService,
              private dialog: MatDialog,
              private imgService: ImageService,
              public broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              private formService: FormService) {
    this.formId = this.activatedRoute.snapshot.params.id;
    this.strings = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingResponse = new Parameters();
    this.outgoingRequest = new Parameters();
    this.outgoingResults = new Parameters();
    this.outgoingResult = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingInvitee = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingResponseResultSelected = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingResponseResults = new Parameters();
    this.inputElm = ElementRef;
    this.addresselm = ElementRef;
    this.drawer = MatDrawer;
    this.contextMenu = MatMenuTrigger;
    this.incomingResponse.setAttributes({});
    this.incomingForm.setAttributes({});
    this.outgoingRequest.setAttributes({});
    this.incomingResponseResults.setAttributes({});
    this.incomingResponseResultSelected.setAttributes({});
    this.incomingInvitee.setAttributes({});
    this.incomingFormSettings.setAttributes({});
    this.incomingInvitee.setAttendeeName('');
    this.incomingInvitee.setAttendeeCompany('');

  }

  ngOnDestroy(): void {try{document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingFormSettings.getBackgroundColor(); }catch (e) {document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgb(16,30,81)'; }}

  ngAfterViewInit(): void {}

  addGooglelocation(inputText){
    let $incomingResponseResult: any = new Parameters();
    $incomingResponseResult.setAttributes(this.incomingResponseResultSelected.getAttributes());
    $incomingResponseResult.setData(this.addresselm.nativeElement.value);
    this.addresselm.nativeElement.value = '';
    this.zoom = 16;
    this.drawer.toggle();
    this.scrollPageDown();
    this.smsWhatsapp(this.incomingResponseResults.getAttributes());
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  workerResponseResultByResponseResults($responseResults){
    this.getResponseResultByResponseResults($responseResults, ($ougoingResponseResults: Parameters, $outgoingResponseResult: Parameters) => {
      if (parseFloat($outgoingResponseResult.getStatus()) === 0){
        console.log('sdfsdfjdsfdsjkf');
      }
    });
    return 'null';
  }

  startSuggestion(){
    this.filteredSuggestionsOptions = this.suggestionControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterSuggestion(name) : this.suggestions.slice()));
  }

  scroll(){setTimeout(() => { document.getElementsByTagName( 'body')[0].style.overflow = 'scroll';},this.service.timeout());}

  ngOnInit(): void {
    // #f3eee0
    this.iconUrl = {url: 'https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_my_location_48px-128.png', scaledSize: {height: 40, width: 40}};
    this.formId  = this.service.decrypt(this.formId);
    if (Object.keys(this.formService.getSelected()).length !== 0) {
      this.incomingForm.setAttributes(this.formService.getSelected());
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
      this.setResponse((result: any) => {
        if (window.location.href.includes('/wait..')) {
          window.history.back();
        } else{
          setTimeout(() => {
            this.incomingResponse.setAttributes(this.responseService.getSelected());
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setFormId(this.incomingForm.getId());
            this.outgoingRequest.setResponseId(this.incomingResponse.getId());
            this.chatbot(this.outgoingRequest.getAttributes());
          });
        }
      });
    }else if (Object.keys(this.formService.getSelected()).length === 0){
      this.router.navigateByUrl('/wait..');
      this.setStrings((result: any) => {
        this.strings.setAttributes(this.service.app.strings.params);
        if (this.service.getNavigatedUrl().includes(this.strings.getInvitations()) &&
            this.service.getNavigatedUrl().includes(this.strings.getChatbot())){
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setCntrlNum(this.service.decrypt(this.activatedRoute.snapshot.params.id));
          this.service.httpService('post', this.service.app.routes.responses.get.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
            this.incomingResponse.setAttributes(result);
            this.incomingForm.setAttributes(this.incomingResponse.getForm());
            this.responseService.setSelected(this.incomingResponse.getAttributes());
            this.formService.setSelected(this.incomingForm.getAttributes());
            window.history.back();
          }, (error: any) => {
          });
        }else {
          this.formService.requestFormByFormId(this.formId, (response) => {
            this.incomingForm.setAttributes(response);
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.formService.setSelected(this.incomingForm.getAttributes());
            this.ngOnInit();
          });
        }
      });
      this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
          .subscribe((item: any) => {
            if (item instanceof Object){
              if (item.hasOwnProperty('prompt')) {
                if (!this.service.isNull(item.prompt)) {
                  const incomingQuestion: any = new Parameters();
                  const incomingOption: any = new Parameters();
                  incomingQuestion.setAttributes(this.incomingResponseResultSelected.getUnknown(this.strings.getFormControll()));
                  incomingOption.setAttributes({});
                  incomingOption.setLabel(item.prompt);
                  incomingOption.setQuestions([]);
                  incomingOption.setSettings({l: null});
                  incomingOption.setResult({l: null});
                  incomingOption.setCntrlNum(this.service.random());
                  incomingQuestion.getOptions().push(incomingOption.getAttributes());
                }
              }
            }
          });
    }
    // window.addEventListener('scroll',() => {
    //   this.zone.run(() =>{
    //     this.yScrollingPart = window.pageYOffset;
    //   });
    // });

    // if(navigator){
    //   navigator.geolocation.getCurrentPosition(pos => {
    //     this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
    //     this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
    //   },(err: any) => {});
    // }

    // Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
    //   if(this.currentLocationLat === 0) setTimeout(() => {
    //     this.lat = parseFloat(pos.coords.latitude.toString());
    //     this.lng = parseFloat(pos.coords.longitude.toString());
    //     this.zoom = 18;
    //   });
    //   this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
    //   this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
    // });
  }



  onInputTextChange(event: any){
  }

  private filterSuggestion(name: string) {
    const filterValue = name.toLowerCase();
    return this.suggestions.filter((suggestion: any) => JSON.stringify(suggestion).toLowerCase().includes(filterValue));
  }

  onGoogleMapAutocompleteSelected(result: any) {
    const $incomingQuestion: any = new Parameters();
    const $incomingResult: any = new Parameters();
    $incomingQuestion.setAttributes(this.incomingResponseResultSelected.getUnknown(this.strings.getFormControll()));
    this.lat = parseFloat(result.geometry.location.lat());
    this.lng = parseFloat(result.geometry.location.lng());
    this.incomingResponseResultSelected.setData(this.addresselm.nativeElement.value);
    $incomingResult.setAttributes({});
    $incomingResult.setLat(result.geometry.location.lat());
    $incomingResult.setLng(result.geometry.location.lng());
    this.incomingResponseResultSelected.setResult($incomingResult.getAttributes());
    this.addresselm.nativeElement.value = '';
    this.zoom = 16;
    this.drawer.toggle();
    this.scrollPageDown();
    this.smsWhatsapp(this.incomingResponseResults.getAttributes());
  }

  onLocationSelected(location: any) {
  }

  openAgmmap(){
    this.yHeight = this.service.getHeight() + this.yScrollingPart;
    window.scrollTo(0, 0);
    this.drawer.toggle();
  }

  scrollPageDown(){
    setTimeout(() => {
   // this.yScrollingPart = window.pageYOffset;
   // window.scrollTo(0, this.service.getHeight() + this.yScrollingPart);
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      let $incomingQuestion: any = new Parameters();
      $incomingQuestion.setAttributes(this.incomingResponseResultSelected.getAttributes().formControl);
      if(parseFloat($incomingQuestion.getIndex()) === 0) {
        setTimeout(() => {
          let elementId: any = document.getElementById(this.incomingResponseResultSelected.attributes.formControl.id);
          if (elementId instanceof HTMLElement)
            elementId.scrollIntoView({behavior: 'smooth'});
        }, this.service.timeout());
      }
    });
  }


  onkeyupenter(text: any){
    text = text === null  ? this.inputElm.nativeElement.value : text;
    if (this.outgoingResponseResults.length !== 0){
      let $incomingResponseResult: any = new Parameters();
      let $incomingQuestion: any = new Parameters();
      $incomingQuestion.setAttributes(this.incomingResponseResultSelected.getAttributes().formControl);
      $incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown($incomingQuestion.getCntrlNum()));
      if ($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePhone())
        this.incomingInvitee.setAttendeePhone(text);
      if ($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany())
        this.incomingInvitee.setAttendeeCompany(text);
      if ($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName())
        this.incomingInvitee.setAttendeeName(text);
      if ($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeEmail()) {
        if(!this.service.validateEmail(text)) {
          this.notifier.notify(this.strings.getSuccess(),  this.service.app.strings.email); return;
        } else this.incomingInvitee.setAttendeeEmail(text);
      }
      if (!(parseFloat($incomingQuestion.getControlId()) === 5 ||
          parseFloat($incomingQuestion.getControlId()) === 3 )){
        if (parseFloat($incomingQuestion.getControlId()) === 23 && !this.service.validateEmail(text)){
          this.notifier.notify('success', this.service.app.strings.email.invalid); return;
        }if (parseFloat($incomingQuestion.getControlId()) === 10 && !this.service.numberIsValid(text)){
          this.notifier.notify('success', this.service.app.strings.number.wrong); return;
        } $incomingResponseResult.setData(text);
        this.inputElm.nativeElement.value = '';
        setTimeout(() => {this.scrollPageDown();}, this.service.timeout());
        this.updateResponseResult($incomingResponseResult, (output: any) => {
          this.smsWhatsapp(this.incomingResponseResults.getAttributes());
        });
      }
    }
  }

  getSuggestions(event: any){
    const incomingQuesstionSelected: any = new Parameters();
    incomingQuesstionSelected.setAttributes(this.incomingResponseResultSelected.getUnknown(this.strings.getFormControll()));
    if (parseFloat(incomingQuesstionSelected.getControlId()) === 1){
      if (this.service.isNull(event.target.value)) { this.suggestions = []; }
      setTimeout(() => {
        this.service.getSuggestions(event.target.value, (responses) => {
          this.suggestions = responses;
          if (this.service.isNull(event.target.value)) {
            this.startSuggestion();
          }
        });
      });
    }
  }

  onInputTextChangeChatbot(text: any){
    if (this.outgoingResponseResults.length !== 0){
      const $incomingResponseResult: any = new Parameters();
      const $incomingQuestion: any = new Parameters();
      $incomingResponseResult.setAttributes(this.incomingResponseResultSelected.getAttributes());
      $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
      if (parseFloat($incomingQuestion.getControlId()) === 6){
        if (text.length === parseFloat('3')){
          this.addresselm.nativeElement.value = text;
          this.inputElm.nativeElement.value = '';
          this.drawer.toggle();
          setTimeout(() => {this.addresselm.nativeElement.focus(); }, this.service.timeout());
        }
      }
    }
  }

  setResponse(call: any){
    this.setStrings((result: any) => {
      this.strings.setAttributes(this.service.app.strings.params);
      if (!this.responseService.getSelected().hasOwnProperty(this.strings.getId())){
        this.addResponseByFormId(this.incomingForm.getId(), (response: any) => {
          this.incomingResponse.setAttributes(response);
          this.responseService.setSelected(this.incomingResponse.getAttributes());
          this.setResponse(call);
        });
      } else { call(this.responseService.getSelected()); }
    });
  }


  onImgflUploaded(event: any){
    const $incomingQuestion: any = new Parameters();
    const $incomingQuestionSettings = new Parameters();
    $incomingQuestion.setAttributes(this.incomingResponseResultSelected.getUnknown(this.strings.getFormControll()));
    $incomingQuestionSettings.setAttributes({});
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.zone.run(() => {
        $incomingQuestion.setSrc(this.service.dataURItoBlob(reader.result));
        $incomingQuestion.setUnknown('pic', reader.result);
        $incomingQuestion.setUnknown('imgSrc', reader.result);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setSrc(reader.result);
        this.outgoingRequest.setFlag(0);
        this.outgoingRequest.setCaption('');
        this.outgoingRequest.setOrderBy('');
        this.outgoingRequest.setStatus(1);
        this.outgoingRequest.setUrl('');
        this.outgoingRequest.setPath('');
        this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
        event.target.files = null;
        this.service.httpService('post', this.service.app.routes.images.addRequest,
            this.outgoingRequest.getAttributes(), {}, (result: any) => {
              this.incomingResult.setAttributes(result);
              this.incomingResponseResultSelected.setData(result.path);
              setTimeout(() => {this.scrollPageDown();}, this.service.timeout());
              this.updateResponseResult(this.incomingResponseResultSelected, (output: any) => {
                this.smsWhatsapp(this.incomingResponseResults.getAttributes());
              });
            }, (error: any) => {
            });
      });
    };
  }

  onSelectDate(event: any){
    this.incomingResponseResultSelected.setData(moment(new Date(event)).format('MM/DD/YYYY'));
    this.incomingResponseResultSelected.setDate(event);
    setTimeout(() => {
      this.smsWhatsapp(this.incomingResponseResults.getAttributes());
    }, this.service.timeout() * parseFloat('1'));
  }

  getResponse(call: any): void{
    this.setStrings((result: any) => {
      this.strings.setAttributes(this.service.app.strings.params);
      if (!this.service.app.hasOwnProperty(this.strings.getData())) {
        this.service.app = Object.assign(this.service.app, {data: {}});
      }
      this.service.app.data = Object.assign(this.service.app.data, {status: {}});
      this.responseService.setPost(this.incomingForm.getAttributes(), (post: any) => {

      });
    });
  }

  chatbot(request: any){
    this.setStrings((result: any) => {
      this.strings.setAttributes(this.service.app.strings.params);
      if (!this.service.app.hasOwnProperty(this.strings.getData()))
        this.service.app = Object.assign(this.service.app, {data: {}});
      this.service.app.data = Object.assign(this.service.app.data, {status: {}});
      const $incomingRequest = new Parameters();
      $incomingRequest.setAttributes(request);
      // if(!$incomingRequest.hasAttribute(this.strings.getResponseId())) {
      //   // $response = $this.addResponseByFormId($incomingRequest.getFormId());
      //   // this.incomingResponse.setAttributes($response);
      // }else this.incomingResponse.setAttributes($this.find($incomingRequest.getResponseId()));
      $incomingRequest.setResponseId(this.incomingResponse.getId());
      // $this.phone = $incomingRequest.getPhone();
      // $request.merge(array($this.strings.getResponseId() => $incomingRequest.getResponseId()));
      if ($incomingRequest.hasAttribute(this.strings.getResponseId()))
        this.sendUserWhatsappQuestion(request);
      this.scroll();
    });
  }

  sendUserWhatsappQuestion($request: any){
    const $incomingRequest: any = new Parameters();
    $incomingRequest.setAttributes($request);
    $incomingRequest.setStart(0);
    const $outgoingResponseResults = new Parameters();
    $outgoingResponseResults.setAttributes({});
    // this.incomingResponse.setAttributes($incomingRequest.hasAttribute(this.strings.getResponseId()) ? );
    // this.incomingResponse.setAttributes(json_decode(json_encode(
    //       $incomingRequest.hasAttribute($this.strings.getResponseId()) ?
    //   collect($this.show($incomingRequest.getResponseId())) :
    //   collect($this.addResponseByFormId($incomingRequest.getFormId()));
    //   $this.incomingResponseSelected.setAttributes(this.incomingResponse.getAttributes());
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
    this.incomingClient.setAttributes(this.incomingCampaign.getClient());
    this.incomingForm.getControls().map(($question: any) => {
      const $incomingQuestion: any = new Parameters();
      let $incomingQuestionSettings: any = new Parameters();
      $incomingQuestion.setAttributes($question);
      $incomingQuestionSettings.setAttributes($incomingQuestion.getSettings());
      const $outgoingResponseResult: any = new Parameters();
      $outgoingResponseResult.setAttributes({});
      $outgoingResponseResult.setId(0);
      $outgoingResponseResult.setCntrlNum($incomingQuestion.getCntrlNum());
      $outgoingResponseResult.setUnknown(this.strings.getFormControll(), $incomingQuestion.getAttributes());
      $outgoingResponseResult.setQuestions({});
      $outgoingResponseResult.setShow(1);
      $outgoingResponseResult.setFlag(0);
      $outgoingResponseResult.setData('');
      $outgoingResponseResult.setPostId(this.incomingResponse.getId());
      $outgoingResponseResult.setFormId(this.incomingResponse.getFormId());
      $outgoingResponseResult.setFormControlId($incomingQuestion.getId());
      $outgoingResponseResult.setControlId($incomingQuestion.getControlId());
      $outgoingResponseResult.setResult('');
      if (parseFloat($incomingQuestion.getControlId()) === 33 ||
          parseFloat($incomingQuestionSettings.getIsMuted()) === 1 ||
          $incomingQuestion.getCntrlNum()  === this.incomingFormSettings.getIsInvited() ||
          $incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsCardSent() ||
          $incomingQuestion.getCntrlNum() === this.incomingFormSettings.getDelivered() ||
          $incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsReceivingInvitationCard() ||
          $incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsWhatsapp() ||
          $incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsAttending()) {
        $outgoingResponseResult.setFlag(1);
      }
      if (this.incomingResponse.hasAttribute(this.strings.getDatas())){
        this.incomingResponse.getUnknown(this.strings.getDatas()).map(($responseResult: any) => {
          let $incomingResponseResult: any = new Parameters();
          $incomingResponseResult.setAttributes($responseResult);
          $incomingResponseResult.setData(
              $incomingResponseResult.hasAttribute(this.strings.getData()) ?
                  $incomingResponseResult.getData() : '');
          $incomingResponseResult.setResult(
              $incomingResponseResult.hasAttribute(this.strings.getResult()) ?
                  $incomingResponseResult.getResult() : '');
          let $incomingResponseResultQuestion: any = new Parameters();
          $incomingResponseResultQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
          if ($incomingResponseResultQuestion.getCntrlNum() === $incomingQuestion.getCntrlNum()){
            $outgoingResponseResult.setData($incomingResponseResult.getData());
            $outgoingResponseResult.setResult($incomingResponseResult.getResult());
            $outgoingResponseResult.setId($incomingResponseResult.getId());
            if($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName())
              this.incomingInvitee.setAttendeeName($incomingResponseResult.getData());
          }
        });
        if (!(parseFloat($incomingQuestion.getControlId()) === 29)) {
          if (parseFloat($incomingRequest.getStart()) === 0){
            $incomingQuestion.setIndex(0);
            $outgoingResponseResult.setUnknown(this.strings.getFormControll() , $incomingQuestion.getAttributes());
            $incomingRequest.setStart(1);
          } $outgoingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $outgoingResponseResult.getAttributes());
        }
      }
    });
    this.showAllQuestions(
        $outgoingResponseResults.getAttributes(),
        ($responseResults: any) => {
          this.incomingResponseResults.setAttributes($responseResults);
          for (const $responseResult in this.incomingResponseResults.getAttributes()) {
            const $incomingResponseResult: any = new Parameters();
            const $incomingQuestion: any = new Parameters();
            const $incomingQuestionSettings: any = new Parameters();
            $incomingResponseResult.setAttributes({});
            $incomingResponseResult.setAttributes(
                this.incomingResponseResults.getUnknown($responseResult));
            $incomingQuestion.setAttributes(
                $incomingResponseResult.hasOwnProperty(this.strings.getFormControll()) ?
                    $incomingResponseResult.getUnknown(this.strings.getFormControll()) : {});
            $incomingQuestionSettings.setAttributes($incomingQuestion.hasAttribute(this.strings.getSettings()) ?
                $incomingQuestion.getSettings() : {});
            if($incomingQuestion.hasOwnProperty(this.strings.getId()) &&
                !this.service.isNull($incomingResponseResult.getData()) &&
                parseFloat($incomingQuestionSettings.getIsMuted()) !== 1 &&
                this.incomingFormSettings.getInvitationCard() !== $incomingQuestion.getCntrlNum() &&
                this.incomingFormSettings.getIsCardSent() !== $incomingQuestion.getCntrlNum() &&
                this.incomingFormSettings.getDelivered() !== $incomingQuestion.getCntrlNum() &&
                this.incomingFormSettings.getIsInvited() !== $incomingQuestion.getCntrlNum()) {
              this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
            }
          }
          for (const $responseResult in this.incomingResponseResults.getAttributes()) {
            const $incomingResponseResult: any = new Parameters();
            const $incomingQuestion: any = new Parameters();
            const $incomingQuestionSettings: any = new Parameters();
            $incomingResponseResult.setAttributes({});
            $incomingQuestion.setAttributes({});
            try {
              $incomingResponseResult.setAttributes(
                  this.incomingResponseResults.getUnknown($responseResult));
              $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
              if($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePhone() &&
                  !this.strings.isNull($incomingResponseResult.getData()))
                this.incomingInvitee.setAttendeePhone($incomingResponseResult.getData());
              if (this.service.findIntegerIndex(this.outgoingResponseResults, this.strings.getFormControlId(),
                      $incomingResponseResult.getFormControlId()) === -1 &&
                  !this.service.isNull($incomingResponseResult.getData()) &&
                  parseFloat($incomingQuestionSettings.getIsMuted()) !== 1 &&
                  this.incomingFormSettings.getAttendeePhone() !== $incomingQuestion.getCntrlNum() &&
                  this.incomingFormSettings.getIsInvited() !== $incomingQuestion.getCntrlNum() &&
                  this.incomingFormSettings.getDelivered() !== $incomingQuestion.getCntrlNum() &&
                  this.incomingFormSettings.getIsCardSent() !== $incomingQuestion.getCntrlNum() &&
                  this.incomingFormSettings.getAttendeeName() !== $incomingQuestion.getCntrlNum()) {
                this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
              } if (this.incomingInvitee.hasAttribute(this.strings.getAttendeeName()))
                $incomingQuestion.setLabel(
                    $incomingQuestion.getLabel().replaceAll('*' +
                        this.strings.getName(), this.incomingInvitee.getAttendeeName()));
            }catch(e){}
          }
          this.smsWhatsapp($responseResults);
        });
  }


  smsWhatsapp($responseResults){
    this.incomingResponseResults.setAttributes($responseResults);
    const worker = new Worker(this.workerResponseResultByResponseResults($responseResults));
    worker.onmessage = (e) => {};
    worker.postMessage('hello'); // Start the worker.
    this.inputElm.nativeElement.value = '';
  }

  addMissing(responseResult: any){
    const incomingResponseResult: any = new Parameters();
    incomingResponseResult.setAttributes(responseResult);
    this.incomingResponseResultSelected.setAttributes(incomingResponseResult);
    this.dialog.open(PromptComponent, {
      data  : {},
      width  : 'auto',
      height : 'auto',
    });
  }

  // getResponseResultByResponseResults($responseResults: any, $callback: any){
  //   this.incomingResponseResults.setAttributes($responseResults);
  //   let $outgoingResponseResult: any = new Parameters();
  //   $outgoingResponseResult.setAttributes({});
  //   $outgoingResponseResult.setStatus(0);
  //   for (var $responseResult in this.incomingResponseResults.getAttributes()) {
  //     if (Object.prototype.hasOwnProperty.call(this.incomingResponseResults.getAttributes(), $responseResult)) {
  //       let $incomingResponseResult = new Parameters();
  //       $incomingResponseResult.setAttributes(
  //           this.incomingResponseResults.getUnknown($responseResult));
  //       $incomingResponseResult.setResult($incomingResponseResult.attributes.hasOwnProperty(this.strings.getResult()) &&
  //       !this.service.isNull($incomingResponseResult.getResult()) ?
  //           $incomingResponseResult.getResult() : {});
  //       let $incomingQuestion = new Parameters();
  //       $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
  //       if(parseFloat($incomingResponseResult.getShow()) === 1){
  //         if(this.service.isNull($incomingResponseResult.getData()) && parseFloat($outgoingResponseResult.getStatus()) ===0){
  //           $outgoingResponseResult.setStatus(1);
  //           this.sendx(this.incomingResponseResults.getAttributes(), $incomingResponseResult.getAttributes());
  //         }else {
  //           if($incomingQuestion.getOptions().length !== 0 &&
  //               this.service.parseFloat($incomingQuestion.getControlId()) === 5){(
  //             $incomingQuestion.getOptions().map(($option: any) => {
  //               let $incomingOption: any = new Parameters();
  //               $incomingOption.setAttributes($option);
  //               if (JSON.stringify($incomingResponseResult.getResult()).includes($incomingOption.getCntrlNum())) {
  //
  //               }
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  getnextQuestionByOption($option: any, $status, $call: any){
    let $incomingOption: any = new Parameters();
    $incomingOption.setAttributes($option);
    $incomingOption.getQuestions().map(($question: any) => {
      let $incomingQuestion: any = new Parameters();
      $incomingQuestion.setAttributes($question);
      if (this.incomingResponseResults.getAttributes().hasOwnProperty($incomingQuestion.getCntrlNum())) {
        let $incomingResponseResult: any = new Parameters();
        $incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown($incomingQuestion.getCntrlNum()));
        $incomingResponseResult.setResult(this.service.isNull($incomingResponseResult.getResult()) ? {} : $incomingResponseResult.getResult());
        if (Object.keys($incomingResponseResult.getAttributes()).length !== 0) {
          if (this.service.isNull($incomingResponseResult.getData())) {
            if ($status === 0) {
              $status = parseFloat('1');
              $call($incomingResponseResult.getAttributes());
            }
          } else {
            if($incomingQuestion.getOptions().length !== 0) $status = parseFloat('1');
            $incomingQuestion.getOptions().map(($opt: any) => {
              let $incomingOpt: any = new Parameters();
              $incomingOpt.setAttributes($opt);
              if($incomingResponseResult.getResult() instanceof Object || $incomingResponseResult.getResult() instanceof Array ) {
                if (JSON.stringify($incomingResponseResult.getResult()).includes($incomingOpt.getCntrlNum())) {
                  this.getnextQuestionByOption($opt, 0, $call);
                }
              }
            });
          }
        }
      }
    });
    if($status === 0) $call({});
  }
  
  getnextQuestionByQuestion($question: any, $status, $call){
    let $incomingResponseResult: any = new Parameters();
    let $incomingQuestion: any = new Parameters();
    $incomingQuestion.setAttributes($question);
    $incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown($incomingQuestion.getCntrlNum()));
    $incomingResponseResult.setResult(this.service.isNull($incomingResponseResult.getResult()) ? {} : $incomingResponseResult.getResult());
    if (Object.keys($incomingResponseResult.getAttributes()).length !== 0) {
      $incomingQuestion.getOptions().map(($option: any) => {
        let $incomingOption: any = new Parameters();
        $incomingOption.setAttributes($option);
        if($incomingResponseResult.getResult() instanceof Object || $incomingResponseResult.getResult() instanceof Array) {
          if (JSON.stringify($incomingResponseResult.getResult()).includes($incomingOption.getCntrlNum())) {
            this.getnextQuestionByOption($incomingOption.getAttributes(), $status, $call);
          }
        }
      });
    }
  }


  getResponseResultByResponseResults($responseResults: any, $callback: any){
    this.incomingResponseResults.setAttributes($responseResults);
    const $outgoingResponseResult: any = new Parameters();
    $outgoingResponseResult.setAttributes({});
    $outgoingResponseResult.setStatus(0);
    for (const $responseResult in this.incomingResponseResults.getAttributes()) {
      if (Object.prototype.hasOwnProperty.call(this.incomingResponseResults.getAttributes(), $responseResult)) {
        const $incomingResponseResult = new Parameters();
        $incomingResponseResult.setAttributes(
            this.incomingResponseResults.getUnknown($responseResult));
        $incomingResponseResult.setResult($incomingResponseResult.attributes.hasOwnProperty(this.strings.getResult()) &&
        !this.service.isNull($incomingResponseResult.getResult()) ?
            $incomingResponseResult.getResult() : {});
        const $incomingQuestion = new Parameters();
        $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
        $outgoingResponseResult.setStatus(
            $outgoingResponseResult.hasAttribute(this.strings.getStatus()) ?
                $outgoingResponseResult.getStatus() : 0);
        if (parseFloat($outgoingResponseResult.getStatus()) === 0) {
          if ((parseFloat($incomingResponseResult.getShow()) === 1 &&
              !($incomingResponseResult.getAttributes().hasOwnProperty(this.strings.getFlag()) && $incomingResponseResult.getFlag() === 1)) ||
              (this.service.isNull($incomingResponseResult.getData()) && parseFloat($incomingResponseResult.getShow()) === 1)) {
            $outgoingResponseResult.setStatus(1);
            if (this.service.isNull($incomingResponseResult.getData())) {
              $incomingResponseResult.setStatus(1);
              $incomingResponseResult.setUnvisible(0);
              $incomingResponseResult.setFlag($incomingQuestion.getOptions().length === 0 ? 1 : 0);
              $outgoingResponseResult.setAttributes($incomingResponseResult.getAttributes());
              this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $outgoingResponseResult.getAttributes());
              this.sendx(this.incomingResponseResults.getAttributes(), $incomingResponseResult.getAttributes());
            } else {
              if($incomingQuestion.getOptions().length !== 0) $outgoingResponseResult.setStatus(1);
              this.getnextQuestionByQuestion($incomingQuestion.getAttributes(), 0, ($responseResultt : any) => {
                let $incomingResponseResultt: any = new Parameters();
                $incomingResponseResultt.setAttributes($responseResultt);
                if(Object.keys($incomingResponseResultt.getAttributes()).length !== 0){
                  let $incomingQuestionn: any = new Parameters();
                  $incomingQuestionn.setAttributes($incomingResponseResultt.getAttributes().formControl);
                  $incomingResponseResultt.setStatus(1);
                  $incomingResponseResultt.setUnvisible(0);
                  $incomingResponseResultt.setFlag($incomingQuestionn.getOptions().length === 0 ? 1 : 0);
                  $incomingResponseResultt.setAncestor($incomingQuestion.getCntrlNum());
                  $outgoingResponseResult.setAttributes($incomingResponseResultt.getAttributes());
                  this.incomingResponseResults.setUnknown($incomingQuestionn.getCntrlNum(), $outgoingResponseResult.getAttributes());
                  this.sendx(this.incomingResponseResults.getAttributes(), $incomingResponseResultt.getAttributes());
                }else {
                  $incomingResponseResult.setFlag(1);
                  $outgoingResponseResult.setStatus(1);
                  this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
                  this.getResponseResultByResponseResults(this.incomingResponseResults.getAttributes(), $callback);
                }
              });
              // $incomingQuestion.getOptions().map((option: any) => {
              //   let $incomingOption: any = new Parameters();
              //   $incomingOption.setAttributes(option);
              //   if($incomingResponseResult.getAttributes().hasOwnProperty(this.strings.getResult()) &&
              //       !this.service.isNull($incomingResponseResult.getResult())){
              //     if(JSON.stringify($incomingResponseResult.getResult()).includes($incomingOption.getCntrlNum())){
              //       this.getnextQuestionByOption(option, 0, ($responseResultt: any) => {
              //         let $incomingResponseResultt: any = new Parameters();
              //         $incomingResponseResultt.setAttributes($responseResultt);
              //         let $incomingQuestionn: any = new Parameters();
              //         $incomingQuestionn.setAttributes($incomingResponseResultt.getAttributes().formControl);
              //         if(Object.keys($incomingResponseResultt.getAttributes()).length !== 0){
              //           $incomingResponseResultt.setStatus(1);
              //           $incomingResponseResultt.setUnvisible(0);
              //           $outgoingResponseResult.setAttributes($incomingResponseResultt.getAttributes());
              //           this.incomingResponseResults.setUnknown($incomingQuestionn.getCntrlNum(), $outgoingResponseResult.getAttributes());
              //           this.sendx(this.incomingResponseResults.getAttributes(), $incomingResponseResultt.getAttributes());
              //         }else {
              //
              //         }
              //       });
              //     }
              //   }
              // });
              // if ($incomingQuestion.getOptions().length !== 0 && this.service.parseFloat($incomingQuestion.getControlId()) === 5){
              //   if (this.hasresponseResult($incomingResponseResult.getAttributes()) === 0) {
              //     const $incomingResult: any = new Parameters();
              //     $incomingResult.setAttributes($incomingResponseResult.getResult());
              //     $incomingQuestion.getOptions().map(($option: any) => {
              //       let $incomingOption: any = new Parameters();
              //       $incomingOption.setAttributes($option);
              //       $incomingOption.setFlag(
              //           $incomingOption.hasAttribute(this.strings.getFlag()) ?
              //               $incomingOption.getFlag() : 0);
              //       if (JSON.stringify($incomingResponseResult.getResult()).includes($incomingOption.getCntrlNum())) {
              //         this.getResponseResultByOption(
              //             this.incomingResponseResults.getAttributes(),
              //             $incomingResponseResult.getAttributes(),
              //             $incomingOption.getAttributes(),
              //             ($outgoingResponseResults: Parameters,
              //              $outgoingResponseResult: any,
              //              $incomingResponseResult: any,
              //              $outgoingOption: Parameters) => {
              //               if (parseFloat($outgoingResponseResult.getStatus()) === 0) {
              //                 const $incomingQuestion: any = new Parameters();
              //                 $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
              //                 const $options: any = $incomingQuestion.getOptions().map(($option: any) => {
              //                   $incomingOption = new Parameters();
              //                   $incomingOption.setAttributes($option);
              //                   if ($incomingOption.getCntrlNum() === $outgoingOption.getCntrlNum())
              //                     $incomingOption.setFlag(1);
              //                   return $incomingOption.getAttributes();
              //                 });
              //                 $incomingQuestion.setOptions($options);
              //                 $incomingResponseResult.setUnknown(this.strings.getFormControll(), $incomingQuestion.getAttributes());
              //                 $outgoingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
              //                 this.getResponseResultByResponseResults($outgoingResponseResults.getAttributes(), $callback);
              //               }
              //             });
              //       }
              //     });
              //   } else { $outgoingResponseResult.setStatus(0); }
              // } else { $outgoingResponseResult.setStatus(0); }
            }
          } else { $outgoingResponseResult.setStatus(0); }
        }
      }
    }
    $callback(this.incomingResponseResults, $outgoingResponseResult);
  }



  addOptionQuestions(option: any){
    const $incomingOption: any = new Parameters();
    $incomingOption.setAttributes(option);
    let $i: any = 0;
    if ($incomingOption.getQuestions().length !== 0) {
      $incomingOption.getQuestions().map((question: any) => {
        const $incomingQuestion: any = new Parameters();
        let $incomingQuestionSettings: any = new Parameters();
        $incomingQuestion.setAttributes(question);
        $incomingQuestionSettings.setAttributes($incomingQuestion.getSettings());
        if (this.incomingResponseResults.hasAttribute($incomingQuestion.getCntrlNum())) {
          const $incomingResponseResult: any = new Parameters();
          $incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown($incomingQuestion.getCntrlNum()));
          if (!this.service.isNull($incomingResponseResult.getData()) &&
              parseFloat($incomingQuestionSettings.getIsMuted()) !== 1 &&
              this.incomingFormSettings.getIsCardSent() !== $incomingQuestion.getCntrlNum() &&
              this.incomingFormSettings.getDelivered() !== $incomingQuestion.getCntrlNum() &&
              this.incomingFormSettings.getIsInvited() !== $incomingQuestion.getCntrlNum()) {
            if (this.service.findIntegerIndex(
                this.outgoingResponseResults,
                this.strings.getFormControlId(),
                $incomingQuestion.getId()) === -1) {
              this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
              $i = parseFloat('1');
              $incomingQuestion.getOptions().map(($opt) => {
                const $incomingOpt: any = new Parameters();
                $incomingOpt.setAttributes($opt);
                this.addOptionQuestions($incomingOpt.getAttributes());
              });
            }
          }
        }
      });
    }
    if ($i === 0){
      const $incomingResponseResult: any = new Parameters();
      $incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown($incomingOption.getQuestions()[0][this.strings.getCntrlNum()]));
      this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
    }
  }


  getResponseResultByOption($responseResults: any, $responseResult: any, $option: any, $callback: any){
    this.incomingResponseResults.setAttributes($responseResults);
    const $incomingResponseResult: any = new Parameters();
    $incomingResponseResult.setAttributes($responseResult);
    const $outgoingResponseResult: any = new Parameters();
    $outgoingResponseResult.setAttributes({});
    $outgoingResponseResult.setStatus(0);
    const $incomingOption: any = new Parameters();
    $incomingOption.setAttributes($option);
    $incomingOption.getQuestions().map(($optionQuestion: any) => {
      $outgoingResponseResult.setStatus(
          $outgoingResponseResult.hasAttribute(this.strings.getStatus()) ?
              $outgoingResponseResult.getStatus() : 0);
      if (parseFloat($outgoingResponseResult.getStatus()) === 0) {
        $outgoingResponseResult.setStatus(1);
        const $incomingQuestionOption: any = new Parameters();
        $incomingQuestionOption.setAttributes($optionQuestion);
        const $incomingResponseResultOptionQuestion: any = new Parameters();
        $incomingResponseResultOptionQuestion.setAttributes(
            this.incomingResponseResults.getUnknown($incomingQuestionOption.getCntrlNum()));
        $incomingResponseResultOptionQuestion.setFlag(0);
        if (parseFloat($incomingResponseResultOptionQuestion.getFlag()) === 0) {
          if (this.hasresponseResult($incomingResponseResultOptionQuestion.getAttributes()) === 0) {
            const $incomingResult: any = new Parameters();
            $incomingResult.setAttributes($incomingResponseResultOptionQuestion.getResult());
            if (this.service.isNull($incomingResponseResultOptionQuestion.getData())) {
              $outgoingResponseResult.setAttributes($incomingResponseResultOptionQuestion.getAttributes());
              $outgoingResponseResult.setFlag(1);
              $outgoingResponseResult.setStatus(1);
              this.incomingResponseResults.setUnknown($incomingQuestionOption.getCntrlNum(), $outgoingResponseResult.getAttributes());
              this.sendx(this.incomingResponseResults.getAttributes(), $outgoingResponseResult.getAttributes());
            } else {
              if ($incomingQuestionOption.getOptions().length !== 0) {
                $incomingQuestionOption.getOptions().map(($opt: any) => {
                  const $incomingOpt: any = new Parameters();
                  $incomingOpt.setAttributes($opt);
                  if ($incomingResult.getAttributes().hasOwnProperty($incomingOpt.getCntrlNum())) {
                    this.getResponseResultByOption(this.incomingResponseResults.getAttributes(),
                        $incomingResponseResultOptionQuestion.getAttributes(),
                        $incomingOpt.getAttributes(), $callback);
                  }
                });
              } else { $outgoingResponseResult.setStatus(0); }
            }
          }else { $outgoingResponseResult.setStatus(0); }
        }else { $outgoingResponseResult.setStatus(0); }
      }
    });
    $callback(this.incomingResponseResults, $outgoingResponseResult, $incomingResponseResult, $incomingOption);
  }

  onSuggestionSelectionChanged(event: any){
  }

  displayFn(value: any){
    const incomingDisplay: any = new Parameters();
    incomingDisplay.setAttributes(value);
    return incomingDisplay.getAttributes().hasOwnProperty(this.strings.getLabel()) ?
        incomingDisplay.getLabel() :
        incomingDisplay.getDescription();
  }

  sendx($responseResults: any, $responseResult: any){
    this.incomingResponseResults.setAttributes($responseResults);
    const $incomingResponseResult: any = new Parameters();
    $incomingResponseResult.setAttributes($responseResult);
    $incomingResponseResult.attributes.formControl.label.replaceAll('\n', '<br>');
    this.incomingResponseResultSelected.setAttributes($incomingResponseResult.getAttributes());
    const $incomingQuestion: any = new Parameters();
    $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
    this.incomingResponseResultSelected.setControlId($incomingQuestion.getControlId());
// this.suggestions = $incomingQuestion.getOptions();
    this.startSuggestion();
    const $incomingQuestionSettings: any = new Parameters();
    $incomingQuestionSettings.setAttributes(
        $incomingQuestion.hasAttribute(this.strings.getSettings()) ?
            $incomingQuestion.getSettings() : {});
    $incomingQuestionSettings.setSrc(
        $incomingQuestionSettings.hasAttribute(this.strings.getSrc()) ?
            $incomingQuestionSettings.getSrc() : '');
    alert($incomingQuestionSettings.getIsMuted());
    const $incomingIsInvited: any = new Parameters();
    $incomingIsInvited.setAttributes({});
    const $incomingInvitee: any = new Parameters();
    $incomingInvitee.setAttributes(this.incomingInvitee.getAttributes());
    const $incomingIsCardSent: any = new Parameters();
    $incomingIsCardSent.setAttributes({});
    const $incomingInvitationCard: any = new Parameters();
    $incomingInvitationCard.setAttributes({});
    const $incomingResponseResultt: any = new Parameters();
    if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeName()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeName())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeName()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeName($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(
            this.incomingFormSettings.getAttendeeSalutation()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeSalutation())) {
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeSalutation()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeSalutation($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeePhone()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeePhone())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeePhone()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeePhone($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeEmail()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeEmail())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeEmail()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeEmail($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeJobPosition()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeJobPosition())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeJobPosition()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeJobPosition($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeJobTitle()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeJobTitle())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeJobTitle()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeJobTitle($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeCompany()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeeCompany())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeCompany()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeeCompany($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeePic()) &&
        !this.service.isNull(this.incomingFormSettings.getAttendeePic())){
      $incomingResponseResultt.setAttributes(
          this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeePic()));
      if (!this.service.isNull($incomingResponseResultt.getData())) {
        $incomingInvitee.setAttendeePic($incomingResponseResultt.getData());
      }
    }if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getIsInvited()) &&
        !this.service.isNull(this.incomingFormSettings.getIsInvited())){
      $incomingResponseResultt.setAttributes(this.incomingResponseResults.getUnknown(this.incomingFormSettings.getIsInvited()));
      $incomingIsInvited.setAttributes($incomingResponseResultt.getAttributes());
    }
    this.incomingForm.getControls().map(($question: any) => {
      const $incomingQuestion: any = new Parameters();
      $incomingQuestion.setAttributes($question);
      $incomingQuestion.setData('');
      $incomingQuestion.setPostId(this.incomingResponse.getId());
      $incomingQuestion.setFormControlId($incomingQuestion.getId());
      $incomingQuestion.setControlId($incomingQuestion.getControlId());
      $incomingQuestion.setResponseId(this.incomingResponse.getId());
      $incomingQuestion.setResponseResultId(0);
      if (this.incomingFormSettings.getInvitationCard() === $incomingQuestion.getCntrlNum()){
        if (this.incomingResponseResults.hasAttribute($incomingQuestion.getCntrlNum())){
          $incomingResponseResultt.setAttributes(this.incomingResponseResults.getUnknown(this.incomingFormSettings.getInvitationCard()));
          $incomingQuestion.setData($incomingResponseResultt.getData());
          $incomingQuestion.setPostId(this.incomingResponse.getId());
          $incomingQuestion.setFormControlId($incomingQuestion.getId());
          $incomingQuestion.setControlId($incomingQuestion.getControlId());
          $incomingQuestion.setResponseId(this.incomingResponse.getId());
          $incomingQuestion.setResponseResultId($incomingResponseResultt.getId());
        }$incomingInvitationCard.setAttributes($incomingQuestion.getAttributes());
      }else if (this.incomingFormSettings.getIsCardSent() === $incomingQuestion.getCntrlNum()){
        if (this.incomingResponseResults.hasAttribute($incomingQuestion.getCntrlNum())){
          $incomingResponseResultt.setAttributes(this.incomingResponseResults.getUnknown(this.incomingFormSettings.getIsCardSent()));
          $incomingQuestion.setData($incomingResponseResultt.getData());
          $incomingQuestion.setPostId(this.incomingResponse.getId());
          $incomingQuestion.setFormControlId($incomingQuestion.getId());
          $incomingQuestion.setControlId($incomingQuestion.getControlId());
          $incomingQuestion.setResponseId(this.incomingResponse.getId());
          $incomingQuestion.setResponseResultId($incomingResponseResultt.getId());
        }$incomingIsCardSent.setAttributes($incomingQuestion.getAttributes());
      }
    });
    if($incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getIsPayed())){
    } else if ($incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationCard()){
      //this.addQuestion($incomingResponseResult);
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setResponseId(this.incomingResponse.getId());
      this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
      if (this.incomingInvitee.getAttributes().hasOwnProperty(this.strings.getAttendeePhone()) &&
          !this.service.isNull(this.incomingInvitee.getAttendeePhone())){
        this.outgoingRequest.setPhone(this.incomingInvitee.getAttendeePhone());
        this.outgoingRequest.setFlag(this.strings.getWhatsapp());
        let incomingResult: any = new Parameters();
        incomingResult.setAttributes({});
        incomingResult.setUnknown($incomingQuestion.getOptions()[0][this.strings.getCntrlNum()], $incomingQuestion.getLabel())
        $incomingResponseResult.setData($incomingQuestion.getLabel());
        $incomingResponseResult.setResult(incomingResult.getAttributes());
        $incomingResponseResult.setData($incomingQuestion.getLabel());
        $incomingResponseResult.setResult('');
        $incomingResponseResult.setFlag(1);
        this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
        this.sendInvitationCard(this.outgoingRequest.getAttributes(), (result: any) => {});
      }
      if (this.incomingInvitee.getAttributes().hasOwnProperty(this.strings.getAttendeeEmail()) &&
          !this.service.isNull(this.incomingInvitee.getAttendeeEmail())){
        this.outgoingRequest.setEmail(this.incomingInvitee.getAttendeeEmail());
        this.outgoingRequest.setFlag(this.strings.getEmail());
        let incomingResult: any = new Parameters();
        incomingResult.setAttributes({});
        incomingResult.setUnknown($incomingQuestion.getOptions()[0][this.strings.getCntrlNum()], $incomingQuestion.getLabel())
        $incomingResponseResult.setData($incomingQuestion.getLabel());
        $incomingResponseResult.setResult(incomingResult.getAttributes());
        $incomingResponseResult.setFlag(1);
        this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
        this.sendInvitationCard(this.outgoingRequest.getAttributes(), (result: any) => {});
      }this.smsWhatsapp(this.incomingResponseResults.getAttributes());
      // setTimeout(() => {
      //   if (this.incomingFormSettings.getInvitationCard() === $incomingQuestion.getCntrlNum()) {
      //     this.service.getDataUrl(this.incomingFormSettings.getFrame(), (url: any) => {
      //       if (!this.service.isNull(url)) {
      //         $incomingQuestion.setLabel($incomingQuestion.getLabel().replaceAll(this.incomingFormSettings.getFrame(), url));
      //         this.getCard($incomingResponseResult.getAttributes());
      //         // setTimeout(() => {
      //         //   const element: any = document.getElementById('qrcodeId');
      //         //   if (element instanceof  HTMLElement){
      //         //     const config: Options = {
      //         //       height: 550,
      //         //       width: 550,
      //         //       shape: 'square',
      //         //       rotate: 0,
      //         //       data: this.strings.getHost() +
      //         //           this.strings.getSlash() +
      //         //           this.strings.getAppForms() +
      //         //           this.strings.getSlash() +
      //         //           this.service.encrypt(this.incomingResponse.getPostId() +
      //         //               this.strings.getSlash() +
      //         //               this.strings.getConfirmation() +
      //         //               this.strings.getAccess() + this.incomingClient.getLogo().split(this.strings.getClients() + this.strings.getSlash())[1] +
      //         //               this.strings.getExtension() + this.incomingFormSettings.getBackgroundColor()).toString(),
      //         //       margin: 0,
      //         //       dotsOptions: {
      //         //         color: this.incomingFormSettings.getBackgroundColor(),
      //         //         type: 'extra-rounded'
      //         //       },
      //         //       cornersDotOptions: {
      //         //         color: this.incomingFormSettings.getBackgroundColor(),
      //         //         type: 'dot'
      //         //       },
      //         //       frameOptions: {
      //         //         style: 'FE_125',
      //         //         height: 250,
      //         //         width: 190,
      //         //         x: 140,
      //         //         y: 120,
      //         //         containers: [{
      //         //           fill: '#FFF',
      //         //           stroke: this.incomingFormSettings.getBackgroundColor()
      //         //         }, {
      //         //           fill: '#fff'
      //         //         }]
      //         //       },
      //         //       qrOptions: {
      //         //         errorCorrectionLevel: 'H',
      //         //       },
      //         //       backgroundOptions: {
      //         //         color: '#fff',
      //         //       },
      //         //       imageOptions: {
      //         //         crossOrigin: 'anonymous',
      //         //         margin: 0
      //         //       }
      //         //     };
      //         //     this.qrcode.create(config, element).subscribe((res) => {
      //         //       setTimeout((e) => {
      //         //         const contentId: any = document.getElementById('cardId');
      //         //         html2canvas(contentId).then(canvas => {
      //         //           this.zone.run(() => {
      //         //             this.pdfUrl = canvas.toDataURL('image/png');
      //         //             setTimeout(() => {
      //         //               this.scrollPageDown();
      //         //               this.getCard($incomingResponseResult.getAttributes());
      //         //             });
      //         //           });
      //         //         });
      //         //       }, this.service.timeout());
      //         //     });
      //         //   }
      //         // }, this.service.timeout());
      //       }
      //     });
      //   }
      // }, this.service.timeout());
    } else {
      this.addQuestion($incomingResponseResult);
    }
  }


  sendInvitationCard(request: any, callback: any){
    this.outgoingRequest.setAttributes(request);
    this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (result: any) => {callback();
        }, (error: any) => { callback(); });
  }


  getCard(responseResult: any){
    setTimeout(() => {
      const $incomingResponseResult: any = new Parameters();
      const $incomingQuestion: any = new Parameters();
      const $incomingResult: any = new Parameters();
      $incomingResult.setAttributes({});
      $incomingResponseResult.setAttributes(responseResult);
      $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
      $incomingResponseResult.setData($incomingQuestion.getOptions()[0].label);
      $incomingResult.setUnknown($incomingQuestion.getOptions()[0][this.strings.getCntrlNum()], {label: $incomingQuestion.getOptions()[0].label});
      $incomingResponseResult.setResult($incomingResult.getAttributes());
      // this.imgService.download(this.incomingForm.getName(), this.pdfUrl, (result: any) => {});
      this.updateResponseResult($incomingResponseResult, (rs: any) => {
        // this.sendInvitationCard()
        this.smsWhatsapp(this.incomingResponseResults.getAttributes());
        this.scrollPageDown();
      });
      // let element: any = document.getElementById('card');
      // if(element instanceof HTMLElement){
      //   html2canvas(element).then(canvas => {
      //     this.imgService.download(this.incomingForm.getName(), canvas.toDataURL('image/png'),(result: any) => {});
      //     this.updateResponseResult($incomingResponseResult, (rs: any) => {
      //       this.smsWhatsapp(this.incomingResponseResults.getAttributes());
      //       this.scrollPageDown();
      //     });
      //   });
      // }
    });
  }

  addQuestion($incomingResponseResult: any){
    setTimeout(() => {
      const $incomingQuestion: any = new Parameters();
      const $incomingQuestionSettings: any = new Parameters();
      $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
      $incomingQuestionSettings.setAttributes($incomingQuestion.getSettings());
      // this.incomingFormSettings.getTemplate().includes(this.strings.getWebsite()) &&
      // if(parseFloat(this.incomingForm.getCategoryId()) === 4 &&
      //     this.service.parseFloat($incomingQuestion.getIndex()) ===0 && this.service.isNull($incomingResponseResult.getData())){
      //   let incomingOption: any = new Parameters();
      //   let incomingResult: any = new Parameters();
      //   let outgoingRequest: any = new Parameters();
      //   incomingResult.setAttributes({});
      //   incomingOption.setAttributes($incomingQuestion.getOptions()[0]);
      //   incomingResult.setUnknown(incomingOption.getCntrlNum(), {label: incomingOption.getLabel(),id: incomingOption.getId()});
      //   $incomingResponseResult.setData(incomingOption.getLabel());
      //   $incomingResponseResult.setResult(incomingResult.getAttributes());
      //   outgoingRequest.setAttributes(Object.assign({}, $incomingResponseResult.getAttributes()));
      //   outgoingRequest.setResult(JSON.stringify(Object.assign({}, outgoingRequest.getResult())));
      //   this.smsWhatsapp(this.incomingResponseResults.getAttributes());
      //   if(parseFloat(outgoingRequest.getId()) === 0)
      //     delete outgoingRequest.attributes.id;
      //   this.service.httpService('post',
      //       this.service.app.routes.forms.responses.responseResults.update.toString(),
      //       outgoingRequest.getAttributes(),
      //       {ignoreLoadingBar : 'true', notify: true},
      //       (result: any) => {
      //         if(result instanceof Object && result.hasOwnProperty(this.strings.getId()))
      //           $incomingResponseResult.setId(result.id);
      //         // this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
      //       }, (error: any) =>  {});
      //   return;
      // }
      this.incomingInvitee.setAttendeeName(this.incomingInvitee.hasAttribute(this.strings.getAttendeeName()) &&
      !this.service.isNull(this.incomingInvitee.getAttendeeName()) ? this.incomingInvitee.getAttendeeName() : '`');
      if (this.incomingInvitee.getAttributes().hasOwnProperty(this.strings.getAttendeeName()) &&
          !this.service.isNull(this.incomingInvitee.getAttendeeName())) {
        $incomingQuestion.setLabel($incomingQuestion.getLabel().replaceAll('*' + this.strings.getName(), this.incomingInvitee.getAttendeeName()));
        $incomingQuestion.setLabel($incomingQuestion.getLabel().replaceAll('*' + this.strings.getCompany(), this.incomingInvitee.getAttendeeCompany()));
        $incomingQuestion.setLabel($incomingQuestion.getLabel().replaceAll('*' + this.strings.getQrcode(), ''));
        $incomingQuestionSettings.setAlias($incomingQuestionSettings.getAlias().replaceAll('*' + this.strings.getName(),
            this.incomingInvitee.getAttendeeName()));
      }if (this.incomingInvitee.getAttributes().hasOwnProperty(this.strings.getAttendeeCompany()) &&
          !this.service.isNull(this.incomingInvitee.getAttendeeCompany())) {
        $incomingQuestion.setLabel($incomingQuestion.getLabel().replaceAll('*' +
            this.strings.getCompany(), this.incomingInvitee.getAttendeeCompany()));
      } let position: any = this.service.findIntegerIndex(
          this.outgoingResponseResults, this.strings.getFormControlId() ,
          $incomingResponseResult.getFormControlId());
      if(position !== -1) this.outgoingResponseResults.splice(position, 1);
      this.service.playSoundByUrl(this.service.app.routes.app.message.play, () => {});
      this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
      this.scrollPageDown();
      // if (this.service.findIntegerIndex(this.outgoingResponseResults, this.strings.getFormControlId() ,
      //         $incomingResponseResult.getFormControlId()) === -1 &&
      //     this.incomingFormSettings.getIsInvited() !== $incomingQuestion.getCntrlNum()){
      //   this.service.playSoundByUrl(this.service.app.routes.app.message.play, () => {});
      //   this.outgoingResponseResults.push($incomingResponseResult.getAttributes());
      //   this.scrollPageDown();
      // }
    }, this.service.timeout());
  }

  next(responseResult: any){
    if (this.service.isNull(responseResult.data)) {
      responseResult.data = 'nulll';
      responseResult.result = {l: null};
    }this.smsWhatsapp(this.incomingResponseResults.getAttributes());
  }


  hasresponseResult($responseResult: any){
    let $incomingResponse = new Parameters();
    $incomingResponse.setAttributes(this.incomingResponse.getAttributes());
    let $incomingResponseResult = new Parameters();
    $incomingResponseResult.setAttributes($responseResult);
    let $incomingQuestion: any = new Parameters();
    $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
    let $incomingQuestionSettings: any = new Parameters();
    $incomingQuestionSettings.setAttributes($incomingQuestion.getSettings());
    let $incomingResult: any = new Parameters();
    $incomingResult.setAttributes(this.service.isNull($incomingResponseResult.getResult()) ? {} : $incomingResponseResult.getResult());
    let $options: any = $incomingQuestion.getOptions().filter(($option) => {
      let $incomingOption: any = new Parameters();
      $incomingOption.setAttributes($option);
      return $incomingOption.hasAttribute(this.strings.getFlag()) &&
          parseFloat($incomingOption.getFlag()) === 1;
    });
    if ($incomingQuestionSettings.hasAttribute(this.strings.getPlusOne())){
      if (parseFloat($incomingQuestionSettings.getPlusOne()) === 1){
        if (!this.service.isNull($incomingResponseResult.getData())){
          if (!(JSON.stringify($incomingResult.getAttributes()).includes(this.strings.getStatus()))){
            let $incomingForm: any = new Parameters();
            $incomingForm.setAttributes(this.incomingForm.getAttributes());
            let $incomingFormSettings = new Parameters();
            $incomingFormSettings.setAttributes($incomingForm.getSettings());
            let $incomingOption: any = new Parameters();
            $incomingOption.setAttributes($incomingQuestion.getOptions()[0]);
            let $incomingPlusone: any = new Parameters();
            $incomingPlusone.setAttributes({});
            $incomingOption.getQuestions().map(($optionQuestion: any) => {
              let $incomingOptionQuestion: any = new Parameters();
              $incomingOptionQuestion.setAttributes(Object.assign({}, $optionQuestion));
              let $incomingResponseResultOptionQuestion: any = new Parameters();
              $incomingResponseResultOptionQuestion.setAttributes(
                  this.incomingResponseResults.getUnknown($incomingOptionQuestion.getCntrlNum()));
              if (!this.service.isNull($incomingResponseResultOptionQuestion.getData())){
                if ($incomingOptionQuestion.getLabel().toLowerCase().includes(this.strings.getEmail()) &&
                    this.service.validateEmail($incomingResponseResultOptionQuestion.getData())){
                  $incomingPlusone.setEmail($incomingResponseResultOptionQuestion.getData());
                }if ($incomingOptionQuestion.getLabel().toLowerCase().includes(this.strings.getName())){
                  $incomingPlusone.setName($incomingResponseResultOptionQuestion.getData());
                }if ($incomingOptionQuestion.getLabel().toLowerCase().includes(this.strings.getPhone())){
                  let phone: any = $incomingResponseResultOptionQuestion.getData();
                  phone = $incomingResponseResultOptionQuestion.getData().replaceAll(' ', '');
                  if(!isNaN(phone)) $incomingPlusone.setPhone($incomingResponseResultOptionQuestion.getData());
                }
              }
            });
            if ($incomingPlusone.hasAttribute(this.strings.getName()) &&
                $incomingPlusone.hasAttribute(this.strings.getPhone()) &&
                $incomingPlusone.hasAttribute(this.strings.getEmail())){
              let $incomingResponsePlusone = new Parameters();
              $incomingResponsePlusone.setAttributes({});
              this.x($incomingPlusone, $incomingForm, $incomingResponsePlusone, $incomingFormSettings, $incomingQuestionSettings,(output: any) =>{
                if($incomingPlusone.hasAttribute('1') && $incomingPlusone.hasAttribute('2') && $incomingPlusone.hasAttribute('3') &&
                    !(JSON.stringify($incomingResponseResult.getResult()).includes(this.strings.getStatus()))){
                  if ($incomingPlusone.hasAttribute(this.strings.getName())){
                    let $outgoingRequest: any = new Parameters();
                    $outgoingRequest.setAttributes({});
                    $outgoingRequest.setResponseId($incomingResponsePlusone.getId());
                    if ($incomingPlusone.hasAttribute(this.strings.getPhone())){
                      $outgoingRequest.setFlag(this.strings.getWhatsapp());
                      this.responseService.sendInvitationCard($outgoingRequest, () => {});
                    }if ($incomingPlusone.hasAttribute(this.strings.getEmail())) {
                      $outgoingRequest.setFlag(this.strings.getEmail());
                      $outgoingRequest.setEmail($incomingPlusone.getEmail());
                      this.responseService.sendInvitationCard($outgoingRequest, () => {});
                    }$incomingResult.setStatus(1);
                    $outgoingRequest.setId($incomingResponseResult.getId());
                    $outgoingRequest.setPostId($incomingResponseResult.getPostId());
                    $outgoingRequest.setFormControlId($incomingQuestion.getId());
                    $outgoingRequest.setControlId($incomingQuestion.getControlId());
                    $outgoingRequest.setData($incomingResponseResult.getData());
                    $outgoingRequest.setResult(JSON.stringify($incomingResult.getAttributes()));
                    $incomingResponseResult.setResult($incomingResult.getAttributes());
                    this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
                    this.responseService.updateResponseResult($outgoingRequest, () => {});
                  }
                }
              });

            }

          }
        }
      }
    }

    return $options.length;
  }


  x($incomingPlusone, $incomingForm, $incomingResponsePlusone, $incomingFormSettings, $incomingQuestionSettings, call: any){
    this.responseService.addResponseByFormId($incomingForm.getId(), (response: any) => {
      if (response !== null){
        $incomingResponsePlusone.setAttributes(response);
        $incomingForm.getControls().map(($question: any) => {
          let $incomingQuestionn = new Parameters();
          $incomingQuestionn.setAttributes($question);
          let $incomingQuestionSettings = new Parameters();
          $incomingQuestionSettings.setAttributes($incomingQuestionn.getSettings());
          let $outgoingRequest = new Parameters();
          $outgoingRequest.setAttributes({});
          $outgoingRequest.setFormControlId($incomingQuestionn.getId());
          $outgoingRequest.setControlId($incomingQuestionn.getControlId());
          $outgoingRequest.setPostId($incomingResponsePlusone.getId());
          if (parseFloat($incomingQuestionn.getIndex()) === 0){
            let $incomingOption: any = new Parameters();
            $incomingOption.setAttributes($incomingQuestionn.getOptions()[0]);
            let $incomingResult = new Parameters();
            $incomingResult.setAttributes({});
            $incomingResult.setUnknown($incomingOption.getCntrlNum(), {data : $incomingOption.getLabel()});
            $outgoingRequest.setData($incomingOption.getLabel());
            $outgoingRequest.setResult(JSON.stringify($incomingResult.getAttributes()));
            this.responseService.updateResponseResult($outgoingRequest, (result: any) => {});
          }else if ($incomingQuestionn.getCntrlNum() ===  $incomingFormSettings.getAttendeePhone() &&
              $incomingPlusone.hasAttribute(this.strings.getPhone())){
            $outgoingRequest.setData($incomingPlusone.getPhone());
            $outgoingRequest.setResult('');
            this.responseService.updateResponseResult($outgoingRequest, (result: any) => {
              $incomingPlusone.setUnknown('1');
              call(null);
            });
          }else if ($incomingQuestionn.getCntrlNum() ===  $incomingFormSettings.getAttendeeEmail() &&
              $incomingPlusone.hasAttribute(this.strings.getEmail())){
            $outgoingRequest.setData($incomingPlusone.getEmail());
            $outgoingRequest.setResult('');
            this.responseService.updateResponseResult($outgoingRequest, (result: any) => {
              $incomingPlusone.setUnknown('2');
              call(null);
            });
          }else if ($incomingQuestionn.getCntrlNum() ===  $incomingFormSettings.getAttendeeName() &&
              $incomingPlusone.hasAttribute(this.strings.getName())){
            $outgoingRequest.setData($incomingPlusone.getName());
            $outgoingRequest.setResult('');
            this.responseService.updateResponseResult($outgoingRequest, (result: any) => {
              $incomingPlusone.setUnknown('3');
              call(null);
            });
          }
        });
      }
    });
  }





  showAllQuestions($responseResults: any, $callback: any){
    this.incomingResponseResults.setAttributes($responseResults);
    for (const $responseResult in this.incomingResponseResults.getAttributes()) {
      if (Object.prototype.hasOwnProperty.call(this.incomingResponseResults.getAttributes(), $responseResult)) {
        const $incomingResponseResult: any = new Parameters();
        $incomingResponseResult.setAttributes(
            this.incomingResponseResults.getUnknown($responseResult));
        const $incomingQuestion = new Parameters();
        $incomingQuestion.setAttributes(
            $incomingResponseResult.getUnknown(this.strings.getFormControll()));
        if (this.incomingResponseResults.hasAttribute(this.incomingFormSettings.getAttendeeName()) &&
            !this.service.isNull(this.incomingFormSettings.getAttendeeName())){
          if (!this.service.isNull(this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeName()).data))
            this.incomingInvitee.setAttendeeName(this.incomingResponseResults.getUnknown(this.incomingFormSettings.getAttendeeName()).data);
        }
        if ($incomingQuestion.hasAttribute(this.strings.getOptions())) {
          $incomingQuestion.getOptions().map( ($option) => {
            const $incomingOption = new Parameters();
            $incomingOption.setAttributes($option);
            if ($incomingOption.hasAttribute(this.strings.getQuestions())) {
              $incomingOption.getQuestions().map(($optionQuestion: any) => {
                const $incomingOptionQuestion = new Parameters();
                $incomingOptionQuestion.setAttributes($optionQuestion);
                if (this.incomingResponseResults.hasAttribute($incomingOptionQuestion.getCntrlNum())) {
                  const $outgoingResponseResult: any = new Parameters();
                  $outgoingResponseResult.setAttributes(
                      this.incomingResponseResults.getUnknown($incomingOptionQuestion.getCntrlNum()));
                  $outgoingResponseResult.setShow(0);
                  $outgoingResponseResult.setParent($incomingResponseResult.getAttributes());
                  $outgoingResponseResult.setOption($incomingOption.getAttributes());
                  this.incomingResponseResults.setUnknown(
                      $incomingOptionQuestion.getCntrlNum(),
                      $outgoingResponseResult.getAttributes());
                }
              });
            }
          });
        }
      }
    }
    $callback(this.incomingResponseResults.getAttributes());
  }

  trashresponseResultByOption(option: any){
    let incomingOption: any = new Parameters();
    incomingOption.setAttributes(option);
    incomingOption.getQuestions().map((question: any) => {
      let incomingQuestion: any = new Parameters();
      incomingQuestion.setAttributes(question);
      if (this.incomingResponseResults.hasAttribute(incomingQuestion.getCntrlNum())) {
        let incomingResponseResult: any = new Parameters();
        incomingResponseResult.setAttributes(this.incomingResponseResults.getUnknown(incomingQuestion.getCntrlNum()));
        this.outgoingResponseResults.map((responseResult: any) => {
          let outgoingResponseResult: any = new Parameters();
          outgoingResponseResult.setAttributes(responseResult);
          let outgoingQuestion: any = new Parameters();
          outgoingQuestion.setAttributes(outgoingResponseResult.getAttributes().formControl);
          if(outgoingQuestion.getCntrlNum().includes(incomingQuestion.getCntrlNum())){
            incomingResponseResult.setStatus(0);
            incomingResponseResult.setFlag(0);
            incomingResponseResult.setData('');
            incomingResponseResult.setResult('');
            incomingResponseResult.setUnvisible(1);
            outgoingResponseResult.setData('');
            outgoingResponseResult.setResult('');
            outgoingResponseResult.setUnvisible(1);
            this.incomingResponseResults.setUnknown(incomingQuestion.getCntrlNum(), incomingResponseResult.getAttributes());
            outgoingQuestion.getOptions().map((opt: any) => {
              this.trashresponseResultByOption(opt);
            });
          }
        });
      }
    });
  }

  onOptionSelect(option: any, responseResult: any){
    const $incomingOption: any = new Parameters();
    const $incomingResponseResult: any = new Parameters();
    const $incomingQuestion: any = new Parameters();
    const $incomingResult: any = new Parameters();
    $incomingResult.setAttributes({});
    $incomingOption.setAttributes(option);
    $incomingResponseResult.setAttributes(responseResult);
    $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
    $incomingResult.setCntrlNum($incomingOption.getCntrlNum());
    $incomingResult.setLabel($incomingOption.getLabel());
    $incomingResult.setUnknown($incomingOption.getCntrlNum(), Object.assign({}, $incomingResult.getAttributes()));
    $incomingResponseResult.setData($incomingOption.getLabel());
    $incomingResponseResult.setResult($incomingResult.getAttributes());
    $incomingResponseResult.setFlag(0);
    $incomingResponseResult.setStatus(0);
    this.incomingResponseResults.setUnknown($incomingQuestion.getCntrlNum(), $incomingResponseResult.getAttributes());
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setId($incomingResponseResult.getId());
    this.outgoingRequest.setData($incomingOption.getLabel());
    this.outgoingRequest.setFormControlId($incomingQuestion.getId());
    this.outgoingRequest.setPostId(this.incomingResponse.getId());
    this.outgoingRequest.setControlId($incomingQuestion.getControlId());
    this.outgoingResults.setAttributes({});
    this.outgoingResult.setAttributes({});
    this.outgoingResult.setLabel($incomingOption.getLabel());
    this.outgoingResult.setCntrlNum($incomingOption.getCntrlNum());
    this.outgoingResults.setUnknown($incomingOption.getCntrlNum(),
        this.outgoingResult.getAttributes());
    if($incomingResponseResult.hasAttribute(this.strings.getAncestor())){
      let $incomongResponseResultAncestor: any = new Parameters();
      $incomongResponseResultAncestor.setAttributes(this.incomingResponseResults.getUnknown($incomingResponseResult.getAncestor()));
      let $incomongAncestor: any = new Parameters();
      $incomongAncestor.setAttributes($incomongResponseResultAncestor.getAttributes().formControl);
      $incomongResponseResultAncestor.setFlag(0);
    }setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    }, this.service.timeout()*parseFloat('2'));
    $incomingQuestion.getOptions().map((opt: any) => {
      let $incomingOpt: any = new Parameters();
      $incomingOpt.setAttributes(opt);
      if(!$incomingOpt.getCntrlNum().includes($incomingOption.getCntrlNum())){
        this.trashresponseResultByOption($incomingOpt.getAttributes());
      }
    });
    if (parseFloat(this.outgoingRequest.getId()) === 0)
      delete this.outgoingRequest.attributes.id;
    this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
    this.service.httpService('post',
        this.service.app.routes.forms.responses.responseResults.update.toString(),
        this.outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: true},
        (result: any) => {
        }, (error: any) =>  {});
    setTimeout(() => {
      this.smsWhatsapp(this.incomingResponseResults.getAttributes());
    }, this.service.timeout() * parseFloat('1'));
  }

  updateResponseResult($incomingResponseResult: any, call: any){
    $incomingResponseResult.setResult($incomingResponseResult.hasAttribute(this.strings.getResult()) ?
        $incomingResponseResult.getResult() : {l: 'null'});
    const $outgoingRequest: any = new Parameters();
    const $incomingQuestion: any = new Parameters();
    $incomingQuestion.setAttributes($incomingResponseResult.getUnknown(this.strings.getFormControll()));
    $outgoingRequest.setAttributes({});
    $outgoingRequest.setId($incomingResponseResult.getId());
    $outgoingRequest.setData($incomingResponseResult.getData());
    $outgoingRequest.setFormControlId($incomingQuestion.getId());
    $outgoingRequest.setPostId(this.incomingResponse.getId());
    $outgoingRequest.setControlId($incomingQuestion.getControlId());
    if (parseFloat($incomingResponseResult.getId()) === 0) {
      delete $outgoingRequest.attributes.id;
    }
    $outgoingRequest.setResult(JSON.stringify(Object.assign({}, $incomingResponseResult.getResult())));
    this.service.httpService('post',
        this.service.app.routes.forms.responses.responseResults.update.toString(),
        $outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: true},
        (result: any) => {
          call(null);
        }, (error: any) =>  {});
  }


  addResponseByFormId($formId: any, call: any){
    const $incomingResponse: any = new Parameters();
    this.incomingResponse.setAttributes({});
    this.incomingResponse.setRegisteredBy(1);
    this.incomingResponse.setTeamId(0);
    this.incomingResponse.setOutletTypeId(0);
    this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY H:i:s'));
    this.incomingResponse.setCreatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
    this.incomingResponse.setUpdatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
    this.incomingResponse.setStartingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
    this.incomingResponse.setEndingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
    this.incomingResponse.setAltitude(0);
    this.incomingResponse.setDeviceUsage('');
    this.incomingResponse.setDepartmentId(0);
    this.incomingResponse.setFormId($formId);
    this.incomingResponse.setPostId(this.service.random());
    this.service.httpService(
        'POST',
        this.service.app.routes.responses.add,
        this.incomingResponse.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          call(response);
        }, (error: any) => {
        });

  }

  onClick(responseResult: any, checked: any, option: any) {
    const incomingResponseResult = new Parameters();
    const incomingQuestion: any = new Parameters();
    const incomingResult: any = new Parameters();
    const incomingOption: any = new Parameters();
    const incomingResults: any = new Parameters();
    const outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    incomingResponseResult.setAttributes(responseResult);
    incomingQuestion.setAttributes(incomingResponseResult.getUnknown(this.strings.getFormControll()));
    incomingOption.setAttributes(option);
    if (!incomingResponseResult.getData().toString().includes(incomingOption.getLabel() + '*' + incomingOption.getCntrlNum())) {
      incomingResponseResult.setData(this.service.isNull(incomingResponseResult.getData()) ?
          incomingOption.getLabel() + '*' + incomingOption.getCntrlNum() : incomingResponseResult.getData() + this.service.separator + incomingOption.getLabel() + '*' + incomingOption.getCntrlNum());
    } else {
      incomingResponseResult.setData(
          incomingResponseResult.getData().toString().includes(this.service.separator + incomingOption.getLabel() + '*' + incomingOption.getCntrlNum()) ?
              incomingResponseResult.getData().toString().replace(this.service.separator + incomingOption.getLabel() + '*' + incomingOption.getCntrlNum(), '') :
              (incomingResponseResult.getData().toString().includes(incomingOption.getLabel() + '*' + incomingOption.getCntrlNum() + this.service.separator) ?
                  incomingResponseResult.getData().toString().replace(incomingOption.getLabel() + '*' + incomingOption.getCntrlNum() + this.service.separator, '') :
                  incomingResponseResult.getData().toString().replace(incomingOption.getLabel() + '*' + incomingOption.getCntrlNum(), ''))
      );
    }
    incomingResults.setAttributes({});
    incomingResult.setAttributes({});
    incomingResponseResult.setResult(incomingResponseResult.attributes.hasOwnProperty('result') ? Object.assign({}, incomingResponseResult.getResult()) : {});
    incomingResult.setLabel(incomingOption.getLabel());
    incomingResult.setCntrlNum(incomingOption.getCntrlNum());
    incomingResults.setAttributes(incomingResponseResult.getResult());
    if (incomingResults.attributes.hasOwnProperty(incomingOption.getCntrlNum())){
      delete incomingResults.attributes[incomingOption.getCntrlNum()];
    }else { incomingResults.setUnknown(incomingOption.getCntrlNum(), incomingResult.getAttributes()); }
    outgoingRequest.setId(incomingResponseResult.hasAttribute(this.strings.getId()) ? incomingResponseResult.getId() : 0);
    outgoingRequest.setData(incomingResponseResult.getData());
    outgoingRequest.setFormControlId(incomingQuestion.getId());
    outgoingRequest.setPostId(this.incomingResponse.getId());
    outgoingRequest.setControlId(incomingQuestion.getControlId());
    outgoingRequest.setResult(JSON.stringify(Object.assign({}, incomingResponseResult.getResult())));
    if (parseFloat(outgoingRequest.getId()) === 0) { delete outgoingRequest.attributes.id; }
    setTimeout(() => {
      this.service.httpService('post',
          this.service.app.routes.forms.responses.responseResults.update.toString(),
          outgoingRequest.getAttributes(),
          {
            ignoreLoadingBar : 'true',
            notify: true
          }, (result: any) => {
            if (result instanceof Object && result.hasOwnProperty(this.strings.getId())) {
              incomingResponseResult.setId(result.id);
            }
          }, (error: any) =>  {});
    }, this.service.timeout());
  }


  setStrings(call: any){if (this.service.app.hasOwnProperty('strings')) { call(null); } else {this.service.setStrings((strings: any) => { call(null); }); }}

  protected readonly JSON = JSON;
}
