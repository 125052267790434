<div style="display: table; width:100vw; height: 100vh; overflow: hidden;">
    <div style="display: table-cell; vertical-align: middle;">
        <center>
            <div>
                <ng-container *ngIf="!service.isNull(service.logo)">
                    <div class="circle mdl-shadow--3dp content-center"
                         [ngStyle]="{'width':'100px','height':'100px','border-width':'0px','animation' : 'mymove 5s infinite'}">
                        <img  class="logox"
                              src="{{service.logo}}"
                              (error)="onerrorHandle($event)"
                              alt=""/>
                    </div>
                </ng-container>
            </div>
        </center>
    </div>
</div>