<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div class="row " style="margin-top: 50px">
                    <center>
                        <button
                                class="sawasdee16"
                                mat-raised-button
                                [ngStyle]="{'background': this.incomingFormSettings.getColor(), 'color': this.incomingFormSettings.getBackgroundColor()}"
                                (click)="ngSubmit()">
                            {{question.label}}
                        </button>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>

</div>
