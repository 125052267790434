<mat-drawer-container
            class="example-container no-padding no-margin"
            [hasBackdrop]="false">
        <mat-drawer #drawer
                    [mode]="'side'"
                    [ngStyle]="{'width': '100%','height': '100%','background-color':'#000'}">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 content-center"
                 style="width: 100%; height:{{this.service.getHeight()}}px; background:url({{this.imageAsDataUrl}}) center / cover">
                <ul class="list-inline">
                    <li>
                        <div class="btn ovalButtonBlackground sawasdee cursor"
                             (click)="aftreCapture();">
                            <i class="material-icons">done</i>
                        </div>
                    </li>
                    <li>
                        <div class="btn ovalButtonBlackground cursor"
                             (click)="cancelCaptureCameraImg();"
                             [ngStyle]="{'width':'100%'}">
                            <i class="material-icons">close</i>
                        </div>
                    </li>
                </ul>
            </div>
        </mat-drawer>
        <mat-drawer-content class="no-margin no-padding"
                            [ngStyle]="{'display': 'table-cell', 'vertical-align': 'top','background-color':'#000','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">
            <center>
                <webcam (imageCapture)="handleImage($event)"
                        [width]="this.service.getWidth()"
                        [height]="this.service.getHeight()"
                        [trigger]="triggerObservable"
                        [mirrorImage]="'never'"
                        [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable"
                        [videoOptions]="videoOptions"
                        [captureImageData]="true"
                        [imageType]="'image/jpeg'"
                        [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)">
                </webcam>
            </center>
            <div [ngStyle]="{'position':'fixed','bottom':'0px','width':'100%'}">
                <center>
                    <ul class="list-inline">
                        <li class="cursor">
                            <i (click)="triggerSnapshot()"
                               class="material-icons cursor"
                               [ngStyle]="{'color':'#fff','font-size':'62px'}">motion_photos_on</i>
                        </li>
                        <li (click)="showNextWebcam(true)">
                            <i class="material-icons cursor"
                               [ngStyle]="{'color':'#fff'}">flip_camera_android</i>
                        </li>
                    </ul>
                </center>
            </div>
            <div [ngStyle]="{'position':'fixed','top':'5px','left':'20px'}" (click)="afterClose()">
                <ul class="list-inline">
                    <li class="cursor">
                        <i class="material-icons cursor" [ngStyle]="{'color':'#fff'}">close</i>
                    </li>
                </ul>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>



