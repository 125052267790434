<!--<mat-drawer-container class="example-container" [hasBackdrop]="false">-->
<!--    <mat-drawer #drawer-->
<!--                [mode]="'side'"-->
<!--                [ngStyle]="{'width': 'auto','height': '100%','z-index': '999999999'}">-->
<!--    </mat-drawer>-->
<!--    <mat-drawer-content [ngStyle]="{'overflow': 'hidden'}">-->
<!--        &lt;!&ndash;<span class="pull-right cursor"&ndash;&gt;-->
<!--        &lt;!&ndash;      (click)="onContextMenu($event, {})"&ndash;&gt;-->
<!--        &lt;!&ndash;      (contextmenu)="onContextMenu($event, {})"&ndash;&gt;-->
<!--        &lt;!&ndash;      (mousedown)="onContextMenuFlag = 2">&ndash;&gt;-->
<!--        &lt;!&ndash;    <i class="material-icons">more_vert</i>&ndash;&gt;-->
<!--        &lt;!&ndash;</span>&ndash;&gt;-->
<!--        &lt;!&ndash;<span class="pull-right" [hidden]="searchInput">&ndash;&gt;-->
<!--        &lt;!&ndash;    <mat-form-field&ndash;&gt;-->
<!--        &lt;!&ndash;            class="example-full-width"&ndash;&gt;-->
<!--        &lt;!&ndash;            appearance='standard'&ndash;&gt;-->
<!--        &lt;!&ndash;            style="width: 200px !important;">&ndash;&gt;-->
<!--        &lt;!&ndash;        <input type="text"&ndash;&gt;-->
<!--        &lt;!&ndash;               #searchElement&ndash;&gt;-->
<!--        &lt;!&ndash;               aria-label = "Number"&ndash;&gt;-->
<!--        &lt;!&ndash;               matInput&ndash;&gt;-->
<!--        &lt;!&ndash;               (mouseleave)="searchIcon = false; searchInput = true"&ndash;&gt;-->
<!--        &lt;!&ndash;               [(ngModel)]="searchCntrl"&ndash;&gt;-->
<!--        &lt;!&ndash;        >&ndash;&gt;-->
<!--        &lt;!&ndash;    </mat-form-field>&ndash;&gt;-->
<!--        &lt;!&ndash;</span>&ndash;&gt;-->
<!--        &lt;!&ndash;<a style="margin-right: 10px"&ndash;&gt;-->
<!--        &lt;!&ndash;   class="material-icons pull-right cursor"&ndash;&gt;-->
<!--        &lt;!&ndash;   [hidden]="searchIcon"&ndash;&gt;-->
<!--        &lt;!&ndash;   (click)="onSearchIconClicked();searchIcon = false; searchInput= false">&ndash;&gt;-->
<!--        &lt;!&ndash;    search&ndash;&gt;-->
<!--        &lt;!&ndash;</a>&ndash;&gt;-->
<!--        <div [ngStyle]="{'position':'fixed','bottom':'30px','right':'10px','z-index':'9999999999999999999'}"-->
<!--             class="cursor"-->
<!--             [fxHide.xl]="true"-->
<!--             [fxHide.lg]="true"-->
<!--             [fxHide.md]="true"-->
<!--             (click)="addResponse()">-->
<!--            <button mat-fab class="defaultColor2">-->
<!--                <mat-icon>add</mat-icon>-->
<!--            </button>-->
<!--        </div>-->

<!--        <div [ngStyle]="{'position':'fixed','top':'10px','left':'10px','z-index':'9999999999999999999'}"-->
<!--             class="cursor" *ngIf="this.service.app.data.forms.data.length > 1">-->
<!--            <ul class="list-inline">-->
<!--                <li (click)="home()"><a class="btn"><i class="fa fa-long-arrow-left"></i></a></li>-->
<!--            </ul>-->
<!--        </div>-->

<!--        <div [ngStyle]="{'position':'fixed','top':'10px','right':'10px','z-index':'9999999999999999999'}"-->
<!--             class="cursor">-->
<!--            <ul class="list-inline">-->
<!--                <li>-->
<!--                    <ul class="list-inline">-->
<!--                        <li style="vertical-align: top" (click)="onContextMenuFlag = 2; onContextMenu($event, {})">-->
<!--                            <mat-icon class="" style="">more_vert</mat-icon>-->
<!--                        </li>-->
<!--                        <li style="vertical-align: top" (click)="onContextMenuFlag = 3; onContextMenu($event, {})">-->
<!--                            <img  class="img-circle"-->
<!--                                  [ngStyle]="{'width':'40px','height':'40px'}"-->
<!--                                  [src]="this.service.isNull(this.service.app.data.user.avatar) ? 'assets/images/avatar.jpg' : this.service.app.data.user.avatar"/>-->
<!--                        </li>-->
<!--                        &lt;!&ndash;                    <li style="vertical-align: top"><div class="mitra textBlueColor" [ngStyle]="{'font-size':'small'}">{{this.service.limitTo(this.service.app.data.user.name, 10).toLowerCase()}}</div></li>&ndash;&gt;-->
<!--                    </ul>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->

<!--        <center>-->
<!--            <img class="img-circle mdl-shadow&#45;&#45;3dp"-->
<!--                 [src]="formService.selected.campaign.client.logo"-->
<!--                 [ngStyle]="{'width':'70px','height':'70px','margin-top':'10px'}" />-->
<!--        </center>-->
<!--        <div class="row plist" style="margin-top: 5%">-->
<!--            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-0"></div>-->
<!--            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">-->
<!--                <mat-tab-group  [(selectedIndex)]="selectedTabIndex" mat-align-tabs="center">-->
<!--                    <mat-tab label="Published({{getResonsesByStatus('1').length}})">-->
<!--                        <div><br><br>-->
<!--                            <section class="post-section blog-post" style="background-color: transparent !important;">-->
<!--                                <div class="container">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 pull-left"-->
<!--                                             (click)="view()"-->
<!--                                             (contextmenu)="onContextMenu($event, response)"-->
<!--                                             (mousedown)="onContextMenuFlag = 1; selectedResponse = response"-->
<!--                                             *ngFor="let response of getResonsesByStatus('1')"-->
<!--                                             style="cursor: pointer">-->
<!--                                            <div class="post-box" style="height: 200px !important">-->
<!--                                                <div class="text-box">-->
<!--                                                    <h4>&nbsp;</h4>-->
<!--                                                    <center>-->
<!--                                                        <h4 class="primary-color">{{getTitle(response)}}</h4>-->
<!--                                                        <h5 class="primary-color">{{service.timeSince(response.date +  ' ' + response.starting_at)}}</h5>-->
<!--                                                        <p clas="primary-color">{{response.form.hasOwnProperty('progress') ? response.form.progress : 0}} %</p>-->
<!--                                                    </center>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </section>-->
<!--                        </div>-->
<!--                    </mat-tab>-->
<!--                    <mat-tab>-->
<!--                        <ng-template mat-tab-label>-->
<!--                    <span matBadge="{{getResonsesByStatus('0').length}}"-->
<!--                          matBadgeColor="warn">-->
<!--                        <span style="padding-right: 50px">Pending </span>-->
<!--                    </span>-->
<!--                        </ng-template>-->
<!--                        <div [ngStyle]="{'padding':'0px','margin-right':'10px','margin-bottom':'0px','margin-top':'20px','border-radius': '20px','background':'#fff'}"-->
<!--                             class="pull-right col-lg-4 col-md-4 col-sm-4 col-xs-4 mdl-shadow&#45;&#45;2dp"-->
<!--                             *ngIf="getResonsesByStatus('0').length > 2">-->
<!--                            <table [ngStyle]="{'width':'100%','margin':'0px'}">-->
<!--                                <td valign="middle" [ngStyle]="{'width':'100%'}">-->
<!--                                    <input  [ngStyle]="{'font-size':'18px','scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
<!--                                            autocomplete="off"-->
<!--                                            class="sawasdeexWithScale searchbar"-->
<!--                                            #searchId-->
<!--                                            [(ngModel)]="searchCntrl"-->
<!--                                            placeholder="">-->
<!--                                </td>-->
<!--                                &lt;!&ndash;                            <td> <mat-icon>panorama_fish_eye</mat-icon> </td>&ndash;&gt;-->
<!--                            </table>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <section class="post-section blog-post" style="background-color: transparent !important;">-->
<!--                                <div class="container">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 pull-left"-->
<!--                                             (click)="addResponse()"-->
<!--                                             style="cursor: pointer">-->
<!--                                            <div class="post-box" style="min-height: 200px !important">-->
<!--                                                <div class="text-box">-->
<!--                                                    <h4> &nbsp;</h4>-->
<!--                                                    <center><a class="cursor"><i class="material-icons">add</i></a></center>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 pull-left"-->
<!--                                             (mousedown)="selectedResponse = response"-->
<!--                                             (contextmenu)="onContextMenu($event, response)"-->
<!--                                             (click)="selectedResponse = response;view()"-->
<!--                                             *ngFor="let response of (getResonsesByStatus('0') | jsonFilter: searchCntrl)"-->
<!--                                             [ngStyle]="{'cursor': 'pointer'}">-->
<!--                                            <div class="post-box"-->
<!--                                                 style="height: 200px !important">-->
<!--                                                <div class="text-box">-->
<!--                                                    <h4>&nbsp;</h4>-->
<!--                                                    <center>-->
<!--                                                        <h4 class="" [title]="getTitle(response)">{{this.service.limitTo(getTitle(response), 20)}}</h4>-->
<!--                                                        <h5 class="">{{service.timeSince(response.date +  ' ' + response.starting_at)}}</h5>-->
<!--                                                        <p  clas="">{{response.form.hasOwnProperty('progress') ? response.form.progress : 0}} %</p>-->
<!--                                                    </center>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </section>-->
<!--                        </div>-->
<!--                    </mat-tab>-->

<!--                    &lt;!&ndash;            <mat-tab label="Dashboard">&ndash;&gt;-->
<!--                    &lt;!&ndash;                <div><br><br>&ndash;&gt;-->
<!--                    &lt;!&ndash;&lt;!&ndash;               <app-dashboard2-form [formId]="incomingForm.getId()"></app-dashboard2-form>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;            </mat-tab>&ndash;&gt;-->
<!--                    &lt;!&ndash;            <mat-tab label="{{incomingReport.getStartingAt()}}">&ndash;&gt;-->
<!--                    &lt;!&ndash;                <div><br><br>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <a mat-mini-fab&ndash;&gt;-->
<!--                    &lt;!&ndash;                           ngClass="pull-right"&ndash;&gt;-->
<!--                    &lt;!&ndash;                           [ngStyle]="{'background': '#fff', 'color': '#263238'}"&ndash;&gt;-->
<!--                    &lt;!&ndash;                           (click)="openDateDialog()">&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <i class="material-icons">keyboard_arrow_down</i></a>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <table id="datatable" class="table table-striped table-bordered" style="width:100%" [hidden]="service.responses.length === 0">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <thead>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <tr>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Outlet</th>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Address</th>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="service.parseFloat(incomingSettings.getResponsesCounter()) === 0">Phone</th>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <th class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngFor="let response of (service.responses.length === 0 ? [] : (getQuestions(service.responses[0].datas)))"><span title="{{service.removeHtmlTags(response.formControl.label)}}" [innerHTML]="service.limitTo(response.formControl.label.toString(), 60)"></span></th>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                </tr>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                </thead>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <tbody>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <tr *ngFor="let response of (getResponsesByUserId(service.app.data.user.id)| jsonFilter:searchCntrl)">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.name}}</td>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.address}}</td>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2" *ngIf="response.hasOwnProperty('outlet')">{{response.outlet.phone}}</td>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2"  *ngFor="let resp of getQuestions(response.datas)">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                        <center>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                            <div *ngIf="resp.formControl.control.id !== 24 && resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"> <span [innerHTML]="resp.data"></span></div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                            <div *ngIf="resp.formControl.control.id === 2">{{resp.data}}</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                            <div *ngIf="resp.formControl.control.id === 24">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                <table style="width: 100%; background-color: #FFFFFF" class="table ">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    <thead style="width: 100%">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    <th *ngFor="let optional of resp.formControl.options" style="font-size: 17px; margin: 2px; min-width: 200px">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                        <span class='cursor headline2' [innerHTML]="optional.label"></span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    </th>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    </thead>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    <tbody>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    <tr *ngFor="let row of resp.rows" style="height: 50px !important; width: 100%; min-width: 200px">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                        <td *ngFor="let option of resp.formControl.options; let optionIndex = index"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            contenteditable="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            style="height: 100%; width: 100%">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            <div #tdElement&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                 style="height: 100%; width: 100%;min-width: 200px" *ngIf="option.type_id !== 3"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                 [innerHTML]="row.hasOwnProperty(option.cntrl_num) ? row[option.cntrl_num].data : ''">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            <div style="height: 100%; width: 100% ; min-width: 200px" *ngIf="option.type_id === 3">&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                <center>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                    <mat-checkbox&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                        #checkboxElement&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                        class="cursor"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                    >&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                    </mat-checkbox>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                                </center>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                    </tbody>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                                </table>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                        </center>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    </td>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                </tr>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                </tbody>&ndash;&gt;-->
<!--                    &lt;!&ndash;                            </table>&ndash;&gt;-->

<!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;            </mat-tab>&ndash;&gt;-->
<!--                </mat-tab-group>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-0"></div>-->
<!--        </div>-->
<!--    </mat-drawer-content>-->
<!--</mat-drawer-container>-->


<div class="panel panel-default no-padding no-margin">
    <div class="panel-body no-padding no-margin">
        <table [ngStyle]="{'width':'100%'}" class="no-margin no-padding">
            <tr>
                <td class="no-padding no-margin sawasdeex" [ngStyle]="{'width':'100%','vertical-align': 'top'}">
                    <div class="border-top-left" [ngStyle]="{'width':'fit-content','padding':'5px'}">
                        {{this.incomingForm.getName().toLowerCase()}}
                    </div>
                    <div class="border-top-left" [ngStyle]="{'width':'fit-content','padding':'5px'}">
                        {{this.service.app.data.user.name.toLowerCase()}}
                    </div>
                    <div class="border-top-left" [ngStyle]="{'width':'fit-content','padding':'5px'}">
                        {{date.toLowerCase()}}
                    </div>
                </td>
                <td class="no-padding no-margin" [ngStyle]="{'width':'auto','vertical-align': 'top'}">
                    <table>
                        <td style="vertical-align: top" (click)="showCharts()" class="cursor">
                            <i class="material-icons">bar_chart</i>
                        </td>
<!--                    <td style="vertical-align: top">-->
<!--                             <span matBadge="{{this.service.appUsage}}"-->
<!--                                   [matBadgePosition]="'above after'"-->
<!--                                   matBadgeColor="accent">-->
<!--                                <span><i class="material-icons">report</i></span>-->
<!--                            </span>-->
<!--                    </td>-->
                        <td style="vertical-align: top" (click)="uploadPosts()" class="cursor">
                             <span matBadge="{{getResponsesunsavedfromsaver()}}"
                                   [matBadgePosition]="'above after'"
                                   matBadgeColor="accent">
                                <span><i class="material-icons">report</i></span>
                            </span>
                        </td>
                        <td class="cursor" style="vertical-align: top;margin-left: 5px" (click)="onContextMenuFlag = 2; onContextMenu($event, {})">
                            <i class="material-icons" style="">more_vert</i>
                        </td>
                        <td class="cursor">
                            <img  class="img-circle"
                                  [ngStyle]="{'width':'40px','height':'40px'}"
                                  [src]="this.service.isNull(this.service.app.data.user.avatar) ? 'assets/images/avatar.jpg' : this.service.app.data.user.avatar"/>
                        </td>
                    </table>
<!--                    <div class="cursor">-->
<!--                        <ul class="list-inline">-->
<!--                            <li>-->
<!--                            </li>-->
<!--                            <li style="vertical-align: top">-->
<!--                                -->
<!--                            </li>-->
<!--                            &lt;!&ndash;                    <li style="vertical-align: top"><div class="mitra textBlueColor" [ngStyle]="{'font-size':'small'}">{{this.service.limitTo(this.service.app.data.user.name, 10).toLowerCase()}}</div></li>&ndash;&gt;-->
<!--                        </ul>-->
<!--                    </div>-->
                </td>
            </tr>
        </table>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-margin no-padding">
            <ul class="list-inline pull-right">
                <li class="{{topBorderSelected === 1 ? 'border-top' : ''}} cursor" (click)="topBorderSelected = 1">
                    Published ({{getResonsesByStatus('1').length}})
                </li>
                <li class="{{topBorderSelected === 0 ? 'border-top-right' : ''}} cursor" (click)="topBorderSelected = 0">
                            <span matBadge="{{getResonsesByStatus('0').length}}"
                                  [matBadgePosition]="'below after'"
                                  matBadgeColor="warn">
                                <span style="padding-right: 50px;">Pending </span>
                            </span>
                </li>
            </ul>
<!--            <br><br>-->
<!--            <div [ngStyle]="{'padding':'0px','margin-right':'10px','margin-bottom':'0px','margin-top':'20px','border-radius': '20px','background':'#fff'}"-->
<!--                 class="pull-right col-lg-4 col-md-4 col-sm-4 col-xs-6 mdl-shadow&#45;&#45;3dp">-->
<!--                <table [ngStyle]="{'width':'100%','margin':'0px'}">-->
<!--                    <td valign="middle" [ngStyle]="{'width':'100%'}">-->
<!--                        <input  [ngStyle]="{'font-size':'18px','scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
<!--                                autocomplete="off"-->
<!--                                class="sawasdeexWithScale searchbar"-->
<!--                                #searchId-->
<!--                                [(ngModel)]="searchCntrl"-->
<!--                                placeholder="">-->
<!--                    </td>-->
<!--                </table>-->
<!--            </div>-->

            <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <ul class="demo-list-three mdl-list" *ngFor="let response of (getResonsesByStatus(topBorderSelected) | jsonFilter: searchCntrl)">
                    <li class="mdl-list__item mdl-list__item--three-line sawasdeex">
                        <span class="mdl-list__item-primary-content cursor" (click)="selectedResponse = response;view()">
                          <a class="content-center mdl-list__item-avatar" [ngStyle]="{'background-color':'#263238'}"><span [ngStyle]="{'font-size':'small'}">{{this.service.getlasttwowords(getTitle(response)).toUpperCase()}}</span></a>
                            <span class="textBlueColor" [title]="getTitle(response)"><b>{{this.service.limitTo(this.service.upperStartingCharacter(getTitle(response), true), 20)}}</b></span>
                          <span class="mdl-list__item-text-body" [ngStyle]="{'text-align':'left','display':'grid','height':'auto'}">
                                <span class="no-margin no-padding">{{service.timeSince(response.date +  ' ' + response.starting_at)}}</span>
                                <span class="no-margin no-padding">{{response.form.hasOwnProperty('progress') ? response.form.progress : 0}} %</span>
                              <!--                            <span class="small">{{response.hasOwnProperty(strings.getPostedBy()) ? response[strings.getPostedBy()].name : ''}}</span>-->
                                <span>{{response.hasOwnProperty('address') ? response.address : ''}}</span>
                                <span *ngFor="let control of response.form.controls" class="textBlueColor">
                                    <ng-container *ngIf="control.label.includes('comments') || control.label.includes('opinion')">
                                     {{this.service.upperStartingCharacter(control.data, true)}}
                                    </ng-container>
                                </span>
                          </span>
                        </span>
                        <span  class="mdl-list__item-secondary-content cursor" (click)="selectedResponse = response;onContextMenuFlag = 1; onContextMenu($event, {})">
                            <a class="mdl-list__item-secondary-action"><i class="material-icons">more_vert</i></a>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
        </div>
    </div>
</div>
<div [ngStyle]="{'position':'fixed','bottom':'30px','right':'20%','z-index':'9999999999999999999'}"
     class="cursor"
     [fxHide.xl]="true"
     [fxHide.lg]="true"
     [fxHide.md]="true"
     (click)="addResponse()">
    <button mat-fab class="defaultColor2">
        <i class="material-icons">add</i>
    </button>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <div *ngIf="onContextMenuFlag === 1">
            <button mat-menu-item (click)="view()">View</button>
            <button mat-menu-item (click)="remove()">Delete</button>
        </div>
        <div *ngIf="onContextMenuFlag === 2">
            <!--        <button mat-menu-item (click)="getResponse()"> Search for response  </button>-->
            <!--                    <button mat-menu-item (click)="setRoute()"> Set routes  </button>-->
            <button mat-menu-item> Help  </button>
            <button mat-menu-item (click)="service.logout()"> Logout  </button>
        </div>
        <div *ngIf="onContextMenuFlag === 3">
            <button mat-menu-item> {{this.responses.length}}  </button>
        </div>
    </ng-template>
</mat-menu>
