import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {MatMenuTrigger} from "@angular/material/menu";
import {FormService} from "../../../form.service";
import {MatDialog} from "@angular/material/dialog";
import {CheckboxDialogComponent} from "../dialog/checkbox-dialog.component";

@Component({
  selector: 'app-check-preview-controls',
  templateUrl: './check-preview-controls.component.html',
  styleUrls: ['./check-preview-controls.component.scss']
})
export class CheckPreviewControlsComponent implements OnInit, OnChanges {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = {x: '0px', y: '0px'};
  incomingSelectedOption: any;
  incomingResults: any;
  incomingQuestion: any;
  strings: any;
  incomingOption: any;
  label: any;
  incomingForm: any;
  incomingResult: any;
  incomingOptional: any;
  incomingSettings: any;
  matChekboxFrme: any = null;
  constructor(public service: AppService,
              private dialog: MatDialog,
              public formService: FormService) {
    this.strings = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.incomingOptional = new Parameters();
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes({});
    this.contextMenu = MatMenuTrigger;
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngStart(){
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.incomingForm.setAttributes(Object.assign({}, this.formService.getSelected()));
      this.incomingSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingSettings.setLargeDisplay(this.incomingSettings.attributes.hasOwnProperty('lfd') ? this.incomingSettings.getLargeDisplay() : 0);
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({}, this.incomingQuestion.getResult()) : {});
      this.ngStart();
      setTimeout(() => {
        let matChekboxFrames = document.getElementsByClassName('mat-checkbox-frame');
        for (let i = 0; i < matChekboxFrames.length; i++) {
          let matChekboxFrame = matChekboxFrames[i] as HTMLElement;
          if(this.matChekboxFrme === null)
            this.matChekboxFrme = matChekboxFrame.style.borderColor;
          matChekboxFrame.style.borderColor = !this.hasNodes() ? this.incomingSettings.getColor() : 'rgb(90, 115, 142)';
        }let matChekboxInnerContainers = document.getElementsByClassName('mat-checkbox-inner-container');
        for (let i = 0; i < matChekboxInnerContainers.length; i++) {
          let matChekboxInnerContainer = matChekboxInnerContainers[i] as HTMLElement;
          matChekboxInnerContainer.style.borderColor = this.service.isNull(this.incomingSettings.getBackgroundColor()) ? 'rgb(255, 255, 255)' : this.incomingSettings.getBackgroundColor();
        }
      });
    });
  }


  onOptionSelected(option: any){
   this.incomingOption.setAttributes(option);
   this.onClick({}, this.incomingOption.getAttributes());
  }

  set(){
    let output: any = '';
    if(!this.service.isNull(this.incomingQuestion.getData())) {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getOptions().map((option: any) => {
        this.incomingOption.setAttributes(option);
        if (this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel().toString())) {
          output = this.service.isNull(output) ?
              this.incomingOption.getLabel() :
              output + this.service.separator + this.incomingOption.getLabel();
        }
      });
    }
    this.incomingQuestion.setData(output);
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = {'item': inspector};
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    if(!this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum'))
    this.contextMenu.closeMenu();
  }


  removeOption(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum')){
        this.formService.newOptional = this.incomingSelectedOption.getAttributes();
        this.incomingQuestion.getOptions().splice(this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingSelectedOption.getCntrlNum()), 1);
        this.formService.removeOther(); this.set();
      }else this.contextMenu.closeMenu();
    });
  }

  handleUpdate(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.attributes.hasOwnProperty('questionCntrlNum')){
        this.label = prompt('', this.incomingSelectedOption.getLabel());
        if (this.label !== null) {
          this.incomingSelectedOption.attributes = Object.assign(this.incomingSelectedOption.attributes, {label: this.label});
          this.formService.newOptional = this.incomingSelectedOption.getAttributes();
          this.formService.updateOther(this.label); this.set();
        }
      }else this.contextMenu.closeMenu();
    });
  }

  hasNodes(){
    let result: any = false;
    this.incomingQuestion.getOptions().map((option: any) => {
      let incomingOption: any = new Parameters();
      incomingOption.setAttributes(option);
      if(incomingOption.getQuestions().length !==0)
        result = true;
    }); return result;
  }

  afterChecked(checkbox: any, option: any){
    setTimeout(() => {
      if(!checkbox.checked){
        this.onClick(checkbox, option);
      }
    });
  }


  afterradioChecked(radio: any, question: any, option: any){
    let incomingQuestion: any = new Parameters();
    let incomingOption: any = new Parameters();
    let incomingResult: any = new Parameters();
    incomingOption.setAttributes(option);
    incomingQuestion.setAttributes(this.formService.getQuestionByCntrlNum(question[this.strings.getCntrlNum()]));
    incomingQuestion.setResult(incomingQuestion.hasAttribute(this.strings.getResult()) && !this.service.isNull(incomingQuestion.getResult()) ? incomingQuestion.getResult() : {});
    incomingResult.setAttributes({});
    incomingResult.setUnknown(incomingOption.getCntrlNum(), {label: incomingOption.getLabel(), id: incomingOption.getId()});
    incomingQuestion.setData(incomingOption.getLabel());
    incomingQuestion.setResult(incomingResult.getAttributes());
  }

  isradionChecked(element: any, question, option: any){
    let result: any = false;
    let incomingQuestion: any = new Parameters();
    let incomingOption: any = new Parameters();
    incomingOption.setAttributes(option);
    incomingQuestion.setAttributes(this.formService.getQuestionByCntrlNum(question[this.strings.getCntrlNum()]));
    incomingQuestion.setResult(incomingQuestion.hasAttribute(this.strings.getResult()) && !this.service.isNull(incomingQuestion.getResult()) ? incomingQuestion.getResult() : {});
    if(JSON.stringify(incomingQuestion.getResult()).includes(incomingOption.getCntrlNum()))
      result = true;
    return result;
  }

  onClick(checkbox: any, option: any) {
    if(this.hasNodes())
      checkbox.checked = !checkbox.checked;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(option);
    if (!this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum())) {
      this.incomingQuestion.setData(this.service.isNull(this.incomingQuestion.getData()) ?
          this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() : this.incomingQuestion.getData() + this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum());
    } else {
      this.incomingQuestion.setData(
          this.incomingQuestion.getData().toString().includes(this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum()) ?
              this.incomingQuestion.getData().toString().replace(this.service.separator + this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum(), '') :
              (this.incomingQuestion.getData().toString().includes(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() + this.service.separator) ?
                  this.incomingQuestion.getData().toString().replace(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum() + this.service.separator, '') :
                  this.incomingQuestion.getData().toString().replace(this.incomingOption.getLabel() + '*' + this.incomingOption.getCntrlNum(), ''))
      );
    }
    this.incomingResults.setAttributes({});
    this.incomingResult.setAttributes({});
    this.incomingQuestion.setResult(
        this.incomingQuestion.attributes.hasOwnProperty(this.strings.getResult()) &&
        !this.service.isNull(this.incomingQuestion.getResult()) ? this.incomingQuestion.getResult() : {});
    this.incomingResult.setLabel(this.incomingOption.getLabel());
    this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
    this.incomingResults.setAttributes(this.incomingQuestion.getResult());
    if(this.incomingResults.attributes.hasOwnProperty(this.incomingOption.getCntrlNum()) && !checkbox.checked){
      delete this.incomingResults.attributes[this.incomingOption.getCntrlNum()];
    }else this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
    this.incomingQuestion.setResult(this.incomingResults.getAttributes());
    if(!checkbox.checked){
      // this.incomingOption.getQuestions().map((question: any) => {
      //   let incomingQuestion: any = new Parameters();
      //   incomingQuestion.setAttributes(question);
      //   this.formService.questions.map((control: any) => {
      //     let incomingControl: any = new Parameters();
      //     incomingControl.setAttributes(control);
      //     if(incomingControl.getCntrlNum() === incomingQuestion.getCntrlNum()) {
      //       incomingControl.setData('');
      //       incomingControl.setResult('');
      //     }
      //   });
      // });
    }
    // if(checkbox.checked && this.incomingOption.getQuestions().length !== 0){
    //   this.dialog.open(CheckboxDialogComponent, { data: {params: {option: this.incomingOption.getAttributes()}}, disableClose: false});
    // }

  }

  isChecked(option: any, checked: any) {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingOptional.setAttributes(option);
      this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({}, this.incomingQuestion.getResult()) : {});
      const l: any = this.incomingQuestion.getResult().hasOwnProperty(this.incomingOptional.getCntrlNum());
      return l;
  }


  onClickSpan(option: any){
    setTimeout(() => {
      this.incomingOption.setAttributes(option);
      if(this.incomingOption.getAttributes().hasOwnProperty('questionCntrlNum')){
      }
    });
  }

  protected readonly alert = alert;
}











