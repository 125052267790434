import { Injectable } from '@angular/core';
import {Parameters} from '../../../parameters';
import {AppService} from '../../../app.service';
import * as moment from 'moment';
import {DialogResponseComponent} from '../../../responses/dialog/dialog-response.component';
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../../form.service";
import {StorageMap} from "@ngx-pwa/local-storage";
import {DeleteDialogComponent} from "../../../main/delete-dialog.component";
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

    constructor(private service: AppService,
                private formService: FormService,
                private notifierService: NotifierService,
                private storage: StorageMap,
                public  dialog: MatDialog) {

        this.incomingForm = new Parameters();
        /**
         * @param mixed report
         */
        this.strings = new Parameters();
        /**
         * @param mixed report
         */
        this.incomingUser = new Parameters();
        /**
         * @param mixed report
         */
        this.incomingResponse = new Parameters();
        /**
         * @param mixed report
         */

        this.reportValues = new Parameters();
        /**
         * @param mixed report
         */
        this.reportValues.setAttributes({});

        /**
         * @param mixed report
         */

        this.reportValues.setTime('datebetween');

        /**
         * @param mixed report
         */

        this.reportValues.setTarget('all');

        /**
         * @param mixed report
         */

        this.reportValues.setType('responses');

        /**
         * @param mixed report
         */

        this.reportValues.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

        /**
         * @param mixed report
         */

        this.reportValues.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

        /**
         * @param mixed report
         */

        this.reportValues.setDisplay('dashbaord');

        /**
         * @param mixed report
         */

        this.reportValues.setData(null);

        /**
         * @param mixed report
         */

    }

    film: any;
    /**
     * @param mixed
     */

    id: any = 0;

    strings: any;
    /**
     * @param mixed
     */
    isDialogOpened: any = false;
    /**
     * @param mixed
     */
    filmUrl: any = 'assets/audio/filmmusic2.mp3';
    /**
     * @param mixed
     */

    isConfirmed: any = false;
    /**
     * @param mixed
     */
    isImgDialogOpened: any = false;
    /**
     * @param mixed
     */
    responses: any = [];

    response2: any = {};

    /**
     * @param mixed
     */


    broadCastedResponses: any = [];
    /**
     * @param mixed
     */
    responseSelectedCntrlNum: any = null;

    /**
     * @param mixed
     */
    incomingResponse: any;
    /**
     * @param mixed
     */
    incomingUser: any;
    /**
     * @param mixed
     */
    incomingForm;
    any;
    /**
     * @param mixed
     */
    searchText: any = '';
    /**
     * @param mixed
     */
    outletId: any = 0;
    /**
     * @param mixed
     */
    yScrollingPart: any = 0;
    /**
     * @param mixed
     */
    xScrollingPart: any = 0;
    /**
     * @param mixed
     */

    reportValues: any;

    /**
     * @param mixed
     */

    userId: any = null;

    /**
     */

    data: any = [];

    /**
     * @param mixed Data
     */

    isDialogOpen: any = false;

    /**
     * @param mixed Data
     */


    selected: any = {};

    /**
     * @param mixed
     */

    getUserId(): void {
        return this.userId;
    }

    /**
     * @param mixed
     */

    setUserId(userId: any): void {
        this.userId = userId;
    }


    getData(): void {
        return this.data;
    }

    /**
     * @param mixed Data
     */


    setData(data: any): void {
        this.data = data;
    }

    /**
     * @param mixed Data
     */

    getSelected(): any {
        return this.selected;
    }

    /**
     * @param mixed Selected
     */

    setSelected(selected: any): any {
        this.selected = selected;
    }
    getQuestionslength(){
        let increment: any = 0;
        this.formService.questions.map((question: any) => {
            let incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(question);
            if(parseFloat(incomingQuestion.getVisible()) === 1){
                if(incomingQuestion.getOptions().length !== 0 &&
                    (parseFloat(incomingQuestion.getControlId()) === 3 ||
                        parseFloat(incomingQuestion.getControlId()) === 5 ||
                        parseFloat(incomingQuestion.getControlId()) === 18)) {
                    increment += (parseFloat('1') * incomingQuestion.getOptions().length);
                }
            }
        }); return increment;
    }


    nullQuestionsByOption(option: any, call: any){
        this.strings.setAttributes(
            this.service.app.hasOwnProperty('strings') ?
                this.service.app.strings.params : {});
        let incomingOption: any = new Parameters();
        incomingOption.setAttributes(option);
        incomingOption.getQuestions().map((question: any) => {
            let incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(question);
            incomingQuestion.setAttributes(this.service.getJSONObjectByJSONArrayAndColumnAndValue(
                this.formService.questions, this.strings.getCntrlNum(), incomingQuestion.getCntrlNum()));
            incomingQuestion.setResult(incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull(incomingQuestion.getResult()) ?
                incomingQuestion.getResult() : {});
            call(null);
            if (!JSON.stringify(incomingQuestion.getResult()).includes(incomingOption.getCntrlNum())){
                incomingQuestion.setData('');
                incomingQuestion.setResult('');
            }
            if(incomingQuestion.getOptions().length !== 0){
                incomingQuestion.getOptions().map((questionOption: any) => {
                    let incomingQuestionOption: any = new Parameters();
                    incomingQuestionOption.setAttributes(questionOption);
                    this.nullQuestionsByOption(incomingQuestionOption.getAttributes(), call);
                });
            }

        });
    }

    nullQuestionsByAncesstorAndOption(ancesstor: any, option: any, call: any){
        let incomingAncesstor: any = new Parameters();
        incomingAncesstor.setAttributes(ancesstor);
        this.strings.setAttributes(
            this.service.app.hasOwnProperty('strings') ?
                this.service.app.strings.params : {});
        let incomingOption: any = new Parameters();
        incomingOption.setAttributes(option);
        incomingOption.getQuestions().map((question: any) => {
            let incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(question);
            incomingQuestion.setAttributes(this.service.getJSONObjectByJSONArrayAndColumnAndValue(
                this.formService.questions, this.strings.getCntrlNum(), incomingQuestion.getCntrlNum()));
            incomingQuestion.setResult(incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull(incomingQuestion.getResult()) ?
                incomingQuestion.getResult() : {});
            call(null);
            if (!JSON.stringify(incomingAncesstor.getResult()).includes(incomingOption.getCntrlNum())){
                incomingQuestion.setData('');
                incomingQuestion.setResult('');
            }
            if(incomingQuestion.getOptions().length !== 0){
                incomingQuestion.getOptions().map((questionOption: any) => {
                    let incomingQuestionOption: any = new Parameters();
                    incomingQuestionOption.setAttributes(questionOption);
                    this.nullQuestionsByAncesstorAndOption(incomingQuestion.getAttributes(), incomingQuestionOption.getAttributes(), call);
                });
            }

        });
    }

    nullQuestions(call: any){
        this.strings.setAttributes(
            this.service.app.hasOwnProperty('strings') ?
                this.service.app.strings.params : {});
        this.formService.questions.map((question: any) => {
            let incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(question);
            incomingQuestion.setResult(incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull(incomingQuestion.getResult()) ? incomingQuestion.getResult() : {});
            if(parseFloat(incomingQuestion.getVisible()) === 1) {
                call(null);
                if(incomingQuestion.getOptions().length !== 0) {
                    incomingQuestion.getOptions().map((option: any) => {
                        let incomingOption: any = new Parameters();
                        incomingOption.setAttributes(option);
                        this.nullQuestionsByAncesstorAndOption(incomingQuestion.getAttributes(), incomingOption.getAttributes(), call);
                    });
                }
            }
        });
    }
    // nullQuestionsByOption(option: any, call: any){
    //     let incomingOption: any = new Parameters();
    //     let hasOptions: any = false;
    //     incomingOption.setAttributes(option);
    //     incomingOption.getQuestions().map((question: any) => {
    //         let incomingQuestion: any = new Parameters();
    //         let incomingResponseResult: any = new Parameters();
    //         incomingQuestion.setAttributes(question);
    //         incomingResponseResult.setAttributes(this.service.getJSONObjectByJSONArrayAndColumnAndValue(
    //             this.formService.questions, this.strings.getCntrlNum(), incomingQuestion.getCntrlNum()));
    //         incomingResponseResult.setResult(incomingResponseResult.hasAttribute(this.strings.getResult()) &&
    //         !this.service.isNull(incomingResponseResult.getResult()) ? incomingResponseResult.getResult() : {});
    //         if(parseFloat(incomingQuestion.getControlId()) !== 18) {
    //             incomingResponseResult.setResult('');
    //             incomingResponseResult.setData('');
    //         }
    //         if(incomingQuestion.getOptions().length !== 0 &&
    //             (parseFloat(incomingQuestion.getControlId()) === 3 ||
    //                 parseFloat(incomingQuestion.getControlId()) === 5 ||
    //                 parseFloat(incomingQuestion.getControlId()) === 18))
    //             hasOptions = true;
    //         incomingQuestion.getOptions().map((opt: any) => {
    //             let incomingOpt: any = new Parameters();
    //             incomingOpt.setAttributes(opt);
    //             this.nullQuestions(incomingOpt.getAttributes(), call);
    //         });
    //     });
    //     });
    //     if(!hasOptions)
    //         call(null);
    // }

    cleartodayPosts(responses: any, call: any){
        responses.map((response: any) => {
            let incomingResponse: any = new Parameters();
            incomingResponse.setAttributes(response);
            if(parseFloat(incomingResponse.getStatus()) === 1){
                let position: any = this.service.findIndex(responses, this.strings.getPostId(), incomingResponse.getPostId());
                responses.splice(position, 1);
            }
        });
        call(null);
    }

    setPost(form: any, callback: any) {
        setTimeout(() => {
            this.incomingForm.setAttributes(Object.assign({}, form));
            this.incomingResponse.setAttributes({});
            this.incomingResponse.setFormId(this.incomingForm.getId());
            this.incomingResponse.setStartingAt('15:35:06');
            this.incomingResponse.setAltitude(0);
            this.incomingResponse.setDeviceUsage('');
            this.incomingResponse.setDepartmentId(1);
            this.incomingResponse.setAddress('');
            this.incomingResponse.setTitle('');
            this.incomingResponse.setStatus(0);
            this.incomingResponse.setLat(0);
            this.incomingResponse.setLng(0);
            this.incomingResponse.setPostId(this.service.random());
            this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
            this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY'));
            this.incomingResponse.setUserId(1);
            this.incomingResponse.setProgress(0);
            this.incomingResponse.setEndingAt('15:35:06');
            this.incomingResponse.setOutletId(0);
            try {
                this.incomingUser.setAttributes(this.service.app.data.status === 0 ? {} : this.service.app.data.user);
                if (Object.keys(this.incomingUser.attributes).length === 0) {
                    window.localStorage.setItem(this.incomingForm.getId(), JSON.stringify(this.incomingForm.getAttributes()));
                } else {
                    this.incomingResponse.setPostedBy(this.incomingUser.getAttributes());
                    this.incomingResponse.setUserId(this.incomingUser.getId());
                }
            } catch (e) {
            }
            this.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
            callback(this.incomingResponse.getAttributes());
        });
    }

    clearSentPosts(responses: any, call: any){
        responses.map((response: any) => {
            let incomingResponse: any = new Parameters();
            incomingResponse.setAttributes(response);
            if(incomingResponse.getDate() !== moment(new Date()).format('MM/DD/YYYY') && parseFloat(incomingResponse.getStatus()) === 1){
                let position: any = this.service.findIndex(responses, this.strings.getPostId(), incomingResponse.getPostId());
                responses.splice(position, 1); }
        });
    }



    saveByResponse(response: any, call: any){
        let incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes({});
        outgoingRequest.setFormId(incomingResponse.getForm().id);
        outgoingRequest.setResponses(JSON.stringify([incomingResponse.getAttributes()]));
        return this.service.httpService( 'post',
            this.service.app.routes.responses.save.add,
            outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                incomingResponse.setIsUploaded(parseFloat('1'));
                call(null);
            },  (error: any) => {
            });
    }

    updateResponseByResponseAndResponses(response: any, responses: any){
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        let incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        let position: any = this.service.findIndex(responses, this.strings.getPostId(), incomingResponse.getPostId());
        responses[position] = incomingResponse.getAttributes();
    }



    sendInvitationCard(outgoingRequest: Parameters, call) {
        this.service.httpService('POST', this.service.app.routes.invitations.card.delivery,
            outgoingRequest.getAttributes(), {}, (result: any) => { call({});}, (error: any) => {call(null);});
    }



    updateResponseResult(outgoingRequest: Parameters, call: any){
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ?
            this.service.app.strings.params : {});
        let incomingOptions: any = new Parameters();
        incomingOptions.setAttributes({});
        incomingOptions.setIgnoreLoadingBar('true');
        incomingOptions.setNotify(true);
        if(outgoingRequest.getAttributes().hasOwnProperty(this.strings.getIgnoreLoadingBar()) &&
            outgoingRequest.getIgnoreLoadingBar().includes('true'))
            delete incomingOptions.attributes[this.strings.getIgnoreLoadingBar()];
        this.service.httpService('POST',
            this.service.app.routes.forms.responses.responseResults.update.toString(),
            outgoingRequest.getAttributes(), incomingOptions.getAttributes(), (responseResult: any) => {
                call({});
            }, (error: any) =>  {call(null)});
    }

    search(outgoingRequest: Parameters, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        if(!(outgoingRequest.hasAttribute(this.strings.getFormId()) && outgoingRequest.getFormId() === null))
            outgoingRequest.setFormId(this.formService.getSelected()[this.strings.getId()]);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.search,
            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {call(null);});
    }

    addPostByFormIdAndControls($formId: any, controls: any, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        const $incomingResponse: any = new Parameters();
        this.incomingResponse.setAttributes({});
        this.incomingResponse.setPostId(this.service.random());
        this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
        this.incomingResponse.setRegisteredBy(1);
        this.incomingResponse.setTeamId(0);
        this.incomingResponse.setOutletTypeId(0);
        this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setCreatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setUpdatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setStartingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setEndingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setAltitude(0);
        this.incomingResponse.setDeviceUsage('');
        this.incomingResponse.setDepartmentId(0);
        this.incomingResponse.setLat(0);
        this.incomingResponse.setLng(0);
        this.incomingResponse.setFormId($formId);
        const $questions: any = [];
        controls.map((question: any) => {
            const $incomingQuestion: any = new Parameters();
            $incomingQuestion.setAttributes(Object.assign({}, question));
            $incomingQuestion.setResult($incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull($incomingQuestion.getResult()) ?
                JSON.stringify($incomingQuestion.getResult()) : '');
            $incomingQuestion.getAttributes()[this.strings.getFormControlId()] =
                $incomingQuestion.getAttributes().control.id;
            $incomingQuestion.attributes = Object.assign($incomingQuestion.getAttributes(), {
                data: this.service.isNull($incomingQuestion.getAttributes().data) ? '' :
                    $incomingQuestion.getAttributes().data
            });
            delete $incomingQuestion.getAttributes().options;
            $questions.push($incomingQuestion.attributes);
        });
        this.incomingResponse.setControls(JSON.stringify($questions));
        const posts: any = [];
        posts.push(this.incomingResponse.getAttributes());
        this.service.httpService(
            'POST',
            '/public/form-posts',
            {posts: JSON.stringify(posts)},
            {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
            });

    }


    addResponseByFormId($formId: any, call: any) {
        const $incomingResponse: any = new Parameters();
        this.incomingResponse.setAttributes({});
        this.incomingResponse.setRegisteredBy(1);
        this.incomingResponse.setTeamId(0);
        this.incomingResponse.setOutletTypeId(0);
        this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setCreatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setUpdatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setStartingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setEndingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setAltitude(0);
        this.incomingResponse.setDeviceUsage('');
        this.incomingResponse.setDepartmentId(0);
        this.incomingResponse.setFormId($formId);
        this.incomingResponse.setPostId(this.service.random());
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.add,
            this.incomingResponse.getAttributes(),
            {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
                call(null);
            });

    }

    getResponseByFormId($formId: any, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        const incomingRequest: any = new Parameters();
        incomingRequest.setAttributes({});
        incomingRequest.setFormId($formId);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.get,
            incomingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
            });
    }

    deleteSavedResponse(incomingResponse: Parameters, responses: any, titl, call){
        const cntrlNum: any = incomingResponse.getCntrlNum();
        const dialog = this.dialog.open(DeleteDialogComponent, {
            data   : {
                data : responses,
                model: incomingResponse.getAttributes(),
                id   : incomingResponse.getCntrlNum(),
                key  : this.strings.getCntrlNum(),
                title: titl,
                url  : '',
            },
            width : 'auto',
        });
        dialog.afterClosed().subscribe(() => {
            const postion: any = this.service.findIndex(
                responses,
                this.strings.getCntrlNum(),
                incomingResponse.getAttributes().cntrl_num);
            if (postion === -1) {
                const outgoingRequest: any = new Parameters();
                outgoingRequest.setAttributes({});
                outgoingRequest.setCntrlNum(incomingResponse.getCntrlNum());
                const subscription = this.service.httpService('post',
                    this.service.app.routes.responses.save.delete,
                    outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: ''}, (result: any) => {
                    }, (error: any) => {});
                call(subscription);
            }
        });
    }


    requestResponseById($id: number, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        let incomingRequest: any = new Parameters();
        incomingRequest.setAttributes({});
        incomingRequest.setId($id);
        incomingRequest.setFormId(this.formService.getSelected()[this.strings.getId()]);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.get,
            incomingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {call(null);});
    }

    playFilm(callback: any) {
        this.film = new Audio(this.filmUrl);
        this.film.volume = 0.5;
        this.film.play();
        this.film.onended = () => {
            callback(null);
        };
    }

    openDialog() {
        setTimeout(() => {
            if (this.isDialogOpen === false) {
                this.isDialogOpen = true;
                const dialogRef = this.dialog.open(DialogResponseComponent, {
                    data: {},
                    width: '60%',
                    height: '100%',
                    disableClose: false,
                });
                dialogRef.afterClosed().subscribe(() => {
                    this.isDialogOpen = false;
                });
            }
        });


    }
}
