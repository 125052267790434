import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from "ngx-webcam";
import {Observable, Subject} from "rxjs";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {FormService} from "../../forms/form.service";
import {Parameters} from "../../parameters";
import {AppService} from "../../app.service";
import {MatDrawer} from "@angular/material/sidenav";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('drawer', {static: false})  drawer: any;
  public webcamImage: any = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public multipleWebcamsAvailable = false;
  public showWebcam = true;
  public facingMode: any = { exact: 'environment'};
  allowCameraSwitch: true;
  incomingForm: any;
  incomingFormSettings: any;
  voiceCamera: any;
  track: any; // the video track which is used to turn on/off the flashlight
  imageCapture: any;
  deviceId: string;
  imageAsDataUrl: any = null;
  constructor(public service: AppService,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CameraComponent>) {
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.drawer = MatDrawer;

  }

  ngAfterViewInit(): void {
    const matDialogContainers = document.getElementsByClassName('mat-dialog-container');
    for (let i = 0; i < matDialogContainers.length; i++) {
      const matDialogContainer = matDialogContainers[i] as HTMLElement;
      matDialogContainer.style.backgroundColor = '#000';
      matDialogContainer.style.boxShadow = '0px';
      matDialogContainer.style.margin  = '0px';
      matDialogContainer.style.padding = '0px';
      matDialogContainer.style.overflow = 'hidden';
    }
  }


  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
    document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.playSoundCamera((result: any) => {});
    this.webcamImage = webcamImage;
    this.capturePic();
  }

  afterClose(){
    this.dialogRef.close(null);
  }


  cancelCaptureCameraImg(){
    this.webcamImage = null;
    this.drawer.toggle();
    this.broadcastChannelService.emitNavChangeEvent({webcamImage: this.webcamImage});
  }


  aftreCapture(){
    this.broadcastChannelService.emitNavChangeEvent({webcamImage: this.webcamImage.imageAsDataUrl, imgId: this.data.imgId});
    this.dialogRef.close(null);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  onErrorHandle(event: any){
  }

  getCameras = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(true);
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  private readAvailableVideoInputs() {
    WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
    }
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
    this.readAvailableVideoInputs();
  }

  public get videoOptions(): MediaTrackConstraints {
    const mediaTrackConstraints: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '')
      mediaTrackConstraints.facingMode = { ideal: this.facingMode };
    return mediaTrackConstraints;
  }

  changeCameraFacingMode(){ this.facingMode === 'enviroment' ? 'user' : 'enviroment';}

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  async  getDevices(call: any) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    call(videoDevices);
  }

  accessFlashlight() {
    // Test browser support
    if (!('mediaDevices' in window.navigator)) {
      alert('Media Devices not available. Use HTTPS!');
      return;
    }

    // Get the environment camera (usually the second one)
    window.navigator.mediaDevices.enumerateDevices().then((devices) => {

      const cameras = devices.filter((device) => device.kind === 'videoinput');
      if (cameras.length === 0) {
        alert('No camera found. If your device has camera available, check permissions.');
        return;
      }

      const camera = cameras[cameras.length - 1];

      window.navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: camera.deviceId
        }
      }).then((stream) => {
        this.track = stream.getVideoTracks()[0];

//                 if (!(this.track.getCapabilities().torch)) {
//                     alert("No torch available.");
//                 };
      });
    });
  }


  switchFlash(status: any){
    this.imageCapture = new (window as any).ImageCapture(this.track);
    const photoCapabilities: any = this.imageCapture.getPhotoCapabilities().then(() => {
      this.track.applyConstraints({
        advanced: [{torch: status}]
      });
    });
  }

  setFlashlightStatus(status) {
    this.accessFlashlight();
    this.track.applyConstraints({
      advanced: [{
        torch: status
      }]
    });
  }

  capturePic(){
    this.drawer.toggle();
    this.playSoundCamera((result: any) => {
      this.imageAsDataUrl = this.webcamImage.imageAsDataUrl;
    });
  }

  openCamera() {
    setTimeout(() => {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({
                video: true
            }).then((stream: MediaStream) => {
                setTimeout(() => {
                    this.track = stream.getVideoTracks()[0];
                    // Create image capture object and get camera capabilities
                    this.imageCapture = new (window as any).ImageCapture(this.track);
                });
            });
        }
    });
  }



  playSoundCamera(callback: any){
    this.voiceCamera = new Audio('../assets/audio/camera.wav');
    this.voiceCamera.play();
    this.voiceCamera.volume = 0.01;
    this.voiceCamera.onended = () => {
      callback(null);
    };
  }

  ngOnDestroy(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingFormSettings.getBackgroundColor();

  }




}
