export class Parameters {

    attributes: any;

    /**
     * @param mixed $Attributes
     */

    getId() {
        return this.attributes.id;
    }


    /**
     * @param mixed $Id
     */


    setId (Id: any){
        this.attributes.id = Id;
    }

    getAsc() {
        return this.attributes.asc;
    }


    /**
     * @param mixed $Asc
     */


    setAsc (Asc: any){
        this.attributes.asc = Asc;
    }

    getIsBill() {
        return this.attributes.is_bill;
    }


    /**
     * @param mixed $IsBill
     */


    setIsBill (IsBill: any){
        this.attributes.is_bill = IsBill;
    }

    getIsUploaded() {
        return this.attributes.is_uploaded;
    }


    /**
     * @param mixed $IsUploaded
     */


    setIsUploaded (IsUploaded: any){
        this.attributes.is_uploaded = IsUploaded;
    }

    getDatebetween() {
        return this.attributes.datebetween;
    }


    /**
     * @param mixed $Datebetween
     */


    setDatebetween (Datebetween: any){
        this.attributes.datebetween = Datebetween;
    }

    getUnvisible() {
        return this.attributes.unvisible;
    }


    /**
     * @param mixed $Unvisible
     */


    setUnvisible (Unvisible: any){
        this.attributes.unvisible = Unvisible;
    }


    getPlusOne() {
        return this.attributes.plus_one;
    }


    /**
     * @param mixed $PlusOne
     */


    setPlusOne (PlusOne: any){
        this.attributes.plus_one = PlusOne;
    }

    getShowDirection() {
        return this.attributes.show_direction;
    }


    /**
     * @param mixed $ShowDirection
     */


    setShowDirection (ShowDirection: any){
        this.attributes.show_direction = ShowDirection;
    }


    getDeliveryUsers() {
        return this.attributes.delivery_users;
    }

    /**
     * @param mixed $DeliveryUsers
     */


    setDeliveryUsers (DeliveryUsers: any){
        this.attributes.delivery_users = DeliveryUsers;
    }

    getWhatsappBusinessPhoneId() {
        return this.attributes.whatsapp_business_phone_id;
    }

    /**
     * @param mixed $WhatsappBusinessPhoneId
     */


    setWhatsappBusinessPhoneId (WhatsappBusinessPhoneId: any){
        this.attributes.whatsapp_business_phone_id = WhatsappBusinessPhoneId;
    }

    getIncluded() {
        return this.attributes.included;
    }

    /**
     * @param mixed $Included
     */


    setIncluded (Included: any){
        this.attributes.included = Included;
    }


    getApp() {
        return this.attributes.app;
    }

    /**
     * @param mixed
     */


    setApp (App: any){
        this.attributes.app = App;
    }

    getIsMuted() {
        return this.attributes.is_muted;
    }

    /**
     * @param mixed
     */


    setIsMuted (IsMuted: any){
        this.attributes.is_muted = IsMuted;
    }


    getDatas() {
        return this.attributes.datas;
    }

    /**
     * @param mixed $Datas
     */


    setDatas (Datas: any){
        this.attributes.datas = Datas;
    }



    getIsCardSent() {
        return this.attributes.is_card_sent;
    }

    /**
     * @param mixed $IsCardSent
     */


    setIsCardSent (IsCardSent: any){
        this.attributes.is_card_sent = IsCardSent;
    }

    getConfirmation() {
        return this.attributes.confirmation;
    }

    /**
     * @param mixed
     */


    setConfirmation (Confirmation: any){
        this.attributes.confirmation = Confirmation;
    }


    getAttendeeCompany() {
        return this.attributes.attendee_company;
    }

    /**
     * @param mixed $AttendeeCompany
     */


    setAttendeeCompany (AttendeeCompany: any){
        this.attributes.attendee_company = AttendeeCompany;
    }

    getAttendeeJobTitle() {
        return this.attributes.attendee_job_title;
    }

    /**
     * @param mixed $AttendeeJobTitle
     */


    setAttendeeJobTitle (AttendeeJobTitle: any){
        this.attributes.attendee_job_title = AttendeeJobTitle;
    }


    getAttendeeJobPosition() {
        return this.attributes.attendee_job_position;
    }

    /**
     * @param mixed $AttendeeJobPosition
     */


    setAttendeeJobPosition (AttendeeJobPosition: any){
        this.attributes.attendee_job_position = AttendeeJobPosition;
    }

    getAttendeeSalutation() {
        return this.attributes.attendee_salutation;
    }

    /**
     * @param mixed
     */


    setAttendeeSalutation (AttendeeSalutation: any){
        this.attributes.attendee_salutation = AttendeeSalutation;
    }

    getTrending() {
        return this.attributes.trending;
    }

    /**
     * @param mixed $Trending
     */


    setTrending (Trending: any){
        this.attributes.trending = Trending;
    }

    getHidden() {
        return this.attributes.hidden;
    }

    /**
     * @param mixed $Hidden
     */


    setHidden (Hidden: any){
        this.attributes.hidden = Hidden;
    }

    getIsMenu() {
        return this.attributes.is_menu;
    }

    /**
     * @param mixed $IsMenu
     */


    setIsMenu (IsMenu: any){
        this.attributes.is_menu = IsMenu;
    }

    getGeometry() {
        return this.attributes.geometry;
    }

    /**
     * @param mixed $Geometry
     */


    setGeometry (Geometry: any){
        this.attributes.geometry = Geometry;
    }


    getText2SpeechEnabled() {
        return this.attributes.text_2_speech_enabled;
    }

    /**
     * @param mixed $Text2SpeechEnabled
     */


    setText2SpeechEnabled (Text2SpeechEnabled: any){
        this.attributes.text_2_speech_enabled = Text2SpeechEnabled;
    }


    getAutoNumber() {
        return this.attributes.auto_number;
    }

    /**
     * @param mixed $AutoNumber
     */


    setAutoNumber (AutoNumber: any){
        this.attributes.auto_number = AutoNumber;
    }

    getIsInvitedControl() {
        return this.attributes.is_invited_control;
    }

    /**
     * @param mixed
     */


    setIsInvitedControl (IsInvitedControl: any){
        this.attributes.is_invited_control = IsInvitedControl;
    }


    getSettings() {
        return this.attributes.settings;
    }

    /**
     * @param mixed
     */


    setSettings (Settings: any){
        this.attributes.settings = Settings;
    }

    getIsAutoNumber() {
        return this.attributes.is_auto_number;
    }

    /**
     * @param mixed $IsAutoNumber
     */


    setIsAutoNumber (IsAutoNumber: any){
        this.attributes.is_auto_number = IsAutoNumber;
    }


    getIsInvited() {
        return this.attributes.is_invited;
    }

    /**
     * @param mixed
     */


    setIsInvited (IsInvited: any){
        this.attributes.is_invited = IsInvited;
    }


    getAddress1() {
        return this.attributes.address1;
    }

    /**
     * @param mixed
     */


    setAddress1 (Address1: any){
        this.attributes.address1 = Address1;
    }

    getAddress4() {
        return this.attributes.address4;
    }

    /**
     * @param mixed
     */


    setAddress4 (Address4: any){
        this.attributes.address4 = Address4;
    }

    getCustomerId() {
        return this.attributes.customer_id;
    }

    /**
     * @param mixed
     */


    setCustomerId (CustomerId: any){
        this.attributes.customer_id = CustomerId;
    }


    getAddress3() {
        return this.attributes.address3;
    }

    /**
     * @param mixed
     */


    setAddress3 (Address3: any){
        this.attributes.address3 = Address3;
    }

    getAddress2() {
        return this.attributes.address2;
    }

    /**
     * @param mixed
     */


    setAddress2 (Address2: any){
        this.attributes.address2 = Address2;
    }

    getQuantity() {
        return this.attributes.quantity;
    }

    /**
     * @param mixed $Quantity
     */


    setQuantity (Quantity: any){
        this.attributes.quantity = Quantity;
    }



    getSupplierId() {
        return this.attributes.supplier_id;
    }

    /**
     * @param mixed $SupplierId
     */


    setSupplierId (SupplierId: any){
        this.attributes.supplier_id = SupplierId;
    }

    getDistance() {
        return this.attributes.distance;
    }

    /**
     * @param mixed $Distance
     */


    setDistance (Distance: any){
        this.attributes.distance = Distance;
    }

    getIsInteger() {
        return this.attributes.is_integer;
    }

    /**
     * @param mixed $IsInteger
     */


    setIsInteger (IsInteger: any){
        this.attributes.is_integer = IsInteger;
    }

    getProgress() {
        return this.attributes.progress;
    }

    /**
     * @param mixed $Progress
     */


    setProgress (Progress: any){
        this.attributes.progress = Progress;
    }

    getTypes() {
        return this.attributes.types;
    }

    /**
     * @param mixed $Types
     */


    setTypes (Types: any){
        this.attributes.types = Types;
    }


    getAddressComponents() {
        return this.attributes.address_components;
    }

    /**
     * @param mixed $AddressComponents
     */


    setAddressComponents (AddressComponents: any){
        this.attributes.address_components = AddressComponents;
    }


    getDisplayName() {
        return this.attributes.display_name;
    }

    /**
     * @param mixed $DisplayName
     */


    setDisplayName (DisplayName: any){
        this.attributes.display_name = DisplayName;
    }

    getPageYOffset() {
        return this.attributes.pageYOffset;
    }

    /**
     * @param mixed $PageYOffset
     */


    setPageYOffset (PageYOffset: any){
        this.attributes.pageYOffset = PageYOffset;
    }

    getCode() {
        return this.attributes.code;
    }

    /**
     * @param mixed
     */


    setCode (Code: any){
        this.attributes.code = Code;
    }


    getComma() {
        return this.attributes.comma;
    }

    /**
     * @param mixed Comma
     */


    setComma (Comma: any){
        this.attributes.comma = Comma;
    }



    getDigits() {
        return this.attributes.digits;
    }

    /**
     * @param mixed Digits
     */


    setDigits (Digits: any){
        this.attributes.digits = Digits;
    }



    getKpiDisplayTitle() {
        return this.attributes.kpi_display_title;
    }

    /**
     * @param mixed $KpiDisplayTitle
     */


    setKpiDisplayTitle (KpiDisplayTitle: any){
        this.attributes.kpi_display_title = KpiDisplayTitle;
    }

    getResponseDisplayTitle() {
        return this.attributes.response_display_title;
    }

    /**
     * @param mixed
     */


    setResponseDisplayTitle (ResponseDisplayTitle: any){
        this.attributes.response_display_title = ResponseDisplayTitle;
    }

    getSaveQuestionnaire() {
        return this.attributes.save_questionnaire;
    }

    /**
     * @param mixed
     */


    setSaveQuestionnaire (SaveQuestionnaire: any){
        this.attributes.save_questionnaire = SaveQuestionnaire;
    }


    getOptionId() {
        return this.attributes.option_id;
    }

    /**
     * @param mixed $OptionId
     */


    setOptionId (OptionId: any){
        this.attributes.option_id = OptionId;
    }

    getOption() {
        return this.attributes.option;
    }

    /**
     * @param mixed $Option
     */


    setOption (Option: any){
        this.attributes.option = Option;
    }


    getAncestor() {
        return this.attributes.ancestor;
    }

    /**
     * @param mixed $Ancestor
     */


    setAncestor (Ancestor: any){
        this.attributes.ancestor = Ancestor;
    }

    getValue() {
        return this.attributes.value;
    }

    /**
     * @param mixed
     */


    setValue (Value: any){
        this.attributes.value = Value;
    }


    getText() {
        return this.attributes.text;
    }

    /**
     * @param mixed
     */


    setText (Text: any){
        this.attributes.text = Text;
    }

    getExtension() {
        return this.attributes.extension;
    }

    /**
     * @param mixed
     */


    setExtension (Extension: any){
        this.attributes.extension = Extension;
    }

    getVisible() {
        return this.attributes.visible;
    }

    /**
     * @param mixed $Visible
     */


    setVisible (Visible: any){
        this.attributes.visible = Visible;
    }

    getAltitude() {
        return this.attributes.altitude;
    }

    /**
     * @param mixed $Altitude
     */


    setAltitude (Altitude: any){
        this.attributes.altitude = Altitude;
    }

    getDepartmentId() {
        return this.attributes.department_id;
    }

    /**
     * @param mixed $DepartmentId
     */


    setDepartmentId (DepartmentId: any){
        this.attributes.department_id = DepartmentId;
    }



    getDeviceUsage() {
        return this.attributes.device_usage;
    }

    /**
     * @param mixed $DeviceUsage
     */


    setDeviceUsage (DeviceUsage: any){
        this.attributes.device_usage = DeviceUsage;
    }



    getQuestionId() {
        return this.attributes.question_id;
    }


    /**
     * @param mixed $QuestionId
     */


    setQuestionId (QuestionId: any){
        this.attributes.question_id = QuestionId;
    }


    getFormUserId() {
        return this.attributes.form_user_id;
    }


    /**
     * @param mixed $FormUserId
     */


    setFormUserId (FormUserId: any){
        this.attributes.form_user_id = FormUserId;
    }



    getPassed() {
        return this.attributes.passed;
    }

    /**
     * @param mixed $Passed
     */


    setPassed (Passed: any){
        this.attributes.passed = Passed;
    }

    getSubject() {
        return this.attributes.subject;
    }

    /**
     * @param mixed $Subject
     */


    setSubject (Subject: any){
        this.attributes.subject = Subject;
    }

    getSales() {
        return this.attributes.sales;
    }

    /**
     * @param mixed $Sales
     */


    setSales (Sales: any){
        this.attributes.sales = Sales;
    }

    getCalls() {
        return this.attributes.calls;
    }

    /**
     * @param mixed $Calls
     */


    setCalls (Calls: any){
        this.attributes.calls = Calls;
    }


    getPortalId() {
        return this.attributes.portal_id;
    }

    /**
     * @param mixed $PortalId
     */


    setPortalId (PortalId: any){
        this.attributes.portal_id = PortalId;
    }

    getSection() {
        return this.attributes.section;
    }

    /**
     * @param mixed $Section
     */


    setSection (Section: any){
        this.attributes.section = Section;
    }


    getAll() {
        return this.attributes.all;
    }

    /**
     * @param mixed $All
     */


    setAll (All: any){
        this.attributes.all = All;
    }

    getDefault() {
        return this.attributes._default;
    }

    /**
     * @param mixed $Default
     */


    setDefault (Default: any){
        this.attributes._default = Default;
    }


    getFormControll() {
        return this.attributes.form_controll;
    }

    /**
     * @param mixed $FormControll
     */


    setFormControll (FormControll: any){
        this.attributes.form_controll = FormControll;
    }

    getSections() {
        return this.attributes.sections;
    }

    /**
     * @param mixed $Sections
     */


    setSections (Sections: any){
        this.attributes.sections = Sections;
    }

    getNotify() {
        return this.attributes.notify;
    }

    /**
     * @param mixed $Notify
     */


    setNotify (Notify: any){
        this.attributes.notify = Notify;
    }



    getInternetDisconnected() {
        return this.attributes.internet_disconnected;
    }

    /**
     * @param mixed $InternetDisconnected
     */


    setInternetDisconnected (InternetDisconnected: any){
        this.attributes.internet_disconnected = InternetDisconnected;
    }

    getDurations() {
        return this.attributes.durations;
    }

    /**
     * @param mixed $Durations
     */


    setDurations (Durations: any){
        this.attributes.durations = Durations;
    }

    getFormControlId() {
        return this.attributes.form_control_id;
    }

    /**
     * @param mixed $FormControlId
     */


    setFormControlId (FormControlId: any){
        this.attributes.form_control_id = FormControlId;
    }

    getIgnoreLoadingBar() {
        return this.attributes.ignoreLoadingBar;
    }

    /**
     * @param mixed $ignoreLoadingBar
     */


    setIgnoreLoadingBar (IgnoreLoadingBar: any){
        this.attributes.ignoreLoadingBar = IgnoreLoadingBar;
    }

    getIsDistributor() {
        return this.attributes.is_distributor;
    }

    /**
     * @param mixed $IsDistributor
     */


    setIsDistributor (IsDistributor: any){
        this.attributes.is_distributor = IsDistributor;
    }


    getChecked() {
        return this.attributes.checked;
    }

    /**
     * @param mixed $Checked
     */


    setChecked (Checked: any){
        this.attributes.checked = Checked;
    }

    getDelivered() {
        return this.attributes.delivered;
    }

    /**
     * @param mixed $Delivered
     */


    setDelivered (Delivered: any){
        this.attributes.delivered = Delivered;
    }

    getIsDirector() {
        return this.attributes.is_director;
    }

    /**
     * @param mixed $IsDirector
     */


    setIsDirector (IsDirector: any){
        this.attributes.is_director = IsDirector;
    }


    getWeekId() {
        return this.attributes.week_id;
    }

    /**
     * @param mixed $WeekId
     */


    setWeekId (WeekId: any){
        this.attributes.week_id = WeekId;
    }


    getWebsocketChannelIsAttending() {
        return this.attributes.websocket_channel_is_attending;
    }

    /**
     * @param mixed $WebsocketChannelIsAttending
     */


    setWebsocketChannelIsAttending (WebsocketChannelIsAttending: any){
        this.attributes.websocket_channel_is_attending = WebsocketChannelIsAttending;
    }


    getCollapsed() {
        return this.attributes.collapsed;
    }

    /**
     * @param mixed $Collapsed
     */


    setCollapsed (Collapsed: any){
        this.attributes.collapsed = Collapsed;
    }


    getSearchText() {
        return this.attributes.searchText;
    }

    /**
     * @param mixed $SearchText
     */


    setSearchText (SearchText: any){
        this.attributes.searchText = SearchText;
    }

    getWaypointId() {
        return this.attributes.waypoint_id;
    }

    /**
     * @param mixed $WaypointId
     */


    setWaypointId (WaypointId: any){
        this.attributes.waypoint_id = WaypointId;
    }

    getWebsocketEventIsAttending() {
        return this.attributes.websocket_event_is_attending;
    }

    /**
     * @param mixed $WebsocketEventIsAttending
     */


    setWebsocketEventIsAttending (WebsocketEventIsAttending: any){
        this.attributes.websocket_event_is_attending = WebsocketEventIsAttending;
    }

    getStarter() {
        return this.attributes.starter;
    }

    /**
     * @param mixed $Starter
     */


    setStarter (Starter: any){
        this.attributes.starter = Starter;
    }

    getWebsocketEventUserWaypoint() {
        return this.attributes.websocket_event_user_waypoint;
    }

    /**
     * @param mixed $WebsocketEventUserWaypoint
     */


    setWebsocketEventUserWaypoint (WebsocketEventUserWaypoint: any){
        this.attributes.websocket_event_user_waypoint = WebsocketEventUserWaypoint;
    }

    getWebsocketChannelUserWaypoint() {
        return this.attributes.websocket_channel_user_waypoint;
    }

    /**
     * @param mixed $WebsocketChannelUserWaypoint
     */


    setWebsocketChannelUserWaypoint (WebsocketChannelUserWaypoint: any){
        this.attributes.websocket_channel_user_waypoint = WebsocketChannelUserWaypoint;
    }

    getDesc() {
        return this.attributes.desc;
    }

    /**
     * @param mixed $Desc
     */


    setDesc (Desc: any){
        this.attributes.desc = Desc;
    }

    getAmbassadors() {
        return this.attributes.ambassadors;
    }

    /**
     * @param mixed $Ambassadors
     */


    setAmbassadors (Ambassadors: any){
        this.attributes.ambassadors = Ambassadors;
    }

    getWebsocketEventDeliveryUser() {
        return this.attributes.websocket_event_delivery_user;
    }

    /**
     * @param mixed $WebsocketEventDeliveryUser
     */


    setWebsocketEventDeliveryUser (WebsocketEventDeliveryUser: any){
        this.attributes.websocket_event_delivery_user = WebsocketEventDeliveryUser;
    }

    getWebsocketChannelDeliveryUser() {
        return this.attributes.websocket_channel_delivery_user;
    }

    /**
     * @param mixed $WebsocketChannelDeliveryUser
     */


    setWebsocketChannelDeliveryUser (WebsocketChannelDeliveryUser: any){
        this.attributes.websocket_channel_delivery_user = WebsocketChannelDeliveryUser;
    }

    getDeliveryUserChannel() {
        return this.attributes.delivery_user_channel;
    }

    /**
     * @param mixed $DeliveryUserChannel
     */


    setDeliveryUserChannel (DeliveryUserChannel: any){
        this.attributes.delivery_user_channel = DeliveryUserChannel;
    }


    getEntityId() {
        return this.attributes.entity_id;
    }

    /**
     * @param mixed $EntityId
     */


    setEntityId (EntityId: any){
        this.attributes.entity_id = EntityId;
    }

    getEqualSlash() {
        return this.attributes.equal_slash;
    }

    /**
     * @param mixed $EqualSlash
     */


    setEqualSlash (EqualSlash: any){
        this.attributes.equal_slash = EqualSlash;
    }


    getShipment() {
        return this.attributes.shipment;
    }

    /**
     * @param mixed $Shipment
     */


    setShipment (Shipment: any){
        this.attributes.shipment = Shipment;
    }

    getTracking() {
        return this.attributes.tracking;
    }

    /**
     * @param mixed $Tracking
     */


    setTracking (Tracking: any){
        this.attributes.tracking = Tracking;
    }


    getTreenodes() {
        return this.attributes.treenodes;
    }

    /**
     * @param mixed $Treenodes
     */


    setTreenodes (Treenodes: any){
        this.attributes.treenodes = Treenodes;
    }



    getPastoralistId() {
        return this.attributes.pastoralist_id;
    }

    /**
     * @param mixed $PastoralistId
     */


    setPastoralistId (PastoralistId: any){
        this.attributes.pastoralist_id = PastoralistId;
    }


    getReference3() {
        return this.attributes.reference3;
    }

    /**
     * @param mixed $Reference3
     */


    setReference3 (Reference3: any){
        this.attributes.reference3 = Reference3;
    }


    getReference() {
        return this.attributes.reference;
    }

    /**
     * @param mixed $Reference
     */


    setReference (Reference: any){
        this.attributes.reference = Reference;
    }

    getConditionId() {
        return this.attributes.condition_id;
    }

    /**
     * @param mixed $ConditionId
     */


    setConditionId (ConditionId: any){
        this.attributes.condition_id = ConditionId;
    }


    getDistrictId() {
        return this.attributes.district_id;
    }

    /**
     * @param mixed $DistrictId
     */


    setDistrictId (DistrictId: any){
        this.attributes.district_id = DistrictId;
    }

    getReference2() {
        return this.attributes.reference2;
    }

    /**
     * @param mixed $Reference2
     */


    setReference2 (Reference2: any){
        this.attributes.reference2 = Reference2;
    }

    getRespond() {
        return this.attributes.respond;
    }

    /**
     * @param mixed $Respond
     */


    setRespond (Respond: any){
        this.attributes.respond = Respond;
    }


    getReferenceId() {
        return this.attributes.reference_id;
    }

    /**
     * @param mixed $ReferenceId
     */


    setReferenceId (ReferenceId: any){
        this.attributes.reference_id = ReferenceId;
    }


    getJudgmentConditionId() {
        return this.attributes.judgment_condition_id;
    }

    /**
     * @param mixed $JudgmentConditionId
     */


    setJudgmentConditionId (JudgmentConditionId: any){
        this.attributes.judgment_condition_id = JudgmentConditionId;
    }


    getJudgmentConditions() {
        return this.attributes.judgment_conditions;
    }

    /**
     * @param mixed $JudgmentConditions
     */


    setJudgmentConditions (JudgmentConditions: any){
        this.attributes.judgment_conditions = JudgmentConditions;
    }

    getDiseases() {
        return this.attributes.diseases;
    }

    /**
     * @param mixed $Diseases
     */


    setDiseases (Diseases: any){
        this.attributes.diseases = Diseases;
    }



    getRecipient() {
        return this.attributes.recipient;
    }

    /**
     * @param mixed $Recipient
     */


    setRecipient (Recipient: any){
        this.attributes.recipient = Recipient;
    }

    getRespondent() {
        return this.attributes.respondent;
    }

    /**
     * @param mixed $Respondent
     */


    setRespondent (Respondent: any){
        this.attributes.respondent = Respondent;
    }

    getConditions() {
        return this.attributes.conditions;
    }

    /**
     * @param mixed $Conditions
     */


    setConditions (Conditions: any){
        this.attributes.conditions = Conditions;
    }

    getRegionId() {
        return this.attributes.region_id;
    }

    /**
     * @param mixed $RegionId
     */


    setRegionId(RegionId: any){
        this.attributes.region_id = RegionId;
    }


    getDiseaseId() {
        return this.attributes.disease_id;
    }

    /**
     * @param mixed $DiseaseId
     */


    setDiseaseId(DiseaseId: any){
        this.attributes.disease_id = DiseaseId;
    }

    getConditionalId() {
        return this.attributes.condition_id;
    }

    /**
     * @param mixed $ConditionalId
     */


    setConditionalId(ConditionalId: any){
        this.attributes.condition_id = ConditionalId;
    }

    getIndustrial() {
        return this.attributes.industrial;
    }

    /**
     * @param mixed $Industrial
     */


    setIndustrial (Industrial: any){
        this.attributes.industrial = Industrial;
    }

    getSpecieConditionId() {
        return this.attributes.specie_condition_id;
    }

    /**
     * @param mixed $SpecieConditionId
     */


    setSpecieConditionId (SpecieConditionId: any){
        this.attributes.specie_condition_id = SpecieConditionId;
    }

    getSpecieDiseaseId() {
        return this.attributes.specie_disease_id;
    }

    /**
     * @param mixed $SpecieDiseaseId
     */


    setSpecieDiseaseId (SpecieDiseaseId: any){
        this.attributes.specie_disease_id = SpecieDiseaseId;
    }





    getMunicipal() {
        return this.attributes.municipal;
    }

    /**
     * @param mixed $Municipal
     */


    setMunicipal (Municipal: any){
        this.attributes.municipal = Municipal;
    }


    getWhatsapp() {
        return this.attributes.whatsapp;
    }

    /**
     * @param mixed $Whatsapp
     */


    setWhatsapp (Whatsapp: any){
        this.attributes.whatsapp = Whatsapp;
    }


    getSeats() {
        return this.attributes.seats;
    }

    /**
     * @param mixed $Seats
     */


    setSeats (Seats: any){
        this.attributes.seats = Seats;
    }


    getInvitations() {
        return this.attributes.invitations;
    }

    /**
     * @param mixed $Invitations
     */


    setInvitations (Invitations: any){
        this.attributes.invitations = Invitations;
    }




    getServiceId (){
        return this.attributes.service_id;
    }

    /**
     * @param mixed $ServiceId
     */


    setServiceId (ServiceId: any){
        this.attributes.service_id = ServiceId;
    }

    getUnknown (unknown: any){
        return this.attributes[unknown];
    }

    /**
     * @param mixed $Unknown
     */


    setUnknown (unknown: any, unknownVal: any){
        this.attributes[unknown] = unknownVal;
    }

    getOutletSectors(){
        return this.attributes.outletSectors;
    }

    /**
     * @param mixed $OutletSectors
     */


    setOutletSectors(OutletSectors: any){
        this.attributes.outletSectors = OutletSectors;
    }

    getAnte (){
        return this.attributes.ante;
    }

    /**
     * @param mixed $Ante
     */


    setAnte (Ante: any){
        this.attributes.ante = Ante;
    }



    getPost (){
        return this.attributes.post;
    }

    /**
     * @param mixed $Post
     */


    setPost (Post: any){
        this.attributes.post = Post;
    }

    getPosition (){
        return this.attributes.position;
    }

    /**
     * @param mixed $Position
     */


    setPosition (Position: any){
        this.attributes.position = Position;
    }

    getChilds (){
        return this.attributes.childs;
    }

    /**
     * @param mixed $Childs
     */


    setChilds (Childs: any){
        this.attributes.childs = Childs;
    }

    getServices (){
        return this.attributes.services;
    }

    /**
     * @param mixed $Services
     */


    setServices (Services: any){
        this.attributes.services = Services;
    }

    getForm (){
        return this.attributes.form;
    }

    /**
     * @param mixed $Form
     */


    setForm (Form: any){
        this.attributes.form = Form;
    }

    getServiceTypeId (){
        return this.attributes.service_type_id;
    }

    /**
     * @param mixed $ServiceTypeId
     */


    setServiceTypeId (ServiceTypeId: any){
        this.attributes.service_type_id = ServiceTypeId;
    }



    getImage (){
        return this.attributes.image;
    }

    /**
     * @param mixed $Image
     */


    setImage (Image: any){
        this.attributes.image = Image;
    }


    getMinimumValue (){
        return this.attributes.minimum_value;
    }

    /**
     * @param mixed $MinimumValue
     */


    setMinimumValue (MinimumValue: any){
        this.attributes.minimum_value = MinimumValue;
    }

    getMaximumValue (){
        return this.attributes.maximum_value;
    }

    /**
     * @param mixed $MaximumValue
     */


    setMaximumValue (MaximumValue: any){
        this.attributes.maximum_value = MaximumValue;
    }

    getPath (){
        return this.attributes.path;
    }

    /**
     * @param mixed $Path
     */


    setPath (Path: any){
        this.attributes.path = Path;
    }



    getKey (){
        return this.attributes.key;
    }

    /**
     * @param mixed $Key
     */


    setKey (Key: any){
        this.attributes.key = Key;
    }

    getLastIndexOf (){
        return this.attributes.lastIndexOf;
    }

    /**
     * @param mixed $LastIndexOf
     */


    setLastIndexOf (LastIndexOf: any){
        this.attributes.lastIndexOf = LastIndexOf;
    }

    getData (){
        return this.attributes.data;
    }

    /**
     * @param mixed $Data
     */


    setData (Data: any){
        this.attributes.data = Data;
    }

    getDisplay (){
        return this.attributes.display;
    }

    /**
     * @param mixed $Display
     */


    setDisplay (Display: any){
        this.attributes.display = Display;
    }


    getDay (){
        return this.attributes.day;
    }

    /**
     * @param mixed $Day
     */


    setDay (Day: any){
        this.attributes.day = Day;
    }

    getDayId (){
        return this.attributes.day_id;
    }

    /**
     * @param mixed $DayId
     */


    setDayId (DayId: any){
        this.attributes.day_id = DayId;
    }


    getDays (){
        return this.attributes.days;
    }

    /**
     * @param mixed $Days
     */


    setDays (Days: any){
        this.attributes.days = Days;
    }


    getTime (){
        return this.attributes.time;
    }

    /**
     * @param mixed $Time
     */


    setTime (Time: any){
        this.attributes.time = Time;
    }


    getArrivingInvitationCardContents(){
        return this.attributes.arriving_invitation_card_contents;
    }
    /**
     * @param mixed
     */
    setArrivingInvitationCardContents (ArrivingInvitationCardContents: any){
        this.attributes.arriving_invitation_card_contents = ArrivingInvitationCardContents;
    }




    getForMedia (){
        return this.attributes.forMedia;
    }

    /**
     * @param mixed $ForMedia
     */


    setForMedia (ForMedia: any){
        this.attributes.forMedia = ForMedia;
    }

    getForResult (){
        return this.attributes.result;
    }

    /**
     * @param mixed $Result
     */


    setForResult (Result: any){
        this.attributes.result = Result;
    }



    getResults (){
        return this.attributes.results;
    }

    /**
     * @param mixed $Results
     */


    setResults (Results: any){
        this.attributes.results = Results;
    }


    getProvider (){
        return this.attributes.provider;
    }

    /**
     * @param mixed $Provider
     */

    setProvider (Provider: any){
        this.attributes.provider = Provider;
    }

    getImageUrl (){
        return this.attributes.imageUrl;
    }

    /**
     * @param mixed $ImageUrl
     */

    setImageUrl (ImageUrl: any){
        this.attributes.imageUrl = ImageUrl;
    }

    getUID (){
        return this.attributes.uid;
    }

    /**
     * @param mixed $UID
     */

    setUID (UId: any){
        this.attributes.uid = UId;
    }


    getResult (){
        return this.attributes.result;
    }

    /**
     * @param mixed $Result
     */


    setResult (Result: any){
        this.attributes.result = Result;
    }


    getCaption (){
        return this.attributes.caption;
    }

    /**
     * @param mixed $Caption
     */

    setCaption (Caption: any){
        this.attributes.caption = Caption;
    }

    getOrgans (){
        return this.attributes.organs;
    }

    /**
     * @param mixed $Organs
     */

    setOrgans (Organs: any){
        this.attributes.organs = Organs;
    }

    getOrganId (){
        return this.attributes.organ_id;
    }

    /**
     * @param mixed $OrganId
     */

    setOrganId (OrganId: any){
        this.attributes.organ_id = OrganId;
    }


    getSlash (){
        return this.attributes.slash;
    }

    /**
     * @param mixed
     */

    setSlash (Slash: any){
        this.attributes.slash = Slash;
    }

    getSlashConfirmation (){
        return this.attributes.slash_confirmation;
    }

    /**
     * @param mixed
     */

    setSlashConfirmation (SlashConfirmation: any){
        this.attributes.slash_confirmation = SlashConfirmation;
    }

    getTemplate (){
        return this.attributes.template;
    }

    /**
     * @param mixed
     */

    setTemplate (Template: any){
        this.attributes.template = Template;
    }

    getWebsite (){
        return this.attributes.website;
    }

    /**
     * @param mixed
     */

    setWebsite (Website: any){
        this.attributes.website = Website;
    }


    getSlashChatbot (){
        return this.attributes.slash_chatbot;
    }

    /**
     * @param mixed
     */

    setSlashChatbot (SlashChatbot: any){
        this.attributes.slash_chatbot = SlashChatbot;
    }


    getEqualSlashConfirmation (){
        return this.attributes.equal_slash_confirmation;
    }

    /**
     * @param mixed
     */

    setEqualSlashConfirmation (EqualSlashConfirmation: any){
        this.attributes.equal_slash_confirmation = EqualSlashConfirmation;
    }

    getEqualSlashChatbot (){
        return this.attributes.equal_slash_chatbot;
    }

    /**
     * @param mixed
     */

    setEqualSlashChatbot (EqualSlashChatbot: any){
        this.attributes.equal_slash_chatbot = EqualSlashChatbot;
    }



    getIsWhatsapp (){
        return this.attributes.is_whatsapp;
    }

    /**
     * @param mixed
     */

    setIsWhatsapp (IsWhatsapp: any){
        this.attributes.is_whatsapp = IsWhatsapp;
    }

    getJobTitle (){
        return this.attributes.job_title;
    }

    /**
     * @param mixed
     */

    setJobTitle (JobTitle: any){
        this.attributes.job_title = JobTitle;
    }

    getCanvasWidth (){
        return this.attributes.canvas_width;
    }

    /**
     * @param mixed
     */

    setCanvasWidth (CanvasWidth: any){
        this.attributes.canvas_width = CanvasWidth;
    }

    getCanvasHeight (){
        return this.attributes.canvas_height;
    }

    /**
     * @param mixed
     */

    setCanvasHeight (CanvasHeight: any){
        this.attributes.canvas_height = CanvasHeight;
    }

    getIsEmail (){
        return this.attributes.is_email;
    }

    /**
     * @param mixed
     */

    setIsEmail (IsEmail: any){
        this.attributes.is_email = IsEmail;
    }


    getChatbot (){
        return this.attributes.chatbot;
    }

    /**
     * @param mixed
     */

    setChatbot (Chatbot: any){
        this.attributes.chatbot = Chatbot;
    }

    getNext (){
        return this.attributes.next;
    }

    /**
     * @param mixed
     */

    setNext (Next: any){
        this.attributes.next = Next;
    }

    getNumber (){
        return this.attributes.number;
    }

    /**
     * @param mixed $Number
     */

    setNumber (Number: any){
        this.attributes.number = Number;
    }

    getBroadcasterDriver (){
        return this.attributes.broadcaster_driver;
    }

    /**
     * @param mixed $BroadcasterDriver
     */

    setBroadcasterDriver (BroadcasterDriver: any){
        this.attributes.broadcaster_driver = BroadcasterDriver;
    }

    getWebsocketKey (){
        return this.attributes.websocket_key;
    }

    /**
     * @param mixed $WebsocketKey
     */

    setWebsocketKey (WebsocketKey: any){
        this.attributes.websocket_key = WebsocketKey;
    }

    getBroadcaster (){
        return this.attributes.broadcaster;
    }

    /**
     * @param mixed $Broadcaster
     */

    setBroadcaster (Broadcaster: any){
        this.attributes.broadcaster = Broadcaster;
    }

    getWsPort (){
        return this.attributes.ws_port;
    }

    /**
     * @param mixed $WsPort
     */

    setWsPort (WsPort: any){
        this.attributes.ws_port = WsPort;
    }

    getWsHost (){
        return this.attributes.ws_host;
    }

    /**
     * @param mixed $WsHost
     */

    setWsHost (WsHost: any){
        this.attributes.ws_host = WsHost;
    }

    getWebsocketEventResponse (){
        return this.attributes.websocket_event_response;
    }

    /**
     * @param mixed $WebsocketEventResponse
     */

    setWebsocketEventResponse (WebsocketEventResponse: any){
        this.attributes.websocket_event_response = WebsocketEventResponse;
    }

    getWebsocketChannelResponse (){
        return this.attributes.websocket_channel_response;
    }

    /**
     * @param mixed $WebsocketChannelResponse
     */

    setWebsocketChannelResponse (WebsocketChannelResponse: any){
        this.attributes.websocket_channel_response = WebsocketChannelResponse;
    }

    getCarcassId (){
        return this.attributes.carcass_id;
    }

    /**
     * @param mixed $CarcassId
     */

    setCarcassId (CarcassId: any){
        this.attributes.carcass_id = CarcassId;
    }

    /**
     * @param mixed
     */

    getCreatedAt (){
        return this.attributes.created_at;
    }

    /**
     * @param mixed $CreatedAt
     */

    setCreatedAt (CreatedAt: any){
        this.attributes.created_at = CreatedAt;
    }


    /**
     * @param mixed
     */

    getUpdatedAt (){
        return this.attributes.updated_at;
    }

    /**
     * @param mixed $UpdatedAt
     */

    setUpdatedAt (UpdatedAt: any){
        this.attributes.updated_at = UpdatedAt;
    }

    getCarcasses (){
        return this.attributes.carcasses;
    }

    /**
     * @param mixed $Carcasses
     */

    setCarcasses (Carcasses: any){
        this.attributes.carcass = Carcasses;
    }


    getAppInvitationsSlash (){
        return this.attributes.app_invitations_slash;
    }

    /**
     * @param mixed $AppInvitationsSlash
     */

    setAppInvitationsSlash (AppInvitationsSlash: any){
        this.attributes.app_invitations_slash = AppInvitationsSlash;
    }

    getSlaughterSpecieId (){
        return this.attributes.slaughter_specie_id;
    }

    /**
     * @param mixed $SlaughterSpecieId
     */

    setSlaughterSpecieId (SlaughterSpecieId: any){
        this.attributes.slaughter_specie_id = SlaughterSpecieId;
    }

    getSpecieId (){
        return this.attributes.specie_id;
    }

    /**
     * @param mixed $SpecieId
     */

    setSpecieId (SpecieId: any){
        this.attributes.specie_id = SpecieId;
    }

    getOrderBy (){
        return this.attributes.order_by;
    }

    /**
     * @param mixed $Caption
     */

    setOrderBy (OrderBy: any){
        this.attributes.order_by = OrderBy;
    }

    getExport (){
        return this.attributes.export;
    }

    /**
     * @param mixed $Export
     */

    setExport (Export: any){
        this.attributes.export = Export;
    }

    getFlag (){
        return this.attributes.flag;
    }

    /**
     * @param mixed $Flag
     */

    setFlag (Flag: any){
        this.attributes.flag = Flag;
    }

    getPhoto (){
        return this.attributes.photo;
    }

    /**
     * @param mixed $Photo
     */

    setPhoto (Photo: any){
        this.attributes.photo = Photo;
    }

    getIsPayed (){
        return this.attributes.is_payed;
    }

    /**
     * @param mixed $IsPayed
     */

    setIsPayed (IsPayed: any){
        this.attributes.is_payed = IsPayed;
    }


    getDashboard (){
        return this.attributes.dashboard;
    }

    /**
     * @param mixed $Dashboard
     */

    setDashboard (Dashboard: any){
        this.attributes.dashboard = Dashboard;
    }

    getDistributors (){
        return this.attributes.distributors;
    }

    /**
     * @param mixed $Distributors
     */

    setDistributors (Distributors: any){
        this.attributes.distributors = Distributors;
    }


    getUs (){
        return this.attributes.us;
    }

    /**
     * @param mixed $Us
     */

    setUs (Us: any){
        this.attributes.us = Us;
    }

    getAfterRegisterSuccessfulMessage (){
        return this.attributes.after_register_successful_message;
    }

    /**
     * @param mixed $AfterRegisterSuccessfulMessage
     */

    setAfterRegisterSuccessfulMessage (AfterRegisterSuccessfulMessage: any){
        this.attributes.after_register_successful_message = AfterRegisterSuccessfulMessage;
    }


    getRedirectUrl (){
        return this.attributes.redirect_url;
    }

    /**
     * @param mixed $RedirectUrl
     */

    setRedirectUrl (RedirectUrl: any){
        this.attributes.redirect_url = RedirectUrl;
    }


    getOrderStatus (){
        return this.attributes.order_status;
    }

    /**
     * @param mixed $OrderStatus
     */

    setOrderStatus (OrderStatus: any){
        this.attributes.order_status = OrderStatus;
    }

    /**
     * @param mixed $Toast
     */

    getHttpUrl (){
        return this.attributes.httpUrl;
    }

    /**
     * @param mixed $httpUrl
     */

    setHttpUrl (HttpUrl: any){
        this.attributes.httpUrl = HttpUrl;
    }



    /**
     * @param mixed $Toast
     */


    getVideo (){
        return this.attributes.video;
    }

    /**
     * @param mixed $Video
     */

    setVideo (Video: any){
        this.attributes.video = Video;
    }

    getTag (){
        return this.attributes.tag;
    }

    /**
     * @param mixed $Tag
     */

    setTag (Tag: any){
        this.attributes.tag = Tag;
    }

    getRegisteredBy (){
        return this.attributes.registered_by;
    }

    /**
     * @param mixed $RegisteredBy
     */

    setRegisteredBy (RegisteredBy: any){
        this.attributes.registered_by = RegisteredBy;
    }

    getSlaughterTypes(){
        return this.attributes.slaughter_types;
    }

    /**
     * @param mixed $SlaughterTypes
     */

    setSlaughterTypes (SlaughterTypes: any){
        this.attributes.slaughter_types = SlaughterTypes;
    }

    getCC(){
        return this.attributes.cc;
    }

    /**
     * @param mixed $CC
     */

    setCC (CC: any){
        this.attributes.cc = CC;
    }


    getParent(){
        return this.attributes.parent;
    }

    /**
     * @param mixed
     */

    setParent (Parent: any){
        this.attributes.parent = Parent;
    }


    getAddress(){
        return this.attributes.address;
    }

    /**
     * @param mixed $Address
     */

    setAddress (Address: any){
        this.attributes.address = Address;
    }

    getDeclinedLandingPageContents(){
        return this.attributes.declined_landing_page_contents;
    }



    /**
     * @param mixed $DeclinedLandingPageContents
     */

    setDeclinedLandingPageContents (DeclinedLandingPageContents: any){
        this.attributes.declined_landing_page_contents = DeclinedLandingPageContents;
    }

    getAfterAcceptLandingPageContents(){
        return this.attributes.after_accept_landing_page_contents;
    }

    /**
     * @param mixed $AfterAcceptLandingPageContents
     */

    setAfterAcceptLandingPageContents (AfterAcceptLandingPageContents: any){
        this.attributes.after_accept_landing_page_contents = AfterAcceptLandingPageContents;
    }

    getDistrict(){
        return this.attributes.district;
    }

    /**
     * @param mixed $District
     */

    setDistrict (District: any){
        this.attributes.district = District;
    }

    getSlaughterFacilityCategory(){
        return this.attributes.slaughter_facility_category;
    }

    /**
     * @param mixed $SlaughterFacilityCategory
     */

    setSlaughterFacilityCategory (SlaughterFacilityCategory: any){
        this.attributes.slaughter_facility_category = SlaughterFacilityCategory;
    }

    getDistricts(){
        return this.attributes.districts;
    }

    /**
     * @param mixed $Districts
     */

    setDistricts (Districts: any){
        this.attributes.districts = Districts;
    }


    getFrameDataUrl (){
        return this.attributes.frame_data_url;
    }

    /**
     * @param mixed
     */

    setFrameDataUrl (FrameDataUrl: any){
        this.attributes.frame_data_url = FrameDataUrl;
    }


    getNotSure (){
        return this.attributes.not_sure;
    }

    /**
     * @param mixed
     */

    setNotSure (NotSure: any){
        this.attributes.not_sure = NotSure;
    }

    getSlaughterTypeId(){
        return this.attributes.slaughter_type_id;
    }

    /**
     * @param mixed $SlaughterTypeId
     */

    setSlaughterTypeId (SlaughterTypeId: any){
        this.attributes.slaughter_type_id = SlaughterTypeId;
    }


    getRegistrationNumber (){
        return this.attributes.registration_number;
    }

    /**
     * @param mixed $RegistrationNumber
     */

    setRegistrationNumber (RegistrationNumber: any){
        this.attributes.registration_number = RegistrationNumber;
    }


    getSlaughterType(){
        return this.attributes.slaughter_type;
    }

    /**
     * @param mixed $SlaughterType
     */

    setSlaughterType (SlaughterType: any){
        this.attributes.slaughter_type = SlaughterType;
    }

    getSlaughters (){
        return this.attributes.slaughters;
    }

    /**
     * @param mixed $Slaughters
     */

    setSlaughters (Slaughters: any){
        this.attributes.slaughters = Slaughters;
    }


    getMemberId (){
        return this.attributes.member_id;
    }

    /**
     * @param mixed $MemberId
     */

    setMemberId (MemberId: any){
        this.attributes.member_id = MemberId;
    }



    getParentNode (){
        return this.attributes.parent_node;
    }

    /**
     * @param mixed $ParentNode
     */

    setParentNode (ParentNode: any){
        this.attributes.parent_node = ParentNode;
    }

    getCntrlNum (){
        return this.attributes.cntrl_num;
    }

    /**
     * @param mixed $CntrlNum
     */

    setCntrlNum (CntrlNum: any){
        this.attributes.cntrl_num = CntrlNum;
    }

    getDeliveryUser (){
        return this.attributes.delivery_user;
    }

    /**
     * @param mixed $DeliveryUser
     */

    setDeliveryUser (DeliveryUser: any){
        this.attributes.delivery_user = DeliveryUser;
    }


    getBusiness (){
        return this.attributes.business;
    }

    /**
     * @param mixed $Business
     */

    setBusiness (Business: any){
        this.attributes.business = Business;
    }

    getCropY (){
        return this.attributes.crop_y;
    }

    /**
     * @param mixed $CropY
     */

    setCropY (CropY: any){
        this.attributes.crop_y = CropY;
    }

    getCropX (){
        return this.attributes.crop_x;
    }

    /**
     * @param mixed $CropX
     */

    setCropX (CropX: any){
        this.attributes.crop_x = CropX;
    }

    getCropH (){
        return this.attributes.crop_h;
    }

    /**
     * @param mixed $CropH
     */

    setCropH (CropH: any){
        this.attributes.crop_h = CropH;
    }

    getCropW (){
        return this.attributes.crop_w;
    }

    /**
     * @param mixed $CropW
     */

    setCropW (CropW: any){
        this.attributes.crop_w = CropW;
    }

    getEncryptedText (){
        return this.attributes.encrypted_text;
    }

    /**
     * @param mixed $EncryptedText
     */

    setEncryptedText (EncryptedText: any){
        this.attributes.encrypted_text = EncryptedText;
    }

    getPostedBy (){
        return this.attributes.posted_by;
    }

    /**
     * @param mixed $PostedBy
     */

    setPostedBy (PostedBy: any){
        this.attributes.posted_by = PostedBy;
    }

    getPostId (){
        return this.attributes.post_id;
    }

    /**
     * @param mixed $PostId
     */

    setPostId (PostId: any){
        this.attributes.post_id = PostId;
    }

    getInspectorId(){
        return this.attributes.inspector_id;
    }

    /**
     * @param mixed $InspectorId
     */

    setInspectorId (InspectorId: any){
        this.attributes.inspector_id = InspectorId;
    }


    getReply(){
        return this.attributes.reply;
    }

    /**
     * @param mixed $Reply
     */

    setReply (Reply: any){
        this.attributes.reply = Reply;
    }

    getRegions(){
        return this.attributes.regions;
    }

    /**
     * @param mixed $Regions
     */

    setTeamMemberId (TeamMemberId: any){
        this.attributes.team_member_id = TeamMemberId;
    }

    /**
     * @param mixed $InspectorId
     */

    getTeamMemberId(){
        return this.attributes.team_member_id;
    }

    /**
     * @param mixed $Regions
     */

    setRegions (Regions: any){
        this.attributes.regions = Regions;
    }

    getExperience (){
        return this.attributes.experience;
    }

    /**
     * @param mixed $Experience
     */

    setExperience (Experience: any){
        this.attributes.experience = Experience;
    }

    getBusinessServiceId (){
        return this.attributes.business_service_id;
    }

    /**
     * @param mixed $BusinessServiceId
     */

    setBusinessServiceId (BusinessServiceId: any){
        this.attributes.business_service_id = BusinessServiceId;
    }

    getImages (){
        return this.attributes.images;
    }

    /**
     * @param mixed $Images
     */

    setImages (Images: any){
        this.attributes.images = Images;
    }

    getMetaFacebookWhatsappToken (){
        return this.attributes.meta_facebook_whatsapp_token;
    }

    /**
     * @param mixed $MetaFacebookWhatsappToken
     */

    setMetaFacebookWhatsappToken (MetaFacebookWhatsappToken: any){
        this.attributes.meta_facebook_whatsapp_token = MetaFacebookWhatsappToken;
    }

    getFrameUrl (){
        return this.attributes.frame_url;
    }

    /**
     * @param mixed
     */

    setFrameUrl (FrameUrl: any){
        this.attributes.frame_url = FrameUrl;
    }

    getVideos (){
        return this.attributes.videos;
    }
    setVideos (Videos: any){
        this.attributes.videos = Videos;
    }

    getResponseChannel (){
        return this.attributes.response_channel;
    }

    /**
     * @param mixed
     */
    setResponseChannel (ResponseChannel: any){
        this.attributes.response_channel = ResponseChannel;
    }

    getChart (){
        return this.attributes.chart;
    }
    /**
     * @param mixed
     */
    setChart (Chart: any){
        this.attributes.chart = Chart;
    }


    getResponseCntrlNum (){
        return this.attributes.response_cntrl_num;
    }
    /**
     * @param mixed
     */
    setResponseCntrlNum (ResponseCntrlNum: any){
        this.attributes.response_cntrl_num = ResponseCntrlNum;
    }

    getFrame (){
        return this.attributes.frame;
    }
    /**
     * @param mixed
     */
    setFrame (Frame: any){
        this.attributes.frame = Frame;
    }

    getQuestions (){
        return this.attributes.questions;
    }
    setQuestions (Questions: any){
        this.attributes.questions = Questions;
    }

    getFormImageId (){
        return this.attributes.form_image_id;
    }
    /**
     * @param mixed
     */
    setFormImageId (FormImageId: any){
        this.attributes.form_image_id = FormImageId;
    }

    getBusinessId (){
        return this.attributes.business_id;
    }
    setBusinessId (BusinessId: any){
        this.attributes.business_id = BusinessId;
    }

    getPdfUrl (){
        return this.attributes.pdf_url;
    }

    /**
     * @param mixed
     */

    setPdfUrl (PdfUrl: any){
        this.attributes.pdf_url = PdfUrl;
    }

    getQrcodeFrame (){
        return this.attributes.qrcode_frame;
    }
    /**
     * @param mixed
     */
    setQrcodeFrame (QrcodeFrame: any){
        this.attributes.qrcode_frame = QrcodeFrame;
    }

    getOrigin() {
        return this.attributes.origin;
    }

    /**
     * @param mixed
     */

    setOrigin (Origin: any){
        this.attributes.origin = Origin;
    }

    getBusinessmanId (){
        return this.attributes.businessman_id;
    }

    /**
     * @param mixed
     */

    setBusinessmanId (BusinessmanId: any){
        this.attributes.businessman_id = BusinessmanId;
    }


    getAudioUrl (){
        return this.attributes.audio_url;
    }

    /**
     * @param mixed AudioUrl
     */

    setAudioUrl (AudioUrl: any){
        this.attributes.audio_url = AudioUrl;
    }

    getName (){
        return this.attributes.name;
    }

    /**
     * @param mixed
     */

    setName (name: any){
        this.attributes.name = name;
    }

    getEnding (){
        return this.attributes.ending;
    }

    /**
     * @param mixed
     */

    setEnding (Ending: any){
        this.attributes.ending = Ending;
    }

    getStarting (){
        return this.attributes.starting;
    }

    /**
     * @param mixed
     */

    setStarting (Starting: any){
        this.attributes.starting = Starting;
    }

    getLimit (){
        return this.attributes.limit;
    }

    /**
     * @param mixed
     */

    setLimit (Limit: any){
        this.attributes.limit = Limit;
    }

    getTickets (){
        return this.attributes.tickets;
    }

    /**
     * @param mixed
     */

    setTickets (Tickets: any){
        this.attributes.tickets = Tickets;
    }


    getPackages (){
        return this.attributes.packages;
    }

    /**
     * @param mixed
     */

    setPackages (Packages: any){
        this.attributes.packages = Packages;
    }

    getYScrolling (){
        return this.attributes.y_scrolling;
    }

    /**
     * @param mixed
     */

    setYScrolling (YScrolling: any){
        this.attributes.y_scrolling = YScrolling;
    }

    getIsPosted (){
        return this.attributes.is_posted;
    }

    /**
     * @param mixed
     */

    setIsPosted (IsPosted: any){
        this.attributes.is_posted = IsPosted;
    }

    getClient (){
        return this.attributes.client;
    }

    /**
     * @param mixed
     */

    setClient (Client: any){
        this.attributes.client = Client;
    }

    getShow (){
        return this.attributes.show;
    }
    setShow (Show: any){
        this.attributes.show = Show;
    }

    getAccounts (){
        return this.attributes.accounts;
    }
    setAccounts (Accounts: any){
        this.attributes.accounts = Accounts;
    }

    getDetails (){
        return this.attributes.details;
    }
    setDetails (Details: any){
        this.attributes.details = Details;
    }


    getGuest (){
        return this.attributes.guest;
    }

    setGuest (Guest: any){
        this.attributes.guest = Guest;
    }


    getTriggerNextButton (){
        return this.attributes.trigger_next_button;
    }
    setTriggerNextButton (TriggerNextButton: any){
        this.attributes.trigger_next_button = TriggerNextButton;
    }

    getOwner (){
        return this.attributes.owner;
    }
    setOwner (Owner: any){
        this.attributes.owner = Owner;
    }

    getReports (){
        return this.attributes.reports;
    }
    setReports (Reports: any){
        this.attributes.reports = Reports;
    }

    getReportQuestionId (){
        return this.attributes.report_question_id;
    }

    /**
     * @param mixed
     */

    setReportQuestionId (ReportQuestionId: any){
        this.attributes.report_question_id = ReportQuestionId;
    }

    getChildren (){
        return this.attributes.children;
    }
    setChildren (Children: any){
        this.attributes.children = Children;
    }

    getLanguage (){
        return this.attributes.language;
    }

    /**
     * @param mixed Language
     */

    setLanguage (Language: any){
        this.attributes.language = Language;
    }

    getLogoUrl (){
        return this.attributes.logo_url;
    }

    /**
     * @param mixed logoUrl
     */

    setLogoUrl (LogoUrl: any){
        this.attributes.logo_url = LogoUrl;
    }

    getUsers (){
        return this.attributes.users;
    }
    setUsers (Users: any){
        this.attributes.users = Users;
    }
    getSlaughterId (){
        return this.attributes.slaughter_id;
    }
    setSlaughterId (SlaughterId: any){
        this.attributes.slaughter_id = SlaughterId;
    }

    getRegionPlaceId (){
        return this.attributes.httpUrl;
    }

    /**
     * @param mixed $httpUrl
     */

    setRegionPlaceId (RegionPlaceId: any){
        this.attributes.httpUrl = RegionPlaceId;
    }

    getType (){
        return this.attributes.type;
    }
    setType (Type: any){
        this.attributes.type = Type;
    }

    getRoutes() {
        return this.attributes.routes;
    }

    /**
     * @param mixed $Routes
     */


    setRoutes (Routes: any){
        this.attributes.routes = Routes;
    }

    getFile (){
        return this.attributes.file;
    }

    /**
     * @param mixed $Routes
     */

    setFile (File: any){
        this.attributes.file = File;
    }

    getRouteId (){
        return this.attributes.route_id;
    }

    /**
     * @param mixed $Routes
     */

    setRouteId (RouteId: any){
        this.attributes.route_id = RouteId;
    }

    getLevel3() {
        return this.attributes.level3;
    }

    /**
     * @param mixed
     */


    setLevel3 (Level3: any){
        this.attributes.level3 = Level3;
    }

    getSize (){
        return this.attributes.size;
    }
    setSize (Size: any){
        this.attributes.size = Size;
    }


    getClients (){
        return this.attributes.clients;
    }
    setClients (Clients: any){
        this.attributes.clients = Clients;
    }

    getLevel() {
        return this.attributes.level;
    }

    /**
     * @param mixed
     */


    setLevel (Level: any){
        this.attributes.level = Level;
    }

    getForms (){
        return this.attributes.forms;
    }

    /**
     * @param mixed
     */

    setForms (Forms: any){
        this.attributes.forms = Forms;
    }

    getDescendinglastAt (){
        return this.attributes.descending_last_at;
    }

    /**
     * @param mixed
     */

    setDescendinglastAt (DescendinglastAt: any){
        this.attributes.descending_last_at = DescendinglastAt;
    }

    getUser (){
        return this.attributes.user;
    }

    /**
     * @param mixed
     */


    setUser (User: any){
        this.attributes.user = User;
    }

    getOutput (){
        return this.attributes.output;
    }

    /**
     * @param mixed
     */

    setOutput (Output: any){
        this.attributes.output = Output;
    }

    getFill() {
        return this.attributes.fill;
    }

    /**
     * @param mixed $Fill
     */


    setFill (Fill: any){
        this.attributes.fill = Fill;
    }

    getIsGPSShouldCaptured() {
        return this.attributes.is_GPS_should_captured;
    }

    /**
     * @param mixed $isGPSShouldCaptured
     */


    setIsGPSShouldCaptured (isGPSShouldCaptured: any){
        this.attributes.is_GPS_should_captured = isGPSShouldCaptured;
    }

    getReportByTeams (){
        return this.attributes.reportByTeams;
    }
    setReportByTeams (ReportByTeams: any){
        this.attributes.reportByTeams = ReportByTeams;
    }



    getStart(){
        return this.attributes.start;
    }
    /**
     * @param mixed
     */
    setStart (Start: any){
        this.attributes.start = Start;
    }

    getManagerId (){
        return this.attributes.manager_id;
    }

    /**
     * @param mixed
     */

    setManagerId (managerId: any){
        this.attributes.manager_id = managerId;
    }

    getResponseResultId(){
        return this.attributes.response_result_id;
    }
    /**
     * @param mixed
     */
    setResponseResultId (ResponseResultId: any){
        this.attributes.response_result_id = ResponseResultId;
    }


    getIsConfirm(){
        return this.attributes.is_confirm;
    }
    /**
     * @param mixed
     */
    setIsConfirm (IsConfirm: any){
        this.attributes.is_confirm = IsConfirm;
    }


    getAscendinglastAt (){
        return this.attributes.ascending_last_at;
    }

    /**
     * @param mixed
     */

    setAscendinglastAt (AscendinglastAt: any){
        this.attributes.ascending_last_at = AscendinglastAt;
    }


    getStrikeRate (){
        return this.attributes.strikeRate;
    }
    setStrikeRate (StrikeRate: any){
        this.attributes.strikeRate = StrikeRate;
    }

    getEnd(){
        return this.attributes.end;
    }
    /**
     * @param mixed
     */
    setEnd (End: any){
        this.attributes.end = End;
    }


    getLineGraphProgressByTeams (){
        return this.attributes.lineGraphProgressByTeams;
    }
    setLineGraphProgressByTeams (LineGraphProgressByTeams: any){
        this.attributes.lineGraphProgressByTeams = LineGraphProgressByTeams;
    }

    getResponseResult(){
        return this.attributes.response_result;
    }
    /**
     * @param mixed
     */
    setResponseResult (ResponseResult: any){
        this.attributes.response_result = ResponseResult;
    }

    getSecondaryGroupBy (){
        return this.attributes.secondary_group_by;
    }

    /**
     * @param mixed
     */


    setSecondaryGroupBy (SecondaryGroupBy: any){
        this.attributes.secondary_group_by = SecondaryGroupBy;
    }

    getAttendeeEmail (){
        return this.attributes.attendee_email;
    }

    /**
     * @param mixed
     */

    setAttendeeEmail (AttendeeEmail: any){
        this.attributes.attendee_email = AttendeeEmail;
    }

    getOutletId (){
        return this.attributes.outlet_id;
    }
    /**
     * @param mixed
     */
    setOutletId (OutletId: any){
        this.attributes.outlet_id = OutletId;
    }



    getMorisProgressByTeams (){
        return this.attributes.morisProgressByTeams;
    }
    setMorisProgressByTeams (MorisProgressByTeams: any){
        this.attributes.morisProgressByTeams = MorisProgressByTeams;
    }

    getUniqid (){
        return this.attributes.uniqid;
    }

    /**
     * @param mixed
     */


    setUniqid (Uniqid: any){
        this.attributes.uniqid = Uniqid;
    }

    getStatus (){
        return this.attributes.status;
    }

    /**
     * @param mixed
     */

    setStatus (status: any){
        this.attributes.status = status;
    }

    getTotal (){
        return this.attributes.total;
    }

    /**
     * @param mixed
     */

    setTotal (Total: any){
        this.attributes.total = Total;
    }

    getTextRotationAngle() {
        return this.attributes.text_rotation_angle;
    }

    /**
     * @param mixed
     */


    setTextRotationAngle (TextRotationAngle: any){
        this.attributes.text_rotation_angle = TextRotationAngle;
    }

    getFormId (){
        return this.attributes.form_id;
    }
    /**
     * @param mixed
     */
    setFormId (FormId: any){
        this.attributes.form_id = FormId;
    }


    getVenue (){
        return this.attributes.venue;
    }
    /**
     * @param mixed
     */
    setVenue(Venue: any){
        this.attributes.venue = Venue;
    }

    getVersion (){
        return this.attributes.version;
    }
    /**
     * @param mixed
     */
    setVersion(Version: any){
        this.attributes.version = Version;
    }

    getMorisGraphBarReportByTeams (){
        return this.attributes.morisGraphBarReportByTeams;
    }
    setMorisGraphBarReportByTeams (MorisGraphBarReportByTeams: any){
        this.attributes.morisGraphBarReportByTeams = MorisGraphBarReportByTeams;
    }

    getActionCaption (){
        return this.attributes.action_caption;
    }
    /**
     * @param mixed
     */
    setActionCaption (ActionCaption: any){
        this.attributes.action_caption = ActionCaption;
    }

    getEchartPieGraphProgressByTeams (){
        return this.attributes.echartPieGraphProgressByTeams;
    }

    setEchartPieGraphProgressByTeams (EchartPieGraphProgressByTeams: any){
        this.attributes.echartPieGraphProgressByTeams = EchartPieGraphProgressByTeams;
    }

    getCriterias (){
        return this.attributes.criterias;
    }

    /**
     * @param mixed $Criterias
     */

    setCriterias (Criterias: any){
        this.attributes.criterias = Criterias;
    }

    getTrashed (){
        return this.attributes.trashed;
    }
    setTrashed (Trashed: any){
        this.attributes.trashed = Trashed;
    }

    getPrimaryGroupBy() {
        return this.attributes.primary_group_by;
    }

    /**
     * @param mixed
     */


    setPrimaryGroupBy (PrimaryGroupBy: any){
        this.attributes.primary_group_by = PrimaryGroupBy;
    }


    getRecaptcha (){
        return this.attributes.grecaptcharesponse;
    }
    setRecaptcha (Recaptcha: any){
        this.attributes.grecaptcharesponse = Recaptcha;
    }

    getTextSize() {
        return this.attributes.text_size;
    }

    /**
     * @param mixed
     */


    setTextSize (TextSize: any){
        this.attributes.text_size = TextSize;
    }



    getSpecies (){
        return this.attributes.species;
    }
    setSpecies (Species: any){
        this.attributes.species = Species;
    }

    getDefaultContainerWidth() {
        return this.attributes.default_container_width;
    }

    /**
     * @param mixed
     */


    setDefaultContainerWidth (DefaultContainerWidth: any){
        this.attributes.default_container_width = DefaultContainerWidth;
    }

    getInspected (){
        return this.attributes.inspected;
    }

    setInspected (Inspected: any){
        this.attributes.inspected = Inspected;
    }

    getRateOptions (){
        return this.attributes.rate_options;
    }

    /**
     * @param mixed $RateOptions
     */

    setRateOptions (RateOptions: any){
        this.attributes.rate_options = RateOptions;
    }

    getCondemned (){
        return this.attributes.condemned;
    }
    setCondemned (Condemned: any){
        this.attributes.condemned = Condemned;
    }

    getEmployees (){
        return this.attributes.employees;
    }
    setEmployees (Employees: any){
        this.attributes.employees = Employees;
    }

    getGroupBy (){
        return this.attributes.group_by;
    }
    /**
     * @param mixed
     */
    setGroupBy (GroupBy: any){
        this.attributes.group_by = GroupBy;
    }

    getOptionDeleted (){
        return this.attributes.optionDeleted;
    }
    setOptionDeleted (optionDeleted: any){
        this.attributes.optionDeleted = optionDeleted;
    }
    getEmail (){
        return this.attributes.email;
    }
    setEmail (email: any){
        this.attributes.email = email;
    }


    getDestination() {
        return this.attributes.destination;
    }

    /**
     * @param mixed
     */

    setDestination (Destination: any){
        this.attributes.destination = Destination;
    }

    getCampaignId (){
        return this.attributes.campaign_id;
    }
    setCampaignId (campaignId: any){
        this.attributes.campaign_id = campaignId;
    }



    getCampaigns (){
        return this.attributes.campaigns;
    }
    setCampaigns (Campaigns: any){
        this.attributes.campaigns = Campaigns;
    }

    getCriteria (){
        return this.attributes.criteria;
    }

    /**
     * @param mixed $Criteria
     */

    setCriteria (Criteria: any){
        this.attributes.criteria = Criteria;
    }

    getLatLng2 (){
        return this.attributes.latlng2;
    }

    /**
     * @param mixed $LatLng2
     */

    setLatLng2 (LatLng2: any){
        this.attributes.latlng2 = LatLng2;
    }

    getLatLng1 (){
        return this.attributes.latlng1;
    }

    /**
     * @param mixed $LatLng1
     */

    setLatLng1 (LatLng1: any){
        this.attributes.latlng1 = LatLng1;
    }

    getSource (){
        return this.attributes.source;
    }
    setSource (source: any){
        this.attributes.source = source;
    }

    getOperator (){
        return this.attributes.operator;
    }

    /**
     * @param mixed $Operator
     */

    setOperator (Operator: any){
        this.attributes.operator = Operator;
    }

    getPhone(){
        return this.attributes.phone;
    }
    setPhone (phone: any){
        this.attributes.phone = phone;
    }

    getLargeDisplay (){
        return this.attributes.lfd;
    }
    /**
     * @param mixed
     */
    setLargeDisplay(LargeDisplay: any){
        this.attributes.lfd = LargeDisplay;
    }

    getLat(){
        return this.attributes.lat;
    }
    setLat (lat: any){
        this.attributes.lat = lat;
    }

    getMedia (){
        return this.attributes.media;
    }

    /**
     * @param mixed $Media
     */

    setMedia (Media: any){
        this.attributes.media = Media;
    }

    getLng(){
        return this.attributes.lng;
    }
    setLng (lng: any){
        this.attributes.lng = lng;
    }

    getDateOnSet() {
        return this.attributes.date_on_set;
    }

    /**
     * @param mixed $DateOnSet
     */


    setDateOnSet (DateOnSet: any){
        this.attributes.date_on_set = DateOnSet;
    }

    getTeam (){
        return this.attributes.team;
    }
    /**
     * @param mixed
     */
    setTeam (Team: any){
        this.attributes.team = Team;
    }
    getRoleId(){
        return this.attributes.role_id;
    }

    /**
     * @param mixed
     */

    setRoleId (role: any){
        this.attributes.role_id = role;
    }

    getDistinct() {
        return this.attributes.distinct;
    }

    /**
     * @param mixed $Distinct
     */


    setDistinct (Distinct: any){
        this.attributes.distinct = Distinct;
    }

    getPassword(){
        return this.attributes.password;
    }

    /**
     * @param mixed
     */

    setPassword (password: any){
        this.attributes.password = password;
    }

    getSellingPrice(){
        return this.attributes.selling_price;
    }

    /**
     * @param mixed
     */

    setSellingPrice (SellingPrice: any){
        this.attributes.selling_price = SellingPrice;
    }

    getCustomReports() {
        return this.attributes.custom_reports;
    }

    /**
     * @param mixed
     */


    setCustomReports (CustomReports: any){
        this.attributes.custom_reports = CustomReports;
    }

    getCampanyId(){
        return this.attributes.campany_id;
    }

    /**
     * @param mixed
     */

    setCampanyId (campanyId: any){
        this.attributes.campany_id = campanyId;
    }

    getDefaultContainerHeight() {
        return this.attributes.default_container_height;
    }

    /**
     * @param mixed
     */


    setDefaultContainerHeight (DefaultContainerHeight: any){
        this.attributes.default_container_height = DefaultContainerHeight;
    }

    getContactPersonName (){
        return this.attributes.contact_person_name;
    }

    /**
     * @param mixed
     */

    setContactPersonName (ContactPersonName: any){
        this.attributes.contact_person_name = ContactPersonName;
    }

    getAvatar (){
        return this.attributes.avatar;
    }

    /**
     * @param mixed
     */
    setAvatar (avatar: any){
        this.attributes.avatar = avatar;
    }


    getSuccessfull (){
        return this.attributes.successfull;
    }

    /**
     * @param mixed
     */
    setSuccessfull (Successfull: any){
        this.attributes.successfull = Successfull;
    }

    getCategoryId() {
        return this.attributes.category_id;
    }

    /**
     * @param mixed
     */

    setCategoryId (CategoryId: any){
        this.attributes.category_id = CategoryId;
    }

    getLocation(){
        return this.attributes.location;
    }
    setLocation (location: any){
        this.attributes.location = location;
    }

    getPlace (){
        return this.attributes.place;
    }
    /**
     * @param mixed
     */
    setPlace(Place: any){
        this.attributes.place = Place;
    }

    getLevel1() {
        return this.attributes.level1;
    }

    /**
     * @param mixed
     */


    setLevel1 (Level1: any){
        this.attributes.level1 = Level1;
    }

    getDescription (){
        return this.attributes.description;
    }
    setDescription (description: any){
        this.attributes.description = description;
    }

    getLandmark (){
        return this.attributes.landmark;
    }
    /**
     * @param mixed
     */
    setLandmark(Landmark: any){
        this.attributes.landmark = Landmark;
    }

    getClientId (){
        return this.attributes.client_id;
    }
    setClientId (clientId: any){
        this.attributes.client_id = clientId;
    }

    getActionButton1() {
        return this.attributes.action_button1;
    }

    /**
     * @param mixed
     */


    setActionButton1 (ActionButton1: any){
        this.attributes.action_button1 = ActionButton1;
    }


    getAttendeePic() {
        return this.attributes.attendee_pic;
    }

    /**
     * @param mixed
     */


    setAttendeePic (AttendeePic: any){
        this.attributes.attendee_pic = AttendeePic;
    }

    getInvitationCardHeight() {
        return this.attributes.invitation_card_height;
    }

    /**
     * @param mixed
     */


    setInvitationCardHeight (InvitationCardHeight: any){
        this.attributes.invitation_card_height = InvitationCardHeight;
    }

    getInvitationCardWidth() {
        return this.attributes.invitation_card_width;
    }

    /**
     * @param mixed
     */


    setInvitationCardWidth (InvitationCardWidth: any){
        this.attributes.invitation_card_width = InvitationCardWidth;
    }

    getBanner() {
        return this.attributes.banner;
    }

    /**
     * @param mixed
     */


    setBanner (Banner: any){
        this.attributes.banner = Banner;
    }


    getTeamId (){
        return this.attributes.team_id;
    }

    /**
     * @param mixed
     */
    setTeamId (TeamId: any){
        this.attributes.team_id = TeamId;
    }

    getDelivery (){
        return this.attributes.delivery;
    }

    /**
     * @param mixed
     */
    setDelivery (Delivery: any){
        this.attributes.delivery = Delivery;
    }

    getActive() {
        return this.attributes.active;
    }

    /**
     * @param mixed
     */


    setActive (Active: any){
        this.attributes.active = Active;
    }

    getContactPersonPhone (){
        return this.attributes.contact_person_phone;
    }

    /**
     * @param mixed
     */

    setContactPersonPhone (ContactPersonPhone: any){
        this.attributes.contact_person_phone = ContactPersonPhone;
    }



    getTypeId (){
        return this.attributes.type_id;
    }

    /**
     * @param mixed
     */

    setTypeId (TypeId: any){
        this.attributes.type_id = TypeId;
    }

    getDeletedAt() {
        return this.attributes.deleted_at;
    }

    /**
     * @param mixed
     */


    setDeletedAt (DeletedAt: any){
        this.attributes.deleted_at = DeletedAt;
    }


    getTeams (){
        return this.attributes.teams;
    }
    setTeams (Teams: any){
        this.attributes.teams = Teams;
    }

    getReference4() {
        return this.attributes.reference4;
    }

    /**
     * @param mixed $Reference4
     */


    setReference4 (Reference4: any){
        this.attributes.reference4 = Reference4;
    }


    getOutlet() {
        return this.attributes.outlet;
    }

    /**
     * @param mixed $Outlet
     */


    setOutlet (Outlet: any){
        this.attributes.outlet = Outlet;
    }


    getStartingAt (){
        return this.attributes.starting_at;
    }
    setStartingAt (startingAt: any){
        this.attributes.starting_at = startingAt;
    }

    getContact (){
        return this.attributes.contact;
    }
    setContact (Contact: any){
        this.attributes.contact = Contact;
    }

    getEndingAt (){
        return this.attributes.ending_at;
    }
    setEndingAt (endingAt: any){
        this.attributes.ending_at = endingAt;
    }
    getDate (){
        return this.attributes.date;
    }
    setDate (date: any){
        this.attributes.date = date;
    }

    getUrl (){
        return this.attributes.url;
    }

    setUrl (url: any){
        this.attributes.url = url;
    }


    getActionButton2() {
        return this.attributes.action_button2;
    }

    /**
     * @param mixed
     */


    setActionButton2 (ActionButton2: any){
        this.attributes.action_button2 = ActionButton2;
    }


    getInvitationCard() {
        return this.attributes.invitation_card;
    }

    /**
     * @param mixed
     */


    setInvitationCard (InvitationCard: any){
        this.attributes.invitation_card = InvitationCard;
    }

    getActionButton (){
        return this.attributes.actionButton;
    }
    setActionButton (actionButton: any){
        this.attributes.actionButton = actionButton;
    }

    getOutletTypeId() {
        return this.attributes.outlet_type_id;
    }

    /**
     * @param mixed
     */


    setOutletTypeId (OutletTypeId: any){
        this.attributes.outlet_type_id = OutletTypeId;
    }

    getConfirmationContents() {
        return this.attributes.confirmation_contents;
    }

    /**
     * @param mixed
     */


    setConfirmationContents (ConfirmationContents: any){
        this.attributes.confirmation_contents = ConfirmationContents;
    }


    getInvitationContents() {
        return this.attributes.invitation_contents;
    }

    /**
     * @param mixed
     */


    setInvitationContents (InvitationContents: any){
        this.attributes.invitation_contents = InvitationContents;
    }



    getSalesBasedOnOutlets() {
        return this.attributes.sales_based_on_outlets;
    }

    /**
     * @param mixed
     */


    setSalesBasedOnOutlets (SalesBasedOnOutlets: any){
        this.attributes.sales_based_on_outlets = SalesBasedOnOutlets;
    }

    getVistedOutletAliasName() {
        return this.attributes.visted_outlet_alias_name;
    }

    /**
     * @param mixed
     */


    setVistedOutletAliasName (VistedOutletAliasName: any){
        this.attributes.visted_outlet_alias_name = VistedOutletAliasName;
    }

    getResponsesCounter() {
        return this.attributes.responses_counter;
    }

    /**
     * @param mixed
     */


    setResponsesCounter (ResponsesCounter: any){
        this.attributes.responses_counter = ResponsesCounter;
    }

    getAction (){
        return this.attributes.action;
    }
    setAction (action: any){
        this.attributes.action = action;
    }

    getSubmit (){
        return this.attributes.submit;
    }
    /**
     * @param mixed
     */
    setSubmit (Submit: any){
        this.attributes.submit = Submit;
    }

    getTitle (){
        return this.attributes.title;
    }
    /**
     * @param mixed
     */

    setTitle (title: any){
        this.attributes.title = title;
    }

    getEmailStatus (){
        return this.attributes.email_status;
    }
    /**
     * @param mixed
     */

    setEmailStatus (EmailStatus: any){
        this.attributes.email_status = EmailStatus;
    }

    getInValid (){
        return this.attributes.in_valid;
    }
    /**
     * @param mixed
     */

    setInValid (InValid: any){
        this.attributes.in_valid = InValid;
    }

    getRisky (){
        return this.attributes.risky;
    }
    /**
     * @param mixed
     */

    setRisky (Risky: any){
        this.attributes.risky = Risky;
    }

    getValid (){
        return this.attributes.valid;
    }
    /**
     * @param mixed
     */

    setValid (Valid: any){
        this.attributes.valid = Valid;
    }


    getOrders (){
        return this.attributes.orders;
    }
    /**
     * @param mixed
     */

    setOrders (Orders: any){
        this.attributes.orders = Orders;
    }


    getEmailStatusIs (){
        return this.attributes.email_status_is;
    }
    /**
     * @param mixed
     */

    setEmailStatusIs (EmailStatusIs: any){
        this.attributes.email_status_is = EmailStatusIs;
    }

    getLive (){
        return this.attributes.live;
    }
    /**
     * @param mixed
     */

    setLive (Live: any){
        this.attributes.live = Live;
    }

    getInvitationCardEmailed (){
        return this.attributes.invitationcard_emailed;
    }
    /**
     * @param mixed
     */

    setInvitationCardEmailed (InvitationCardEmailed: any){
        this.attributes.invitationcard_emailed = InvitationCardEmailed;
    }

    getInvitationCardSentThroughWhatsapp (){
        return this.attributes.invitationcard_sent_through_whatsapp;
    }
    /**
     * @param mixed
     */

    setInvitationCardSentThroughWhatsapp (InvitationCardSentThroughWhatsapp: any){
        this.attributes.invitationcard_sent_through_whatsapp = InvitationCardSentThroughWhatsapp;
    }

    getLevel2() {
        return this.attributes.level2;
    }

    /**
     * @param mixed
     */


    setLevel2 (Level2: any){
        this.attributes.level2 = Level2;
    }


    getLogo (){
        return this.attributes.logo;
    }
    setLogo (logo: any){
        this.attributes.logo = logo;
    }

    getLongName (){
        return this.attributes.long_name;
    }
    /**
     * @param mixed
     */
    setLongName (LongName: any){
        this.attributes.long_name = LongName;
    }

    getRecipientPhone (){
        return this.attributes.recipient_phone;
    }
    /**
     * @param mixed
     */
    setRecipientPhone (RecipientPhone: any){
        this.attributes.recipient_phone = RecipientPhone;
    }

    getHttpMethod (){
        return this.attributes.method;
    }
    setHttpMethod (method: any){
        this.attributes.method = method;
    }


    getTimes (){
        return this.attributes.times;
    }
    /**
     * @param mixed
     */
    setTimes (Times: any){
        this.attributes.times = Times;
    }

    getDisabled (){
        return this.attributes.disabled;
    }
    /**
     * @param mixed
     */
    setDisabled (Disabled: any){
        this.attributes.disabled = Disabled;
    }

    getAttributes (){
        return this.attributes;
    }

    setAttributes (attr: any){
        this.attributes = attr;
    }

    getCurrency (){
        return this.attributes.currency;
    }
    /**
     * @param mixed
     */
    setCurrency (Currency: any){
        this.attributes.currency = Currency;
    }

    getSenderEmail (){
        return this.attributes.sender_email;
    }
    /**
     * @param mixed
     */
    setSenderEmail (SenderEmail: any){
        this.attributes.sender_email = SenderEmail;
    }

    getRecipientEmail (){
        return this.attributes.recipient_email;
    }
    /**
     * @param mixed
     */
    setRecipientEmail (RecipientEmail: any){
        this.attributes.recipient_email = RecipientEmail;
    }

    getEventName (){
        return this.attributes.event_name;
    }
    /**
     * @param mixed
     */
    setEventName (EventName: any){
        this.attributes.event_name = EventName;
    }

    getAnnoucement (){
        return this.attributes.annoucement;
    }
    /**
     * @param mixed
     */
    setAnnoucement (Annoucement: any){
        this.attributes.annoucement = Annoucement;
    }


    getEvents (){
        return this.attributes.events;
    }
    /**
     * @param mixed
     */
    setEvents (Events: any){
        this.attributes.events = Events;
    }

    getClientName (){
        return this.attributes.client_name;
    }
    /**
     * @param mixed
     */
    setClientName (ClientName: any){
        this.attributes.client_name = ClientName;
    }

    getPeriods(){
        return this.attributes.periods;
    }
    /**
     * @param mixed
     */
    setPeriods (Periods: any){
        this.attributes.periods = Periods;
    }

    /**
     * @param mixed
     */

    hasAttribute(param: any){
        return this.attributes.hasOwnProperty(param);
    }


    getEditorHtml (){
        return document.getElementsByClassName("Editor-editor")[0].innerHTML;
    }
    setEditorHtml (innerHtml: any){
        document.getElementsByClassName("Editor-editor")[0].innerHTML = innerHtml;
    }

    getBackgroundColor (){
        return this.attributes.background_color;
    }
    /**
     * @param mixed
     */
    setBackgroundColor(BackgroundColor: any){
        this.attributes.background_color = BackgroundColor;
    }

    gethashKey (){
        return this.attributes.hashKey;
    }


    sethashKey (hashKey: any){
        this.attributes.hashKey = hashKey;
    }

    getStock (){
        return this.attributes.stock;
    }
    /**
     * @param mixed
     */
    setStock(Stock: any){
        this.attributes.stock = Stock;
    }

    getHasOutlets (){
        return this.attributes.hasOutlets;
    }
    /**
     * @param mixed
     */
    setHasOutlets(HasOutlets: any){
        this.attributes.hasOutlets = HasOutlets;
    }


    getColor (){
        return this.attributes.color;
    }
    /**
     * @param mixed
     */
    setColor(Color: any){
        this.attributes.color = Color;
    }

    getCampany (){
        return this.attributes.campany;
    }
    setCampany (campany: any){
        this.attributes.campany = campany;
    }

    getInOutQty (){
        return this.attributes.in_out_qty;
    }
    /**
     * @param mixed
     */
    setInOutQty (InOutQty: any){
        this.attributes.in_out_qty = InOutQty;
    }


    getElement (){
        return this.attributes.element;
    }

    /**
     * @param mixed
     */

    setElement (Element: any){
        this.attributes.element = Element;
    }

    getOrder (){
        return this.attributes.order;
    }

    /**
     * @param mixed
     */

    setOrder (Order: any){
        this.attributes.order = Order;
    }

    getDot (){
        return this.attributes.dot;
    }

    /**
     * @param mixed
     */

    setDot (Dot: any){
        this.attributes.dot = Dot;
    }

    getItems (){
        return this.attributes.items;
    }
    /**
     * @param mixed
     */
    setItems (Items: any){
        this.attributes.items = Items;
    }


    getReference1 (){
        return this.attributes.reference1;
    }
    /**
     * @param mixed
     */
    setReference1 (Reference1: any){
        this.attributes.reference1 = Reference1;
    }

    getSparkline (){
        return this.attributes.sparkline;
    }
    setSparkline (Sparkline: any){
        this.attributes.sparkline = Sparkline;
    }

    getItemTypeId (){
        return this.attributes.item_type_id;
    }
    /**
     * @param mixed
     */
    setItemTypeId (ItemTypeId: any){
        this.attributes.item_type_id = ItemTypeId;
    }

    getResponses (){
        return this.attributes.responses;
    }
    setResponses (Responses: any){
        this.attributes.responses = Responses;
    }

    getQrcodeUrl (){
        return this.attributes.qrcode_url;
    }

    /**
     * @param mixed
     */

    setQrcodeUrl (QrcodeUrl: any){
        this.attributes.qrcode_url = QrcodeUrl;
    }

    getCampaign (){
        return this.attributes.campaign;
    }
    /**
     * @param mixed
     */
    setCampaign (Campaign: any){
        this.attributes.campaign = Campaign;
    }

    getResponse (){
        return this.attributes.response;
    }

    /**
     * @param mixed
     */

    setResponse (Response: any){
        this.attributes.response = Response;
    }

    getOutletsThatSellKpi() {
        return this.attributes.outlets_that_sell_kpi;
    }

    /**
     * @param mixed
     */

    setOutletsThatSellKpi (OutletsThatSellKpi: any){
        this.attributes.outlets_that_sell_kpi = OutletsThatSellKpi;

    }

    getLegend (){
        return this.attributes.legend;
    }
    setLegend (Legend: any){
        this.attributes.legend = Legend;
    }

    getItem (){
        return this.attributes.item;
    }
    /**
     * @param mixed
     */
    setItem(Item: any){
        this.attributes.item = Item;
    }



    getEmployeeId (){
        return this.attributes.employee_id;
    }
    setEmployeeId (EmployeeId: any){
        this.attributes.employee_id = EmployeeId;
    }

    getIsQrcode (){
        return this.attributes.is_qrcode;
    }

    /**
     * @param mixed
     */

    setIsQrcode (IsQrcode: any){
        this.attributes.is_qrcode = IsQrcode;
    }

    getBranchId (){
        return this.attributes.branch_id;
    }

    /**
     * @param mixed
     */

    setBranchId (BranchId: any){
        this.attributes.branch_id = BranchId;
    }

    getTarget (){
        return this.attributes.target;
    }

    /**
     * @param mixed
     */

    setTarget (target: any){
        this.attributes.target = target;
    }

    getAttendeeName() {
        return this.attributes.attendee_name;
    }

    /**
     * @param mixed
     */


    setAttendeeName (AttendeeName: any){
        this.attributes.attendee_name = AttendeeName;
    }


    getDownload() {
        return this.attributes.download;
    }

    /**
     * @param mixed
     */


    setDownload (Download: any){
        this.attributes.download = Download;
    }

    getSelectedTabIndex (){
        return this.attributes.selectedTabIndex;
    }
    setSelectedTabIndex (SelectedTabIndex: any){
        this.attributes.selectedTabIndex = SelectedTabIndex;
    }

    getItemId (){
        return this.attributes.item_id;
    }
    /**
     * @param mixed
     */
    setItemId (ItemId: any){
        this.attributes.item_id = ItemId;
    }

    getInvitees (){
        return this.attributes.invitees;
    }
    setInvitees (Invitees: any){
        this.attributes.invitees = Invitees;
    }

    getSubmitCaption (){
        return this.attributes.submit_caption;
    }
    /**
     * @param mixed
     */
    setSubmitCaption (SubmitCaption: any){
        this.attributes.submit_caption = SubmitCaption;
    }



    getControls (){
        return this.attributes.controls;
    }
    setControls (controls: any){
        this.attributes.controls = controls;
    }


    getMembers (){
        return this.attributes.members;
    }
    /**
     * @param mixed
     */
    setMembers (members: any){
        this.attributes.members = members;
    }

    getAccess (){
        return this.attributes.access;
    }
    /**
     * @param mixed
     */
    setAccess (Access: any){
        this.attributes.access = Access;
    }

    getLock (){
        return this.attributes.lock;
    }
    /**
     * @param mixed
     */
    setLock (Lock: any){
        this.attributes.lock = Lock;
    }

    getAppForms (){
        return this.attributes.app_forms;
    }
    /**
     * @param mixed
     */
    setAppForms (AppForms: any){
        this.attributes.app_forms = AppForms;
    }

    getHost (){
        return this.attributes.host;
    }
    /**
     * @param mixed
     */
    setHost (Host: any){
        this.attributes.host = Host;
    }


    getHeader (){
        return this.attributes.header;
    }
    /**
     * @param mixed
     */
    setHeader (Header: any){
        this.attributes.header = Header;
    }

    getFeedback (){
        return this.attributes.feedback;
    }
    /**
     * @param mixed
     */
    setFeedback (Feedback: any){
        this.attributes.feedback = Feedback;
    }


    getRegister (){
        return this.attributes.register;
    }
    /**
     * @param mixed
     */
    setRegister (Register: any){
        this.attributes.register = Register;
    }

    getSuggestions (){
        return this.attributes.suggestions;
    }
    /**
     * @param mixed
     */
    setSuggestions (Suggestions: any){
        this.attributes.suggestions = Suggestions;
    }

    getOthers (){
        return this.attributes.others;
    }
    /**
     * @param mixed
     */
    setOthers (Others: any){
        this.attributes.others = Others;
    }

    getBody (){
        return this.attributes.body;
    }
    /**
     * @param mixed
     */
    setBody (Body: any){
        this.attributes.body = Body;
    }

    getIcon (){
        return this.attributes.icon;
    }
    /**
     * @param mixed
     */
    setIcon (Icon: any){
        this.attributes.icon = Icon;
    }

    getAddOthers (){
        return this.attributes.add_others;
    }
    /**
     * @param mixed
     */
    setAddOthers (AddOthers: any){
        this.attributes.add_others = AddOthers;
    }

    getPercentageField2 (){
        return this.attributes.percentage_field2;
    }
    /**
     * @param mixed
     */
    setPercentageField2 (PercentageField2: any){
        this.attributes.percentage_field2 = PercentageField2;
    }

    getCoreBusinessField (){
        return this.attributes.core_business_field;
    }
    setCoreBusinessField (CoreBusinessField: any){
        this.attributes.core_business_field = CoreBusinessField;
    }

    getPercentageField1 (){
        return this.attributes.percentage_field1;
    }
    /**
     * @param mixed
     */
    setPercentageField1 (PercentageField1: any){
        this.attributes.percentage_field1 = PercentageField1;
    }

    getEntity2 (){
        return this.attributes.entity2;
    }
    /**
     * @param mixed
     */
    setEntity2 (Entity2: any){
        this.attributes.entity2 = Entity2;
    }

    getBranches (){
        return this.attributes.branches;
    }
    /**
     * @param mixed
     */
    setBranches (Branches: any){
        this.attributes.branches = Branches;
    }

    getProducts (){
        return this.attributes.products;
    }
    /**
     * @param mixed
     */
    setProducts (Products: any){
        this.attributes.products = Products;
    }

    getIsProduct (){
        return this.attributes.is_product;
    }
    /**
     * @param mixed
     */
    setIsProduct (IsProduct: any){
        this.attributes.is_product = IsProduct;
    }

    getVarWwwHtml (){
        return this.attributes.var_www_html;
    }
    /**
     * @param mixed
     */
    setVarWwwHtml (VarWwwHtml: any){
        this.attributes.var_www_html = VarWwwHtml;
    }

    getRequired (){
        return this.attributes.required;
    }
    /**
     * @param mixed
     */
    setRequired (Required: any){
        this.attributes.required = Required;
    }

    getWillTouch (){
        return this.attributes.will_touch;
    }
    /**
     * @param mixed
     */
    setWillTouch (WillTouch: any){
        this.attributes.will_touch = WillTouch;
    }

    getSuccess (){
        return this.attributes.success;
    }
    /**
     * @param mixed
     */
    setSuccess (Success: any){
        this.attributes.success = Success;
    }

    getRecaptchaValidationText (){
        return this.attributes.recaptcha_validation_text;
    }
    /**
     * @param mixed
     */
    setRecaptchaValidationText (RecaptchaValidationText: any){
        this.attributes.recaptcha_validation_text = RecaptchaValidationText;
    }



    getEntity1 (){
        return this.attributes.entity1;
    }
    /**
     * @param mixed
     */
    setEntity1 (Entity1: any){
        this.attributes.entity1 = Entity1;
    }

    getSample (){
        return this.attributes.sample;
    }
    /**
     * @param mixed
     */
    setSample (Sample: any){
        this.attributes.sample = Sample;
    }


    getWait (){
        return this.attributes.wait;
    }
    /**
     * @param mixed
     */
    setWait (Wait: any){
        this.attributes.wait = Wait;
    }


    getAtleastOptionalSelections (){
        return this.attributes.atleast_optional_selections;
    }
    /**
     * @param mixed
     */
    setAtleastOptionalSelections (AtleastOptionalSelections: any){
        this.attributes.atleast_optional_selections = AtleastOptionalSelections;
    }

    getAutoPercentageField (){
        return this.attributes.auto_percentage_field;
    }
    /**
     * @param mixed
     */
    setAutoPercentageField (AutoPercentageField: any){
        this.attributes.auto_percentage_field = AutoPercentageField;
    }

    getAutoAggregatedField (){
        return this.attributes.auto_aggregated_field;
    }
    /**
     * @param mixed
     */
    setAutoAggregatedField (AutoAggregatedField: any){
        this.attributes.auto_aggregated_field = AutoAggregatedField;
    }

    getRespondentAlias (){
        return this.attributes.respondent_alias;
    }
    /**
     * @param mixed
     */

    setRespondentAlias (RespondentAlias: any){
        this.attributes.respondent_alias = RespondentAlias;
    }

    getUserId (){
        return this.attributes.user_id;
    }
    /**
     * @param mixed
     */
    setUserId (UserId: any){
        this.attributes.user_id = UserId;
    }

    getStrokeColor (){
        return this.attributes.stroke_color;
    }
    /**
     * @param mixed
     */
    setStrokeColor (StrokeColor: any){
        this.attributes.stroke_color = StrokeColor;
    }

    getIsGPSTracked (){
        return this.attributes.is_gps_tracked;
    }
    /**
     * @param mixed
     */
    setIsGPSTracked (IsGPSTracked: any){
        this.attributes.is_gps_tracked = IsGPSTracked;
    }

    getFormattedAddress (){
        return this.attributes.formatted_address;
    }
    /**
     * @param mixed
     */
    setFormattedAddress (FormattedAddress: any){
        this.attributes.formatted_address = FormattedAddress;
    }

    getGpsReference (){
        return this.attributes.gps_reference;
    }
    setGpsReference (GpsReference: any){
        this.attributes.gps_reference = GpsReference;
    }

    getWaypoints (){
        return this.attributes.waypoints;
    }
    /**
     * @param mixed
     */
    setWaypoints (Waypoints: any){
        this.attributes.waypoints = Waypoints;
    }

    getWaypoints2 (){
        return this.attributes.waypoints2;
    }
    /**
     * @param mixed
     */
    setWaypoints2 (Waypoints2: any){
        this.attributes.waypoints2 = Waypoints2;
    }

    getParts (){
        return this.attributes.parts;
    }
    setParts (Parts: any){
        this.attributes.parts = Parts;
    }


    getIsScanning (){
        return this.attributes.is_scanning;
    }
    setIsScanning (IsScanning: any){
        this.attributes.is_scanning = IsScanning;
    }

    getTreeNode (){
        return this.attributes.treenode;
    }

    /**
     * @param mixed
     */

    setTreeNode (TreeNode: any){
        this.attributes.treenode = TreeNode;
    }


    getRSVPConfirmation (){
        return this.attributes.rsvp_confirmation;
    }
    /**
     * @param mixed
     */
    setRSVPConfirmation (RSVPConfirmation: any){
        this.attributes.rsvp_confirmation = RSVPConfirmation;
    }

    getMarkers (){
        return this.attributes.markers;
    }
    setMarkers (Markers: any){
        this.attributes.markers = Markers;
    }

    getIsInvitedByWhatsapp(){
        return this.attributes.is_invited_by_whatsapp;
    }
    /**
     * @param mixed
     */
    setIsInvitedByWhatsapp (IsInvitedByWhatsapp: any){
        this.attributes.is_invited_by_whatsapp = IsInvitedByWhatsapp;
    }

    getIsNull() {
        return this.attributes.is_null;
    }

    /**
     * @param mixed
     */


    setIsNull (IsNull: any){
        this.attributes.is_null = IsNull;
    }
    getRows (){
        return this.attributes.rows;
    }
    setRows (Rows: any){
        this.attributes.rows = Rows;
    }

    getResponseId (){
        return this.attributes.response_id;
    }
    /**
     * @param mixed
     */
    setResponseId (ResponseId: any){
        this.attributes.response_id = ResponseId;
    }

    getOutletSegmentId (){
        return this.attributes.outlet_segment_id;
    }
    /**
     * @param mixed
     */
    setOutletSegmentId (OutletSegmentId: any){
        this.attributes.outlet_segment_id = OutletSegmentId;
    }

    getReport (){
        return this.attributes.report;
    }

    /**
     * @param mixed
     */
    setReport (report: any){
        this.attributes.report = report;
    }


    getSearch (){
        return this.attributes.search;
    }

    /**
     * @param mixed
     */
    setSearch (Search: any){
        this.attributes.search = Search;
    }

    getYes (){
        return this.attributes.yes;
    }

    /**
     * @param mixed
     */
    setYes (Yes: any){
        this.attributes.yes = Yes;
    }

    getSalutation (){
        return this.attributes.salutation;
    }
    /**
     * @param mixed
     */
    setSalutation (Salutation: any){
        this.attributes.salutation = Salutation;
    }

    getEqual (){
        return this.attributes.equal;
    }
    /**
     * @param mixed
     */
    setEqual (Equal: any){
        this.attributes.equal = Equal;
    }

    getRaffles (){
        return this.attributes.raffles;
    }
    /**
     * @param mixed
     */
    setRaffles (Raffles: any){
        this.attributes.raffles = Raffles;
    }

    getSrc (){
        return this.attributes.src;
    }
    /**
     * @param mixed
     */
    setSrc (Src: any){
        this.attributes.src = Src;
    }

    getPlaceholder (){
        return this.attributes.placeholder;
    }
    setPlaceholder (Placeholder: any){
        this.attributes.placeholder = Placeholder;
    }

    getPlaceId  (){
        return this.attributes.place_id;
    }
    /**
     * @param mixed
     */
    setPlaceId  (PlaceId : any){
        this.attributes.place_id = PlaceId ;
    }


    getOptions (){
        return this.attributes.options;
    }
    setOptions (Options: any){
        this.attributes.options = Options;
    }

    getCustom() {
        return this.attributes.custom;
    }

    /**
     * @param mixed
     */


    setCustom (Custom: any){
        this.attributes.custom = Custom;
    }

    getMoments() {
        return this.attributes.moments;
    }

    /**
     * @param mixed
     */


    setMoments (Moments: any){
        this.attributes.moments = Moments;
    }

    getYearly() {
        return this.attributes.yearly;
    }

    /**
     * @param mixed
     */


    setYearly (Yearly: any){
        this.attributes.yearly = Yearly;
    }

    getMonthly() {
        return this.attributes.monthly;
    }

    /**
     * @param mixed
     */


    setMonthly (Monthly: any){
        this.attributes.monthly = Monthly;
    }


    getOutletChannel() {
        return this.attributes.outlet_channel;
    }

    /**
     * @param mixed
     */


    setOutletChannel (OutletChannel: any){
        this.attributes.outlet_channel = OutletChannel;
    }

    getMonthId() {
        return this.attributes.month_id;
    }

    /**
     * @param mixed
     */


    setMonthId (MonthId: any){
        this.attributes.month_id = MonthId;
    }

    getWeekly() {
        return this.attributes.weekly;
    }

    /**
     * @param mixed
     */


    setWeekly (Weekly: any){
        this.attributes.weekly = Weekly;
    }

    getLabel (){
        return this.attributes.label;
    }
    setLabel (Label: any){
        this.attributes.label = Label;
    }

    getDecimalPlaces() {
        return this.attributes.decimal_places;
    }

    /**
     * @param mixed
     */


    setDecimalPlaces (DecimalPlaces: any){
        this.attributes.decimal_places = DecimalPlaces;
    }

    getObj (){
        return this.attributes.obj;
    }
    setObj (Obj: any){
        this.attributes.obj = Obj;
    }

    getCanBeSavedBeforePublished() {
        return this.attributes.canBeSavedBeforePublished;
    }

    /**
     * @param mixed
     */


    setCanBeSavedBeforePublished (CanBeSavedBeforePublished: any){
        this.attributes.canBeSavedBeforePublished = CanBeSavedBeforePublished;
    }

    getCompanyName (){
        return this.attributes.company_name;
    }

    /**
     * @param mixed
     */


    setCompanyName (CompanyName: any){
        this.attributes.company_name = CompanyName;
    }

    getIndex (){
        return this.attributes.index;
    }

    /**
     * @param mixed
     */
    setIndex (Index: any){
        this.attributes.index = Index;
    }

    getQty() {
        return this.attributes.qty;
    }

    /**
     * @param mixed
     */


    setQty (Qty: any){
        this.attributes.qty = Qty;
    }

    getIsOutlet() {
        return this.attributes.is_outlet;
    }

    /**
     * @param mixed
     */


    setIsOutlet (IsOutlet: any){
        this.attributes.is_outlet = IsOutlet;
    }

    getAdvertUrl() {
        return this.attributes.advert_url;
    }

    /**
     * @param mixed
     */


    setAdvertUrl (AdvertUrl: any){
        this.attributes.advert_url = AdvertUrl;
    }

    getControl (){
        return this.attributes.control;
    }

    /**
     * @param mixed
     */

    setControl (Control: any){
        this.attributes.control = Control;
    }

    getIsAttending() {
        return this.attributes.is_attending;
    }


    /**
     * @param mixed
     */


    setIsAttending (IsAttending: any){
        this.attributes.is_attending = IsAttending;
    }

    getIsAttendingControl() {
        return this.attributes.is_attending_control;
    }

    /**
     * @param mixed
     */


    setIsAttendingControl (IsAttendingControl: any){
        this.attributes.is_attending_control = IsAttendingControl;
    }

    getAttendingDate() {
        return this.attributes.attending_date;
    }


    /**
     * @param mixed
     */


    setAttendingDate (AttendingDate: any){
        this.attributes.attending_date = AttendingDate;
    }



    getAlias() {
        return this.attributes.alias;
    }


    /**
     * @param mixed
     */


    setAlias (Alias: any){
        this.attributes.alias = Alias;
    }

    getRSVPDate() {
        return this.attributes.rsvp_date;
    }


    /**
     * @param mixed
     */


    setRSVPDate (RSVPDate: any){
        this.attributes.rsvp_date = RSVPDate;
    }



    getDuration() {
        return this.attributes.duration;
    }

    /**
     * @param mixed
     */


    setDuration (Duration: any){
        this.attributes.duration = Duration;
    }

    getCompany (){
        return this.attributes.company;
    }

    /**
     * @param mixed
     */

    setCompany (Company: any){
        this.attributes.company = Company;
    }




    getBusinessman (){
        return this.attributes.businessman;
    }

    /**
     * @param mixed
     */

    setBusinessman (Businessman: any){
        this.attributes.businessman = Businessman;
    }


    getAttendeePhone (){
        return this.attributes.attendee_phone;
    }

    /**
     * @param mixed
     */


    setAttendeePhone (AttendeePhone: any){
        this.attributes.attendee_phone = AttendeePhone;
    }

    getInteger() {
        return this.attributes.integer;
    }

    /**
     * @param mixed
     */


    setInteger (Integer: any){
        this.attributes.integer = Integer;
    }


    getDocumentTitle() {
        return this.attributes.document_title;
    }

    /**
     * @param mixed
     */


    setDocumentTitle (DocumentTitle: any){
        this.attributes.document_title = DocumentTitle;
    }

    getMailUserName() {
        return this.attributes.mail_username;
    }

    /**
     * @param mixed
     */


    setMailUserName (MailUserName: any){
        this.attributes.mail_username = MailUserName;
    }

    getMailPassword() {
        return this.attributes.mail_password;
    }

    /**
     * @param mixed
     */


    setMailPassword (MailPassword: any){
        this.attributes.mail_password = MailPassword;
    }

    getInvitationConfirmationControl() {
        return this.attributes.invitation_confirmation_control;
    }

    /**
     * @param mixed
     */


    setInvitationConfirmationControl (InvitationConfirmationControl: any){
        this.attributes.invitation_confirmation_control = InvitationConfirmationControl;
    }


    getLogoDataUrl (){
        return this.attributes.logo_data_url;
    }
    /**
     * @param mixed
     */
    setLogoDataUrl(LogoDataUrl: any){
        this.attributes.logo_data_url = LogoDataUrl;
    }

    getControlId (){
        return this.attributes.control_id;
    }
    /**
     * @param mixed
     */
    setControlId (ControlId: any){
        this.attributes.control_id = ControlId;
    }

    getPercentage() {
        return this.attributes.percentage;
    }

    /**
     * @param mixed
     */


    setPercentage (Percentage: any){
        this.attributes.percentage = Percentage;
    }


    getIsReceivingInvitationCard() {
        return this.attributes.is_receiving_invitation_card;
    }

    /**
     * @param mixed
     */


    setIsReceivingInvitationCard (IsReceivingInvitationCard: any){
        this.attributes.is_receiving_invitation_card = IsReceivingInvitationCard;
    }


    getFolder() {
        return this.attributes.folder;
    }

    /**
     * @param mixed
     */


    setFolder (Folder: any){
        this.attributes.folder = Folder;
    }

    getQrcode() {
        return this.attributes.qrcode;
    }

    /**
     * @param mixed
     */


    setQrcode (Qrcode: any){
        this.attributes.qrcode = Qrcode;
    }


    getInvoice() {
        return this.attributes.invoice;
    }

    /**
     * @param mixed
     */


    setInvoice (Invoice: any){
        this.attributes.invoice = Invoice;
    }

    getPaymentMethod() {
        return this.attributes.payment_method;
    }

    /**
     * @param mixed
     */


    setPaymentMethod (PaymentMethod: any){
        this.attributes.payment_method = PaymentMethod;
    }


    getFilename() {
        return this.attributes.filename;
    }

    /**
     * @param mixed
     */


    setFilename (Filename: any){
        this.attributes.filename = Filename;
    }

    getJobPosition (){
        return this.attributes.job_position;
    }

    /**
     * @param mixed
     */
    setJobPosition (JobPosition: any){
        this.attributes.job_position = JobPosition;
    }

    getApi (){
        return this.attributes.api;
    }


    setApi (Api: any){
        this.attributes.api = Api;
    }

    getContactPersonTitle (){
        return this.attributes.contact_person_title;
    }

    /**
     * @param mixed
     */

    setContactPersonTitle (ContactPersonTitle: any){
        this.attributes.contact_person_title = ContactPersonTitle;
    }


}
