import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  isDialogOpen: any = false;
  constructor() {

  }
}
