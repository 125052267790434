import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormService} from '../../form.service';
import {DialogCampaignsComponent} from '../../../campaigns/dialog/dialog-campaigns.component';
import {DialogLockQuestionnairesComponent} from '../../../questions/dialog-lock-questionnaires/dialog-lock-questionnaires.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatMenuTrigger} from "@angular/material/menu";
import {SettingsQuestionsComponent} from "../../questions/settings/settings-questions.component";
import {Parameters} from "../../../parameters";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-bottom-button-controls',
  templateUrl: './bottom-button-controls.component.html',
  styleUrls: ['./bottom-button-controls.component.scss']
})
export class BottomButtonControlsComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingQuestion: any;
  incomingQuestionSettings: any;
  questions: any;
  deletedQuestions: any;
  checked: any = false;
  isMutedChecked: any = false;
  strings: any;
  constructor(public metthods: FormService,
              private service: AppService,
              private dialog: MatDialog) {
    this.strings = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.questions = metthods.questions;
    this.deletedQuestions = metthods.deletedQuestions;
    this.contextMenu = MatMenuTrigger;
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
    this.checked = parseFloat(this.question.required) !== 0;
    this.isMutedChecked = !this.service.isNull(this.incomingQuestionSettings.getIsMuted()) && parseFloat(this.incomingQuestionSettings.getIsMuted()) === 1;
  }

  handle(option: any){
  }


  onContextMenu(event: MouseEvent, campany: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': campany };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  settings(){
    this.dialog.open(SettingsQuestionsComponent, {
      data  : {question: this.question},
      width : ' 100%',
      height: '90%',
    });
  }

  upstairs(){
    this.questions.splice(this.position, 1);
    this.questions.splice(this.position - 1, 0, this.question);
    this.metthods.positions();
  }

  onToggleChange(event: any, checked){
    this.question.required = event.checked === false ? 0 : 1;
  }

  onMuted(event: any, checked: any){
    this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
    this.incomingQuestionSettings.setIsMuted(event.checked === false ? 0 : 1);
  }


  lock(){
    this.dialog.open(DialogLockQuestionnairesComponent, {data  : {question: this.question}, width : '55%', height: '100%', });
  }


  addControl(){
    this.metthods.addQuestion(this.questions, this.question.control, this.position);
  }

  downstairs(){
    this.questions.splice(this.position, 1);
    this.questions.splice(this.position + 1, 0, this.question);
    this.metthods.positions();

  }

  close(){
    this.questions.splice(this.position, 1);
    this.metthods.positions();
    if(this.question.hasOwnProperty('id')) this.deletedQuestions.push(this.question);
  }

}
