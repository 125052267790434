import { Component, OnInit,Input, OnChanges, SimpleChanges } from '@angular/core';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";
import {ResponseService} from "../../../responses/data/response.service";
import {FormService} from "../../../form.service";

@Component({
  selector: 'app-view-submit-control',
  templateUrl: './view-submit-control.component.html',
  styleUrls: ['./view-submit-control.component.scss']
})
export class ViewSubmitControlComponent implements OnInit, OnChanges {

  @Input() question: any;
  @Input() position: any;
  incomingResponse: any;
  incomingForm: any;
  incomingFormSettings: any;
  constructor(private broadcastChannel: BroadcastChannelService,
              private responseService: ResponseService,
              public formService: FormService,
              public  service: AppService) {
    this.incomingForm = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingForm.setAttributes(this.formService.selected);
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.button.next.hide});
    this.incomingResponse.setAttributes(this.responseService.selected);
    this.incomingForm.setAttributes(Object.assign({}, this.formService.getSelected()));
  }

  ngSubmit(){
    this.broadcastChannel.emitNavChangeEvent({action: 'ngSubmit'});
  }
}
